import React, { useState } from "react";
import {Box,CssBaseline,Button,Card,CardContent,Grid,Typography,Container,CardMedia} from "@material-ui/core";
import usersvg from "../../static/images/user.svg";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import { useHistory } from "react-router-dom"; 
import { HomeCards } from '../../common/components/placeholder'; 
import {GeneralLogo,PaymentLogo,TeamLogo} from  '../../static/svgfile';
import usePersistedState from "../../usePersistedState";
/** Admin Home/Dashboard Page
 *  Page contain link to 
 *  TENANT MANAGEMENT module
 *  PROPERTY MANAGEMENT module
 *  VIEW PROPERTY module
 */

export  function PropertySettings(props) {
  let history = useHistory();
  const [selectedProperty] = usePersistedState('selectedProperty', {});  
  return (
    <div className="home-root">       
      <Grid container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="property-setting-paper">
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <Grid container spacing={1} style={{ paddingBottom: "56px" }} onClick={e => {
                  history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                }} >
                <Grid item>
                  <Box mb={5} className="property-settings-return">
                  <Button >
                    <div className="addlease-top-flex-container">
                      <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                    </div>
                  </Button>
                </Box>
                </Grid> 
                <Grid item xs={12} className="property-settings-header-2">

                  <Typography
                    component="h1"
                    className="home-headerOne"
                    variant="h1"
                  >
                    Property Settings
              </Typography> 
                </Grid>
                <Grid item  xs={12}>
                  <Typography
                    className="home-headerfive"
                    variant="subtitle1"
                  >
                    Manage property products for your Dashboard.
                  </Typography>
                </Grid>
              </Grid>
              </div>
              <Grid container direction="row" spacing={1} className= {"property-settings-row"}>
                <Grid item xs={12} sm={6} md={4} lg={4} className= {"property-settings-col"}>
                  {usersvg ? <Card className="home-card property-settings-card-h100">
                    <CardActionArea style={{ display: "flex" }} onClick={() => {
                      
                     history.push({pathname:`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings/general`, props:{ isEditing: true,rowEditData:{}}});   
                    }} >
                      <CardMedia 
                        title="Live from space album cover"
                      >
                         <GeneralLogo />
                      </CardMedia>
                      <div className="home-details">
                        <CardContent className="home-content">
                          <Typography component="h5" variant="h5">
                           General
                  </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                          View and update your property details
                  </Typography>
                        </CardContent> 
                      </div>
                    </CardActionArea>
                  </Card> : <HomeCards />
                  }
                </Grid>
                <Grid item  xs={12} sm={6} md={4} lg={4} className= {"property-settings-col"}>
                  {usersvg ? <Card className="home-card property-settings-card-h100">
                    <CardActionArea style={{ display: "flex" }} onClick={() => {
                      history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings/payments`,props:{ isEditing: true,rowEditData:{}}});
                    }} >
                      <CardMedia 
                        title="PROPERTY Payments"
                        >
                        <PaymentLogo />
                     </CardMedia>
                      <div className="home-details">
                        <CardContent className="home-content">
                          <Typography component="h5" variant="h5">
                           Payments
                  </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                          Enable and manage your property’s payment providers
                  </Typography>
                        </CardContent> 
                      </div>
                    </CardActionArea>
                  </Card> : <HomeCards />}

                </Grid>
                <Grid item  xs={12} sm={6} md={4} lg={4} className= {"property-settings-col"}>
                  {usersvg ? <Card className="home-card property-settings-card-h100">
                    <CardActionArea style={{ display: "flex" }} onClick={() => { 
                      history.push({ pathname:`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings/teams`,props:{ isEditing: true,rowEditData:{}} });
                    }} >
                      <CardMedia 
                        title="Live from space album cover"
                        >
                        <TeamLogo />
                     </CardMedia>
                      <div className="home-details">
                        <CardContent className="home-content">
                          <Typography component="h5" variant="h5">
                           Team
                  </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                          Add and remove staff who will be managing the property
                  </Typography>
                        </CardContent>                       
                      </div>
                    </CardActionArea>
                  </Card> : <HomeCards />}
                </Grid>
              </Grid>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}