import React, { Component } from "react";
import clsx from 'clsx';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { API, Auth } from 'aws-amplify';
import LoaderButton from "./LoaderButton"; 
import { openSnackbar } from '../components/Notifier';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import md5 from 'crypto-js';
import Policy from "../lib/Policy"
import PoweredBy from '../lib/PoweredBy';
import { S3Image } from 'aws-amplify-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import awsdev from '../aws-dev';
import awsdev2 from '../aws-dev2';
const MapData =  (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];

const fontScale = window.devicePixelRatio;
//const [isLoading, setIsLoading] = useState(false);


const useStyles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),

  },
  miButtonroot: {
    borderRadius: "0px",
    backgroundColor: "yellow",
    color: "black"
  },
  muiButtoncontained: {
    boxShadow: "none"
  },
  myImage: {
    width: "100%"
  },
  pageSecondHeader: {
    fontSize: 17 / fontScale,
    textAlign: "center",
    fontWeight: 401
  },
  headerGrid: {
    width: "100%"
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  }
});


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    const search = this.props.location.search;
    const email = new URLSearchParams(search).get("email");
    console.log('i am inside forgot password');
    this.state = {
      code: "",
      email: email?email:"",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      isLoading: false,
      setIsLoading: (value) => {
        this.state.isLoading = value;
      },
      showPassword: false
    };

  }


  handleClickShowPassword = () => {
    
    //this.state.showPassword = !this.state.showPassword;
    this.setState({ showPassword: !this.state.showPassword });
    
  };

  handleMouseDownPassword = e => {
    e.preventDefault();
  };

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }




  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  //RESEND the email invitation
  reinvite=async props =>{
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/verify/reinvite";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {
          email: this.state.email
        }
      };
      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
   }

  //FORGOT PASSWORD COGNITO FUNTION
  forgotPassword = async (e) => {
    this.state.setIsLoading(true); 
    e.preventDefault();
    this.setState({ isSendingCode: true });
    await Auth.forgotPassword(
      this.state.email
    )
      .then(response => {        
        this.state.setIsLoading(false);
        this.setState({ codeSent: true });
        this.setState({ password: "" });
      })
      .catch(async err => {
        //alert(e.message);  
        console.log('forgot err',err);  
        if(err.code==='UserNotFoundException')
        {
            openSnackbar({ message: `Email doesn't exist in the system`, variant: "error" });
        }
        else if(err.code==='NotAuthorizedException')
        {
            if(err.message==='User password cannot be reset in the current state.')
            {
              await this.reinvite();
              openSnackbar({ message: `Your account is currently not active. We have resent the email confirmation.
               Please confirm your email and setup a password to proceed`, variant: "error" });
            }
            else
            {
              openSnackbar({ message: err.message, variant: "error" });
            }
        }
        else
        {
          openSnackbar({ message: err.message, variant: "error" });
        }       
        this.state.setIsLoading(false);
        this.setState({ isSendingCode: false });
      });
  }

  //CONFIRMATION PASSWORD
  confirmPassword = async (e) => {    
    this.state.setIsLoading(true);
    e.preventDefault();
    this.setState({ isConfirming: true });
    var pass = md5.MD5(this.state.password).toString();
    await Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.code,
      pass
    )
      .then(response => {
        
        this.state.setIsLoading(false);
        this.setState({ confirmed: true });
        openSnackbar({ message: "Your password has been reset.!!", variant: "success" });
      })
      .catch(err => {
        //alert(e.message);
        openSnackbar({ message: err.message, variant: "error" });
        this.state.setIsLoading(false);
        this.setState({ isConfirming: false });
      });
  }

  //IF CODESEND IS FALSE THEN EMAIL ASKING PAGE WILL RENDERED
  renderRequestCodeForm() {
    const { classes } = this.props;    
    return (
      <div width="100%">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '85vh' }}
        >

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Container component="main" >
              <CssBaseline />
              <div className="forgot-password-paper">              
                <Grid container justify="center" alignItems="center" spacing={1}>
                  <Grid item>
                    {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                      <Typography className='managment-header' component="h2" variant="h2" >
                        {MapData.management_name}
                      </Typography>}
                  </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className="verify-user-header" component="h5" variant="h5">
                FORGOT YOUR PASSWORD
                </Typography>
              </Grid>
            </Grid> 
                <form className="forgot-password-form" noValidate onSubmit={this.forgotPassword}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="fname"
                        type="text"
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        value={this.state.email}
                        onChange={this.onChange}
                        autoFocus
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={12}  className="forgot-password-submit">
                      <LoaderButton
                        block="true"
                        tuseStateype="submit"
                        size="large" 
                        isLoading={this.state.isLoading}
                        disabled={!this.validateCodeForm()}
                      >
                        SUBMIT
                        </LoaderButton>
                    </Grid>

                    <Grid item>
                      <MLink color="textSecondary" underline={"always"} component={Link} to="/">
                        <Typography color="textSecondary" gutterBottom={true} variant="body2">
                          &#8249; {"BACK"}
                        </Typography>
                      </MLink>
                    </Grid>
                  </Grid>
                </form>
              </div>

            </Container>


          </Grid></Grid><PoweredBy /> <Policy class={"policy-cls"} />   </div>
    )
  }

  //RENDERED FOR COLLECTING NEW PASSWORD
  renderConfirmationForm() {
    const { classes } = this.props;
    return (
      <div width="100%">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '85vh' }}
        >

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Container component="main" >
              <CssBaseline />
              <div className="forgot-password-paper">
                <Grid container justify="center" alignItems="center" spacing={1}>
                  <Grid item>
                    {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                      <Typography className='managment-header' component="h2" variant="h2" >
                        {MapData.management_name}
                      </Typography>}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className="forgot-pass-header">
                      FORGOT YOUR PASSWORD
                    </Typography>
                  </Grid>
                </Grid>
                <form className="forgot-password-form" noValidate onSubmit={this.confirmPassword}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="fname"
                        name="code"
                        variant="outlined"
                        required
                        value=""
                        fullWidth
                        id="code"
                        label="Verification Code"
                        value={this.state.code}
                        onChange={this.onChange}
                        autoFocus
                      />
                    </Grid>
                  </Grid>

                  <FormControl fullWidth required className={clsx(classes.margin)} variant="outlined">
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <OutlinedInput
                      id="password"

                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={80}
                    />
                  </FormControl>


                  <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={12}  className="forgot-password-submit">
                      <LoaderButton
                        block
                        type="submit"
                        size="large" 
                        isLoading={this.state.isLoading}
                        disabled={!this.validateCodeForm()}
                      >
                        SUBMIT
                        </LoaderButton>
                    </Grid>


                    <Grid item>
                      <MLink color="textSecondary" underline={"always"} component={Link} to="/">
                        <Typography color="textSecondary" gutterBottom={true} variant="body2">
                          BACK
                            </Typography>
                      </MLink>
                    </Grid>
                  </Grid>
                </form>
              </div>

            </Container>

          </Grid></Grid><PoweredBy />  <Policy class={"policy-cls"} />   </div>
    );
  }

  //RENDER SUCCESS PAGE
  renderSuccessMessage() {
    const { classes } = this.props;
    return (
      <div width="100%">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '85vh' }}
        >

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Container component="main"  >
              <CssBaseline />
              <div className="forgot-password-paper">
                <Grid container justify="center" alignItems="center"   direction="column" spacing={1}>
                  <Grid item>
                    {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                      <Typography className='managment-header' component="h2" variant="h2" >
                        {MapData.management_name}
                      </Typography>}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div  className={'forgot-user-icon'}>
                    <CheckCircleIcon style={{fill: '#0AE20A'  , fontSize:'54px'}} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className="forgot-user-header" component="h5" variant="h5">
                      Password set successfully
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className="forgot-user-body"  gutterBottom={true} variant="h5">
                      To continue, please click the login button below
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{ padding: "20px 0px 40px 0px" }}>
                      <LoaderButton
                        block="true"
                        type="submit"
                        size="large"
                        component={Link}
                        to="/"
                      >
                        LOGIN
                      </LoaderButton>
                    </div>
                
                  </Grid> 
                </Grid> 
              </div>

            </Container>
          </Grid></Grid> <PoweredBy/>  <Policy class={"policy-cls"} />   </div>);
  }

  //MAIN RENDER FUNCTION
  render() {
    return (
      <div className="forgot-password-reset-password">
        {!this.state.codeSent //ENTER EMAIL PAGE
          ? this.renderRequestCodeForm() //RENDER ENTER EMAIL PAGE
          : !this.state.confirmed //RECEIVED FROM EMAIL PAGE
            ? this.renderConfirmationForm() //GO TO CONFIRMATION PAGE
            : this.renderSuccessMessage()//GO TO SUCCESS PAGE
        }
      </div>);
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ForgotPassword);
