import React from "react";
import { Route } from "react-router-dom"; 
import Copyright from "../lib/Copyright";
import PaymentInfo from "../lib/securepayinfo";
import {MenuAppBar} from "../common/components/AppBar";
import usePersistedState from '../usePersistedState';
export default function AppliedRoute({ component: C, appProps, ...rest }) {  /* 
  const [myProperties, setMyProperities] = usePersistedState('myPropertes', []);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty',[]);  */
  
  /**
   * It will update drop-down on menu app bar
   * 
   * @param {*} propertyList list of properties to be shown on drop-down
   */
/*   async function handleChildClick(propertyList, selectedProperty) {
    console.log('handleChildClick in applied route working',propertyList);
    setMyProperities(propertyList);
    if (selectedProperty) setSelectedProperty(selectedProperty);
  } */

  return (
    { ...appProps.isAuthenticated && (rest.path==="/:property/manage" || rest.path=== "/:property/payment") ?
    <Route {...rest} render={props => <><MenuAppBar
      {...appProps}
      {...rest} 
      /* myProperties = {myProperties} */
     // selectedProperty = {selectedProperty}
      /* renderPortion={true} */
      /* item={[]}  */
    /><C {...props} {...appProps} /><PaymentInfo /></>} />:
    appProps.isAuthenticated && rest.path!=='/verify'?
    <Route {...rest} render={props => <><MenuAppBar
      {...appProps}
      {...rest} 
     /*  myProperties = {myProperties} */
    //  selectedProperty = {selectedProperty}
     /*  renderPortion={true} */
      /* item={[]}  */
    /><C {...props} {...appProps} /><Copyright {...props} {...appProps}  class= {"footer-wtbg"} /></>} />:
    <Route {...rest} render={props => <><C {...props} {...appProps} /><Copyright {...props} {...appProps}   class= {"footer-wtbg"} /></>} />
  }
    
  );
}

 