import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid"; 
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container"; 
import { API } from "aws-amplify"; 
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"; 
import CardActions from "@material-ui/core/CardActions";
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { openSnackbar } from '../Notifier';
import Divider from '@material-ui/core/Divider';
import { Hidden } from '@material-ui/core'; 
import { trackPromise } from 'react-promise-tracker';
import { Skeleton } from '@material-ui/lab';
import { areas } from '../../common/constants/areas';
import usePersistedState from "../../usePersistedState";
import usePersistedStateString from "../../usePersistedStateString";
import { MakePaymentRentCards } from '../../common/components/placeholder';  
import CardForm from './creditcardform';
import Button from '@material-ui/core/Button';
import BankForm from './bankaccountform';
const MapData = window['countryConfig']; 

const useStyles = makeStyles(theme =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: theme.spacing(8),
      flexDirection: "column",
      alignItems: "center",
      /*   minHeight: "600px", */
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    card: {
      display: "flex",
      /* padding: theme.spacing(2, 2,3, 2)  */
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      /*  width:"-moz-available" */
    },
    content: {
      flex: "1 0 auto"
    },
    cover: {
      width: "170px",
      height: "170px"
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },  
    cards: {
      display: "flex",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
    },
    rentCardClick: {
      display: "flex",
      width: "100%",
      background: "#3f51b52e",
      border: "1px solid #3f51b5",
      borderRadius: "5px",
      /*  opacity:"0.8", */
      boxShadow: "none",
    },
    rentCard: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px"
    },
    f: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px",
      boxShadow: "none"
    },
    rentCardSelect: {
      display: "flex",
      width: "100%",
      border: "1px solid #6200EE",
      borderRadius: "5px"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3)
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "36%",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    payImg: {
      width: "100%",
      height: "100%"
    },
    payCardCont: {
      padding: "8px"
    },
    main: {
      maxWidth: "100% !important"
    },
  })
);
export function MakePayment(props) {
  const classes = useStyles();
  let history = useHistory(); 
  console.log("### Renter Make Payment props", props);    
  const [isLoading, setIsLoading] = useState(false); 
  const [paymentSourceType,setPaymentSourceType]=useState('')
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [monthSelected, SetMonthSelected] = useState([]);
  const [carDetails, setCardDetails] = usePersistedState("carDetails", "");
  const [selctedData, setSelctedData] = usePersistedState('selctedData', []);
  const [expanded, setExpanded] = useState(false);
  const [cardSelected, setcardSelected] = useState({});
  const [transactionData] = useState({ TaxAmount: 0.0, Tax: 0.0, TrasnactionFee: 0.0, TotalAmount: 0.0, Misc: [], MiscAddition: 0.0 });
  const [isThankyou, setIsThankyou] = useState(false);  
  const [cardData, setCardData] = useState({
    MasterID: "",
    NameOnCard: "",
    BillingAddress: "",
    Country: "",
    PostalCode: "",
    CardNumber: "",
    CvvCid: "",
    ExpiryMonth: "",
    ExpiryYear: "",
    UserName: props.userSession.UserName,
    RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName 
  });
  const [bankData,setBankData]=useState({
    UserName: props.userSession.UserName,
    RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName,
    FirstName:"",
    LastName:"",
    AccountType:"",
    RoutingNumber:"",
    AccountNumber:""
  }); 
  const [rentReadyToPay, setRentReadyToPay] = useState([]);
  const [currentRentDetails, setCurrentRentDetails] = useState([]); // useState([]);  
  const [userCreditCards, setUserCreditCards] = usePersistedState('userCreditCards', []);
  const isRefresh = React.useRef(true); 
  const inputLabel = useRef(1);
  const [paymentMethod, setPaymentMethod] = useState("Saved Source");
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  useEffect(() => {
    let isSubscribed = true;
    const userFetch = async () => { 
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) {
        isRefresh.current = false; 
        setIsLoading(true); 
          getRentPeriod(selectedProperty.PropertyID, props.userSession)
            .then(async (data) => {
              await setCurrentRentDetails(data);
              if (data && data.hasOwnProperty("MiscFee")) {
                let miscAddition = 0.0, miscflag = false;
                data.MiscFee.forEach((each) => {
                  if (each.activeDatePlan === "oneTimePlan" && isInitialLoading) {
                    setIsInitialLoading(false);
                    miscflag = true;
                    if (each.appFeeType === "percentage") {
                      miscAddition += (Number(data.RentEachMonth) * Number(each.appFeeValue)) / 100;
                    } else {
                      miscAddition += Number(each.appFeeValue);
                    }
                  }
                })
                var trnx = transactionData.TotalAmount;
                Object.assign(transactionData, { TaxAmount: Number((transactionData.TaxAmount).toFixed(2)), Tax: selectedProperty.hasOwnProperty("Tax") ? parseFloat(selectedProperty.Tax) : 0.0, Misc: data.MiscFee });
                if (miscflag) {
                  Object.assign(transactionData, { MiscAddition: Number((miscAddition).toFixed(2)), TotalAmount: Number((trnx + miscAddition).toFixed(2)) });
                }
              }
            }).then(async () => {
              console.log("@@@@@@@@ transactionData", transactionData);
              var cardData = await getCardsData(props.userSession);
              let setcard=false,paymentType='';
              await setUserCreditCards(cardData);
              if (cardData && cardData.length > 0) {
                cardData.forEach(async element => {
                 if(element.Primary === "Yes")
                  {
                    await setSelctedData(element);
                    await setCardDetails(element.MasterID);
                    setcard=true;  
                   if (element.MasterType === "User Card")
                     paymentType = 'Stripe';
                   if (element.MasterType === "Bank Account")
                     paymentType = 'Plaid';
                  } 
                });
                if(!setcard)
                {
                  await setSelctedData(cardData[0]);
                  await setCardDetails(cardData[0].MasterID);
                  if (cardData[0].MasterType === "User Card")
                    paymentType = 'Stripe';
                  if (cardData[0].MasterType === "Bank Account")
                    paymentType = 'Plaid';
                } 
              }
              else {
               cardData = []; 
               paymentType= 'Stripe';
               await setPaymentMethod(prvCard => {return "Manual Card"});
               await setCardDetails(prvCard => {return "null"});
               await setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Card"} }); 
              }
              setPaymentSourceType(paymentType);
              if (selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee) { 
                var totalAmount = transactionData.TotalAmount;
                var taxPercentage = transactionData.Tax, taxAmount = 0.0, trasnactionFee = 0.0;
                taxAmount = totalAmount * (taxPercentage / 100);
                trasnactionFee = CalculateFee(totalAmount + taxAmount, paymentType);
                Object.assign(transactionData, { TrasnactionFee: Number(trasnactionFee.toFixed(2)) });
              }
            
            })
            .then(async ()=>{              
              await setIsLoading(false);
            });
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [selectedProperty]);
  const getCardsData = async (userSession) => {
    console.log("GET CARD DATA ", userSession);
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: ["Bank Account", "User Card"], CurrentUserID: userSession.UserName, userData: userSession,PropertyId:selectedProperty.PropertyID   },
        queryStringParameters: {
          module: "renter",
          op: "getCardData"
        }
      };

      API.post(apiName, path, myInit).then(response => {
        console.log("card response", response);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  const getRentPeriod = async (propertyId, userSession) => {  
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "Rent", renter: userSession.UserName, PropertyID: propertyId, userData: userSession },
        queryStringParameters: {
          module: "renter",
          op: "getRentPeriod"
        }
      };
      API.post(apiName, path, myInit).then(response => {
        console.log("card response", response); 
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  function getMonth(date) {
    let month = date.getMonth();
    return month < 10 ? '0' + month : '' + month;
  }
  function getTwoDigit(value) {
    return value < 10 ? '0' + value : '' + value;
  }
  /**
   * CALCULATE Stripe/Plaid transaction fee 
   * Stripe feee 2.9% + 0.3 per transaction
   * Plaid fee 0.8% per transaction
   * @param {*} amount 
   * @param {*} sourceType - Plaid/Stripe 
   */
  function CalculateFee(amount,sourceType) {
    let fee=0.0;
    console.log('source type ## ', sourceType);
    if (amount === 0.0) return 0.0;
    if(sourceType==='Stripe')
    {
      var tamount = parseFloat(amount);
      var total = ((tamount + 0.3) / (1 - (2.9 / 100)));
      fee = total - tamount;
    }
    if(sourceType==='Plaid')
    {
      var tamount = parseFloat(amount);
      var total = ((tamount) / (1 - (0.8 / 100)));
      fee = total - tamount;
    } 
    return parseFloat(fee.toFixed(2));
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
     * Handle Card selection, calculate transaction amount
     * @param {*} clicked 
     * @param {*} keyIndex 
     */
  function handleSelection(clicked, keyIndex) {
    Object.assign(cardSelected, {[keyIndex]: !cardSelected[keyIndex]});
    //setcardSelected(prv=>{ return { ...cardSelected, [keyIndex]: !cardSelected[keyIndex] }});
    console.log("## makeaPayment selctedData",selctedData);
    console.log("Selected Period ", clicked);
    console.log("Selected transactionData ", transactionData);
    console.log("cardSelected[keyIndex]", cardSelected[keyIndex]); 
    var totalAmount = transactionData.TotalAmount;
    var totalMiscAmount = transactionData.MiscAddition;
    var miscAmount = 0.0;
    var taxPercentage = transactionData.Tax, taxAmount = 0.0;
    var trasnactionFee = transactionData.TrasnactionFee;
    //CALCULATE MISC
    if (clicked.MonthName !== "SecurityDeposit") {
      var Misc = transactionData.Misc;
      Misc.forEach((eachMisc) => {
        if (eachMisc.activeDatePlan === "reccuringPlan") {
          let validFrom = new Date(eachMisc.validFrom);
          let validTo = new Date(eachMisc.validTo);
          console.log(Number(clicked.Year + "" + getTwoDigit(clicked.Month)), Number(validFrom.getFullYear() + "" + getMonth(validFrom)));
          console.log(Number(clicked.Year + "" + getTwoDigit(clicked.Month)), Number(validTo.getFullYear() + "" + getMonth(validTo)));
          if (
            (Number(clicked.Year + "" + getTwoDigit(clicked.Month)) >= Number(validFrom.getFullYear() + "" + getMonth(validFrom))) &&
            (Number(clicked.Year + "" + getTwoDigit(clicked.Month)) <= Number(validTo.getFullYear() + "" + getMonth(validTo)))
          ) {
            console.log("inside misc", eachMisc.appFeeType);
            miscAmount += (eachMisc.appFeeType !== "percentage" ? Number(eachMisc.appFeeValue) :
              (Number(clicked.Rent) * Number(eachMisc.appFeeValue)) / 100);
            console.log("ENTERED MISC AMOUNT : ", miscAmount)
          }
        }
      });
    }
    if (!cardSelected[keyIndex]) {
      const rentItems = monthSelected.filter(rItem => {
        console.log(rItem.RentMonth + "==" + clicked.RentMonth);
        return rItem.RentMonth !== clicked.RentMonth
      });
      SetMonthSelected(rentItems);
      totalAmount = totalAmount - clicked.Rent - miscAmount;
      totalMiscAmount -= miscAmount;
      taxAmount = totalAmount * (taxPercentage / 100);
      if (selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
        trasnactionFee = CalculateFee(totalAmount + taxAmount,paymentSourceType);
      Object.assign(transactionData, { TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TrasnactionFee: Number(trasnactionFee.toFixed(2)) });
      let temp = rentReadyToPay;
      temp.splice(rentReadyToPay.findIndex((f) => f.ID === clicked.ID), 1);
      setRentReadyToPay(temp);
    }
    else {
      var tmp = [...monthSelected, clicked];
      totalAmount = totalAmount + clicked.Rent + miscAmount;
      totalMiscAmount += miscAmount;
      taxAmount = totalAmount * (taxPercentage / 100);
      if (selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
        trasnactionFee = CalculateFee(totalAmount + taxAmount,paymentSourceType);
      Object.assign(monthSelected, tmp);
      Object.assign(transactionData, { TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TrasnactionFee: Number(trasnactionFee.toFixed(2)) })
      console.log("Card add", monthSelected);
      setRentReadyToPay(rentReadyToPay.concat([clicked]));
    }
  }
  function formChange(type,e,child) {
    console.log('from change ',e.name,e.value )  
    console.log("form type",type);  
    if(type==='card'  && e.name){ 
          setCardData(prvCard => { return {...prvCard,[e.name]: e.value} });
      }
    else if(type==='bank' && e.name)
    {
       setBankData(prvCard => { return {...prvCard, [e.name]: e.value} });         
    }
  } 
  function onChange(e, child) {
    console.log("onchange data",selctedData);
    console.log("before onchange", child.props.name);
    console.log("save card",e.target.value); 
    console.log("save card",e.target.name);
    let paymentType='';
    if (e.target.value === "null") { 
      setPaymentMethod("Manual Card");
      setCardDetails("null");
      setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Card"} });      
      console.log("onChange manual card", e);
      paymentType='Stripe';     
    }
    else if(e.target.value==="ach"){ 
      setPaymentMethod("Manual Bank");
      setCardDetails("ach");
      setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Bank"} });   
      console.log("onChange manual bank", e);
      setPaymentSourceType('Plaid'); 
      paymentType='Plaid';
    }
    else if (e.target.value !== "null" && e.target.value !== "ach" && e.target.name === "CardDetails") {
      console.log("save card",e.target.value);  
      setPaymentMethod("Saved Source")
      setSelctedData(prvCard => { return  child.props.name });  
      setCardDetails(prvCard => { return e.target.value });   
      if( child.props.name.MasterType==="User Card")
          paymentType='Stripe';
      if( child.props.name.MasterType==="Bank Account")
          paymentType='Plaid';
    }
    else {
      setCardDetails(prvCard => { return "" });      
      setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    setPaymentSourceType(paymentType);
    if (selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee) { 
      var totalAmount = transactionData.TotalAmount;
      var taxPercentage = transactionData.Tax, taxAmount = 0.0,trasnactionFee=0.0;
      taxAmount = totalAmount * (taxPercentage / 100);
      trasnactionFee = CalculateFee(totalAmount + taxAmount,paymentType);
      Object.assign(transactionData, {TrasnactionFee: Number(trasnactionFee.toFixed(2)) });
    }
  }
  function validatePaymetButton() {
    return (rentReadyToPay.length > 0 && selctedData.hasOwnProperty("MasterID"));
  }

  function makeaPayment() {
    if (isLoading) {
      return;
    }
    console.log("## makeaPayment selctedData",selctedData);
    setIsLoading(true);
    let bodyOb = {};
    bodyOb.TransactionData =transactionData;//{ ...transactionData,TrasnactionFee: 0.0};
    bodyOb.RentData = rentReadyToPay;
    bodyOb.PropertyID = selectedProperty.PropertyID;
    bodyOb.PropertyName = selectedProperty.PropertyName;
    bodyOb.BankData=bankData;
    bodyOb.CardData = paymentMethod==="Manual Card"? cardData : selctedData;
    bodyOb.CurrentUserID = props.userSession.UserName;
    bodyOb.CurrentUserName = props.userSession.FirstName + " " + props.userSession.LastName;
    bodyOb.CurrentUserEmail = props.userSession.Email;
    bodyOb.CurrentRentMaster = props.userSession.currentRentDetails;
    bodyOb.Unit = currentRentDetails.Unit;
    bodyOb.RentMasterID = currentRentDetails.RentMasterID;
    bodyOb.userData = props.userSession;
    bodyOb.PaymentMethod = paymentMethod;
    bodyOb.IncludeStripeFee=selectedProperty.IncludeStripeFee;
    let apiName = "rentapi";
    let path = "/modules";

    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: bodyOb,
      queryStringParameters: {
        module: "renter",
        op: "saveTransaction"
      }
    };
    API.post(apiName, path, myInit).then(response => {
      if (response.success) {
        /* After payment customer Id will get it from server,  */
        /* if(localStorage.getItem("renterProperty")!=="" && localStorage.getItem("renterProperty")!=="{}")
        {
          var property=JSON.parse(localStorage.getItem("renterProperty")); 
          localStorage.setItem("renterProperty",JSON.stringify(property));
        }  */
        setIsLoading(false);
        openSnackbar({ message: response.message, variant: "success" });
        setIsThankyou(true);
      }
      else {
        setIsLoading(false);
        openSnackbar({ message: response.message, variant: "error" });
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: "error" });
    });
  }
  return (
    <div className={"home-root"}> 
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <div className="makepayment-thakyou-div">
              {isThankyou ?
                <div>
                  <Grid container justify="center" direction="row" spacing={1}>
                    <Grid item lg={2} md={2} implementation="css" smDown component={Hidden} />
                    <Grid item lg={8} md={8}>
                      <Grid container justify="center" direction="column" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            component="h1"
                            className="renter-headerOne"
                            variant="h1"
                          >
                            Thank You
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="renter-headerFive"
                            component="h2"
                            variant="h2"
                          >
                            {"Your total payment of $"}{Number((transactionData.TrasnactionFee + transactionData.TotalAmount + (transactionData.TotalAmount * (transactionData.Tax / 100))).toFixed(2))} {" is recieved for "} {selectedProperty.PropertyName}{"!"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="renter-transpage-fee">
                          <Typography className="renter-headerFive"
                            component="h5"
                            variant="h5"
                          >
                            Here is the breakdown of your transaction:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="renter-transpage-fee">
                          <Grid container direction="column" spacing={2}>
                            {rentReadyToPay.map((each, keyIndex) => {
                              return (
                                <Grid item key={keyIndex} xs={12}>
                                  <Typography className="renter-thankYouHFour"
                                    component="h6"
                                    variant="h6"
                                  >
                                    {"$"}{each.Rent}{" - "} {each.RentMonth}
                                  </Typography>
                                </Grid>
                              )
                            })}
                            {
                              transactionData.Misc.length > 0 && <Grid item xs={12}>
                                {transactionData.Misc.map((eachMisc, keyIndex) => {
                                  console.log("LOOPINGMISC", eachMisc);
                                  return (
                                    <Typography key={keyIndex} className="renter-thankYouHFour"
                                      component="h6"
                                      variant="h6"
                                    >
                                      {"$"}{transactionData.MiscAddition}{" - "} {eachMisc.appfeeName} {eachMisc.appFeeType !== "percentage" ? '' : ' (' + eachMisc.appFeeValue + '%)'}
                                    </Typography>
                                  )
                                })
                                }  </Grid>
                            }
                            {
                              transactionData.Tax > 0.0 && <Grid item xs={12}>
                                <Typography className="renter-thankYouHFour"
                                  component="h6"
                                  variant="h6"
                                >
                                  {"$"}{Number((transactionData.TotalAmount * (transactionData.Tax / 100)).toFixed(2))}{" - "} {"Taxes ("}{transactionData.Tax}{"%)"}
                                </Typography>
                              </Grid>
                            }
                            {
                              transactionData.TrasnactionFee > 0.0 && <Grid item xs={12}>
                                <Typography className="renter-thankYouHFour"
                                  component="h6"
                                  variant="h6"
                                >
                                  {"$"}{transactionData.TrasnactionFee}{" - "} {"Convenience Fee"}
                                </Typography>
                              </Grid>
                            }
                          </Grid>

                        </Grid>
                        <Grid item style={{ width: "100%", paddingTop: "25px" }} xs={12}>
                          <Divider light />
                        </Grid>
                        <Grid item xs={12}>
                          <LoaderButton
                            block="true"
                            style={{ backgroundColor: 'transparent', padding: "0px" }}
                            size="large"
                            onClick={() => {
                              history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                            }}>
                            &#8249; {" RETURN TO DASHBOARD"}
                          </LoaderButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={2} md={2} implementation="css" smDown component={Hidden} />
                  </Grid>

                </div> :
                <div >
                  <div style={{ padding: "0px 0px 40px 0px" }}>

                    <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                      history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                    }}>
                      <Grid item>
                        <Button>
                          <div className="addlease-top-flex-container">
                            <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                          </div>
                        </Button>
                      </Grid>
                    </Grid>


                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        {!isLoading &&
                          <Typography
                            component="h1"
                            className={"renter-headerOne"}
                            variant="h1"
                          >
                            Make A Payment
                          </Typography>
                        }
                        {isLoading && <Skeleton variant="rect" width={1051} height={69} />}
                      </Grid>
                      <Grid item xs={12}>
                        {!isLoading &&
                          <Typography
                            className="renter-headerfour-up"
                            component="subtitle1"
                            variant="subtitle1"
                          >
                            Outstanding payment details are given below. Make the payment with a credit card. We accept all major credit cards.
                            Your payment through credit card will be processed immediately and you will receive a confirmation mail.
                          </Typography>
                        }
                        {isLoading && <Skeleton variant="text" width={1664} height={32} />}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Card style={{ border: "none", boxShadow: "none", backgroundColor: "#F8F8F8" }}>
                        {((currentRentDetails && currentRentDetails.RentPeriods && currentRentDetails.RentPeriods.length > 0) || isLoading) ?
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {!isLoading &&
                                  <Typography
                                    component="h2"
                                    className={"renter-headerFive"}
                                    variant="h2"
                                  >
                                    Outstanding Payment
                                  </Typography>
                                }
                                {isLoading && <Skeleton variant="rect" width={386} height={47} />}

                              </Grid>
                              <Grid item xs={12}
                                className={isLoading ? "skelton-padding-0008" : ""}
                              >
                                {!isLoading &&
                                  <Typography
                                    className={"renter-headerfour-up"}
                                    component="subtitle1"
                                    variant="subtitle1"
                                  >
                                    Choose which month you will like to make the payment towards.
                                  </Typography>
                                }
                                {isLoading && <Skeleton variant="text" width={1664} height={42} />}
                              </Grid>
                            (
                           <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {!isLoading && currentRentDetails.RentPeriods.map((e, keyIndex) => {
                                    if (keyIndex <= 2) {
                                      return (
                                        <Grid key={keyIndex} item xs={4}>
                                          <Card className={cardSelected[keyIndex] ? classes.rentCardClick : classes.rentCard}>
                                            <CardActionArea onClick={() => { handleSelection(e, keyIndex) }}>

                                              <CardContent
                                                className={classes.payCardCont}
                                              >
                                                <div
                                                  style={{
                                                    float: "left",
                                                    padding: "6px",
                                                  }}
                                                >
                                                  {cardSelected[keyIndex] ? <div style={{ width: "100%" }}> <CheckCircleIcon style={{ right: "6px", top: "6px", position: "absolute" }} color={"primary"} /></div> : null}
                                                  <img width="61px" alt='' src={'../' + e.MonthName + '.svg'} style={{paddingTop: "13%"}}/>
                                                </div>

                                                {/* <div
                                                  style={{
                                                    paddingTop: "8px",
                                                    paddingRight: "8px",
                                                  }}
                                                >
                                                  <Typography component="h6" variant="h6" color="textSecondary">
                                                  ${e.Rent}
                                                  </Typography>

                                                </div> */}
                                                <div
                                                  style={{
                                                    padding: "3px 3px 6px 2%",
                                                    float: "left",
                                                  }}
                                                >
                                                  <Typography component="h6" variant="h6" color="textSecondary">
                                                  ${e.Rent}
                                                  </Typography>

                                                  <Typography
                                                    component="h4" variant="h4"
                                                    className="renter-rentamount-date"
                                                  >
                                                    {e.RentMonth.split(" ")[0].toUpperCase()}<br /> {e.Year ? e.Year : <br />}
                                                  </Typography>
                                                </div>
                                              </CardContent>

                                            </CardActionArea>
                                          </Card>
                                        </Grid>
                                      );
                                    }
                                    else {
                                      return null;
                                    }

                                  })}

                                  {isLoading && [1, 2, 3].map((val) => {
                                    return <MakePaymentRentCards key={val} />
                                  })
                                  }

                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                  <Grid container spacing={3}>
                                    {!isLoading && currentRentDetails.RentPeriods.map((e, keyIndex) => {
                                      if (keyIndex > 2) {
                                        return (

                                          <Grid key={keyIndex} item xs={4}>
                                            <Card className={cardSelected[keyIndex] ? classes.rentCardClick : classes.rentCard}>
                                              <CardActionArea onClick={() => { handleSelection(e, keyIndex) }} >
                                                <CardContent
                                                  className={classes.payCardCont}
                                                >
                                                  <div
                                                    style={{
                                                      /*   position: "absolute",
                                                        top: "13px",
                                                        right: "158px" */
                                                      float: "left",
                                                      padding: "6px"
                                                    }}
                                                  >
                                                    {cardSelected[keyIndex] ? <div style={{ width: "100%" }}> <CheckCircleIcon style={{ right: "6px", top: "6px", position: "absolute" }} color={"primary"} /></div> : null}
                                                    <img width="61px" alt='' src={'../' + e.MonthName + '.svg'} style={{paddingTop: "13%"}} />
                                                  </div>
                                                  {/* <div
                                                    style={{
                                                      paddingTop: "8px",
                                                      paddingRight: "8px",
                                                    }}
                                                  >
                                                    <Typography component="h6" variant="h6" color="textSecondary">
                                                      {e.RentMonth}
                                                    </Typography>
                                                  </div> */}
                                                  <div
                                                    style={{
                                                      padding: "3px 3px 6px 2%",
                                                      float: "left",
                                                    }}
                                                  >

                                                    <Typography component="h6" variant="h6" color="textSecondary">
                                                      ${e.Rent}
                                                    </Typography>

                                                    <Typography
                                                    component="h4" variant="h4"
                                                    className="renter-rentamount-date"
                                                    >
                                                      {e.RentMonth.split(" ")[0].toUpperCase()}<br /> {e.Year ? e.Year : <br />}
                                                    </Typography>
                                                  </div>
                                                </CardContent>
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "22px",
                                                    right: "10px"
                                                  }}
                                                ></div>
                                              </CardActionArea>
                                            </Card>

                                          </Grid>
                                        );

                                      }
                                      else {
                                        return null;
                                      }

                                    })}
                                  </Grid>
                                </Collapse>
                              </Grid>
                              )
                              {!isLoading && <Grid item xs={12}>
                                <CardActions style={{ cursor: "pointer", paddingBottom: "0px", borderBottom: "1px solid #e7e7e7" }} disableSpacing onClick={handleExpandClick}>
                                  <IconButton
                                    style={{}}
                                    className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded
                                    })}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                  <Typography
                                    className="renter-headerSeven"
                                    component="h6"
                                    variant="h6"
                                  >
                                    {expanded ? "SHOW LESS" : "SHOW ALL"}
                                  </Typography>
                                </CardActions>
                              </Grid>
                              }
                                {/* //hghg */}
                                {/*<Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {
                                    !isLoading && transactionData.Misc.length && <Grid item xs={12}>
                                      {

                                        transactionData.Misc.map((eachMisc, keyIndex) => {
                                          return (<Typography key={keyIndex}
                                            className="renter-headerfour-up"
                                            component="h5"
                                            variant="h5"
                                          >
                                            {eachMisc.appfeeName}{eachMisc.appFeeType !== "percentage" ? ': $ ' + transactionData.MiscAddition : ' (' + eachMisc.appFeeValue + '%): $' + transactionData.MiscAddition}
                                          </Typography>)
                                        })
                                      }

                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.TrasnactionFee > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        className="renter-headerfour-up"
                                        component="h5"
                                        variant="h5"
                                      >
                                        {"Convenience Fee: $"}{transactionData.TrasnactionFee}
                                      </Typography>
                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.Tax > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        className="renter-headerfour-up"
                                        component="h5"
                                        variant="h5"
                                      >
                                        {'Taxes (' + transactionData.Tax + '%): $' + Number((transactionData.TotalAmount * (transactionData.Tax / 100)).toFixed(2))}
                                      </Typography>
                                    </Grid>
                                  }
                                  <Grid item xs={12}>
                                    {!isLoading &&
                                      <Typography
                                        component="h2"
                                        className="renter-headerFive"
                                        variant="h2"
                                      >
                                        Total Payment:  ${Number((transactionData.TotalAmount + transactionData.TrasnactionFee + (transactionData.TotalAmount * (transactionData.Tax / 100))).toFixed(2))}
                                      </Typography>
                                    }
                                    {isLoading && <Skeleton variant="rect" width={455} height={50} />}
                                  </Grid>
                                  {props.isMobile ? null : <Grid item xs={12}>
                                    {!isLoading &&
                                      <LoaderCLickButton
                                        style={{ fontSize: "30px" }}
                                        isLoading={isLoading}
                                        size="large" 
                                        disabled={!validatePaymetButton()}
                                        onClick={() => { makeaPayment() }}
                                      >
                                        MAKE PAYMENT
                                      </LoaderCLickButton>
                                    }
                                    {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                      <Skeleton variant="rect" width={295} height={63} />
                                    </Grid>
                                    }
                                  </Grid>}
                                </Grid>
                              </Grid> */}

                            </Grid>

                          </> : <> <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                component="h3"
                                className={"renter-headerFive fontweight-401"}
                                variant="h3"
                              >
                                Outstanding Payment
                          </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                  component="subtitle1"
                                  variant="subtitle1"
                                className={"renter-headerFive fontweight-401"} 
                              >
                                No data found
                          </Typography>
                            </Grid>
                          </Grid> </>
                        }
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {!isLoading &&
                            <Typography
                              ycomponent="h2"
                              className={"renter-headerFive"}
                              variant="h2"
                            >
                              Payment Method
                            </Typography>
                          }
                          {isLoading && <Skeleton variant="rect" width={386} height={47} />}
                        </Grid>
                        <Grid item xs={12}
                          className={isLoading ? "skelton-padding-0008" : ""}
                        >
                          {!isLoading &&
                            <Typography
                              className={"renter-headerfour-up"}
                              component="subtitle1"
                              variant="subtitle1"
                            >
                             Choose saved payment method from the list or enter a new card/account detail manually 
                              to initiate the payment.
                            </Typography>
                          }

                          {isLoading && <Skeleton variant="text" width={1664} height={42} />}
                        </Grid>
                        <Grid item xs={12}>
                          {!isLoading &&
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel ref={inputLabel} id="select-state-label">
                                SAVED CARDS
                        </InputLabel>
                              <Select
                                labelid="select-Card-label"
                                id="Card-select-outlined"
                                fullWidth
                                name="CardDetails"
                                variant="outlined"
                                label="SAVED CARDS"
                                value={carDetails}
                                labelWidth={110}
                                onChange={(ed, child) => {
                                  onChange(ed, child);
                                }}
                              >
                                <MenuItem value="null">
                                  <em>Enter Credit Card Details Manually</em>
                                </MenuItem> 
                                {/* Uncomment for ach release*/ /*   <MenuItem value="ach">
                                  <em>Enter Bank Account Details Manually</em>
                                </MenuItem>   */}
                                {userCreditCards.map((e, keyIndex) => { 
                                  return (
                                    <MenuItem
                                      key={keyIndex+2}
                                      name={e}
                                      value={e.MasterID}
                                    >
                                      {e.MasterType==="User Card" ? `${e.DisplayCardNo} ${"exp:"}${e.ExpiryMonth}/${e.ExpiryYear}${" "}${e.Primary === "Yes" ? "(Primary)" : ""}` : `${e.BankAccount}${" "}${e.Primary === "Yes" ? "(Primary)" : ""}`}
                                    </MenuItem>
                                  );
                                })} 
                              </Select>
                            </FormControl>
                          }
                          {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Skeleton variant="rect" width={738} height={52} />
                          </Grid>
                          }

                        </Grid>
                        {paymentMethod==="Manual Card" ?
                        <CardForm {...props} formChange={formChange}/>
                        : paymentMethod==="Manual Bank" ?
                        <BankForm {...props} formChange={formChange}/>   
                        :<Grid item xs={12}  >
                            {!isLoading &&
                              <LoaderCLickButton
                                style={{ fontSize: "20px" }}
                                isLoading={isLoading}
                                size="large"
                                onClick={() => {
                                  history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/manage`, props: { fromRenter: true } });
                                }}
                              >
                                UPDATE CARDS
                                </LoaderCLickButton>
                            }
                            {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Skeleton variant="rect" width={211} height={52} />
                            </Grid>
                            }
                          </Grid>}
                        {props.isMobile ?
                          <Grid item xs={12}>
                            <LoaderCLickButton
                              style={{ fontSize: "30px" }}
                              isLoading={isLoading}
                              size="large"
                              disabled={!validatePaymetButton()}
                              onClick={() => { makeaPayment() }}
                            >
                              MAKE PAYMENT
                              </LoaderCLickButton>

                          </Grid>
                          : null}
                      </Grid>
                    </Grid>
                    {((currentRentDetails && currentRentDetails.RentPeriods && currentRentDetails.RentPeriods.length > 0)) && 
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Grid container spacing={2}>
                                  {
                                    !isLoading && (transactionData.Misc.length > 0) && <Grid item xs={12}>
                                      {

                                        transactionData.Misc.map((eachMisc, keyIndex) => {
                                          return (<Typography key={keyIndex}
                                            className="renter-headerfour-up"
                                            component="h5"
                                            variant="h5"
                                          >
                                            {eachMisc.appfeeName}{eachMisc.appFeeType !== "percentage" ? ': $ ' + transactionData.MiscAddition : ' (' + eachMisc.appFeeValue + '%): $' + transactionData.MiscAddition}
                                          </Typography>)
                                        })
                                      }

                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.TrasnactionFee > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        className="renter-headerfour-up"
                                        component="h5"
                                        variant="h5"
                                      >
                                        {"Convenience Fee: $"}{transactionData.TrasnactionFee}
                                      </Typography>
                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.Tax > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        className="renter-headerfour-up"
                                        component="h5"
                                        variant="h5"
                                      >
                                        {'Taxes (' + transactionData.Tax + '%): $' + Number((transactionData.TotalAmount * (transactionData.Tax / 100)).toFixed(2))}
                                      </Typography>
                                    </Grid>
                                  }
                                  <Grid item xs={12}>
                                    {!isLoading &&
                                      <Typography
                                        component="h2"
                                        className="renter-headerFive"
                                        variant="h2"
                                      >
                                        Total Payment:  ${Number((transactionData.TotalAmount + transactionData.TrasnactionFee + (transactionData.TotalAmount * (transactionData.Tax / 100))).toFixed(2))}
                                      </Typography>
                                    }
                                    {isLoading && <Skeleton variant="rect" width={455} height={50} />}
                                  </Grid>
                                  {props.isMobile ? null : <Grid item xs={12}>
                                    {!isLoading &&
                                      <LoaderCLickButton
                                        style={{ fontSize: "30px" }}
                                        isLoading={isLoading}
                                        size="large" 
                                        disabled={!validatePaymetButton()}
                                        onClick={() => { makeaPayment() }}
                                      >
                                        MAKE PAYMENT
                                      </LoaderCLickButton>
                                    }
                                    {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                      <Skeleton variant="rect" width={295} height={63} />
                                    </Grid>
                                    }
                                  </Grid>}
                                </Grid>
                      </Grid>
                      }
                    <Grid item xs={12} sm={12} md={5} lg={5}>

                    </Grid>
                  </Grid>
                </div>}
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}  