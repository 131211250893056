import React from 'react';
import {
  Typography,
  Box,
  makeStyles,
  IconButton,
  Grid 
 }from '@material-ui/core'; 
 import CloseIcon from "@material-ui/icons/Close";
import { Alert } from '@material-ui/lab';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  cookieAlert: {
    color: '#fff',
    backgroundColor: '#6200EE',
    textAlign: 'center'
  },
}));
export default function Copyright(props) { 
  console.log('Copyright props',props);
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies(['adminMessage', 'renterMessage', 'userClosed']);
  const handleAlertClose=(type)=>{
        if(type=='renter') removeCookie('renterMessage');
        else removeCookie('adminMessage', { path: '/' });
        setCookie('userClosed', 'true', {
          path: '/'
        });
  } 
  React.useEffect(() => {
    var targetDate = new Date('2021/09/15 00:00:00:000').getTime();    // Target Date to remove the cookie. 
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); 
    let isDateOver= currentDate.getTime() > targetDate ? true:false;
    if((cookies.userClosed &&  isDateOver))  removeCookie('userClosed', { path: '/' });
    if (cookies.adminMessage &&  isDateOver) removeCookie('adminMessage', { path: '/' });
    else {
      // target date will compare with current date, if the condition is match it will create cookie. this message will show it in admin dashboard
      if (!isDateOver && props.isAuthenticated && props.userSession.Privilege !== 'RENTERS' && cookies.userClosed!=='true' && !cookies.adminMessage) {        
        setCookie('userClosed', 'false', {
          path: '/'
        });
        setCookie('adminMessage', 'We made some changes to design and integrated Plaid - a leader in ACH payment processing. Same software with few upgrades and more features to come.' +
          '\nTenant’s now have the ability to make payment using through their direct bank accounts. Its lower payment processing fee (0.8% per transaction)' +
          ' If you have a question or feedbacks please reach out to support@leaseleads.com', {
          path: '/'
        });
      }
    }
    if (cookies.renterMessage && isDateOver) {
      removeCookie('renterMessage'); 
    }
    else {
      // target date will compare with current date, if the condition is match it will create cookie. this message will show it in renter dashboard
      if (!isDateOver && props.isAuthenticated && props.userSession.Privilege === 'RENTERS' && cookies.userClosed!=='true' && !cookies.renterMessage) {
        setCookie('userClosed', false, {
          path: '/'
        });
        setCookie('renterMessage', 'We made some upgrades and integrated Plaid - a leader in direct bank payment processing.' +
          'You now have the ability to make payments directly through your bank account. It’s safe and secure, with a lower processing fee. Try it out!');
      }
    }

  }, []); 
  return (
    !props.isAuthenticated ?
      <Box className={props.class}>
        <br />
        <Typography variant='body2' color='textSecondary' align='center'>
          {'For any technical inquiries please contact:'}
          <a
            /*   target='_blank' */
            style={{ 'color': 'rgba(0, 0, 0, 0.54)'/* ,textDecoration:'none' */ }}
            color='textSecondary'
            href='mailto:support@leaseleads.com'
          >
            support@leaseleads.com
          </a>
        </Typography> <br />
        <Typography variant='body2' color='textSecondary' align='center'>
          {'Copyright © '} {new Date().getFullYear()}{' '}
          <a
            /*   target='_blank' */
            style={{ 'color': 'rgba(0, 0, 0, 0.54)'/* ,textDecoration:'none' */ }}
            color='textSecondary'
            href='https://www.leaseleads.com/'
          >
            Lease Leads, Ltd.
          </a>
          {' '}All Rights Reserved.
        </Typography>
        <br />
      </Box>
      /* Uncomment for ach release*/
      /* : props.history.location.pathname === '/' && props.userSession.Privilege !== 'RENTERS' && cookies.adminMessage && cookies.adminMessage !== '' ?
      <div className={classes.root}>
       <Alert icon={false} severity='success'  className={classes.cookieAlert}  action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleAlertClose('admin');
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          }>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid lg={9} md={9} mg={9} item>{cookies.adminMessage}</Grid>
            </Grid>          
        </Alert>  
        </div> :
        props.match.path.includes('/:property')  && props.userSession.Privilege === 'RENTERS' && cookies.renterMessage && cookies.renterMessage !== '' ?
        <div className={classes.root}>
         <Alert icon={false} severity='success'  className={classes.cookieAlert} action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleAlertClose('renter');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>            
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
             <Grid  lg={9} md={9} mg={9} item>{cookies.renterMessage}</Grid> 
            </Grid>
          </Alert>  
        </div>  */:
          <Box className={props.class}>
            <br />
            <Typography variant='body2' color='textSecondary' align='center'>
              {'For any technical inquiries please contact:'}
              <a style={{ 'color': 'rgba(0, 0, 0, 0.54)' }}
                color='textSecondary'
                href='mailto:support@leaseleads.com'
              >
                support@leaseleads.com
              </a>
            </Typography> <br />
            <Typography variant='body2' color='textSecondary' align='center'>
              {'Copyright © '} {new Date().getFullYear()}{' '}

              <a style={{ 'color': 'rgba(0, 0, 0, 0.54)' }}
                color='textSecondary'
                href='https://www.leaseleads.com/'
              >Lease Leads, Ltd.</a>
              {' '}All Rights Reserved.
            </Typography>
            <br />
          </Box>
  );
}
