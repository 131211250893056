import React, { Component} from "react";
import clsx from 'clsx';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'; 
import { Auth } from 'aws-amplify';  
import LoaderButton from "./LoaderButton";
import portaLogo from "../static/images/leaseleads_logo_Rectangle_3.png";  
import { openSnackbar } from '../components/Notifier';

const fontScale = window.devicePixelRatio;
//const [isLoading, setIsLoading] = useState(false);


const useStyles =theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
   /*  marginTop: theme.spacing(3) */
  },
  submit: {
    /* margin: theme.spacing(3, 0, 2), */
    marginTop:theme.spacing(1),
  },
  miButtonroot: {
    borderRadius: "0px",
    backgroundColor: "yellow",
    color: "black"
  },
  muiButtoncontained: {
      boxShadow: "none"
  },
  myImage: {
    width: "100%"
  },
  pageSecondHeader: {
    fontSize: 17 / fontScale,
    textAlign: "center",
    fontWeight: 401
  },
  headerGrid: {
    width: "100%"
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  }
});

 class ChangeEmail extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        code: "",
        email: "",
        currentEmail:this.props.userSession.Email,
        codeSent: false,
        isConfirming: false,
        isSendingCode: false
      };
    }
  
    validatEmailForm() {
      return this.state.email.length > 0;
    }
  
    validateConfirmForm() {
      return this.state.code.length > 0;
    }
  
    handleChange = event => {
      if(event.target.id==='email')
      {
        this.setState({
          [event.target.id]: event.target.value.toLowerCase()
        });
      }
      else
      {
        this.setState({
          [event.target.id]: event.target.value
        });
      }
     
    };
  
    handleUpdateClick = async event => {
      event.preventDefault();
  
      this.setState({ isSendingCode: true });
  
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: this.state.email });
  
        this.setState({ codeSent: true });
      } catch (e) {
        alert(e.message);
        this.setState({ isSendingCode: false });
      }
    };
  
    handleConfirmClick = async event => {
      event.preventDefault();  
      this.setState({ isConfirming: true });  
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", this.state.code);  
        //this.props.history.push("/change-email");
        this.setState({ isConfirming: false });
        openSnackbar({ message: "Your password has been reset.!!", variant:"success" });  
      } catch (e) {
        alert(e.message);
        this.setState({ isConfirming: false });
        openSnackbar({ message: e.message, variant:"error" });  
      }
    };  
    handleLogout=async  () => {
        await Auth.signOut();    
        this.props.userHasAuthenticated(false);    
      //  this.props.history.push("/");
      } 
    renderUpdateForm() {
      const { classes } = this.props;
      console.log("SCREEN SIZE ", fontScale);
      return (        
        <form className={classes.form} noValidate  onSubmit={this.handleUpdateClick}> 
        <Grid container spacing={3} >
        <Grid item xs={12}>
            <TextField
            autoComplete="fname"
            type="text"
            name="currentEmail"
            variant="outlined"
            disabled
            fullWidth
            id="currentEmail"
            label="Current Email"
            value={this.state.currentEmail}
            onChange={this.handleChange}
            autoFocus
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
            autoComplete="fname"
            type="text"
            name="email"
            variant="outlined"
            required
            fullWidth
            id="email"
            label="New Email"
            value={this.state.email}
            onChange={this.handleChange}
            autoFocus
            />
        </Grid>
        </Grid>

        <Grid container    direction="column"  justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
                <LoaderButton
                    block="true"
                    tusestateype="submit"
                    size="large"
                    className={classes.submit} 
                    disabled={!this.validatEmailForm()}
                    isLoading={this.state.isSendingCode}        
                    >
                    SUBMIT
                </LoaderButton>
            </Grid>
        </Grid>
    </form>
    
      );
    }
  
    renderConfirmationForm() {
      const { classes } = this.props;
      return (      
        <form className={classes.form} noValidate  onSubmit={this.handleConfirmClick}> 
        <Grid container  spacing={3}>
          <Grid item xs={12}>
          <TextField
              autoComplete="fname"
              name="code"
              variant="outlined"
              required
              value=""
              fullWidth
              id="code"
              label="Verification Code"
              value={this.state.code}
              onChange={this.handleChange}
              helperText={"Please check your email"+ this.state.email+" for the confirmation code."}
              autoFocus
            />            
          </Grid>
        </Grid> 
        <Grid container    direction="column" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
                <LoaderButton
                block
                type="submit"
                size="large" 
                className={classes.submit} 
                isLoading={this.state.isConfirming}
                disabled={!this.validateConfirmForm()}     
                >
                SUBMIT
                </LoaderButton>
            </Grid>
        </Grid>
      </form>
 
      );
    }
  
    render() {
      return (
        <div className="ChangeEmail">
          {!this.state.codeSent
            ? this.renderUpdateForm()
            : this.renderConfirmationForm()}
        </div>
      );
    }
  }

ChangeEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ChangeEmail);