import React from "react";
import { API } from 'aws-amplify';  
  const getMaster =async props=> {  
    console.log("Props " , props);
    return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: { masterType: "User"},
          queryStringParameters: {         
            module: "user",
            op: "getUser"
          }
        };
      
        if (props.fromManagePayment) myInit.body.fromManagePayment=true;
        if (props.for) myInit.body.for=props.for;
        if((props.type==="User" || props.type==="renterProperty") && props.masterId!=="")
        {
          myInit.body.MasterID=props.masterId;          
        }
        myInit.body.userData=props.userSession;
        API.post(apiName, path, myInit).then(response => {          
          console.log("user response",response);              
          resolve(response);
        }).catch(err=>{console.log(err); reject(err);
        });
      });
  }
   const getRentPeriod =async props=> {  
     console.log("DATA FETCHER GET RENTER PERIOD " , props.propertyid);
    return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: { masterType: "Rent",renter:props.UserID, PropertyID: props.propertyid, userData:props.userSession},
          queryStringParameters: {         
            module: "renter",
            op: "getRentPeriod"
          }
        };
        API.post(apiName, path, myInit).then(response => {          
          console.log("card response",response);              
          resolve(response);
        }).catch(err=>{console.log(err); reject(err);
        });
      });
  }
  const getCardData =async props=> {  
    console.log("GET CARD DATA ", props);
    return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: { masterType: props.type, CurrentUserID: props.CurrentUserID,userData:props.userSession},
          queryStringParameters: {         
            module: "renter", 
            op: "getCardData"
          }
        };
        API.post(apiName, path, myInit).then(response => {          
          console.log("card response",response);              
          resolve(response);
        }).catch(err=>{console.log(err); reject(err);
        });
      });
  }

  const getRenterOrUnitComboData = async props => {
    console.log("PROPS COMBO GET DATA : " , props);
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";


      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {"PropertyId": props.PropertyID,"userData":props.userSession} ,
        queryStringParameters: {         
          module: "biller",
          op: props.type==="UnitCombo"?"getUnitComboData":"getRenterComboData"
        }
      };


      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
  }

  const getPropertyListFromRent = async props => {
    console.log("PROPS COMBO GET DATA : " , props);
    return new Promise((resolve, reject) => {
      new Promise((_resolve, _reject) => {
        getMaster(props)
            .then(result => _resolve(result))
            .catch(error => _reject(error));
      })
      .then((result) => {
        console.log("THEN PART ----------------------- " , result);
        if (result.length && result[0].Property && result[0].Property.length) {
          let apiName = "rentapi";
          let path = "/modules";
  
          let myInit = {
            headers: { "Content-Type": "application/json" },
            body: {"Property": result[0].Property, "UserId":props.masterId,"userData":props.userSession} ,
            queryStringParameters: {         
              module: "renter",
              op: "getPropertyListFromRent"
            }
          };
  
          API.post(apiName, path, myInit).then(response => {        
            console.log("user response",response);        
            result[0].Property = response.data;   
            resolve(result);
          }).catch(err=>{console.log(err); reject(err);
          });
        }
        else {
          result[0].Property = [];
          resolve(result);
        }
  
      });
    });

  }

   const verifyUser=async props =>{
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/verify/verify";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: props.userData 
      };
      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
   }
   
   const declineUser=async props =>{
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/verify/decline";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: props.userData 
      };
      API.post(apiName, path, myInit).then(response => {        
        console.log("user response",response);              
        resolve(response);
      }).catch(err=>{console.log(err); reject(err);
      });
    });
   }


  export default class DataFetcher extends React.Component {
    constructor(props) {  
      super(props);
      this.state = {
        data: null,
        IsLoading: false,
        error: null,
      };
     console.log("Fetcher after",props);    
    }
    async componentDidUpdate(nextProps) {
      console.log("getMaster componentDidMount",nextProps);
      console.log("this.props.type",nextProps.type);
      if(nextProps.render){
        this.setState({ IsLoading: true });
        if(nextProps.type==="User")
        {
          await getMaster({...nextProps})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
        else if (nextProps.type==="renterProperty") {
          
          await getPropertyListFromRent({...nextProps})
          .then(result => {
            console.log("RESULT------------------------" , result);
            this.setState({
            data: result,
            IsLoading: false
          })})
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
        else if(nextProps.type==="verify")
        {
          console.log("verifyUser componentDidMount",nextProps.userData);
          await verifyUser({...nextProps})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
        else if(nextProps.type==="User Card")
        {
          await getCardData({...nextProps})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
        else if(nextProps.type==="Rent Period")
        {
          await getRentPeriod({...nextProps})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
        else if (nextProps.type === 'decline') {
          await declineUser({ ...this.props })
            .then(result => this.setState({
              data: result,
              IsLoading: false
            }))
            .catch(error => this.setState({
              error,
              IsLoading: false
            }));
        }
      }
    }  
    async componentDidMount() {
      console.log("getMaster componentDidMount 2",this.props);
      console.log("this.props.type",this.props.type);
      
      this.setState({ IsLoading: true });
      if(this.props.type==="User")
      {
        await getMaster({...this.props})
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if (this.props.type==="renterProperty") {
          
        await getPropertyListFromRent({...this.props})
        .then(result => {
          console.log("RESULT------------------------" , result);
          this.setState({
          data: result,
          IsLoading: false
        })
      })
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if(this.props.type==="verify")
      { 
        await verifyUser({...this.props})
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if(this.props.type==="User Card")
      {
        await getCardData({...this.props})
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if (this.props.type==="RenterCombo") {
        await getRenterOrUnitComboData(this.props)
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if (this.props.type==="UnitCombo") {
        await getRenterOrUnitComboData(this.props)
        .then(result => this.setState({
          data: result,
          IsLoading: false
        }))
        .catch(error => this.setState({
          error,
          IsLoading: false
        }));
      }
      else if(this.props.type==="Rent Period")
        {
          await getRentPeriod({...this.props})
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
        }
      else if (this.props.type === 'decline') {
        console.log("declineUser componentDidMount", this.props.userData);
        await declineUser({ ...this.props })
          .then(result => this.setState({
            data: result,
            IsLoading: false
          }))
          .catch(error => this.setState({
            error,
            IsLoading: false
          }));
      }
    }
     
    render() {  
      console.log("Fetcher render",this.props.children);
      console.log(" this.state ", this.state );
      return (this.props.children(this.state));
    }
  }
 
