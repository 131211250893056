import React, { useState, useEffect } from "react";
import {  useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent"; 
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container"; 
import Divider from '@material-ui/core/Divider';
import { API } from "aws-amplify"; 
import CardActions from "@material-ui/core/CardActions";
import { LoaderCLickButton } from "../LoaderButton";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton"; 
import LoaderButton from "../LoaderButton";
import _AddCard from "./_AddCard";  
import { openSnackbar } from '../Notifier';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import usePersistedState from '../../usePersistedState';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@material-ui/core/TextField';
import { Skeleton } from '@material-ui/lab';
import AddCreditCard from './addCreditCard';
import {UserCreditCards} from '../../common/components/placeholder';

const useStyles = makeStyles(theme =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: theme.spacing(8),
      flexDirection: "column",
      alignItems: "center",
    /*   minHeight: "600px", */
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    card: {
      display: "flex",
      /* padding: theme.spacing(2, 2,3, 2)  */
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      /*  width:"-moz-available" */
    },
    content: {
      flex: "1 0 auto"
    },
    cover: {
      width: "170px",
      height: "170px"
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    headerOne: {
      color: "rgba(0,0,0,1)",
      fontSize: "60px"
    },
    headerTwo: {
      color: "rgba(123,123,123,1)",
      fontSize: "60px"
    },
    headerThree: {
      color: "rgba(0,0,0,1)",
      fontSize: "22px"
    },
    headerfour: {
      color: "rgba(123,123,123,1)",
      fontSize: "20px"
    },
    headerFive: {
      color: "#7c7c7c",
      fontSize: "40px"
    },
    headerSix: {
      color: "rgba(0,0,0,1)",
      fontSize: "22px",
      fontWeight: "bold"
    },
    headerSeven: {
      color: "#7c7c7c",
      fontSize: "14px"
    },
    thankYouHTwo: {
      color: "rgba(123,123,123,1)"
    },
    thankYouHThree: {
      color: "rgba(123,123,123,1)",
      fontSize: "16px",
    },
    thankYouHFour: {
      color: "rgba(0,0,0,1)",
      borderLeft: "6px solid #ffe100",
      paddingLeft: "7px",
    },

    filterProperty: {
      margin: theme.spacing(1),
      minWidth: 220,
      width: 564
    },
    filterUType: {
      margin: theme.spacing(1),
      minWidth: 120,
      float: "left"
    },
    filterLabel: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(4),
      width: "56px",
      color: "rgba(123,123,123,1)",
      fontSize: "17px",
      float: "left"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    papers: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    cards: {
      display: "flex",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
    },
    rentCardClick: {
      display: "flex",
      width: "100%",
      background: "#3f51b52e",
      border: "1px solid #3f51b5",
      borderRadius: "5px",
      /*  opacity:"0.8", */
      boxShadow: "none",
    },
    rentCard: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px"
    },
    f: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px",
      boxShadow: "none"
    },
    rentCardSelect: {
      display: "flex",
      width: "100%",
      border: "1px solid #6200EE",
      borderRadius: "5px"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3)
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "36%",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    payImg: {
      width: "100%",
      height: "100%"
    },
    payCardCont: {
      padding: "8px"
    },
    main: {
      maxWidth: "100% !important"
    },
  })
); 
export  function ManageCards(props) {
  const classes = useStyles();
  const location = useLocation(); 
  const primaryCard=React.useRef();  
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);    
  const [deleteOpen, setDeleteOpen] = useState(false);  
  const [addCreditCard, setAddCreditCardOpen] = useState(false);   
  const [cardForDelete, setCardForDelete] = useState({}); 
  const [userCards,setUserCards]=usePersistedState('userCards',[]);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  //const [creditCardData, setCreditCardData] = useState({});
  const year = (new Date()).getFullYear();
  const [expYear] = useState(Array.from(new Array(20),(val, index) => (index + year).toString())); 
  const [expMonth] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);
  const [creditCardData, setCreditCardData] = useState({
    MasterID:"",
    NameOnCard: "",
    BillingAddress: "",
    Country:"",
    CountryCode:"",
    PostalCode: "",
    CardNumber: "",
    CvvCid: "",
    ExpiryMonth:"",
    ExpiryYear:"",
    UserName:props.userSession.UserName,
    RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
  });
  const MapData = window['countryConfig'];
  useEffect(() => {
    let isSubscribed = true
    console.log(location.pathname); // result: '/secondpage'   
    console.log(location.props); // result: 'some_value' 
    const userFetch = async () => {
      if (location.props && isSubscribed) {
        console.log("user useeffect", props);
        setIsLoading(true);
        trackPromise(
          getCardsData(props.userSession,"getCards")
            .then(async tablData => {
              if (isSubscribed) {
                await setUserCards(tablData);
                setIsLoading(false);
              }
            })
            .catch(er => { throw er }),
          areas.renter,
        )
        return () => isSubscribed = false
      }
    }
    userFetch();
  }, [location]); 
  const CardFetching=async (op)=>{
    setIsLoading(true);    
    trackPromise(
      getCardsData(props.userSession,op)
      .then(async tablData => {        
        await setUserCards(tablData);    
        setIsLoading(false);      
      })           
      .catch(er=>{ throw er}),
      areas.renter,
      )
  }
  const getCardsData =async (userSession,op)=> {      
    return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: { masterType:"User Card", CurrentUserID:userSession.UserName,userData:userSession},
          queryStringParameters: {         
            module: "renter", 
            op: op?op:"getCardData"
          }
        };
        API.post(apiName, path, myInit).then(response => {                 
          resolve(response);
        }).catch(err=>{console.log(err); reject(err);
        });
      });
  }   
  async function handleChildClick(type, data = {},clickData) {    
    if (type === "manage card") {
       await CardFetching("getCards");
    } 
  }  

  async function handleChildClickCreditCard(fieldName, value, child) {
    console.log("FieldName : value : child : ", fieldName , value, child)
    let ob = {};
    ob[fieldName] =  value;
    setCreditCardData({...creditCardData, ...ob})
    
  }

  function validateCreditCardForm() {
    console.log("Log validateCreditCardForm",creditCardData);
    
    return creditCardData.ExpiryMonth.length>0 && creditCardData.ExpiryYear.length>0 &&
    creditCardData.NameOnCard.length > 0 && creditCardData.BillingAddress.length > 0 &&
    creditCardData.Country.length > 0 && creditCardData.PostalCode.length > 0 && 
    creditCardData.CardNumber.length > 0 && creditCardData.CvvCid.length > 0;
}

async function saveCardData(e) {
  e.preventDefault();
  setIsLoadingModal(true);
  let apiName = "rentapi";
  let path = "/modules";
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: {...creditCardData,userData:props.userSession, MasterType: 'User Card', CurrentUserID: props.userSession.UserName, CurrentUserEmail:props.userSession.Email, CurrentUserName: props.userSession.FirstName + " " + props.userSession.LastName },
    queryStringParameters: {
      module: "renter",
      op: "saveCardData"
    }
  };
  API.post(apiName, path, myInit)
    .then(response => {
      setIsLoadingModal(false);
      if(response && response.success)
      {
        console.log("response" + JSON.stringify(response));             
        openSnackbar({
          message:
          response.message,
          variant: "success"
        });
        setCreditCardData({
          MasterID:"",
          NameOnCard: "",
          BillingAddress: "",
          Country:"",
          PostalCode: "",
          CardNumber: "",
          CvvCid: "",
          ExpiryMonth:"",
          ExpiryYear:"",
          UserName:props.userSession.UserName,
          RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
        });
        //handleClearForm();     
        //handleClick("manage card");
      }
      else{          
        openSnackbar({ message:response.message, variant: "error" });
        setIsLoadingModal(false);
      }
    })
    .catch(err => {
      console.log(err);
      openSnackbar({ message: err.message, variant: "error" });
      setIsLoadingModal(false);
    });
}

  async  function changePrimaryCard(clickedCard) {
    console.log("CLICKED");
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        "CurrentPrimaryCard":  primaryCard.current,
        "NewPrimaryCard": clickedCard,
        "userData":props.userSession
      },
      queryStringParameters: {
        module: "renter",
        op: "changePrimaryCard"
      }
    };

    API.post(apiName, path, myInit).then(async response => {
      openSnackbar({ message: response.message, variant: "success" });
      await CardFetching("getCardData");
    }).catch(err => {     
      openSnackbar({ message: err.message, variant: "error" });
    });
  }
  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles(theme => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();  
    return <Tooltip arrow classes={classes} {...props} />;
  }
  
  async function deleteCard() {
    console.log("CLICKED ", cardForDelete);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        "CardData": cardForDelete,
        "userData":props.userSession
      },
      queryStringParameters: {
        module: "renter",
        op: "deleteCard"
      }
    };

    API.post(apiName, path, myInit).then(async response => {
      setDeleteOpen(false);
      openSnackbar({ message: response.message, variant: "success" });
      await CardFetching("getCardData");

    }).catch(err => {
      setDeleteOpen(false);
      openSnackbar({ message: err.message, variant: "error" });
    });
  }
  const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h2" component="h2">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const DialogTitleCreditCard = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }; 
    return (
      <div className={"home-root"}>
   
      <Grid
      container
      spacing={0}
      direction="column"
      >
      <Grid item>
      <Container component="main" className={classes.main}>
        <CssBaseline />
        {/* <Spinner className="common-spinner"
          area={areas.renter}
        /> */}
      <div className="home-paper">
      <Dialog  open={addCreditCard}
      onClose={e => setAddCreditCardOpen(false)}  fullWidth={true}
      maxWidth={"sm"} aria-labelledby="customized-dialog-title">
        <DialogTitleCreditCard className="addnew-user-dialog-title"  id="addnew-user-dialog-title" onClose={e=>{setAddCreditCardOpen(false);}}>
                                
                                <div className={"skelton-flex-parent"}>
                                    {
                                isLoadingModal && 
                                <Skeleton  variant="rect" width={263} height={29} className={"skelton-margin-right-5 skelton-margintop-2p"}/>
                                }
                                    {!isLoadingModal && <div>Add Credit Card</div>}
                                
                                </div>
                            </DialogTitleCreditCard>
        
        <DialogContent className="addtenant-dialog-content">
          <AddCreditCard  creditCardData = {creditCardData} isLoading = {isLoadingModal} onChildClick={(fieldName, value) => handleChildClickCreditCard(fieldName, value)} />
        </DialogContent>
        <Divider variant="middle" />

        <DialogActions className={"addcreditcard-DialogActions offline-modal-bt"}>
        <Grid container
                                spacing={2}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                  <Grid item xs={12}>
                                  <LoaderButton
                                  block="true"
                                  type="submit"
                                  size="large"
                                  isLoading={isLoading}
                                  disabled={!validateCreditCardForm()}
                                >
                                  + SAVE CARD
                                </LoaderButton>
                                  </Grid>
                                </Grid>
                        
        </DialogActions>



      </Dialog>
      <Dialog  open={deleteOpen}
          onClose={e => setDeleteOpen(false)}  fullWidth={true}
        maxWidth={"sm"} aria-labelledby="customized-dialog-title">
        <DialogTitle className="addnew-user-dialog-title" id="customized-dialog-title" onClose={e => { setDeleteOpen(false) }}>
        Are you sure to delete this card?
        </DialogTitle>
        <DialogContent className="addnew-user-dialog-content">
        It cannot be reversed. 
        </DialogContent>
        <DialogActions className="addnew-user-dialog-actions"> 
                  <LoaderCLickButton            
            onClick={e => {deleteCard();}}
            isLoading={isLoading}            
          >
            DELETE
                  </LoaderCLickButton>
        </DialogActions>
      </Dialog>    
        <div style={{ padding: "20px 0px 40px 0px" }}>
          <Grid
            container
            spacing={1}
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item>
            {!isLoading &&
              <Typography
                component="h1"
                className={"renter-headerOne  fontweight-bold"}
                variant="h1"
              >
                Manage Payment Method
              </Typography>
              }
              {isLoading && <Skeleton  variant="rect" width={553} height={69}/>}
            </Grid>
            <Grid item xs={12}>
            {!isLoading && 
              <Typography
                className={classes.headerfour}
                variant="subtitle1"
              >
                You can add new credit card to your account. Also, you can remove existing cards from the list given below.
              </Typography>
              }
              {isLoading && <Skeleton variant="rect" width={931} height={28}/>}
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              {!isLoading && 
                <Typography
                  component="h3"
                  className={"renter-headerFive fontweight-401"}
                  variant="h3"
                >
                  Add New Credit Card
                </Typography>
                }
                {isLoading && <Skeleton  variant="rect" width={369} height={45}/>}
              </Grid>
              <Grid item xs={12}>
                <_AddCard {...props} render={render} isNullForm = {isLoading ? true : false} onChildClick={(type, data,clickData) => handleChildClick(type, data,clickData)} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid
                      container
                      spacing={2}
                      direction="column"
                      justify="center"

                    >
                      <Grid item xs={12}>
                      {!isLoading && 
                        <Typography
                          component="h3"
                          className={"renter-headerFive fontweight-401"}
                          variant="h3"
                        >
                          {userCards.length > 0 ? "Your credit cards on file" : "No credit cards added"}
                        </Typography>
                        }
                        {isLoading && <Skeleton  variant="rect" width={419} height={45}/>}
                      </Grid>
                      {!isLoading && userCards.map((e, keyIndex) => {
                        if (e.Primary === "Yes") primaryCard.current=e; // setPrimaryAccount(e);
                        return (
                          <Grid key={keyIndex} item xs={12}>
                            <Card className={classes.rentCard}>
                              <CardContent style={{ width: "100%" }}>
                                <Typography>
                                  {e.DisplayCardNo} exp:{e.ExpiryMonth}/
                                  {e.ExpiryYear}{" "}
                                  {e.Primary === "Yes" ? "(Primary)" : ""}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Grid
                                  container
                                  direction="row">
                                  <Grid item xs={6}>
                                    <BootstrapTooltip title={e.Primary !== "Yes" ? "Make Primary" : "Remove Primary"}>
                                      <IconButton
                                        color={
                                          e.Primary !== "Yes"
                                            ? "primary"
                                            : "secondary"
                                        }
                                        onClick={() => {
                                          changePrimaryCard(e);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                      >
                                        {e.Primary !== "Yes" ? (
                                          <FavoriteBorderOutlinedIcon />
                                        ) : (
                                            <FavoriteIcon />
                                          )}
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <BootstrapTooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          setCardForDelete(e);
                                          setDeleteOpen(true);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        );
                      })}

                {isLoading && [1,2,3,4].map(() => {
                  return <UserCreditCards />
                })
                }
                    </Grid>
          </Grid>
        </Grid>
      </div>
      </Container>
      </Grid>
      </Grid>
      </div>);
}