import React, { useRef, useState, useEffect } from "react";
import './App.css';
import Amplify, { Auth } from 'aws-amplify';
import { withRouter, useHistory } from "react-router-dom";
import Routes from "./Routes";
import Notifier from './components/Notifier';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { load as loadIntercom, boot as bootIntercom, update as updateIntercom, shutdown as shutdownIntercom } from "./lib/intercom";
import { createBrowserHistory } from "history"
import { ModelWindow } from "./common/components/modelwindow"
import { useIdleTimer } from 'react-idle-timer'
import awsdev from './aws-dev';
import awsdev2 from './aws-dev2';
import * as Sentry from '@sentry/browser';


const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];


Amplify.configure(MapData);  /* Configure Amplify */ 
const bhistory = createBrowserHistory()
bhistory.listen((location) => {
  // Calls Intercom('update') on every page change
  updateIntercom();
});

function App(props) {
  const history = useHistory();
  const ref = useRef(null);
  /* create global mui theame*/
  let theme = createMuiTheme({
    typography: {
      h1: {
        fontSize: '3.75rem',  // 60px
        fontWeight: "bold"
      },
      h2: {
        fontSize: '2.5rem',  // 40px
        fontWeight: "bold"
      },
      h3: {
        fontSize: '2rem',   // 32px
        fontWeight: "bold"
      },
      h4: {
        fontSize: '1.5rem'  // 24px
      },
      h5: {
        fontSize: '1.25rem',  // 20px
      },
      subtitle1: {
        fontSize: '1rem',
      },
      h6: {
        fontSize: '.875rem',  // 14px
      },
      speciallabel1: {
        fontSize: '1.875rem'
      }
    },
    palette: {
      textPrimary: { main: "#000000de" },
      textSecondary:{ main: "#707070" },
      background: {
        default: "#C7C7C7"
      },
      secondary: { main: "#ffe100" },
      error: { main: "#f44336" },
    },
  });
  theme = responsiveFontSizes(theme, {
    "breakpoints": ['xs', 'sm', 'md', 'lg'],
    "factor": 2
  });
  const [idleCounter, setIdleCounter] = useState(1);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setuserSession] = useState({});
  const [isForceLogout, setIsForceLogout] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]) 
  const [whichDevice, setWhichDevice] = useState('Desktop'); 
  useEffect(() => {
    /** Handling Browser back button */
    return history.listen(location => {
      // Calls Intercom('update') on every page change 
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // Handle forward event  
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event 
          history.goBack();
        }
      }
    })
  }, [locationKeys,]);

  /** useIdleTimer SATRT
   * Module will check user is idle or not
   * Show user idle warning 
   * Handle force logout
  */
  useEffect(() => {
    if (isAuthenticated) {
      if (idleCounter === 5) {
        if (ref) ref.current.handleOpen();
      }
      else if (idleCounter === 6) {
        setIsForceLogout(true);
      }
    }
  }, [idleCounter]);
  useEffect(() => {
    if (isForceLogout && isAuthenticated)
      handleLogout();
  }, [isForceLogout]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    let id = params.get('prCode') // 123  
    if(id && id!=='')
       localStorage.setItem('paymentPath', JSON.stringify({prCode:id}));
  }, []);
  const handleOnIdle = event => {
    reset();
    setIdleCounter(idleCounter + 1);
  }
  const handleOnActive = event => {
    setIsForceLogout(false);
    setIdleCounter(1);
  }
  const handleOnAction = (e) => {
    setIsForceLogout(false);
    setIdleCounter(1);
  }
  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 5000
  });
  const handleClose = () => {
    if (ref) ref.current.handleClose();
    setIsForceLogout(false);
    setIdleCounter(1);
  };
  /** useIdleTimer  END */

  useEffect(() => {
    onLoad();
  }, []);
  async function onLoad() {
    console.log('app js 1',props); 
    try { 
      isMobileOnly ? setWhichDevice('Mobile') : isTablet ? setWhichDevice('Tablet') : setWhichDevice('Desktop');
      const user = await Auth.currentSession(); 
      if (user) { 
        /** chack for user session present or not */ 
        Sentry.setUser({ email:  user.idToken.payload["email"] });
        userHasAuthenticated(true);
        setuserSession({
          FirstName: user.idToken.payload["custom:firstname"],
          LastName: user.idToken.payload["custom:lastname"],
          Email: user.idToken.payload["email"],
          UserName: user.idToken.payload["cognito:username"],
          Privilege: user.idToken.payload["custom:privilege"],
          PoolId: MapData.poolId
        });
        loadIntercom() // Initialise intercom chat bot
        bootIntercom({
          email: localStorage.email
        }) 
      }
      else { 
        userHasAuthenticated(false);
        props.history.push("/");
      }
    }
    catch (e) {
      console.log("User Exception", e);
      if ((e !== 'No current user' || e.code === 'UserNotFoundException') && props.location.pathname !== "/verify") {
        console.log("UserNotFoundException", e);
        userHasAuthenticated(false);
        props.history.push("/");
      }
    }
    setIsAuthenticating(false);
  }


  const handleLogout = async () => { 
    shutdownIntercom()
    delete localStorage.email
    window.localStorage.removeItem('selectedProperty');
    window.localStorage.removeItem('renterProperty');
    window.localStorage.removeItem('user');
    bootIntercom();
    await Auth.signOut();
    userHasAuthenticated(false);
    handleClose();
    props.history.push("/");
  }
  return (

    !isAuthenticating &&
    <div>
      <Notifier />
      <MuiThemeProvider theme={theme}>
      <Routes appProps={{ whichDevice, isMobile, isAuthenticated, userHasAuthenticated, userSession, setuserSession, history }} />        
      </MuiThemeProvider>
      <ModelWindow /* Show user idle model window */
        onClickOne={handleLogout}
        onClickTwo={handleClose}
        buttonOneText={"Logout"}
        buttonTwoText={"Stay"}
        title={"You Have Been Idle!"}
        content={"You will get timed Out. You want to stay?"}
        IsbuttonOne={true}
        IsbuttonTwo={true}
        ref={ref} />
    </div>

  );
}
export default withRouter(App);
