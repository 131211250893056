import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Storage, API } from 'aws-amplify';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import { openSnackbar } from '../Notifier';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { S3Image } from 'aws-amplify-react';
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomizedTooltips from '../../lib/tooltIp';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from "@material-ui/core/CardContent";
import StripeLogo from "../../static/images/Stripe_Logo.svg"
import usePersistedState from '../../usePersistedState'; 
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {GridLoader} from '../../common/components/placeholder';
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as PlaidSvg } from '../../static/images/Plaid_logo.svg';
import { Box } from "@material-ui/core";
import { StipeKeyManage,PlaidKeyManage } from "./index";
import * as Yup from 'yup';
import { Formik } from 'formik';
const MapData = window['countryConfig']; 
const ImpStyles = makeStyles(theme => ({
  card1: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "215px",
    maxWidth: "215px"
  },
  card2: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "315px",
    maxWidth: "315px"
  },
  details: {
    display: "flex"
  },
  cover: {

  },
}));
const useStyles = makeStyles((theme) => createStyles({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.

    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  myImage: {
    width: "100%"
  },
  input: {
    display: 'none',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    wordWrap: "nowrap",
    paddingLeft: "9px"
  },
}));
const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
export function SettingsPayment(props) {
  console.log("ADD NEW PROPERTY PROPS ", props); 
  let history = useHistory();
  const classes = useStyles();
  const inputSize = props.fromRent ? 12 : 6; 
  const [isEditing,setIsEditing] = useState(false);
  const [stripeOpen, setStripeOpen] = useState(false); 
  const [plaidOpen, setPlaidOpen] = useState(false); 
  const [plaidValues, setPlaidValues] = useState({
     PlaidApiMode:""
    ,PlaidSecretKey:""
    ,PlaidClientKey:""
    ,DisplayPlaidKey:"" 
  }); 
  const [stripeValues, setStripeValues] = useState({
     StripePublicKey: ""
    ,StripeSecretKey: ""
    ,DisplayStripeKey:""
  }); 
  const [property, setProperty] = usePersistedState("property", {     
      MasterID: ""    
    , StripePublicKey: ""
    , StripeSecretKey: ""
    ,PlaidApiMode:""
    ,PlaidSecretKey:""
    ,PlaidClientKey:""     
    , OmitTax: true
    , Tax: 0
    , CurrencyName: ""
    , CurrencyCode: "",
    IncludeStripeFee: true,
    DisplayStripeKey:"",
    DisplayPlaidKey:""
  }); 
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperty,setSelectedProperty] = usePersistedState('selectedProperty', {});
  const [userProperties,setUserProperties] = usePersistedState('userProperties', []);
  useEffect(() => {
    console.log("selectedProperty-----------" , selectedProperty);
    if((selectedProperty.DisplayPlaidKey && selectedProperty.DisplayPlaidKey!=="")|| (selectedProperty.DisplayStripeKey && selectedProperty.DisplayStripeKey!=="") )
       {
          setIsEditing(true);
          setStripeValues(prvproperty => { return { ...prvproperty,DisplayStripeKey:selectedProperty.DisplayStripeKey }}); 
          setPlaidValues(prvproperty => { return { ...prvproperty,DisplayPlaidKey:selectedProperty.DisplayPlaidKey }}); 
       }
    setProperty(selectedProperty);
  },[]);

  const StripeDisplayCard = prop => {
    console.log("stripe keu add window",prop);
    let editMode=prop.type==="plaid" && plaidValues.DisplayPlaidKey &&  plaidValues.DisplayPlaidKey!=""?true:prop.type==="stripe" && stripeValues.DisplayStripeKey &&  stripeValues.DisplayStripeKey!=""?true:false;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card className="stripe-key-card">
          <CardContent className="stripe-key-card-content">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={2}>
              {prop.type==="plaid"?<SvgIcon
                            viewBox="0 0 240 240"
                            className={"makepayment-gatewaysvg makepayment-paddingleft-1p"}
                            >
                            <PlaidSvg 
                              width='240'
                              height='240'
                              
                            />
                            </SvgIcon>
                :<img src={StripeLogo} alt="" height="24" />
                }
              </Grid>
              {!editMode? <>
                <Grid item xs={7}>
                <Typography >
                {prop.type!=="plaid"? ("Credit Card rate: 2.9% + $0.30 per transaction"):"ACH Debit rate: 0.8% up to $5 per transaction"}
                </Typography>
                </Grid>
              </>:
                <Grid item xs={7}>               
                <Typography> 
                   {prop.type==="plaid"? "MODE:":""} {prop.PlaidApiMode} 
                   {prop.type!=="plaid"? "API KEY:":", PLAID SECRET:"} {prop.displayKey}
                 </Typography> 
                 </Grid>} 
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
              justify="center"
             alignItems="center" 
            >
              <Grid item xs={12}>
                {editMode?
                     <BootstrapTooltip title="Edit">
                     <IconButton
                       onClick={() => {                    
                        prop.type==="plaid"?   setPlaidOpen(true):setStripeOpen(true);
                       }}
                       className={classes.button}
                       aria-label="Edit"
                     >
                       <EditIcon />
                     </IconButton>
                   </BootstrapTooltip>:  <Button href="#text-buttons" color="primary"  style={{whiteSpace:"nowrap"}}
                     onClick={() => {                    
                      prop.type==="plaid"?   setPlaidOpen(true):   setStripeOpen(true);
                    }} > ADD KEYS  </Button>} 
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    )
  } 
  function onChange(e) {
    console.log("property onChange", e.target);
     if (e.target.name === "OmitTax") { 
      if (e.target.checked) { 
        console.log("property OmitTax", e.target.checked);
        setProperty(prvproperty => { return { ...prvproperty, "tax": 0, "OmitTax": true } });
      }
      else {
        setProperty(prvproperty => { return { ...prvproperty, "OmitTax": false } });
      }
    }
    else if (e.target.name === "IncludeStripeFee") { 
      if (e.target.checked) { 
        setProperty(prvproperty => { return { ...prvproperty, "IncludeStripeFee": true } });
      }
      else {
        setProperty(prvproperty => { return { ...prvproperty, "IncludeStripeFee": false } });
      }
    } 
    else {
      setProperty({ ...property, [e.target.name]: e.target.value });
    } 
  } 
  function validateForm() {
    return ( 
      (property.OmitTax || (property.tax && property.tax > 0)) &&
      property.CurrencyName &&
      property.CurrencyName.length > 0 
    );
  } 
/*   function handleClearForm() {
    document.getElementById("proeprty-form").reset();
  }  */
  async function saveProperty(e) {
   /*  e.preventDefault(); */
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {...stripeValues, ...plaidValues, ...e,Tax:e.OmitTax?0:e.Tax, userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "savePaymentSetting"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        setIsLoading(false);
        if (response && response.success) { 
          openSnackbar({
            message:
              response.message,
            variant: "success"
          });
          setTimeout(async() => {
           /*  handleClearForm(); */
           let myProperties = await loadMyProperties(props.userSession.UserName);           
           if (myProperties && myProperties.length >0) {
            let currentProperty=myProperties[myProperties.findIndex(x => x.PropertyID == selectedProperty.PropertyID)]      
            localStorage.userProperties =JSON.stringify(myProperties); 
            setSelectedProperty(currentProperty? currentProperty:{});  
           } 
            history.push({
              pathname: `/${property.PropertyName.replace(/\s+/g, '-')}/settings`,
              props: {
                fromAppbar: true,                   
              },
            });
          }, 500); 
        }
        else {
          openSnackbar({ message: response.message, variant: "error" });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: "error" });
        setIsLoading(false);
      });
  } 

  async function eventChange(e,updatedKey) {
      console.log('eventChange ##',e,updatedKey);
      if(e==="stripe add")
      {
        var disKey=updatedKey.StripePublicKey;
        if(disKey)
        {
          disKey=disKey.slice(disKey.length -15).replace(/.(?=.{4})/g, "*")
        } 
        setStripeValues(prvproperty => { return { ...prvproperty,StripePublicKey:updatedKey.StripePublicKey,
          StripeSecretKey: updatedKey.StripeSecretKey,DisplayStripeKey:disKey }}); 
        setStripeOpen(false);
      }
      else if(e==="stripe close") {
          setStripeOpen(false);
      }
      else if(e==="plaid add")
      {
        var disKey=updatedKey.PlaidClientKey;
        if(disKey)
        {
          disKey=disKey.slice(disKey.length -15).replace(/.(?=.{4})/g, "*")
        } 
        setPlaidValues(prvproperty => { return { ...prvproperty,PlaidClientKey:updatedKey.PlaidClientKey,
        PlaidSecretKey: updatedKey.PlaidSecretKey,PlaidApiMode:updatedKey.PlaidApiMode,DisplayPlaidKey:disKey }}); 
        setPlaidOpen(false);
      }
      else if(e==="plaid close") {
          setPlaidOpen(false);
      }
  }
  /**
   * this function will load properties for the user
   */
   function loadMyProperties() {
    setIsLoading(true);
    var masterType = 'User';
    var apiModule = 'property';
    var op = 'listMyProperty';
    let body = { userData: props.userSession, masterType: masterType, MasterID: props.userSession.UserName, for: 'CardDisplay' };
    return new Promise((resolve, reject) => {
      let apiName = 'rentapi';
      let path = '/modules';
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          if (response && response.length > 0) {
            
            resolve(response);
          }
          else {
            resolve([]);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
  return (

    <div className="home-root">
      <StipeKeyManage {...property} eventChange={eventChange} isEditing={isEditing} stripeOpen={stripeOpen} />
      <PlaidKeyManage {...property} eventChange={eventChange} isEditing={isEditing} plaidOpen={plaidOpen} /> 
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="home-paper">
              <div style={{ padding: "20px 0px 40px 0px" }}>

              <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                  history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings`);
                }}>
                  <Grid item>
                    <Button>
                      <div className="addlease-top-flex-container">
                        <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                      </div>
                    </Button>
                  </Grid>
                </Grid> 
                <Grid container
                  spacing={1}
                  direction="column"
                  justify="center"
                  alignItems="flex-start">
                  <Grid item>
                    <Typography
                      component="h1"
                      className="home-headerOne"
                      variant="h1"
                    >
                     Payments
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography className="home-headerfour"
                    variant="subtitle1"
                  >
                   Update property banking, payment method, currency and taxes
                  </Typography>
                </Grid>
              </Grid> 
              <Formik
                initialValues={{
                  MasterID:property.PropertyID,
                  CurrencyName: property.CurrencyName,
                  OmitTax: property.OmitTax,
                  Tax:property.Tax,
                  DisplayStripeKey:property.DisplayStripeKey,
                  DisplayPlaidKey:property.DisplayPlaidKey,
                  PlaidApiMode:property.PlaidApiMode,
                  IncludeStripeFee:property.IncludeStripeFee
                }}
                validationSchema={Yup.object().shape({
                  CurrencyName:Yup.string().max(150).required('Field is required'),
                  OmitTax:Yup.boolean(),
                  Tax: Yup.number().when("OmitTax", {
                    is: false,
                    then: Yup
                      .number()
                      .required("Field is required")
                      .positive("Tax rate should be greater than 0")                      
                  }),
                  IncludeStripeFee:Yup.boolean()
                })}
                onSubmit={async (e) => { 
                  await saveProperty(e);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form   /* id="proeprty-form"
                  className={classes.form} */ onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CustomizedTooltips content={"Currency is based on country/region selection. Contact support if you want to change your currency"} />
                            <InputLabel style={{ color: "rgba(0, 0, 0, 0.87)" }} className={classes.wrapIcon} >
                              PROPERTY CURRENCY
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                              error={Boolean(touched.CurrencyName && errors.CurrencyName)}
                              fullWidth
                              helperText={touched.CurrencyName && errors.CurrencyName}
                              label="PROPERTY CURRENCY"
                              margin="normal"
                              name="CurrencyName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.CurrencyName}
                              disabled
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize} >
                            <FormControlLabel
                              label="OMIT TAX"
                              control={<Checkbox
                                color="default"
                                checked={values.OmitTax}
                                name="OmitTax"
                                onChange={handleChange}
                              />}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8} md={inputSize} lg={inputSize} >
                            <TextField
                              error={Boolean(touched.Tax && errors.Tax)}
                              fullWidth
                              helperText={touched.Tax && errors.Tax}
                              label="TAX %"
                              margin="normal"
                              name="Tax"
                              type='number'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.Tax}
                              disabled={values.OmitTax}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                        <StripeDisplayCard type={"stripe"} displayKey={stripeValues.DisplayStripeKey} inputSize={inputSize} />
                      </Grid>
                     {/* Uncomment for ach release*/ /*  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                        <StripeDisplayCard type={"plaid"} PlaidApiMode={plaidValues.PlaidApiMode} displayKey={plaidValues.DisplayPlaidKey} inputSize={inputSize} />
                      </Grid> */}
                      <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                          <Grid item  >
                            <FormControlLabel
                              label="Yes, Pass processing fee on to tenants"
                              control={<Checkbox
                                color="default"
                                checked={values.IncludeStripeFee}
                                name="IncludeStripeFee"
                                onChange={handleChange}
                              />}
                            />
                          </Grid>
                          <Grid item>
                            <CustomizedTooltips content={"Stripe fee (2.9% + 0.30 cents per transaction ) Plaid fee (0.8% per transaction)"} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div style={{ padding: "20px 0px 40px 0px" }}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <LoaderButton
                            block="true"
                            type="submit"
                            size="large"
                            isLoading={isLoading}
                            disabled={isSubmitting}
                          >
                            UPDATE
                          </LoaderButton>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                )}
              </Formik> 
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
