import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import managementsvg from "../static/images/managerent.svg";
import viewtransactionsvg from "../static/images/viewtransaction.svg"; 
import CardActionArea from "@material-ui/core/CardActionArea";
import { Spinner } from '../common/components/spinner';
import { areas } from '../common/constants/areas';
import { useHistory,useLocation } from "react-router-dom"; 
import usePersistedState from "../usePersistedState";
/** Admin Home/Dashboard Page
 *  Page contain link to 
 *  TENANT MANAGEMENT module
 *  PROPERTY MANAGEMENT module
 *  VIEW PROPERTY module
 */

export default function Biller(props) {
  let history = useHistory();
  const location = useLocation();  
  const [selectedProperty,setSelectedProperty] = usePersistedState('selectedProperty', {});  
  useEffect(() => {
    let isSubscribed = true; 
    const propertyFetch = async () => {
      if (location.props && isSubscribed) { 
        if(location.props.currentProperty)
         {
           await setSelectedProperty(location.props.currentProperty);  
         } 
       }
      return () => isSubscribed = false
    }
    propertyFetch();
  }, [location]); 
  return (
    <div className="home-root">       
      <Grid container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="home-paper">
              <Grid container spacing={1} style={{ paddingBottom: props.userSession.Privilege !== "RENTERS" ? "15px" : "56px"}} >
                <Grid item>

                  <Typography
                    component="h1"
                    className="home-headerOne"
                    variant="h1"
                  >
                    Welcome {props.userSession.Privilege !== "RENTERS" ? "to " : ""}
              </Typography> 
                </Grid>
                <Grid item>
                  <Typography
                    component="h1"
                    className="home-headerTwo"
                    variant="h1"
                  >
                     {props.userSession.Privilege !== "RENTERS" ? (selectedProperty && selectedProperty.PropertyName ? selectedProperty.PropertyName : "") : props.userSession.FirstName}
                  </Typography>
                </Grid>
              </Grid>

              {props.userSession.Privilege !== "RENTERS" &&
              <Grid container spacing={1} style={{ paddingBottom: "41px" }} >
                <Grid item>
                  <Typography className="homemenu-headerfour"
                    variant="subtitle1"
                  >
                    Please select below to start managing the property
                  </Typography> 
                </Grid>
              </Grid>
              }


              <Grid container direction="row" spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card className="home-card">
                    <CardActionArea style={{ display: "flex" }} onClick={() => {
                      history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/transactions`, props: { PropertyName: selectedProperty.PropertyName, selectedProperty: selectedProperty } });
                    }} >
                      <CardMedia
                        className="home-cover"
                        style={{ width: '186px', height: "186px", display: "flex" }}
                        image={viewtransactionsvg}
                        title="Manage users here. Add, remove, update"
                      />
                      <div className="home-details">
                        <CardContent className="home-content">
                          <Typography component="h5" variant="h5">
                            VIEW TRANSACTION
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Viewing transaction from {selectedProperty.PropertyName}
                          </Typography>
                        </CardContent>
                        <div className="home-controls">
                          <MLink
                            variant="body2"
                          >
                            VIEW
                         </MLink>
                        </div>
                      </div>
                    </CardActionArea>
                  </Card>
                </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card className="home-card">
                    <CardActionArea style={{ display: "flex"}} onClick={() => {
                      history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease`, props: { PropertyName: selectedProperty.PropertyName, selectedProperty: selectedProperty } });
                    }}>
                      <CardMedia
                      className="home-cover"
                      style={{ width: '186px', height: "186px", display: "flex" }}
                        image={managementsvg}
                        title="Live from space album cover"
                      />
                      <div className="home-details">
                        <CardContent className="home-content">
                          <Typography component="h5" variant="h5">
                            MANAGE LEASE
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            Add, update, remove lease agreements for tenants.
                          </Typography>
                        </CardContent>
                        <div className="home-controls">
                          <MLink
                            variant="body2"
                          >
                            VIEW
                        </MLink>
                        </div>
                      </div>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
