import React, { useState, useRef, useEffect } from "react"; 
import Grid from "@material-ui/core/Grid"; 
import { makeStyles, createStyles } from "@material-ui/core/styles"; 
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"; 
import TextField from "@material-ui/core/TextField"; 
const MapData = window['countryConfig']; 
 
export default function  BankForm(props) { 
    console.log("Renter home props", props); 
    const [labelWidth, setLabelWidth] = useState(0); 
    const inputLabel = useRef(1); 
    const [bankData,setBankData]=useState({
        UserName: props.userSession.UserName,
        RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName,
        FirstName:"",
        LastName:"",
        AccountType:"",
        RoutingNumber:"",
        AccountNumber:""
      })
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    function onChange(e, child) { 
        setBankData({ ...bankData, [e.target.name]: e.target.value });
        props.formChange('bank',{name:e.target.name,value:e.target.value},child);
      }

    return (
        <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="FirstName"
              label="ACCOUNT FIRST NAME"
              onChange={onChange}
              name="FirstName"
              autoComplete="FirstName"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="LastName"
              label="ACCOUNT LAST NAME"
              onChange={onChange}
              name="LastName"
              autoComplete="LastName"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel ref={inputLabel} id="select-AccountType-label">
              BANK ACCOUNT TYPE
              </InputLabel>
              <Select
                labelid="select-AccountType-label"
                id="AccountType-select-outlined"
                fullWidth
                variant="outlined"
                name="AccountType"
                label="BANK ACCOUNT TYPE" 
                labelWidth={labelWidth}
                onChange={onChange}
              >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={"INDIVIDUAL"}>
                    {"INDIVIDUAL"}
                </MenuItem>
                <MenuItem value={"CORPORATION"}>
                    {"CORPORATION"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="RoutingNumber"
              label="ROUTING NUMBER"
              onChange={onChange}
              name="RoutingNumber"
              autoComplete="RoutingNumber"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="AccountNumber"
              label="ACCOUNT NUMBER"
              onChange={onChange}
              name="AccountNumber"
              autoComplete="AccountNumber"
            />
          </Grid> 
        </Grid>
      </Grid>
    )
}