import React, { useState, useEffect,useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Box, 
  Card, 
  Divider,
  IconButton, 
  CardActionArea,
  makeStyles,
  createStyles,
  Container,
  Typography,
  Grid,
  DialogContentText
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent"; 
import { API } from "aws-amplify";
import CardActions from "@material-ui/core/CardActions";
import { LoaderCLickButton } from "../LoaderButton";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CloseIcon from "@material-ui/icons/Close";
import { openSnackbar } from "../Notifier";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import { trackPromise } from "react-promise-tracker";
import LoaderButton, {LoaderDeleteButton} from "../LoaderButton"; 
import { areas } from "../../common/constants/areas";
import usePersistedState from "../../usePersistedState";
import usePersistedStateString from "../../usePersistedStateString";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Skeleton } from "@material-ui/lab";
import { UserCreditCards } from "../../common/components/placeholder";
import { usePlaidLink} from 'react-plaid-link';
import AddCreditCard from './addCreditCard';
import Button from '@material-ui/core/Button';
import { AmericanExpress,BitCoin,Cirrus,DinersClub,Discover,Jcb,Maestro,MasterCard,Paypal,Visa,ACH } from '../../static/cardlogo/cards'; 
 
const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      marginTop: theme.spacing(8),
      flexDirection: "column",
      alignItems: "center",
      /*   minHeight: "600px", */
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    card: {
      display: "flex",
      /* padding: theme.spacing(2, 2,3, 2)  */
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      /*  width:"-moz-available" */
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: "170px",
      height: "170px",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    headerOne: {
      color: "rgba(0,0,0,1)",
      fontSize: "60px",
    },
    headerTwo: {
      color: "rgba(123,123,123,1)",
      fontSize: "60px",
    },
    headerThree: {
      color: "rgba(0,0,0,1)",
      fontSize: "22px",
    },
    headerfour: {
      color: "rgba(123,123,123,1)",
      fontSize: "20px",
    },
    headerFive: {
      color: "#7c7c7c",
      fontSize: "40px",
    },
    headerSix: {
      color: "rgba(0,0,0,1)",
      fontSize: "22px",
      fontWeight: "bold",
    },
    headerSeven: {
      color: "#7c7c7c",
      fontSize: "14px",
    },
    thankYouHTwo: {
      color: "rgba(123,123,123,1)",
    },
    thankYouHThree: {
      color: "rgba(123,123,123,1)",
      fontSize: "16px",
    },
    thankYouHFour: {
      color: "rgba(0,0,0,1)",
      borderLeft: "6px solid #ffe100",
      paddingLeft: "7px",
    },

    filterProperty: {
      margin: theme.spacing(1),
      minWidth: 220,
      width: 564,
    },
    filterUType: {
      margin: theme.spacing(1),
      minWidth: 120,
      float: "left",
    },
    filterLabel: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(4),
      width: "56px",
      color: "rgba(123,123,123,1)",
      fontSize: "17px",
      float: "left",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    papers: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    cards: {
      display: "flex",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
    rentCardClick: {
      display: "flex",
      width: "100%",
      background: "#3f51b52e",
      border: "1px solid #3f51b5",
      borderRadius: "5px",
      /*  opacity:"0.8", */
      boxShadow: "none",
    },
    rentCard: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px",
    },
    f: {
      display: "flex",
      width: "100%",
      border: "1px solid #DADADA",
      borderRadius: "5px",
      boxShadow: "none",
    },
    rentCardSelect: {
      display: "flex",
      width: "100%",
      border: "1px solid #6200EE",
      borderRadius: "5px",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "36%",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    payImg: {
      width: "100%",
      height: "100%",
    },
    payCardCont: {
      padding: "8px",
    },
    main: {
      maxWidth: "100% !important",
    },
    image: {
      height: 35,
      width: 35,
      display: "flex",
    },
    iconRoot: {
      textAlign: "center",
    },
  })
);
export function ManagePaymentMethod(props) {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory(); 
  const primaryCard = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cardForDelete, setCardForDelete] = useState({});
  const [userCards, setUserCards] = usePersistedState("userCards", []);
  const [linkToken,setLinkToken]=usePersistedStateString("linkToken",""); 
  const [plaidToken,setPlaidToken]=useState({"token":"","accountId":""});
  const [plaidOpen,setPlaidOpen]=useState(false);     
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const year = (new Date()).getFullYear();
  const [expYear] = useState(Array.from(new Array(20),(val, index) => (index + year).toString())); 
  const [expMonth] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);
  const [addCreditCard, setAddCreditCardOpen] = useState(false); 
  const [creditCardData, setCreditCardData] = useState({
    MasterID:"",
    NameOnCard: "",
    BillingAddress: "",
    Country:"",
    CountryCode:"",
    PostalCode: "",
    CardNumber: "",
    CvvCid: "",
    ExpiryMonth:"",
    ExpiryYear:"",
    UserName:props.userSession.UserName,
    RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
  }); 
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const onSuccess = useCallback(async (token, metadata) => {
     await setIsLoading(true);
    // send token to server
    console.log('onSuccess', token, metadata)
    console.log('Customer-selected account ID: ' + metadata.account_id);
    await setPlaidToken({token:token,accountId:metadata.account_id}); 
    await setPlaidOpen(false);
    await savePlaidData(token,metadata.account_id); 
  }, []);   
  const onEvent = useCallback(
    (eventName, metadata) => console.log('onEvent', eventName, metadata),
    []
  ); 
  const onExit = useCallback(
    async (err, metadata) => {
     console.log('onExit', err, metadata);
     await setPlaidOpen(false); 
     await setPlaidToken({token:"",accountId:""}); 
    },
    []
  ); 

  useEffect(() => { 
        if(selectedProperty.PlaidLinked && selectedProperty.PlaidLinked!=="Yes" && plaidOpen )
            open();
  },[plaidOpen]);
  useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) { 
        setIsLoading(true);
        if (isSubscribed) {
        if (selectedProperty.LinkToken && selectedProperty.LinkToken !== "")
             await setLinkToken(selectedProperty.LinkToken); 
        await getCardsData(props.userSession, selectedProperty.PropertyID, "getCards")
        .then(async (tablData) => {
          await setUserCards(tablData);
          await setIsLoading(false);
        });
        }
        return () => (isSubscribed = false);
      }
    };
    userFetch();
  }, [location]);
  const CardFetching = async (op) => {
    setIsLoading(true);
    if(selectedProperty.hasOwnProperty("PropertyID"))
    {
      trackPromise(
      getCardsData(props.userSession,selectedProperty.PropertyID, op)
      .then(async (tablData) => {
        await setUserCards(tablData); 
        await setIsLoading(false);
      }) 
        .catch((er) => {
          throw er;
        }),
      areas.renter
    );
  }
  };
  const getCardsData = async (userSession,propertyId, op) => {
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {
          masterType: ['Bank Account',"User Card"],
          CurrentUserID: userSession.UserName,
          PropertyId:propertyId,
          userData: userSession,
        },
        queryStringParameters: {
          module: "renter",
          op: op ? op : "getCardData",
        },
      };
      API.post(apiName, path, myInit)
        .then((response) => {
          console.log("### getcard data response",response);
          if(response)
            resolve(response);
          else 
          resolve(response);
            resolve([]);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };
  const openPlaidLink= async ()=>{
      console.log("link clicked");
      await setPlaidOpen(true); 
      console.log("plaid open",plaidOpen);
  }

  async function handleChildClickCreditCard(fieldName, value, child) {
    console.log("FieldName : value : child : ", fieldName , value, child)
    let ob = {};
    ob[fieldName] =  value;
    setCreditCardData({...creditCardData, ...ob})
    
  }

  function validateCreditCardForm() {
    console.log("Log validateCreditCardForm",creditCardData);
    
    return creditCardData.ExpiryMonth.length>0 && creditCardData.ExpiryYear.length>0 &&
    creditCardData.NameOnCard.length > 0 && creditCardData.BillingAddress.length > 0 &&
    creditCardData.Country.length > 0 && creditCardData.PostalCode.length > 0 && 
    creditCardData.CardNumber.length > 0 && creditCardData.CvvCid.length > 0;
}

async function saveCardData(e) {
  e.preventDefault();
  setIsLoadingModal(true);
  let apiName = "rentapi";
  let path = "/modules";
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: {...creditCardData,userData:props.userSession, MasterType: 'User Card', CurrentUserID: props.userSession.UserName, CurrentUserEmail:props.userSession.Email, CurrentUserName: props.userSession.FirstName + " " + props.userSession.LastName },
    queryStringParameters: {
      module: "renter",
      op: "saveCardData"
    }
  };
  API.post(apiName, path, myInit)
    .then(async response => {
      setIsLoadingModal(false);
      if(response && response.success)
      {
        console.log("response" + JSON.stringify(response));             
        openSnackbar({
          message:
          response.message,
          variant: "success"
        });
        setCreditCardData({
          MasterID:"",
          NameOnCard: "",
          BillingAddress: "",
          Country:"",
          PostalCode: "",
          CardNumber: "",
          CvvCid: "",
          ExpiryMonth:"",
          ExpiryYear:"",
          UserName:props.userSession.UserName,
          RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
        });
        setAddCreditCardOpen(false);
        setIsLoadingModal(false);
        await CardFetching("getCards");
      }
      else{          
        openSnackbar({ message:response.message, variant: "error" });
        setAddCreditCardOpen(false);
        setIsLoadingModal(false);
      }
    })
    .catch(err => {
      console.log(err);
      openSnackbar({ message: err.message, variant: "error" });
      setIsLoadingModal(false);
    });
} 
  async function changePrimaryCard(clickedCard,type) {
    console.log("CLICKED");
    let apiName = "rentapi";
    let path = "/modules";
    let  myInit = {
      headers: { "Content-Type": "application/json" },
      body:{},
      queryStringParameters: {
        module: "renter",
        op: "changePrimaryCard",
      }
    };
    if(type==='Bank Account')
    {
      myInit.body= {
        CurrentPrimaryCard: primaryCard.current,
        NewPrimaryCard: clickedCard,
        userData: props.userSession,
      };
    }
    else
    {
      myInit.body= {
        CurrentPrimaryCard: primaryCard.current,
        NewPrimaryCard: clickedCard,
        userData: props.userSession,
      };
    }
    

    API.post(apiName, path, myInit)
      .then(async (response) => {
        openSnackbar({ message: response.message, variant: "success" });
        await CardFetching("getCardData");
      })
      .catch((err) => {
        openSnackbar({ message: err.message, variant: "error" });
      });
  }
  function BootstrapTooltip(props) {
    const useStylesBootstrap = makeStyles((theme) => ({
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const classes = useStylesBootstrap();
    return  <Tooltip arrow classes={classes} {...props} />;
  } 
  async function deleteCard() {
    console.log("CLICKED ", cardForDelete);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        CardData: cardForDelete,
        userData: props.userSession,
        PropertyID:selectedProperty.PropertyID
      },
      queryStringParameters: {
        module: "renter",
        op: "deleteCard",
      },
    };

    API.post(apiName, path, myInit)
      .then(async (response) => {
        setDeleteOpen(false);
        openSnackbar({ message: response.message, variant: "success" });
        await CardFetching("getCardData");
      })
      .catch((err) => {
        setDeleteOpen(false);
        openSnackbar({ message: err.message, variant: "error" });
      });
  }
  const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
            <Typography variant="h2" component="h2">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

  const DialogTitleCreditCard = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }; 


  const savePlaidData = async (token,accountId ) => { 
    console.log("selectedProperty #########",selectedProperty);
    var propertyId=selectedProperty.PropertyID;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {"MasterType":"Bank Account","PropertyID":propertyId,"CurrentUserID": props.userSession.UserName, "userData": props.userSession,"token":token,"accountId":accountId },
        queryStringParameters: {
          module: "renter",
          op: "savePlaidData"
        }
      }; 
      API.post(apiName, path, myInit).then(async response => {
        console.log("card response", response);
        await setIsLoading(false);
        openSnackbar({ message: response.message, variant: "success" });
        await CardFetching("getCardData");
        resolve();
      }).catch(err => {
        console.log(err);   
        openSnackbar({ message: err.message, variant: "error" });
        reject(err);
      });
    });
  }  
  const config = {  
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  };
  const { open, ready, error } = usePlaidLink(config); 
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >      
      <Container component="main" className={classes.main}>
        <CssBaseline />
        <div className="home-paper">
          <Dialog
            open={deleteOpen}
            onClose={(e) => setDeleteOpen(false)}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle
              className="addnew-tenantUser-dialog-title"
              id="customized-dialog-title"
              onClose={(e) => {
                setDeleteOpen(false);
              }}
            >
              Are you sure to delete this card?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                It cannot be reversed.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '8px 24px' }}>
              <Grid
                container
                direction='row'
                spacing={2}
                alignItems='flex-start'
              >
                <Grid item>
                  <LoaderCLickButton
                    onClick={(e) => {
                      deleteCard();
                    }}
                    isLoading={isLoading}
                  >
                    DELETE
                  </LoaderCLickButton>
                </Grid>
                <Grid item>
                  <LoaderDeleteButton
                    isLoading={isLoading}
                    size='large'
                    onClick={() => { setDeleteOpen(false); }}
                  >
                    CANCEL
                  </LoaderDeleteButton>
                </Grid>
              </Grid>
              <div style={{ flex: '1 0 0' }} />
            </DialogActions>
          </Dialog>
          <Dialog
            open={addCreditCard}
            onClose={(e) => setAddCreditCardOpen(false)}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitleCreditCard
              className="addnew-user-dialog-title"
              id="addnew-user-dialog-title"
              onClose={(e) => {
                setAddCreditCardOpen(false);
              }}
            >
              <div className={"skelton-flex-parent"}>
                {isLoadingModal && (
                  <Skeleton
                    variant="rect"
                    height={55}
                    className={
                      "skelton-margin-right-5 skelton-margintop-2p addcreditcard-skelton-minwidth"
                    }
                  />
                )}
                {!isLoadingModal && <div>Add Credit Card</div>}
              </div>
            </DialogTitleCreditCard>

            <DialogContent className="addtenant-dialog-content">
              <AddCreditCard
                creditCardData={creditCardData}
                isLoading={isLoadingModal}
                onChildClick={(fieldName, value) =>
                  handleChildClickCreditCard(fieldName, value)
                }
              />
            </DialogContent>
            <Divider variant="middle" />

            <DialogActions
              className={"addcreditcard-DialogActions addcreditcard-modal-bt"}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <LoaderButton
                    block="true"
                    type="submit"
                    size="large"
                    //isLoading={isLoading}
                    disabled={!validateCreditCardForm()}
                    onClick={saveCardData}
                  >
                    + SAVE CARD
                  </LoaderButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
     {/*      <div style={{ padding: "20px 0px 20px 0px" }}></div> */}
          <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
            history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
          }}>
            <Grid item>
              <Button>
                <div className="addlease-top-flex-container">
                  <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                </div>
              </Button>
            </Grid>
          </Grid>

          <Box mb={6}>
            {!isLoading && (
              <Typography
                component="h1"
                className={"renter-headerOne"}
                variant="h1"
              >
                Manage Payment Method
              </Typography>
            )}
            {isLoading && <Skeleton variant="rect" width={553} height={69} />}
          </Box>
          <Box mb={7}>
            {!isLoading && (
              <Typography
                className={"renter-headerFive"}
                variant="subtitle1"
              >
                You can add new credit card or bank account to your account.
                Also, you can remove existing cards from the list given below.
              </Typography>
            )}
            {isLoading && <Skeleton variant="rect" width={931} height={28} />}
          </Box>
          <Box mb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mb={3}>
                  {!isLoading && (
                    <Typography
                      component="h3"
                      className={"renter-headerFive"}
                      variant="h3"
                    >
                      Add New
                    </Typography>
                  )}
                  {isLoading && (
                    <Skeleton variant="rect" width={369} height={45} />
                  )}
                </Box>
                <Box mb={3}>
                  <Grid container spacing={4} justify="space-between">
                    <Grid item lg={6} sm={6} xl={6} xs={12}>
                      {!isLoading && (
                        <Card className={classes.rentCard}>
                          <CardActionArea
                            style={{ display: "flex" }}
                            onClick={() => { 
                              setAddCreditCardOpen(true);
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: "19px",
                                color: "white",
                                backgroundColor: "#2404D5",
                              }}
                            >
                              <Grid
                                container
                                alignItems="center"
                                direction="row"
                              >
                                <Grid item xs={4}>
                                  <img
                                    alt="CREDIT CARD"
                                    className={classes.image}
                                    src={"/img/credit_card-white-24px.svg"}
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>{"CREDIT CARD"}</Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      )}
                      {isLoading && <Skeleton variant="rect" height={76} />}
                    </Grid>
                   {/* Uncomment for ach release*//*  <Grid item lg={6} sm={6} xl={6} xs={12}> 
                      {!isLoading && (
                        <Card className={classes.rentCard}>
                          <CardActionArea
                            style={{ display: "flex" }}
                            onClick={async () => {
                              if (
                                selectedProperty.PlaidLinked  &&
                                selectedProperty.PlaidLinked !== "Yes" && selectedProperty.LinkToken !==""
                              ) {
                                console.log('plaid config not ready');
                                await openPlaidLink();
                              }
                              else {
                                openSnackbar({ message: 'Bank account payment is not enabled, Please try other payment method', variant: "error" });
                              }
                            }}
                          >
                            <BootstrapTooltip
                              title={
                                selectedProperty.PlaidLinked &&
                                selectedProperty.PlaidLinked !== "Yes"
                                  ? ""
                                  : "Apologies, you can only link one account at time. Please remove account on file to proceed."
                              }
                            >
                              <CardContent
                                style={{
                                  width: "100%",
                                  padding: "19px",
                                  color: "white",
                                  backgroundColor:
                                  selectedProperty.PlaidLinked &&
                                  selectedProperty.PlaidLinked !== "Yes"
                                      ? "#D50454"
                                      : "#6E6E6E",
                                }}
                              >
                                <Grid
                                  container
                                  justify="center"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Grid item xs={4}>
                                    <img
                                      alt="BANK ACCOUNT"
                                      className={classes.image}
                                      src={
                                        "/img/account_balance-white-24px.svg"
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography>{"BANK ACCOUNT"}</Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </BootstrapTooltip>
                          </CardActionArea>
                        </Card>
                      )}
                      {isLoading && <Skeleton variant="rect" height={76} />}
                    </Grid> */}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mb={3}>
                  {!isLoading && (
                    <Typography
                      component="h3"
                      className={"renter-headerFive"}
                      variant="h3"
                    >
                      Your payment method on file
                    </Typography>
                  )}
                  {isLoading && (
                    <Skeleton variant="rect" width={369} height={45} />
                  )}
                </Box>
                <Box mb={3}>
                  <Grid container spacing={4}>
                    {!isLoading &&
                      userCards.map((e, keyIndex) => {
                        if (e.Primary === "Yes") primaryCard.current = e; // setPrimaryAccount(e);
                        return e.MasterType === "User Card" ? (
                          <Grid key={keyIndex} item xs={10}>
                            <Card className={classes.rentCard}>
                              <CardContent style={{ width: "100%" }}>
                                <Box alignItems="center" display="flex">
                                  <Box mr={6}>
                                    {e.CardType && e.CardType == "visa" ? (
                                      <Visa />
                                    ) : e.CardType &&
                                      e.CardType == "mastercard" ? (
                                      <MasterCard />
                                    ) : e.CardType &&
                                      e.CardType == "american-express" ? (
                                      <AmericanExpress />
                                    ) : e.CardType &&
                                      e.CardType == "discover" ? (
                                      <Discover />
                                    ) : e.CardType &&
                                      e.CardType == "maestro" ? (
                                      <Maestro />
                                    ) :e.CardType &&
                                    e.CardType == "diners-club" ? (
                                    <DinersClub />
                                  ) :e.CardType &&
                                  e.CardType == "jcb" ? (
                                  <Jcb />
                                ) : (
                                     <CreditCardIcon fontSize="large"  />
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography>
                                      {e.DisplayCardNo} exp:{e.ExpiryMonth}/
                                      {e.ExpiryYear}{" "}
                                      {e.Primary === "Yes" ? "(Primary)" : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </CardContent>
                              <CardActions>
                                <Grid container direction="row">
                                  <Grid item xs={6}>
                                    <BootstrapTooltip
                                      title={
                                        e.Primary !== "Yes"
                                          ? "Make Primary"
                                          : "Remove Primary"
                                      }
                                    >
                                      <IconButton
                                        color={
                                          e.Primary !== "Yes"
                                            ? "primary"
                                            : "secondary"
                                        }
                                        onClick={() => {
                                          changePrimaryCard(e);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                      >
                                        {e.Primary !== "Yes" ? (
                                          <FavoriteBorderOutlinedIcon />
                                        ) : (
                                          <FavoriteIcon />
                                        )}
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <BootstrapTooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          setCardForDelete(e);
                                          setDeleteOpen(true);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        ) :<Grid key={keyIndex} item xs={10}>
                        <Card className={classes.rentCard}>
                          <CardContent style={{ width: "100%" }}>
                            <Box alignItems="center" display="flex">
                              <Box mr={6}>
                                <ACH />
                              </Box>
                              <Box>
                                <Typography>{e.BankAccount}</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Grid container direction="row">
                              <Grid item xs={6}>
                                <BootstrapTooltip
                                  title={
                                    e.Primary !== "Yes"
                                      ? "Make Primary"
                                      : "Remove Primary"
                                  }
                                >
                                  <IconButton
                                    color={
                                      e.Primary !== "Yes"
                                        ? "primary"
                                        : "secondary"
                                    }
                                    onClick={() => {
                                      changePrimaryCard(e, "Bank Account");
                                    }}
                                    className={classes.button}
                                    aria-label="Favourite"
                                  >
                                    {e.Primary !== "Yes" ? (
                                      <FavoriteBorderOutlinedIcon />
                                    ) : (
                                      <FavoriteIcon />
                                    )}
                                  </IconButton>
                                </BootstrapTooltip>
                              </Grid>
                              <Grid item xs={6}>
                                <BootstrapTooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      setCardForDelete(e);
                                      setDeleteOpen(true);
                                    }}
                                    className={classes.button}
                                    aria-label="Favourite"
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </BootstrapTooltip>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                      })}
                    {isLoading &&
                      [1, 2, 3, 4].map((keyIndex) => {
                        return <UserCreditCards key={keyIndex} />;
                      })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </Box>
  );
}
