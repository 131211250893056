import React, { useState } from "react";
import clsx from 'clsx';
import Container from "@material-ui/core/Container";
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom"; 
import { Auth} from "aws-amplify";
import LoaderButton from "./LoaderButton";
import { openSnackbar } from '../components/Notifier'; 
import portaLogo from "../static/images/leaseleads_logo_Rectangle_3.png";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import md5 from 'crypto-js';
const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },  
  header: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },
  myImage: {
    width: "auto",
    height:"103px"
  },
  margin: {
    marginTop: theme.spacing(2),
  },  
  typography: {
    align: "center",
    textAlign: 'center',
    marginTop: theme.spacing(7),
    flexGrow: 1,
  }
}));
export default function NotFound() {
  const classes = useStyles();
  var path ="#";
  if (typeof window !== 'undefined') {
    path = window.location.protocol + '//' + window.location.host;
  } else {
    path="#"
  }
  return (
    <Container component="main">
      <CssBaseline />

      <div className={classes.paper}>
      <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item>
          <MLink color="inherit" href={path}>
          <img alt={'card image'} src={portaLogo} className={classes.myImage} />
      </MLink>           
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h5"   className={classes.typography}>
            Oops, something went wrong. <br/>We apologize this happened! One of our engineers is looking into this issue!<br/><br/>If you have questions in the meantime, please contact: support@leaseleads.com
            </Typography>
          </Grid>
        </Grid>
      </div>       
      </Container>
  );
}