import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { Storage, Auth, API } from 'aws-amplify';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl'; 
import Button from '@material-ui/core/Button';
import { LoaderDeleteButton } from "../LoaderButton";
import md5 from 'crypto-js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import ChangeEmail from "../ChangeEmail";
import { openSnackbar } from '../Notifier'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import { Skeleton } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from "@material-ui/icons/Add";
import { S3Image } from 'aws-amplify-react';
import Fab from "@material-ui/core/Fab";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import { trackPromise } from 'react-promise-tracker'; 
import { areas } from '../../common/constants/areas';
import usePersistedState from '../../usePersistedState';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2'; 
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
/* const MapData = window['getConfig']; */
const useStyles = makeStyles(theme =>
  createStyles({ 
    newpasswordmargin: {
      marginTop: theme.spacing(3)
    }, 
    input: {
      display: 'none',
    },  
  })
); 
export function ManageUser(props) {
  const location = useLocation();
  let history = useHistory(); 
  const [isEditing, setIsEditing] =usePersistedState("isEditing",false); // useState(false);
  const [fromGrid, setFromGrid] = usePersistedState("fromGrid",false); //useState(props.fromGrid); 
  const [isLoading, setIsLoading] = useState(false);     
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState(""); 
  const [passOpen, setpassOpen] = useState(false);
  const [user, setUser] =usePersistedState("user",{ "PropertyID": [], "PassWord": "", "Privilege": "RENTERS" });// useState({ "PropertyID": [], "PassWord": "" });      
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false); 
  const [deltedKey] = useState([]);  
  const [deleteOpen, setDeleteOpen] = useState(false);
  useEffect(() => {
    let isSubscribed = true;    
    console.log(location.pathname); // result: '/secondpage'   
    console.log(location.props); // result: 'some_value' 
    const userFetch=async ()=> {
      if(location.props && isSubscribed)
      {        
        if(location.pathname==='/profile')
        {          
          trackPromise(
            GetUserProfile(),
            areas.admin,
          ); 
        }
        else
        {
          if (location.props
            && location.props.rowEditData
            && Object.keys(location.props.rowEditData).length)
            await setUser(location.props.rowEditData);
          if (!location.props.isEditing) {
            await setUser({ ...user, "PropertyID": [{ PropertyID: selectedProperty.PropertyID, PropertyName: selectedProperty.PropertyName }], "PassWord": "", "Privilege": "RENTERS" });
          }
        }
      
       //commented because, on App bar, profile click, isEditing is true location.props.rowEditData = [], so will become empty.
       //await setUser(location.props.rowEditData ? location.props.rowEditData : { "PropertyID": [], "PassWord": "" });      
       await setIsEditing(location.props.isEditing);       
       await setFromGrid(location.props.fromGrid);      
      }
      else
      {
        if (isEditing)
          {
            let userFromCache = JSON.parse(window.localStorage.getItem('user') || {});
            setUser({ ...userFromCache, ...{"PropertyID": [{PropertyID :selectedProperty.PropertyID, PropertyName: selectedProperty.PropertyName}]} })
          }
      }   
      return () => isSubscribed = false
    }
    userFetch();
  }, [location]);
  

  useEffect(() => {
    if(!isEditing) setUser({ ...user, "Privilege": 'RENTERS' })
  },[isEditing])


  async function GetUserProfile() {
    console.log("UserID ", props.userSession.UserName);
    setIsLoading(true);
    var columns = [];
    var masterType = "User";
    var apiModule = "user";
    var op = "getUser";
    var userData = { "userData": props.userSession }
    let body = { masterType: masterType, MasterID: props.userSession.UserName, for: "CardDisplay", ...userData };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          setIsLoading(false);
          if (response && response.length > 0) {
            window.localStorage.setItem('user', JSON.stringify({ ...user, ...response[0] }));
            setUser({ ...user, ...response[0] });
            console.log("prop lateMinutes changed 1 : " , response[0],  user);
          }          
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }   
  const handleClickOpen = (e) => {   
    if (e === "email") {
      setEmailOpen(true);
    }
    if (e === "password") { 
      setpassOpen(true);
    } 
    if (e === "delete") {
      setDeleteOpen(true); 
    } 
  };
  const handleClose = (e) => {   
    if (e === "email") {
      setEmailOpen(false);
    }
    if (e === "password") {
      setpassOpen(false);
    }
    if (e === "delete") {
      setDeleteOpen(false); 
    }  
  };
const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h2"component = "h2">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  function imageDelete(e) {
    console.log("DeletedKeyset,e.FileKey", e);
    let items = deltedKey;
    if (items.length > 0) {
      for (let i = 0; i < items; i++) {
        if (items[i].FileKey !== e.FileKey) {
          items.push(e.FileKey);
        }
      }
    }
    else {
      items.push(e.FileKey);
    }
    Object.assign(deltedKey, items);
    setUser({ ...user, "ProfileImage": {} })
    console.log("DeletedKeyset,go and  delete deltedKey", deltedKey);

  }
  const ImageComponent = props => { 
    return (<div>
      <Card className="addnew-user-card1">
        <div className="addnew-user-details">
          <CardActions>
            <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
              <CloseSharpIcon />
            </Fab>
          </CardActions>
        </div>
        <CardMedia>
          <S3Image theme={{ photoImg: { maxWidth: '159px', maxHeight: '159px', display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={props.FileKey} />
        </CardMedia>
      </Card>
    </div>);
  };
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }  
  function onChange(e, child) {  
    if (e.target.name === "currentPassword") setCurrentPassword(e.target.value);
    if (e.target.name === "newPassword") setNewPassword(e.target.value);
    if (e.target.name === "firstname") setUser({ ...user, "FirstName": e.target.value })
    if (e.target.name === "lastName") setUser({ ...user, "LastName": e.target.value })
    if (e.target.name === "password") setUser({ ...user, "PassWord": e.target.value })
    if (e.target.name === "unit") setUser({ ...user, "Unit": e.target.value })
    if (e.target.name === "privilege") setUser({ ...user, "Privilege": e.target.value })
    if(e.target.name==="phonenumber") setUser({ ...user, "PhoneNumber": e.target.value }) 
    if (e.target.name === "email") {
      setUser({ ...user, "Email": e.target.value, "UserName": e.target.value }); 
    }; 
    if (e.target.name === "ProfileImage") {
      const target = e.target; 
      const file = target.files[0];
      let targetName = target.name;
      var img = document.createElement("img");
      img.onload = function () {
        console.log(this.width + " " + this.height);
        if (targetName === "ProfileImage" && this.width <= 500 && this.height <= 500) {

          let fileName = file.name;
          let fileExt = getFileExtension(fileName);
          let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
          const fileData = { FileKey: s3Key, FileName: fileName };
          Storage.put(s3Key, file, {
            contentType: file.type
          })
            .then(result => {
              console.log(result);
              setUser({ ...user, "ProfileImage": fileData });
            })
            .catch(err => console.log(err));
        }
        else {
          openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });
        }
      };
      let sfile = target.files[0]
	    if (sfile) {
        var reader = new FileReader();
        reader.onloadend = function(ended) {
          img.src = ended.target.result;
        };
        reader.readAsDataURL(file);
        console.log("img",img);
      }
    } 
  };
  function validateForm() {
    console.log("validate form : " , user.Privilege , user.PropertyID);
    return user !== null && user.FirstName && user.FirstName.length > 0 && user.LastName && user.LastName.length > 0 &&
      (user.Privilege == "ADMIN" || (user.PropertyID && user.PropertyID.length > 0)) &&
      /* (isEditing || (user.Privilege && user.Privilege.length > 0)) */
      (isEditing || (user.Email && user.Email.length > 0));
  };

  function validateChangePassword() {
    return currentPassword != "" && newPassword != "";
  }

  function validatePasswordEmailForm() {
    console.log("VALIDATE PASSWORD ", isEditing);
    return isEditing;
  }

  function handleClearForm() {
    setUser({ "PropertyID": [], "PassWord": "", "Privilege": "RENTERS" });
    document.getElementById("user-form").reset();
  } 
  function handleClickShowNewPassword() {
    console.log("Handle show ", showNewPassword);
    setShowNewPassword(!showNewPassword);
    console.log("Handle show ", showNewPassword);
  }; 
  function handleMouseDownNewPassword(e) {
    e.preventDefault();
  }; 
  function handleClickShowCurrentPassword() {
    console.log("Handle show ", showCurrentPassword);
    setShowCurrentPassword(!showCurrentPassword);
    console.log("Handle show ", showCurrentPassword);
  }; 
  function handleMouseDownCurrentPassword(e) {
    e.preventDefault();
  }; 
  async function updatepropertyDB(res) {
    console.log("ADD USER TO DB");
    setIsLoading(true);
    let apiName = "rentapi";
    let path = '/store/updateUserProperty';
    var body = {};
    body.PropertyID = user.PropertyID;
    body.MasterID = res.userSub;
    body.email = user.Email;
    body.name = user.FirstName + ' ' + user.LastName;
    body["custom:firstname"] = user.FirstName;
    body["custom:lastname"] = user.LastName;
    body["custom:privilege"] = user.Privilege;
    body["custom:unit"] = user.Unit;
    body["custom:domain"] = MapData.aws_app_domain_prefix;
    if(user.PhoneNumber && user.PhoneNumber!=="") body.PhoneNumber= user.PhoneNumber;
    if (user.ProfileImage && user.ProfileImage.hasOwnProperty("FileKey") && user.ProfileImage.FileKey.length > 0) body.ProfileImage = user.ProfileImage;
    body.userData = props.userSession;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setIsLoading(false);     
      openSnackbar({ message: "Invitation sent out" + user.Email, variant: "success" });
      handleClearForm();
      history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err, variant: "error" });

    });
  } 
  async function changePassword(event) {
    event.preventDefault();
    console.log("CHANGE PASSWORD USER ", newPassword + " : " + currentPassword + " : " + md5.MD5(newPassword).toString() + " : " + md5.MD5(currentPassword).toString());

    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, md5.MD5(currentPassword).toString(), md5.MD5(newPassword).toString());
      })
      .then(data => {
        console.log("CHANGE PASSWORD ", data);
        if (data == "SUCCESS") {
          // handleModal(false);
          setNewPassword(""); 
          setCurrentPassword("");
          handleClose("password");
          openSnackbar({
            message: "Password was successfully changed",
            variant: "success"
          });
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        openSnackbar({ message: err.message, variant: "error" });
      });
  } 
  async function addNewUser(event) {
    console.log("ADD NEW USER ", event, user.Privilege);
    event.preventDefault();
    setIsLoading(true);

    console.log("ADD NEW USER DATA ", user.UserName);

    var username = user.Email;
    var password = md5.MD5(user.PassWord).toString();
    var attrVal = {
      email: user.Email,
      name: user.FirstName + ' ' + user.LastName,
      "custom:firstname": user.FirstName,
      "custom:lastname": user.LastName,
      "custom:privilege": user.Privilege,
      "custom:domain": MapData.aws_app_domain_prefix
    }; 
    if (user.Unit && user.Unit !== "" && user.Privilege !== "BILLING" && user.Privilege !== "ADMIN") {
      attrVal["custom:unit"] = user.Unit;
    }
    trackPromise(   
    Auth.signUp({
      username,
      password,
      attributes: attrVal
    })
      .then(response => {
        //setIsLoading(false);
        console.log("response" + JSON.stringify(response));
        if (response.UserSub !== "") {
          console.log("Successful userSub" + response.UserSub);
          console.log("Successful CodeDeliveryDetails" + response.CodeDeliveryDetails);     
          updatepropertyDB(response);
        }
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: "error" });
        setIsLoading(false);
      }), areas.admin);
  } 
  async function updateUser(event) {
    event.preventDefault();
    setIsLoading(true); 
    if (user.hasOwnProperty("PassWord") && user.PassWord.length > 0) {
      user.PassWord = md5.MD5(user.PassWord).toString();
    }
    user.getMaster = props.userSession;
    console.log("ProfileImage", user.ProfileImage);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: user,
      queryStringParameters: {
        module: "user",
        op: "updateUser"
      }
    };
    trackPromise(   
    API.post(apiName, path, myInit).then(response => {
      if(response.success)
      {
        console.log("UPDATE USER", response);
        setIsLoading(false);
        openSnackbar({
          message:
            response.message,
          variant: "success"
        });  
        handleClearForm();
        if(location.pathname!=='/profile')
              history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
        else 
            history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
      }
      else{        
        setIsLoading(false);
        openSnackbar({ message:response.message, variant: "error" });
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: "Network problem, Please try after refresh!", variant: "error" });

    }), areas.admin);
  };
  async function disableUser() {
    console.log("DISABLE USER ");     
    setIsLoading(true);
    user.userData = props.userSession;
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: user,
      queryStringParameters: {
        module: "user",
        op: "disableUser"
      }
    };
    console.log("PROPERTY OBJECT ", myInit);
    trackPromise(  
    API.post(apiName, path, myInit).then(response => {

      console.log("UPDATE USER", response);
      setDeleteOpen(false);
      setIsLoading(false);
      openSnackbar({
        message:
          response.message,
        variant: "success"
      });
      if (fromGrid && user.Email !== props.userSession.Email) {
        setIsLoading(false);  
        history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
      }
      else {
        Auth.signOut();
        props.userHasAuthenticated(false);
        props.history.push("/");
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: "error" });
    }), areas.admin);
  }
  const classes = useStyles();
  return (
    <div className="home-root"> 
      <Dialog open={passOpen} onClose={e => { handleClose("password") }} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="customized-dialog-title">
        <DialogTitle  id="customized-dialog-title" onClose={e => { handleClose("password") }}>
          Change Your Password
        </DialogTitle>
        <DialogContent >
          <Grid container direction="column">
            <FormControl fullWidth required className="addnew-user-currentpasswordmargin" variant="outlined">
              <InputLabel htmlFor="currentPassword">Verify Your Old Password </InputLabel>
              <OutlinedInput
                id="currentPassword"
                name="currentPassword"
                type={showCurrentPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={onChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownCurrentPassword}
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={240}
              />
            </FormControl>
            <FormControl fullWidth required className={clsx(classes.newpasswordmargin)} variant="outlined">
              <InputLabel htmlFor="newPassword">Enter Your New Password</InputLabel>
              <OutlinedInput
                id="newPassword"
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={onChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={240}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions  style={{padding: '8px 24px 16px 24px'}} >

          <LoaderCLickButton
            //size="large"
            onClick={e => changePassword(e)}
            isLoading={isLoading}
            disabled={!validateChangePassword()}
          >
            UPDATE
                  </LoaderCLickButton>
                  <div style={{flex: '1 0 0'}} />
        </DialogActions>
      </Dialog>    
      <Dialog open={emailOpen} onClose={e => handleClose("email")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle className="addnew-user-dialog-title" id="email-dialog-title" onClose={e => handleClose("email")}>
          Change Email
        </DialogTitle>
        <DialogContent style={{padding: '8px 24px 16px 24px'}}>
          <ChangeEmail {...props} />
        </DialogContent>
      </Dialog> 
      <Dialog open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle className="delete-dialog-title" id="delete-dialog-title" onClose={e => handleClose("delete")}>
         Delete Confirmation
        </DialogTitle>
        <DialogContent>
          {fromGrid ? <DialogContentText>
            {`${user.Email} will no longer be able to access this account.`}
          </DialogContentText> :
            <DialogContentText>
              You are about to delete your account.<br />
              If you confirm the deletion request, your data will be removed from our system immediately!
            </DialogContentText>}
        </DialogContent>
        <DialogActions className={"addTenant-DialogActions"} style={{ padding: '8px 24px' }} >
          <LoaderCLickButton
            onClick={e => disableUser()}
            isLoading={isLoading}
          >
            YES
          </LoaderCLickButton>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </Dialog>
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <div className="home-paper">
              <div style={{ padding: location.pathname==='/profile'?" 20px 0px 40px 0px":"20px 0px 40px 0px" }}>

                <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                  history.push(location.pathname==='/profile' ? '/' : `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
                }}>
                  <Grid item>
                    <Button>
                      <div className="addlease-top-flex-container">
                        <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                      </div>
                    </Button>
                  </Grid>
                </Grid>


                <Grid container
                  spacing={1}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"> 
                  <Grid item>
                  { !isLoading && (!isEditing || (isEditing && user.Name)) && 
                    <Typography
                      component="h1"
                      className="home-headerOne"
                      variant="h1"
                    >
                      {isEditing ? user.Name : "Add Tenant"}
                    </Typography>
                    
                    }
                    {isLoading && <Skeleton  variant="rect" className={"skelton-max-width-407 skelton-min-width-400"} height={69}/>}
                  </Grid>
                </Grid>
              </div>
              <form
            id="user-form"
            className="addnew-user-form"
            noValidate
            autoComplete="off"
            onSubmit={isEditing== true ? updateUser: addNewUser}
          >           
            <Grid container   direction="column" spacing={2}>           
              <Grid item xs={12} sm={12} md={6} lg={6}>
              {!isLoading && 
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="EMAIL"
                  disabled={validatePasswordEmailForm()}
                  onChange={onChange}
                  name="email"
                  autoComplete="off"
                  value={user && user.Email ? user.Email: ""}
                  
                  InputProps={isEditing && !fromGrid?{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={()=>{ handleClickOpen("email");}}>
                          <EditIcon  />
                        </IconButton>
                      </InputAdornment>
                    )
                  }:null}
                />
              }
              {isLoading && <Skeleton variant="rect"  height={52}/>}
              </Grid>          
              <Grid item xs={12} sm={12} md={6} lg={6}>
              {!isLoading && 
                <TextField
                  autoComplete="fname"
                  name="firstname"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstname"
                  label="FIRST NAME"
                  onChange={onChange}
                  
                  value={user && user.FirstName ? user.FirstName: ""}
                />
                }
                {isLoading && <Skeleton variant="rect"  height={52}/>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
              {!isLoading && 
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="LAST NAME"
                  onChange={onChange}
                  name="lastName"
                  autoComplete="lname"
                  value={user && user.LastName ? user.LastName : ""}
                />
                }
                {isLoading && <Skeleton variant="rect"  height={52}/>}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
              {!isLoading && 
                <TextField
                  variant="outlined"
                  fullWidth
                  id="phonenumber"
                  label="PHONE NUMBER"
                  onChange={onChange}
                  name="phonenumber"
                  autoComplete="lname"
                  value={user && user.PhoneNumber ? user.PhoneNumber : ""}
                />
                }
                {isLoading && <Skeleton variant="rect"  height={52}/>}
              </Grid>
              { user.Privilege !== "BILLING" && user.Privilege !== "ADMIN" && 
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!isLoading && 
                <TextField
                  variant="outlined"                 
                  fullWidth
                  name="unit"
                  label="UNIT #"
                  onChange={onChange}
                  type="text"
                  id="unit"
                  autoComplete="current-unit"
                  value={user && user.Unit ? user.Unit : ""}
                />
                }
                {isLoading && <Skeleton variant="rect"  height={52}/>}
              </Grid>}
              {user.ProfileImage && user.ProfileImage.hasOwnProperty("FileName")?
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!isLoading && 
          <ImageComponent Type={"ProfileImage"} FileKey={user.ProfileImage.FileKey} FileName={user.ProfileImage.FileName} />
        }
        {isLoading && <Skeleton variant="rect" />}
           
          </Grid>
           :
           <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container direction="row" spacing={2} className={"skelton-flex-parent skelton-float-left"}>
              <Grid item>                
                <input name='ProfileImage'  onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                <label htmlFor="icon-button-file1">
                { !isLoading && 
                  <Fab color="primary" size="small" color="secondary" aria-label="upload picture" component="span">
                    <AddIcon />
                  </Fab>
                }
                  { isLoading && <Skeleton variant="circle" width={40} height={40}/>}
                </label>
              </Grid>
              <Grid item>
              {!isLoading && <div>
                <Typography variant="h6"> Upload Profile Photo</Typography>
                <Typography variant="caption">500x500 - png, jpeg, gif</Typography></div>
              }
                {isLoading && <Skeleton variant="rect" className={"skelton-min-width-190"} height={52}/>}
              </Grid>
            </Grid>
            </Grid>
            }
            </Grid>
            <div  className="addnew-div">
              <Grid
                container
                direction="row" 
                spacing={2}
                alignItems="flex-start"
              >
                <Grid item> 
                {!isLoading && 
                  <LoaderButton 
                    type="submit"
                    size="large"
                    
                    isLoading={isLoading}
                    disabled={!validateForm()}
                  >
                    {isEditing && fromGrid ? "UPDATE": isEditing && !fromGrid? "UPDATE PROFILE" : "+ ADD"}
                  </LoaderButton>
                  }
                  {isLoading && <Skeleton variant="rect" className={"skelton-min-width-200 skelton-max-width-200"} height={52}/>}
                </Grid>

                {isEditing && !fromGrid && props.userSession.Email === user.Email && props.userSession.Privilege !== "ADMIN" && 
                <Grid item>
                <LoaderCLickButton                   
                    isLoading={isLoading}
                    size="large"
                    disabled={!validateForm()}
                    onClick ={() => {
                      setNewPassword("");
                      setCurrentPassword("");
                      handleClickOpen("password");
                    }}
                  >
                   CHANGE PASSWORD
                  </LoaderCLickButton>
                </Grid>
                }   
              {isEditing /* && fromGrid && (props.userSession.Privilege == "ADMIN") && (user.Email != "ADMIN") */ &&  
              <Grid item>
              {!isLoading && !fromGrid &&
              <LoaderDeleteButton
              block="true" 
              size="large"
              isLoading={isLoading}
              onClick ={() => {handleClickOpen("delete")}}>
                 DELETE YOUR ACCOUNT
              </LoaderDeleteButton>
              }
              {isLoading && <Skeleton variant="rect" className={"skelton-min-width-259 skelton-max-width-259"} height={52}/>}
              </Grid>}             
              </Grid>
            </div>       
          </form>
     
            </div>
          </Container>
        </Grid>
      </Grid>     
    </div>)
}