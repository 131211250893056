import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Storage, API } from 'aws-amplify';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import { openSnackbar } from '../Notifier';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { S3Image } from 'aws-amplify-react';
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import usePersistedState from '../../usePersistedState';
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { useFormik } from 'formik';
import * as yup from 'yup';
import FocusError from "../../lib/FocusError";

const MapData = window['countryConfig'];
const ImpStyles = makeStyles(theme => ({
  card1: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "215px",
    maxWidth: "215px"
  },
  card2: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "315px",
    maxWidth: "315px"
  },
  details: {
    display: "flex"
  },
}));
const useStyles = makeStyles((theme) => createStyles({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.

    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  myImage: {
    width: "100%"
  },
  input: {
    display: 'none',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    wordWrap: "nowrap",
    paddingLeft: "9px"
  },
}));
const validationSchema = yup.object({
  PropertyName: yup
    .string('Enter property name')
    .required('Property name is required'),
  Email: yup
    .string('Enter property email')
    .email('Enter a valid email')
    .required('Email is required'),
  BusinessAddress: yup
    .string('Enter your Business address')
    .max(250, 'Address should be of maximum 250 characters length')
    .required('Business address is required'),
  Phone: yup
    .string('Enter your Phone number')
    /* .Phone('Enter a valid phone number') */
    .required('Phone number is required'),
  WebSite: yup
    .string('Enter your website')
    .required('Website URL is required'),
});

export const getFieldErrorNames = formikErrors => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

export function SettingsGeneral(props) {
  console.log("ADD NEW PROPERTY PROPS ", props);
  let history = useHistory();
  const classes = useStyles();
  const inputSize = props.fromRent ? 12 : 6;
  const [isEditing] = useState(true);
  const [property, setProperty] = usePersistedState("property", {
    PropertyName: ""
    , MasterID: ""
    , OwnerFirstName: ""
    , OwnerLastName: ""
    , Email: ""
    , PropertyUnit: ""
    , BusinessAddress: ""
    , City: ""
    , State: ""
    , Country: ""
    , PostalCode: ""
    , Phone: ""
    , WebSite: ""
    , PropertyLogo: {}
    , PropertyImage: {}
  })
  const [deltedKey] = useState([]);
  const [fromRent, setFromRent] = usePersistedState("fromRent", false);
  const [isLoading, setIsLoading] = useState(false);
  const [comboDisable, setcomboDisable] = useState(true);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [isStateSelect, setIsStateSelect] = useState(false);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
  console.log("editing", isEditing);
  const formik = useFormik({
    initialValues: {
      PropertyName: selectedProperty.PropertyName
      , MasterID: selectedProperty.MasterID
      , OwnerFirstName: selectedProperty.OwnerFirstName
      , OwnerLastName: selectedProperty.OwnerLastName
      , Email: selectedProperty.Email
      , PropertyUnit: selectedProperty.PropertyUnit
      , BusinessAddress: selectedProperty.BusinessAddress
      , City: selectedProperty.City
      , State: selectedProperty.State
      , Country: selectedProperty.Country
      , PostalCode: selectedProperty.PostalCode
      , Phone: selectedProperty.Phone
      , WebSite: selectedProperty.WebSite
      , PropertyLogo: selectedProperty.PropertyLogo
      , PropertyImage: selectedProperty.PropertyImage
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveProperty(values);
    },
  });
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  React.useEffect(function effectFunction() {
    if (!isStateSelect && property.Country !== "") {
      const result = MapData.filter((country) => country.name === property.Country);
      if (result.length > 0) {
        console.log("use effect country" + property.Country, result[0].states)
        setStateData(result[0].states);
        setcomboDisable(false);
        setIsStateSelect(true);
      }
    }
  }, []);

  useEffect(() => {
    console.log("selectedProperty-----------", selectedProperty);
    setProperty(selectedProperty);
  }, [])

  const ImageComponent = props => {
    const classes = ImpStyles();
    return (<div style={{ paddingTop: "30px" }}>
      <Card className={props.Type === "PropertyLogo" ? classes.card1 : classes.card2}>
        <div className={classes.details}>
          <CardActions>
            <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
              <CloseSharpIcon />
            </Fab>
          </CardActions>
        </div>
        <CardMedia>
          <S3Image theme={{ photoImg: { maxWidth: props.Type === "PropertyLogo" ? '159px' : '259px', maxHeight: props.Type === "PropertyLogo" ? '159px' : "259px", display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={props.FileKey} />
        </CardMedia>
      </Card>
    </div>);
  };

  function imageDelete(e) {
    console.log("DeletedKeyset,e.FileKey", e);
    let items = deltedKey;
    if (items.length > 0) {
      for (let i = 0; i < items; i++) {
        if (items[i].FileKey !== e.FileKey) {
          items.push(e.FileKey);
        }
      }
    }
    else {
      items.push(e.FileKey);
    }
    Object.assign(deltedKey, items);
    (e.Type === "PropertyImage") ? setProperty({ ...property, "PropertyImage": {} }) : setProperty({ ...property, "PropertyLogo": {} });
    console.log("DeletedKeyset,go and  delete deltedKey", deltedKey);

  }

  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  const onImageChange = React.useCallback((e) => {
    const { files, name } = e.target;
    const file = files[0];
    var img = document.createElement("img");
    img.onload = function () {
      if (name === "PropertyLogo" && this.width <= 500 && this.height <= 500 || (name === "PropertyImage" && this.width <= 1000 && this.height <= 500)) {
        let fileName = file.name;
        let fileExt = getFileExtension(fileName);
        let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
        const fileData = { FileKey: s3Key, FileName: fileName };
        Storage.put(s3Key, file, {
          contentType: file.type
        })
          .then(result => {
            setProperty({ ...property, [name]: fileData });
            formik.setFieldValue(name, fileData);
          })
          .catch(err => console.log(err));
      }
      else {
        (name === "PropertyImage") ? openSnackbar({ message: "Image size should be smaller than 1000X500 pixels", variant: "error" }) : openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });
      }
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(files[0]);
  }, [property.PropertyImage, property.PropertyLogo]);

  function onChange(e, inputProps) {
    console.log("property onChange", e.target);
    if (e.target.name === "Country") {
      var selected = inputProps.props.alldata;
      console.log(inputProps);
      console.log(selected);
      setcomboDisable(false);
      setStateData(selected.states)
      setIsStateSelect(true);
      setProperty(prvproperty => { return { ...prvproperty, "Country": e.target.value, "CurrencyName": selected.currency.name + " (" + selected.currency.code + ")", "CurrencyCode": selected.currency.code } });
    }
    else if (e.target.name === "PropertyImage" || e.target.name === "PropertyLogo") {
      const target = e.target;
      const file = target.files[0];
      let targetName = target.name;
      var img = document.createElement("img");
      img.onload = function () {
        if (targetName === "PropertyLogo" && this.width <= 500 && this.height <= 500 || (targetName === "PropertyImage" && this.width <= 1000 && this.height <= 500)) {
          let fileName = file.name;
          let fileExt = getFileExtension(fileName);
          let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
          const fileData = { FileKey: s3Key, FileName: fileName };
          Storage.put(s3Key, file, {
            contentType: file.type
          })
            .then(result => {
              setProperty({ ...property, [targetName]: fileData });
            })
            .catch(err => console.log(err));
        }
        else {
          (targetName === "PropertyImage") ? openSnackbar({ message: "Image size should be samaller than 1000X500 pixels", variant: "error" }) : openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });
        }
      };
      var reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(target.files[0]);
    }
    else {
      setProperty({ ...property, [e.target.name]: e.target.value });
    }

  }
  const handleClose = (e) => {
    if (e == "delete") {
      setDeleteOpen(false);
    }

  };
  const handleClickOpen = (e) => {
    setDeleteOpen(true);
  };
  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  function validateForm() {
    return (
      property.PropertyName && property.PropertyName.length > 0 &&
      /* property.OwnerFirstName && property.OwnerFirstName.length > 0 && */
      /* property.OwnerLastName &&  property.OwnerLastName.length > 0 && */
      property.Email && property.Email.length > 0 &&
      /* property.PropertyUnit && property.PropertyUnit.length > 0 && */
      property.BusinessAddress && property.BusinessAddress.length > 0 &&
      /* property.City && property.City.length > 0 && */
      /* property.State && property.State.length > 0 && */
      /* property.Country && property.Country.length > 0 && */
      /* property.PostalCode && property.PostalCode.length && */
      property.Phone && property.Phone.length > 0 &&
      property.WebSite && property.WebSite.length > 0

    );
  }


  function handleClearForm() {
    document.getElementById("proeprty-form").reset();
  }

  async function deleteProperty() {
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property, MasterType: 'Property', userData: props.userSession },
      queryStringParameters: {
        module: "property",
        op: "deleteProperty"
      }
    };
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false);
      openSnackbar({
        message:
          response.message,
        variant: "success"
      });

      handleClearForm();
      history.push({
        pathname: `/${property.PropertyName.replace(/\s+/g, '-')}/settings`,
        props: {
          fromAppbar: true,
        },
      });
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err, variant: "error" });
    });

  }
  const deleteFileFromS3 = async (fileKeys) => {
    fileKeys.forEach(async element => {
      await Storage.remove(element)
        .then(result => { console.log(result); })
        .catch(err => console.log(err));
    });
  }
  async function getProperty(e) {
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property, userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "getMyPropertyById"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        setIsLoading(false);
        console.log("get property ", response);
        if (response && response.success) {
          await setSelectedProperty(response.data);
        }
        else {
          openSnackbar({ message: 'Something gone wrong, Try refresh the page', variant: "error" });
          setIsLoading(false);
        }
      })
      .then(() => {
        history.push({
          pathname: `/${property.PropertyName.replace(/\s+/g, '-')}/settings`,
          props: {
            fromAppbar: true,
          },
        });
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: "error" });
        setIsLoading(false);
      });
  }
  async function saveProperty(e) {
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...e, PropertyID: selectedProperty.PropertyID, userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "saveGeneral"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        if (response && response.success) {
          await deleteFileFromS3(deltedKey);
          openSnackbar({
            message:
              response.message,
            variant: "success"
          });
          setTimeout(async () => {
            await getProperty();
            await handleClearForm();
          }, 500);
        }
        else {
          openSnackbar({ message: response.message, variant: "error" });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: "error" });
        setIsLoading(false);
      });
  }

  return (

    <div className="home-root">
      <Dialog
        open={deleteOpen}
        onClose={e => handleClose("delete")}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={e => handleClose("delete")}
        >
          Deletion Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We just want to make sure this is the correct decision. This is irreversible action and the data will be removed from our system immediately
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoaderCLickButton
            //size="large"
            onClick={e => deleteProperty()}
            isLoading={isLoading}
          >
            YES
          </LoaderCLickButton>
        </DialogActions>
      </Dialog>
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="home-paper">
              <div style={{ padding: "20px 0px 40px 0px" }}>

                <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                  history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings`);
                }}>
                  <Grid item>
                    <Button>
                      <div className="addlease-top-flex-container">
                        <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                      </div>
                    </Button>
                  </Grid>
                </Grid>


                <Grid container
                  spacing={1}
                  direction="column"
                  justify="center"
                  alignItems="flex-start">
                  <Grid item>
                    <Typography
                      component="h1"
                      className="home-headerOne"
                      variant="h1"
                    >
                      {isEditing && property && property.PropertyName ? property.PropertyName : "Add A New Property"}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography className="home-headerfour"
                    variant="subtitle1"
                  >
                    {isEditing ? "Update property information" : "Add a new property"}
                  </Typography>
                </Grid>
              </Grid>
              <form
                id="proeprty-form"
                className={classes.form}
                noValidate
                onSubmit={formik.handleSubmit/* saveProperty */}
              >
                <FocusError formik={formik}/>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="PropertyName"
                      label="PROPERTY NAME"
                      /* onChange={onChange} */
                      value={formik.values.PropertyName}
                      name="PropertyName"
                      autoComplete="name"
                      autoFocus
                      value={formik.values.PropertyName}
                      onChange={formik.handleChange}
                      error={formik.touched.PropertyName && Boolean(formik.errors.PropertyName)}
                      helperText={formik.touched.PropertyName && formik.errors.PropertyName}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Email"
                      label="EMAIL"
                      /* onChange={onChange} */
                      name="Email"
                      autoComplete="property-Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      error={formik.touched.Email && Boolean(formik.errors.Email)}
                      helperText={formik.touched.Email && formik.errors.Email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="OwnerFirstName"
                      label="PROPERTY OWNER FIRST NAME"
                      /* onChange={onChange} */
                      name="OwnerFirstName"
                      autoComplete="owner-FirstName"
                      value={formik.values.OwnerFirstName}
                      onChange={formik.handleChange}
                    /*     error={formik.touched.OwnerFirstName && Boolean(formik.errors.OwnerFirstName)}
                        helperText={formik.touched.OwnerFirstName && formik.errors.OwnerFirstName} */
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="OwnerLastName"
                      label="PROPERTY OWNER LAST NAME"
                      /* onChange={onChange} */
                      name="OwnerLastName"
                      autoComplete="owner-LastName"
                      value={formik.values.OwnerLastName}
                      onChange={formik.handleChange}
                      error={formik.touched.OwnerLastName && Boolean(formik.errors.OwnerLastName)}
                      helperText={formik.touched.OwnerLastName && formik.errors.OwnerLastName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="PropertyUnit"
                      label="# OF UNITS"
                      /* onChange={onChange} */
                      type="text"
                      id="PropertyUnit"
                      autoComplete="current-PropertyUnit"
                      value={formik.values.PropertyUnit}
                      onChange={formik.handleChange}
                      error={formik.touched.PropertyUnit && Boolean(formik.errors.PropertyUnit)}
                      helperText={formik.touched.PropertyUnit && formik.errors.PropertyUnit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="BusinessAddress"
                      label="BUSINESS ADDRESS"
                      /* onChange={onChange} */
                      id="BusinessAddress"
                      autoComplete="current-address"
                      value={formik.values.BusinessAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.BusinessAddress && Boolean(formik.errors.BusinessAddress)}
                      helperText={formik.touched.BusinessAddress && formik.errors.BusinessAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="City"
                      label="CITY"
                      /* onChange={onChange} */
                      id="City"
                      autoComplete="current-City"
                      value={formik.values.City}
                      onChange={formik.handleChange}
                      error={formik.touched.City && Boolean(formik.errors.City)}
                      helperText={formik.touched.City && formik.errors.City}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="PostalCode"
                      label="ZIP/POSTAL CODE"
                      /* onChange={onChange} */
                      id="PostalCode"
                      autoComplete="current-PostalCode"
                      value={formik.values.PostalCode}
                      onChange={formik.handleChange}
                      error={formik.touched.PostalCode && Boolean(formik.errors.PostalCode)}
                      helperText={formik.touched.PostalCode && formik.errors.PostalCode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel ref={inputLabel} id="select-state-label">
                            COUNTRY/REGION
                          </InputLabel>
                          <Select
                            labelid="select-Country-label"
                            id="Country-select-outlined"
                            fullWidth
                            variant="outlined"
                            name="Country"
                            label="COUNTRY/REGION"
                            value={property.Country}
                            labelWidth={labelWidth}
                            /* onChange={(e, alldata) => { onChange(e, alldata); }} */
                            value={formik.values.Country}
                            onChange={async (e, allData) => {
                              const { value } = e.target;
                              var selected = allData.props.alldata;
                              setStateData(selected.states)
                              setcomboDisable(false);
                              setIsStateSelect(true);
                              formik.setFieldValue('Country', value);
                              formik.setFieldValue('CurrencyName', selected.currency.name + " (" + selected.currency.code + ")");
                              formik.setFieldValue('CurrencyCode', selected.currency.code);
                            }}
                            error={formik.touched.Country && Boolean(formik.errors.Country)}
                            helperText={formik.touched.Country && formik.errors.Country}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {MapData.map((e, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel ref={inputLabel} id="select-state-label">
                            STATE/PROVINCE
                          </InputLabel>
                          <Select
                            labelid="select-state-label"
                            id="state-select-outlined"
                            fullWidth
                            variant="outlined"
                            name="State"
                            label="STATE/PROVINCE"
                            /* value={property.State} */
                            labelWidth={labelWidth}
                            disabled={comboDisable}
                            value={formik.values.State}
                            onChange={formik.handleChange}
                            error={formik.touched.State && Boolean(formik.errors.State)}
                            helperText={formik.touched.State && formik.errors.State}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {stateData.map((e, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      autoComplete="p-Phone"
                      name="Phone"
                      variant="outlined"
                      required
                      fullWidth
                      id="Phone"
                      /* value={property.Phone} */
                      label="PHONE #"
                      /* onChange={onChange} */
                      value={formik.values.Phone}
                      onChange={formik.handleChange}
                      error={formik.touched.Phone && Boolean(formik.errors.Phone)}
                      helperText={formik.touched.Phone && formik.errors.Phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      id="WebSite"
                      /* value={property.WebSite} */
                      label="WEBSITE"
                      /* onChange={onChange} */
                      name="WebSite"
                      autoComplete="auto-Website"
                      value={formik.values.WebSite}
                      onChange={formik.handleChange}
                      error={formik.touched.WebSite && Boolean(formik.errors.WebSite)}
                      helperText={formik.touched.WebSite && formik.errors.WebSite}
                    />
                  </Grid>
                  {!props.fromRent ?
                    <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                      {property.PropertyLogo && property.PropertyLogo.hasOwnProperty("FileName") ?
                        <ImageComponent Type={"PropertyLogo"} FileKey={property.PropertyLogo.FileKey} FileName={property.PropertyLogo.FileName} />
                        :
                        <Grid container style={{ paddingTop: "30px" }} direction="row" spacing={3}>
                          <Grid item xs={1}>
                            <input name='PropertyLogo'
                              onChange={(e) => onImageChange(e)}
                              accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                            <label htmlFor="icon-button-file1">
                              <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                          <Grid item xs={11}>
                            <Typography variant="h6"> UPLOAD PROPERTY LOGO</Typography>
                            <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
                          </Grid>
                        </Grid>}
                    </Grid>
                    : null}
                  {!props.fromRent ?
                    <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                      {property.PropertyImage && property.PropertyImage.hasOwnProperty("FileName") ?
                        <ImageComponent Type={"PropertyImage"} FileKey={property.PropertyImage.FileKey} FileName={property.PropertyImage.FileName} />
                        :
                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={1}>
                            <input name='PropertyImage' onChange={(e) => onImageChange(e)} accept="image/*" className={classes.input} id="icon-button-file2" type="file" />
                            <label htmlFor="icon-button-file2">
                              <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                          <Grid item xs={11}>
                            <Typography variant="h6"> UPLOAD PROPERTY IMAGE</Typography>
                            <Typography variant="caption">1000x500 - png, jpeg, gif</Typography>
                          </Grid>
                        </Grid>}
                    </Grid>
                    : null}
                </Grid>
                <div style={{ padding: "20px 0px 40px 0px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <LoaderButton
                        block="true"
                        type="submit"
                        size="large"
                        isLoading={isLoading}
                      /*   disabled={!validateForm()} */
                      >
                        {isEditing ? "UPDATE" : "SETUP A PROPERTY"}
                      </LoaderButton>
                    </Grid>
                    {isEditing && !fromRent && (
                      <Grid item>
                        <Button
                          block="true"
                          style={{ backgroundColor: 'transparent' }}
                          size="large"
                          onClick={() => { handleClickOpen("delete") }}>
                          CLOSE ACCOUNT
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </form>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
