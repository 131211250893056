import React, {  useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { S3Image } from 'aws-amplify-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import usePersistedState from '../../usePersistedState';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CarRectImg from "../../static/images/Image_A11_Rectangle_9_pattern.png";
import MLink from "@material-ui/core/Link";
import { API } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab"; 
import AddNewProperty from './addnewpropertydialog';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];

export function ListMyProperty(props) { 
    let history = useHistory();
    const location = useLocation();  
    const [isLoading, setIsLoading] = useState(false);    
    const loggedInUserName = props.userSession && props.userSession.FirstName ? props.userSession.FirstName.toUpperCase() : "";
    const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
    const [addNewProperty, setAddNewProperty] = React.useState(false); 
    const [userProperties,setUserProperties] = usePersistedState('userProperties', []);
    useEffect(()=>{
        (async () => { 
            if (props.userSession && props.userSession.UserName) { 
                let myProperties=await loadMyProperties(); 
                setUserProperties(myProperties?myProperties:[]);
                setSelectedProperty(myProperties && myProperties.length>0? myProperties[0]:{});
               /*  updateMenuAppBar(myProperties);  */
                setIsLoading(false); 
            }
        })();
      },[props.userSession]);

      useEffect(() => {
        let isSubscribed = true; 
        const propertyFetch = async () => {
          if (location.props && isSubscribed) { 
            if(location.props.currentProperty)
             {
               await setSelectedProperty(location.props.currentProperty);  
             } 
           }
          return () => isSubscribed = false
        }
        propertyFetch();
      }, [location]);

      /**
       * This will update menu appbar
       * 
       * @param {*} propertyList 
       */
      function updateMenuAppBar(propertyList, thisProperty = null) {        
       /*  props.onChildClick(propertyList || [], thisProperty);  */
      }
    /**
     * this function will load properties for the user
     */
    function loadMyProperties () { 
        setIsLoading(true);
        var masterType = "User";
        var apiModule = "property";
        var op = "listMyProperty";
        let body = { userData: props.userSession, masterType: masterType, MasterID: props.userSession.UserName, for: "CardDisplay" };
        
        return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
              headers: { "Content-Type": "application/json" },
              body: body,
              queryStringParameters: {
                module: apiModule,
                op: op
              }
            };
            API.post(apiName, path, myInit)
              .then(response => {
                if (response && response.length > 0) { 
                  resolve(response);
                }
                else {
                  resolve([]);
                } 
              })
              .catch(err => {
                console.log(err);
                reject(err);
              });
          });
    }

    /**
     *this function will add new property
     */
/*     function addNewProperty () {

    } */

    /**
     * This will select property as default
     * @param {*} property 
     */
    function selectThisProperty(property) { 
        setSelectedProperty(prv=> {return { ...prv, property}; });                   
        history.push({ pathname: `/${property.PropertyName.replace(/\s+/g, '-')}`,props:{ currentProperty:property, userProperties:userProperties}});    
      /*   updateMenuAppBar(userProperties, property);   */      
    } 
      async function handleChildClick(type,value) {   
        if(type==='close'){ 
          setAddNewProperty(false);
        }
        if(type==='success')
        {
          setAddNewProperty(false);
          let myProperties = await loadMyProperties(props.userSession.UserName);
          if (myProperties && myProperties.length == 1) {
           setSelectedProperty(myProperties[0]);       
          }
          else
          {
            setUserProperties(myProperties && myProperties.length > 1?myProperties:[]);
            setSelectedProperty({});

          }
          setIsLoading(false);
        } 
      } 


    return (<div width="100%">
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '94vh' }}
        >

            {props.userSession.Privilege === "ADMIN" && 
                <AddNewProperty {...props} addNewProperty={addNewProperty} onChildClick={handleChildClick} />
            }
            <Grid item xs={12} sm={12} md={12} lg={12} className="mainpropertylist-width100p mainpropertylist-paddingtop7p">
                <Container component="main" className="mainpropertylist-width100p mainpropertylist-maxwidth100p">
                    <div className="mainpropertylist-paper">
                            <Grid container justify="center" alignItems="center" spacing={1}>
                            <Grid item  >
                                {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                    <Typography className='mainpropertylist-managment-header'  component="h2" variant="h2" >
                                        {MapData.management_name}
                                    </Typography>}
                                    
                            </Grid>
                            <Grid item xs={12} container justify="center">
                            <Divider variant="middle" className="mainpropertylist-short-divider mainpropertylist-paddingbottom1p" />
                            </Grid>
                            
                            <Grid item xs={12} >
                                <Typography className='mainpropertylist-header'  component="h5" variant="h5" >
                                    WELCOME {loggedInUserName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Grid>


            <Grid item xs={12} container spacing={2}>
                {!isLoading && userProperties.length>0 && userProperties.map((e, keyIndex) => {
                    return(
                        <Grid key={keyIndex} item xs={12} sm={12} md={6} lg={6}>
                          
                            <Card  className={"mainpropertylist-card"}>
                                <CardActionArea style={{ display: "flex", padding: "16px" }} onClick={() => {
                                    selectThisProperty(e);
                                }} >
                                    {e.PropertyLogo && e.PropertyLogo.hasOwnProperty("FileKey") ?
                                        <CardMedia
                                        className={"home-cover"}
                                        >
                                        <S3Image theme={{ photoImg: { width: '170px', height: "170px", display: "flex" } }} level="public" imgKey={e.PropertyLogo.FileKey} />
                                        </CardMedia>
                                        :
                                        <CardMedia 
                                        title="Make A Payment" > <img className={"home-cover"} src={CarRectImg} /> </CardMedia>
                                    }
                                    
                                    <div className={"mainpropertylist-details"}>

                                        <CardContent className={"mainpropertylist-content"}>
                                        <Typography component="h5" variant="h5">
                                            {e.PropertyName}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            {e.BusinessAddress}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            Phone # {' '} {e.Phone}
                                        </Typography>
                                        </CardContent>

                                        <div className={"home-controls"}>
                                        <MLink
                                            variant="body2"
                                        >
                                            VIEW
                                        </MLink>
                                        </div>

                                    </div>
                                    
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}

                
            </Grid>



            {isLoading ?  <Grid item xs={12}  container justify="center" className="mainpropertylist-width100p">
            <CircularProgress size={50} />
            </Grid> : null}
            {isLoading ?  <Grid item xs={12}  container justify="center" className="mainpropertylist-width100p mainpropertylist-paddingtop3p">
            <div>Loading {selectedProperty && selectedProperty.PropertyName ? selectedProperty.PropertyName : "Property"}</div>
            </Grid> : null}

            {!isLoading && props.userSession.Privilege === "ADMIN" ?  <Grid item xs={12}  container justify="center" className="mainpropertylist-width100p mainpropertylist-paddingtop3d5p">
                <Grid item xs={12}>
                <Grid item container justify="center">
                  <Fab size="small" color="secondary" aria-label="edit" className="mainpropertylist-add-fab" onClick={() => {  
                    setAddNewProperty(true);
                    }}>
                    <AddIcon style={{ fontSize: 18 }} />
                    
                  </Fab>
                  <Typography className='mainpropertylist-add-text'
                    component="h5"
                    variant="h5"

                    onClick={() => {  
                        setAddNewProperty(true);
                        }}
                  >
                    ADD A NEW PROPERTY
                  </Typography>
                  </Grid>

                  <Grid item xs={12} container justify="center">
                        <Divider variant="middle" className="mainpropertylist-short-divider" />
                  </Grid>

                  <Grid item xs={12} container justify="center" className="mainpropertylist-paddingtop1d5p">
                  <Link style={{ textDecoration: 'none' }} to={'/settings'}>
                  <span
                        variant="outlined"
                        className="mainpropertylist-settings-text"
                    >
                        GLOBAL SETTINGS
                    </span>
                  </Link>
                  
                </Grid>
                </Grid>
            </Grid> : null}

            {isLoading ?  <Grid item xs={12} container justify="center">
                        <Divider variant="middle" className="mainpropertylist-short-divider" />
            </Grid> : null}
            
            </Grid></div>
    );
}
