import React, { useRef, useState, useEffect } from 'react'; 
import { API } from 'aws-amplify';
import {DialogContent,DialogActions,Dialog,TextField,Divider,Grid,InputLabel,Typography,FormControl,MenuItem,FormLabel,Radio ,RadioGroup ,FormControlLabel  } from '@material-ui/core'; 
import { withStyles} from '@material-ui/core/styles'; 
import { openSnackbar } from '../Notifier';
import { Skeleton } from '@material-ui/lab';   
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import  { LoaderCLickButton, LoaderDeleteButton } from '../LoaderButton';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add'; 
import usePersistedState from '../../usePersistedState'; 
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container'; 
import { Box } from '@material-ui/core';
import MaterialTable,{MTableBodyRow} from 'material-table';
import Moment from 'react-moment';
import MLink from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';  
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select'; 
import ListItemText from '@material-ui/core/ListItemText'; 
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Button from '@material-ui/core/Button'; 
import { useHistory, useLocation } from "react-router-dom";
const MapData = (process.env.REACT_APP_STAGE === 'dev') ? awsdev : (process.env.REACT_APP_STAGE === 'dev2') ? awsdev2 : window['runConfig'];

function RenderUserStatus (rowData) {    
    switch (rowData.CognitoUserStatus) {
      case 'CONFIRMED':
        return 'Active';
        break;
      case 'UNCONFIRMED':
        return 'Invited';
        break;
      case 'FORCE_CHANGE_PASSWORD':
        return 'Password Set Required';
        break;
      case 'DISABLED':
        return 'Inactive';
        break;
      default:
        break;
    }
  } 
let defaultUserValue={ 'firstName': '-',
'lastName': '-',
'CognitoUserID':'',
'Name':'',
'mobNumber':'',
'Email':'',
'PropertyID': [], 
'Privilege':'',
'checkedNotification': false };
export function SettingsTeams(props) {
  console.log('ADD NEW PROPERTY PROPS ', props); 
  const [isEditing, setIsEditing] = usePersistedState('isEditing', false);   
  const tableRef = React.createRef();  
  const [isLoading, setIsLoading] = useState(false); 
  const inputLabel = useRef(null);
  const [labelWidth] = useState(0);
  const [deleteOpen,setDeleteOpen] = useState(false);  
  const [pageSize,setPageSize]=useState(25);
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [userViewFilters, setuserViewFilters] = useState({'PrivilegeName':[],'Property':[], 'PropertyID': (selectedProperty && Object.keys(selectedProperty).length) ? [selectedProperty] : [], 'Privilege': [], 'Month': [], 'Year': [],'UserFilter':'' });
  console.log('editing', isEditing);  
  const [user, setUser] =usePersistedState('user',defaultUserValue);
  const [addUserOpen, setAddUserOpen] = useState(false);  
  const [editRoleOpen, setEditRoleOpen] = useState(false); 
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false); 
  const [propertyData, setPropertyData] = useState({});
  const [emailHelper, setEmailHelper]= useState('');
  let history = useHistory();
  //selectedProperty
  useEffect(function effectFunction() { 
    const propertyData = localStorage.getItem('selectedProperty');
    setPropertyData(propertyData ?  JSON.parse(propertyData):{});
  }, []);
  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h2'component = 'h2' className='addlease-addtenant-header'>{children}</Typography>
        {onClose ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }); 
  async function GetPagingData(query) { 
    var pageSizes= query.pageSize;
    var pageNo=(query.page + 1); 
    let body = {};
    var masterType = '',
      apiModule = '',
      op = '',
      userTab='All';  
      setPageSize(pageSizes);   
      masterType = 'User';
      apiModule = 'user';
      op = 'loadTeamGrid';
      body = { masterType: masterType,tabName:userTab,pageSize:pageSizes,pageNo:pageNo}; 
      if (userViewFilters && userViewFilters.UserFilter!=='') {
        body.UserFilter = userViewFilters.UserFilter;
      }
      if (userViewFilters && userViewFilters.PropertyID && userViewFilters.PropertyID.length) {
        body.PropertyID = userViewFilters.PropertyID;
      }
      if (userViewFilters && userViewFilters.Privilege && userViewFilters.Privilege.length) {
        body.Privilege = userViewFilters.Privilege;
      } 
    body.userData=props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = 'rentapi';
      let path = '/modules';
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      setIsLoading(true);
      API.post(apiName, path, myInit).then(response => { 
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  async function onKeyPressFilter(e) { 
    if(e.keyCode == 13){
      if (e.target.name == 'UserFilter') {
        if(tableRef.current) {
          tableRef.current.onQueryChange();
        }
      }
      
   }
  }
  async function onFilterChange(e, child) {
    console.log('e.target.name',e.target.name);
    console.log(' FILTER CHANGE EVENT ', e.target.value);    
    let type = 'User' 
    if (e.target.name === 'UserFilter') { 
     setuserViewFilters({ ...userViewFilters, 'UserFilter': e.target.value });
    }
    if (e.target.name === 'property') {
      let arrayProperty = userViewFilters.PropertyID;

      if (!arrayProperty.some((each) => each.PropertyID === child.props.id.PropertyID)) {
        arrayProperty.push(child.props.id);
      }
      else {
        arrayProperty.splice(arrayProperty.map((mapp) => mapp.PropertyID).indexOf(child.props.id.PropertyID), 1);
      }
      setuserViewFilters({ ...userViewFilters, ['Property']: e.target.value,'PropertyID':arrayProperty });
      //Object.assign(userViewFilters, { PropertyID: arrayProperty });
      console.log('AFTER VAKL CHANGE,', userViewFilters);
    }

    if (e.target.name === 'privilege' && e.target.value.length>0 && e.target.value[0]!=='') {
      let arrayPrivilege = userViewFilters.Privilege;

      if (!arrayPrivilege.some((each) => each.PrivilegeName === child.props.id.PrivilegeName)) {
        arrayPrivilege.push(child.props.id);
      }
      else {
        arrayPrivilege.splice(arrayPrivilege.map((mapp) => mapp.PrivilegeName).indexOf(child.props.id.PrivilegeName), 1);
      } 
        setuserViewFilters({ ...userViewFilters,['PrivilegeName']: e.target.value,'Privilege':arrayPrivilege }); 
    } 
 
    if(tableRef.current) {
      if (e.target.name !== 'UserFilter') tableRef.current.onQueryChange();

    }
      
  };  
  async function onSearchFilter(e) { 
    if (userViewFilters.UserFilter) {
      if(tableRef.current) {
        tableRef.current.onQueryChange();
      }
    } 
  } 
  function getRoleName(privilege,TenantAdmin) {  return TenantAdmin==='Yes'? 'Admin (owner)':privilege==='ADMIN'?'Admin':privilege==='BILLING'?'Billing':privilege==='MANAGER'?'Manager':'';  }
   
  const handleClose = (e) => {
    if (e === 'delete') {
      setDeleteOpen(false);
      setUser(defaultUserValue);
    } 
    if(e==='User')
    {
      setAddUserOpen(false);
      setUser(defaultUserValue);
    }
    if(e==='Role Edit')
    {
      setEditRoleOpen(false);
      setUser(defaultUserValue);
    }
  };
  const handleGridAction = (e, rowData) => {
    setUser({
      'firstName': rowData.FirstName,
      'Name': rowData.Name,
      'lastName': rowData.LastName,
      'CognitoUserID':rowData.CognitoUserID,
      'mobNumber': rowData.PhoneNumber,
      'Email': rowData.Email,
      'PropertyID': rowData.PropertyID,
      'Privilege': rowData.Privilege,
      'MasterID': rowData.MasterID
    })
    if (e === 'delete') {
      setDeleteOpen(true);
    }
    if (e === 'Role Edit') {
      setEditRoleOpen(true);
    }
    if (e === 'reset') {
      setResetPasswordOpen(true);
    }
  };
  async function disableUser() {   
    setIsLoading(true);
    user.userData = props.userSession;
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: user,
      queryStringParameters: {
        module: 'user',
        op: 'disableUser'
      }
    };
 
   await API.post(apiName, path, myInit).then(response => { 
      setDeleteOpen(false); 
      openSnackbar({
        message:
          response.message,
        variant: 'success'
      });
      setIsLoading(false); 
      if(tableRef.current)
            tableRef.current.onQueryChange();
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: 'error' });

    });

  }
  async function resetPassword() {    
    setIsLoading(true);
    user.userData = props.userSession;
    user.DomainName=MapData.aws_app_domain_prefix;
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: user,
      queryStringParameters: {
        module: 'user',
        op: 'resetPassword'
      }
    }; 
   await API.post(apiName, path, myInit).then(response => {  
      setResetPasswordOpen(false); 
      setUser(defaultUserValue);
      openSnackbar({
        message:
          response.message,
        variant: 'success'
      });
      setIsLoading(false); 
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: 'error' });
    }); 
  }
  function addNewUserChange(e) {
    let ob = {}; 
    if (e.target && e.target.name) {  
      if (e.target.name === 'checkedNotification') {
        ob[e.target.name] =  e.target.checked;
        setUser({...user, ...ob});
      }
      else {
        ob[e.target.name] =  e.target.value
        setUser({...user, ...ob});
      }
    }
  } 
  function editUserRole() { 
    /* const MapData =window['getConfig']; */
    if (user.Privilege) { 
        let apiName = 'rentapi';
        let path = '/modules';
        let userRequest = { 
          Email: user.Email,
          domain:MapData.aws_app_domain_prefix,
          userData:props.userSession,
          Privilege:user.Privilege,
          MasterID:user.MasterID
        }
        let myInit = {
          headers: { 'Content-Type': 'application/json' },
          body: userRequest,
          queryStringParameters: {
            module: 'user',
            op: 'editUserRole'
          }
        };
        API.post(apiName, path, myInit).then(response => { 
          setIsLoading(false); 
          if(!response.success)
          {
            openSnackbar({ message: response.message, variant: 'error' });
          }
          else
          {           
            setEditRoleOpen(false);
            setUser(defaultUserValue);
            if(tableRef.current)
              tableRef.current.onQueryChange();
            openSnackbar({ message: 'Role Changed successfully', variant: 'success' });
          }         
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          openSnackbar({ message: 'Role change failed', variant: 'error' });
        });

    }
    else {
      //PLEASE FILL ALL DATA
      setIsLoading(false);
      openSnackbar({ message: 'Please select role', variant: 'error' }); 
    }
  } 
  function aadNewTeamMember() { 
    /* const MapData =window['getConfig']; */
    setIsLoading(true);
    if (user.firstName &&
      user.lastName &&
      user.Email) {
        
        let apiName = 'rentapi';
        let path = '/modules';
        let userRequest = { 
          firstName: user.firstName,
          lastName: user.lastName,
          PropertyID: propertyData.PropertyID,
          PropertyName: propertyData.PropertyName, 
          Email: user.Email,
          domain:MapData.aws_app_domain_prefix,
          userData:props.userSession,
          Privilege:user.privilage,
          enableNotification: user.checkedNotification

        }
        let myInit = {
          headers: { 'Content-Type': 'application/json' },
          body: userRequest,
          queryStringParameters: {
            module: 'user',
            op: 'addTeamMember'
          }
        };
        API.post(apiName, path, myInit).then(response => { 
          setIsLoading(false);
          
          if(!response.success)
          {
           // openSnackbar({ message: response.message, variant: 'error' });
           setEmailHelper(response.message)                       
          }
          else
          {           
            setAddUserOpen(false);
            setUser(defaultUserValue);
            if(tableRef.current)
              tableRef.current.onQueryChange();
              let message =response.isUserExist?`${user.Email} already exist in the system. We have sent out an invitation`:`Invitation sent out ${user.Email}`;
            openSnackbar({ message: message, variant: 'success' });
          }           
          setUser(defaultUserValue);          
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          setUser(defaultUserValue);
          openSnackbar({ message: 'Tenant adding failed', variant: 'error' });
        });

    }
    else {
      //PLEASE FILL ALL DATA
      setIsLoading(false);
      openSnackbar({ message: 'Please enter all required fields', variant: 'error' }); 
    }
  }
  function validateFields () {
    return (!(user && user.privilage && (user.privilage != '')) || isLoading)
  }

  
  return (
    
    <div className='home-root'>  
    <Dialog classes={{ paper: 'dialog-paper'}}  open={resetPasswordOpen} onClose={e => {  setUser(defaultUserValue); setResetPasswordOpen(false);}} fullWidth
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='delete-dialog-title' onClose={e => {  setUser(defaultUserValue); setResetPasswordOpen(false);}}>
          Reset Password
        </DialogTitle>
        <DialogContent className='addtenant-dialog-content'>
        <DialogContentText color='textPrimary'>
        {user.Email} will have to change their password the next time they sign in or link a new device.
        <br/><br/>
        They will also receive email in their inbox requesting to change the login. To proceed, please hit reset below.
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={{padding: '8px 24px'}} > 
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          > 
          <Grid item>
          <LoaderCLickButton
            onClick={e => resetPassword()}
            isLoading={isLoading}
          >
            RESET PASSWORD
          </LoaderCLickButton>
          </Grid>
          <Grid item>
          <LoaderDeleteButton                   
                    isLoading={isLoading}
                    size='large'                   
                    onClick ={() => {  setUser(defaultUserValue); setResetPasswordOpen(false);}}
                  >
                  CANCEL
                  </LoaderDeleteButton>
          </Grid>
          </Grid> 
          <div style={{flex: '1 0 0'}} />
        </DialogActions>
      </Dialog>
     <Dialog
          maxWidth={'md'}
          open={editRoleOpen} 
          onClose={e => handleClose('Role Edit')}
          aria-labelledby='addnew-user-dialog-title'
        >
          <DialogTitle className='addnew-user-dialog-title'  id='addnew-user-dialog-title' onClose={e=>{handleClose('Role Edit')}}>
          Edit Role
          </DialogTitle>
          <DialogContent className='addtenant-dialog-content'> 
            <Grid container
              className='addtenant-grid'
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'> 
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl width='100%' component='fieldset'>
                <FormLabel component='legend'><Typography style={{ fontWeight: 'bold' }} variant='h6'>
                Role  </Typography></FormLabel>
                <RadioGroup  aria-label='role' name='Privilege' value={user.Privilege} onChange={addNewUserChange}>
                  <FormControlLabel value='ADMIN' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}> 
                  <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Administrator
                   </Typography>
                    <Typography variant='subtitle1' >
                      Full access to all the features including the property & business settings
                  </Typography></Box>} />
                  <FormControlLabel value='MANAGER' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Manager
                    </Typography>
                    <Typography variant='subtitle1' >
                      Full access to edit and manage Tenant Management, Manage Lease, View Transaction features
                  </Typography></Box>} />
                  <FormControlLabel value='BILLING' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Billing
                    </Typography>
                    <Typography variant='subtitle1' >
                      View access only of the Tenant Managements, View Transactions & Manage Lease features
                    </Typography>
                    </Box>} />
                </RadioGroup>
              </FormControl>
            </Grid>
            </Grid>
          </DialogContent>
          <Divider variant='middle' />
          <DialogActions className={'addTenant-DialogActions'}>
          <Grid container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'>
              <Grid item xs={12}>
              <LoaderCLickButton
                    isLoading={false}
                    size='large'
                    disabled={false}
                    onClick={() => { editUserRole() }}
                  >
                  UPDATE
              </LoaderCLickButton>
              </Grid>

            </Grid>
     
          </DialogActions>
      </Dialog>
    <Dialog
          maxWidth={'md'}
          open={addUserOpen}
         /*  scroll={addManualTenantScroll} */
          onClose={e => handleClose('User')}
          aria-labelledby='addnew-user-dialog-title'
        >
          <DialogTitle className='addnew-user-dialog-title'  id='addnew-user-dialog-title' onClose={e=>{handleClose('User')}}>
          Add A Team Member
          </DialogTitle>
          <DialogContent className='addtenant-dialog-content'>
          <DialogContentText color='textPrimary'>
          Enter the email addresses of the users you’d like to invite, and choose the role they should have.
          </DialogContentText>  
            <Grid container
              className='addtenant-grid'
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  className='addtenant-email'
                  id='input-addtenant-email'
                  variant='outlined'
                  name='Email'
                  onChange={addNewUserChange}
                  value={user.Email}
                  placeholder='Email*'
                  error={Boolean(emailHelper)}
                  helperText={emailHelper}
                />
              </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl width='100%' component='fieldset'>
                <FormLabel component='legend'><Typography style={{ fontWeight: 'bold' }} variant='h6'>
                Role  </Typography></FormLabel>
                <RadioGroup  aria-label='role' name='privilage' value={user.privilage} onChange={addNewUserChange}>
                  <FormControlLabel value='ADMIN' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}> 
                  <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Administrator
                   </Typography>
                    <Typography variant='subtitle1' >
                      Full access to all the features including the property & business settings
                  </Typography></Box>} />
                  <FormControlLabel value='MANAGER' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Manager
                    </Typography>
                    <Typography variant='subtitle1' >
                      Full access to edit and manage Tenant Management, Manage Lease, View Transaction features
                  </Typography></Box>} />
                  <FormControlLabel value='BILLING' control={<Radio style={{color: '#6200EE'}}/>} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                    Billing
                    </Typography>
                    <Typography variant='subtitle1' >
                      View access only of the Tenant Managements, View Transactions & Manage Lease features
                    </Typography>
                    </Box>} />
                </RadioGroup>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.checkedNotification}
                        onChange={addNewUserChange}
                        name='checkedNotification'
                        style={{color: '#374761'}}
                      />
                    }
                    label='Yes, team member will receive notifications when tenant make a payment'
                  />
            </Grid>

            </Grid>
          </DialogContent>
          <Divider variant='middle' />
          <DialogActions className={'addTenant-DialogActions'}>
          <Grid container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'>
              <Grid item xs={12}
              className='add-teammember-margin-top-p7p'
              >
              <LoaderCLickButton
                    isLoading={false}
                    size='large'
                    disabled={validateFields()}
                    onClick={() => { aadNewTeamMember() }}
                  >
                    + ADD
              </LoaderCLickButton>
              </Grid>

            </Grid>
     
          </DialogActions>
      </Dialog>
    <Dialog open={deleteOpen} onClose={e => handleClose('delete')} fullWidth={true}
        maxWidth={'sm'} aria-labelledby='form-dialog-title'>
        <DialogTitle id='delete-dialog-title' onClose={e => handleClose('delete')}>
        Remove User From Team
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='textPrimary'>
          {user.Email} will no longer be able to access this account..
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => disableUser()}
                isLoading={isLoading}
              >
                REMOVE
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('delete') }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{flex: '1 0 0'}} />
        </DialogActions>
      </Dialog>
     
      <Grid
        container
        spacing={0}
        direction='column'
      >
        <Grid item>
          <Container component='main' className='home-main'>
            <CssBaseline />
            <Spinner className='common-spinner'
              area={areas.admin}
            />
            <div className='home-paper'> 
            <div style={{ padding: "20px 0px 40px 0px" }}>
            <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                  history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings`);
                }}>
                  <Grid item>
                    <Button>
                      <div className="addlease-top-flex-container">
                        <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                      </div>
                    </Button>
                  </Grid>
                </Grid>

             <Grid  container
                  spacing={1}
                  direction='row'
                  justify='center'
                  alignItems='flex-start'>
                  <Grid  xs={12} item>
                    <Box mb={3}>
                    <Typography
                      component='h1'
                      className='home-headerOne'
                      variant='h1'
                    >
                     Teams
                    </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <Box mb={3}>
                  <Grid container direction='row' spacing={1} alignItems='center'>
                    <Grid item>
                    { !isLoading &&
                      <Fab
                        size='small'
                        onClick={() => {        
                          setUser(defaultUserValue);
                         setAddUserOpen(true);
                        }}
                        color='secondary'
                        aria-label='add'

                      >
                        <AddIcon className='home-linkIcon' />
                      </Fab>
                      }
                      { isLoading && <Skeleton variant='circle' width={40} height={40}/>}
                    </Grid>
                    <Grid item>
                    { !isLoading && 
                    <Typography
                      className='home-headerThree add-teammember-tag'
                      component='h5'
                      variant='h5'
                      onClick={() => {
                        setUser(defaultUserValue);
                        setAddUserOpen(true);
                       }}
                    >
                      ADD A TEAM MEMBER
                </Typography>
                }
                { isLoading && <Skeleton variant='rect' width={218} height={32}/>} 
                </Grid>
                  </Grid>
                  </Box>
                </Grid>
                  <Grid item xs={12}>
                    <Box mb={3}>    
                  <Typography className='home-headerfour'
                    variant='subtitle1'
                  >
                  Add and update team members to the property and its features.
                  </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} item>
                <Grid container direction='row'   alignItems='center' spacing={1}>
                  { !isLoading && 
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        style={{ width: '100%' }}                     
                        id='input-with-icon-textfield'
                        variant='outlined'
                        placeholder='Filter email, name unit #'
                        name='UserFilter'
                        value={userViewFilters.UserFilter}
                        onChange={onFilterChange}
                        onKeyDown={onKeyPressFilter}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <MLink
                              className={'gridfilter-innersearch-bt'}>
                              <SearchIcon
                              onClick={onSearchFilter}
                              />
                              </MLink>
                            </InputAdornment>
                          ),
                        }}

                      />
                    </Grid>
                    }
                    { isLoading &&  <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Skeleton variant='rect'  height={56}/>
                                        </Grid>
                                        }  
                    {!isLoading &&                     
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <FormControl fullWidth variant='outlined'>
                        <InputLabel ref={inputLabel} id='select-outlined-label'>
                          ALL ROLES
                      </InputLabel>
                        <Select
                          labelid='select-outlined-label'
                          id='property-select-outlined'
                          multiple 
                          value={userViewFilters.PrivilegeName}
                          onChange={(e, child) => {
                            onFilterChange(e, child);
                          }}
                          variant='outlined'
                          name='privilege'
                          label='PROPERTY'
                          // input={<Input />}
                          renderValue={selected => selected.join(', ')} //selected.join(', ')}
                          //MenuProps={MenuProps}
                          labelWidth={labelWidth}
                        >
                          <MenuItem value=''>
                            <em>SELECT PRIVILEGE</em>
                          </MenuItem>
                          <MenuItem
                            value='MANAGER'
                            id={{
                              PrivilegeName: 'MANAGER',
                              PrivilegeID: 'MANAGER'
                            }}
                          >
                            <Checkbox
                              checked={
                                userViewFilters.PrivilegeName.indexOf('RENTERS') > -1
                              }
                            />
                            <ListItemText primary='MANAGER' />
                          </MenuItem>
                          <MenuItem
                            value='BILLING'
                            id={{
                              PrivilegeName: 'BILLING',
                              PrivilegeID: 'BILLING'
                            }}
                          >
                            <Checkbox
                              checked={
                                userViewFilters.PrivilegeName.indexOf('BILLING') > -1
                              }
                            />
                            <ListItemText primary='BILLING' />
                          </MenuItem>
                          <MenuItem
                            value='ADMIN'
                            id={{ PrivilegeName: 'ADMIN', PrivilegeID: 'ADMIN' }}
                          >
                            <Checkbox
                              checked={
                                userViewFilters.PrivilegeName.indexOf('ADMIN') > -1
                              }
                            />
                            <ListItemText primary='ADMIN' />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    }
                    { isLoading && <Grid item xs={12} sm={12} md={7} lg={7}>
                                            <Skeleton variant='rect' className={'skelton-min-width-380 skelton-max-width-408'} height={56}/>
                                        </Grid>
                                        }


                  </Grid>
                </Grid>
                  <Grid xs={12} item>
                  <div className={isLoading ? 'tenant-table-hide' : 'tenant-table-show'}>
                <MaterialTable
                  tableRef={tableRef}
                  columns={[
                  { title: 'MEMBER', width:'20%', field: 'Name', searchable: false,
                     render: (rowData) => {return rowData.Email===props.userSession.Email?
                      <Grid container direction='row' alignContent={'center'} spacing={1}>
                         <Grid item style={{'alignSelf': 'center'}}>
                         {rowData.Name}
                         </Grid>
                        <Grid item>
                        <TextField width='max-content' variant='outlined'  inputProps={{min: 0, style: { textAlign: 'center' }}} disabled= {true} className={'team-grid-tf'} value='YOU'  />                          
                        
                        </Grid> 
                      </Grid>:<>{rowData.Name}</>
                     }
                  },
                  { title: 'EMAIL', width:'20%', field: 'Email', searchable: false },                                 
                  { title: 'ROLE', width:'20%', field: 'Privilege', searchable: false, render: rowData =>{ return getRoleName(rowData.Privilege,rowData.TenantAdmin)} },
                  { title: 'LAST ACTIVITY', width:'20%', field: 'LastLogin', searchable: false, render: rowData => <Moment format='MMMM DD YYYY - hh:mma' >{rowData.LastLogin?rowData.LastLogin:rowData.CreatedDate}</Moment> },
                  { 
                    title: 'STATUS', 
                    field: 'LeaseActivity', 
                    searchable: false,
                    width:'20%',
                    render: (rowData) => { return rowData.TenantAdmin==='Yes'? null:<TextField  variant='outlined'  inputProps={{min: 0, style: { textAlign: 'center' }}} disabled= {true} className={RenderUserStatus(rowData) == 'Inactive' ? 'tenant-grid-tf-dsbl' : 'tenant-grid-tf'} value = {RenderUserStatus(rowData)} > </TextField>}
          
                  }]}
                  data={async query=> new Promise((resolve, reject) => { 
                    GetPagingData(query) 
                      .then(result => { 
                        resolve({
                          data: result.data,
                          page: result.page,
                          totalCount: result.total
                        })
                      })
                    
                  })}                  
                  options={{
                    searchFieldStyle: {
                      display: 'none'
                    },
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: true,
                    pageSize:pageSize,
                    pageSizeOptions: [25, 50, 100, 200]
                  }}
                  components={{
                    Row: props => {
                      const propsCopy = { ...props };
                      propsCopy.actions.find(a => a.name === 'edit').disabled = propsCopy.data.TenantAdmin==='Yes' || propsCopy.data.UserStatus==='Deleted';
                      propsCopy.actions.find(a => a.name === 'delete').disabled = propsCopy.data.TenantAdmin==='Yes' || propsCopy.data.UserStatus==='Deleted';
                      propsCopy.actions.find(a => a.name === 'lock').disabled = propsCopy.data.TenantAdmin==='Yes' || propsCopy.data.UserStatus==='Deleted';
                      return <MTableBodyRow {...propsCopy} />
                    }
                  }}
                  actions= {[
                    { 
                      name: 'edit',
                      icon: 'edit',
                      tooltip: 'Edit', 
                      onClick: (event, rowData) => {
                        console.log('edit user');
                        handleGridAction('Role Edit',rowData);
                      }
                    },
                    { 
                      name: 'delete',
                      icon: 'delete',
                      tooltip: 'Delete',
                      onClick: (event, rowData) => {
                        console.log('edit user');
                        handleGridAction('delete',rowData);
                      }
                    },
                    { 
                      name: 'lock',
                      icon: 'lock',
                      tooltip: 'Reset password',
                      onClick: (event, rowData) => {
                        console.log('edit user');
                        handleGridAction('reset',rowData);
                      }
                    }
                  ]}
                />
                </div>
                  </Grid> 
             </Grid> 
             </div>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
