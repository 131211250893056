import React, { useState } from "react";
import clsx from 'clsx';
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import portaLogo from "../images/leaseleads_logo_Rectangle_3.png";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },

    myImage: {
        width: "auto",
        height: "103px"
    },

}));
export default function NotFound() {
    const classes = useStyles();
    var path = "#";
    if (typeof window !== 'undefined') {
        path = window.location.protocol + '//' + window.location.host;
    } else {
        path = "#"
    }
    return (
        <Container component="main">
            <CssBaseline />

            <div className="policy-paper">
                <Grid container justify="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <MLink color="inherit" href={path}>
                            <img alt={'card image'} src={portaLogo} className={classes.myImage} />
                        </MLink>
                    </Grid>
                    <Grid item>
                        <h2>Privacy Policy</h2>
                        <h2>Introduction</h2>
                        <p>In our mission to make real estate management better for everyone, we collect and use information about you, our</p>
                        <ul>
                            <li> property owners/manager use Lease Leads to manage their property</li>
                            <li> residents (tenants) who use portal to pay rent online power by Lease Leads</li>
                            <li> partners who develop apps for property owners/managers to use, build websites on behalf of owners</li>
                            <li> This Privacy Policy will help you better understand how we collect, use, and share your personal information. If we change our privacy practices, we may update this privacy policy. If any changes are significant, we will let you know (for example, through the Lease Leads admin or by email).</li>
                        </ul>
                        <h2>Our values</h2>
                        <p>Trust is the foundation of the Lease Leads platform and includes trusting us to do the right thing with your information. Three main values guide us as we develop our products and services. These values should help you better understand how we think about your information and privacy.</p>
                        <ul>
                            <li> <h3>Your information belongs to you</h3>
<p>We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it. When building and improving our products, our engineers work closely with our privacy and security teams to build with privacy in mind. In all of this work our guiding principle is that your information belongs to you, and we aim to only use your information to your benefit.</p>
                            </li>
                            <li>  <h3>We protect your information from others</h3>
<p>If a third party requests your personal information, we will refuse to share it unless you give us permission or we are legally required. When we are legally required to share your personal information, we will tell you in advance, unless we are legally forbidden.</p>
                            </li>
                            <li><h3> We help owners/managers and partners meet their privacy obligations</h3>
<p>Many of the merchants and partners using Lease Leads do not have the benefit of a dedicated privacy team, and it is important to us to help them meet their privacy obligations. To do this, we try to build our products and services so they can easily be used in a privacy-friendly way. We also provide detailed FAQs, documentation and whitepapers covering the most important privacy topics, and respond to privacy-related questions we receive. We generally process your information when we need to do so to fulfill a contractual obligation (for example, to process your subscription payments to use the Lease Leads platform), or where we or someone we work with needs to use your personal information for a reason related to their business (for example, to provide you with a service). These include:l</p>
                                <ul>
                                    <li> preventing risk and fraud</li>
                                    <li> answering questions or providing other types of support</li>
                                    <li> helping owners/manager find and use our apps</li>
                                    <li> providing and improving our products and services</li>
                                    <li> providing reporting and analytics</li>
                                    <li> testing out features or additional services</li>
                                    <li> assisting with marketing, advertising, or other communications</li>
                                </ul>
                                <p>We only process personal information for these after considering the potential risks to your privacy&mdash;for example, by providing clear transparency into our privacy practices, offering you control over your personal information where appropriate, limiting the information we keep, limiting what we do with your information, who we send your information to, how long we keep your information, or the technical measures we use to protect your information.</p>
                            </li>
                        </ul>
                        <h2>
                            Your rights over your information
</h2>
                        <p>
                            We believe you should be able to access and control your personal information no matter where you live. Depending on how you use Lease Leads, you may have the right to request access to, correct, amend, delete, port to another service provider, restrict, or object to certain uses of your personal information (for example, direct marketing). We will not charge you more or provide you with a different level of service if you exercise any of these rights.
</p>
                        <p>
                            If you buy something from a Lease Leads-powered portal and wish to exercise these rights over information about your purchase, you need to directly contact the merchant you interacted with. We are only a processor on their behalf, and cannot decide how to process their information. As such, we can only forward your request to them to allow them to respond. We will of course help our merchants to fulfill these requests by giving them the tools to do so and by answering their questions.
</p>
                        <p>
                            Please note that if you send us a request relating to your personal information, we have to make sure that it is you before we can respond. In order to do so, we may ask to see documentation verifying your identity, which we will discard after verification.
</p>
                        <p>
                            If you would like to designate an authorized agent to exercise your rights for you, please email us from the email address we have on file for you. If you email us from a different email address, we cannot determine if the request is coming from you and will not be able to accommodate your request. In your email, please include the name and email address of your authorized agent.
</p>
                        <p>
                            If you are not happy with our response to a request, you can contact us to resolve the issue. You also have the right to contact your local data protection or privacy authority at any time.
</p>
                        <p>
                            Finally, because there is no common understanding about what a “Do Not Track” signal is supposed to mean, we don’t respond to those signals in any particular way.
</p>
                        <h2>
                            Where we send your information
</h2>
                        <p>
                            We are a Canadian company, but we work with and process data about individuals across the world. To operate our business, we may send your personal information outside of your state, province, or country, including to the United States. This data may be subject to the laws of the countries where we send it. When we send your information across borders, we take steps to protect your information, and we try to only send your information to countries that have strong data protection laws. If you would like more information about where your information might be sent, please contact us.
</p>
                        <p>
                            Finally, while we do what we can to protect your information, we may at times be legally required to disclose your personal information (for example, if we receive a valid court order).
</p>
                        <h2>
                            How we protect your information
</h2>
                        <p>
                            Our teams work tirelessly to protect your information, and to ensure the security and integrity of our platform. We also have independent auditors assess the security of our data storage and systems that process financial information. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure. This means we cannot guarantee the absolute security of your personal information.
</p>
                        <h2>
                            How we use “cookies” and other tracking technologies
</h2>
                        <p>
                            When you visit our websites or use our apps, read, click or download information, or receive emails from us in response to your requests, your computer may be offered “cookies”. Cookies are small text files that are stored on your computer or mobile device when you visit a website. The next time you visit our website, we may use the information stored on your cookie to make your visit easier and to help us to make continual improvements so that we can update our pages to stay relevant and useful to you.
</p>

                        <h2>
                            How you can reach us
</h2>
                        <p>
                            If you would like to ask about, make a request relating to, or complain about how we process your personal information, you can contact us by email at <a href="mailto: support@leaseleads.com"> support@leaseleads.com</a>.
</p>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}