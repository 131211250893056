
import React, {useState, forwardRef, useEffect } from "react"; 
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid"; 
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField'; 
import InputAdornment from '@material-ui/core/InputAdornment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { ReactComponent as TableAccountSvg } from '../../static/images/table-account.svg';
import { SvgIcon } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from '@date-io/date-fns';
import { LoaderCLickButton } from "../LoaderButton";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { AddTenant } from "../user/AddTenant"; 
import {
  MuiPickersUtilsProvider,  
  KeyboardDatePicker
} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from "material-table";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import Check from '@material-ui/icons/Check';
import Fab from "@material-ui/core/Fab";
import SearchIcon from '@material-ui/icons/Search';
import {API } from "aws-amplify";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from '@material-ui/core/Tooltip';
import { openSnackbar } from '../Notifier';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import GridOnIcon from '@material-ui/icons/GridOn';
import Divider from '@material-ui/core/Divider';
import MuiDialogTitle from '@material-ui/core/DialogTitle'; 
import { useHistory,useLocation } from "react-router-dom";
import BillingPage from '../biller';
import usePersistedState from '../../usePersistedState';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import DeleteIcon from '@material-ui/icons/Delete';
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
      color: theme.palette.common.black,
  },
  tooltip: {
      backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

 
const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleRoundedIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <RemoveCircleRoundedIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <CancelRoundedIcon {...props} ref={ref} />)
};
const useStyles = makeStyles(theme =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    main: {
      maxWidth: "100% !important",
    }
  })
);
   
export  function AddLease(props) { 
  let history = useHistory(); 
  const location = useLocation();
  const classes = useStyles(); 
  const [currentView, SetCurrentView] = useState("AddLease"); 
  const [labelWidth] = React.useState(0); 
  const [editLease, setEditLease] = usePersistedState('editLease',false);
  const [leaseData, setLeaseData] = usePersistedState('leaseData',{
    "unit": "",
    "monthlyRent": "",
    "securityDeposit": "",
    "leaseDueOn": "",
    "leaseStart": new Date().toISOString(),
    "leaseEnd": new Date().toISOString(),
    "afterLeaseEnd": "",
    "tenantSearch": "",
    "status": ""
  }); 
  const [miscData, setMiscData] = usePersistedState('miscData',[{
    "appfeeName": "",
    "appFeeValue": 0,
    "appFeeType":"percentage",
    "activeDatePlan":"oneTimePlan",
    "validFrom":"",
    "validTo": "",
    "added": false
  }]); 
  const [tenantData, setTenantData] =usePersistedState('tenantData',[]);
  const [propertyData, setProeprtyData] = usePersistedState('propertyData',{});
  const [isPastLeaseStart, setIsPastLeaseStart] = React.useState(false);
  const [manualTenantAdd, setManualTenantAdd] = React.useState(false);
  const [addTenant, setAddTenant] = React.useState(false); 
  const [residentTableState, setResidentTableState] = React.useState({}); 
  const [rentTableData,setRentTableData] = useState([]);
  const [rentVacancy, setRentVacancy] = React.useState(""); 
  const [addMiscFeeOpen, setAddMiscFeeOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
const [addUserOpen, setAddUserOpen] = useState(false);
  const [manualTenant,setManualTenant] = useState({
    "firstName": "",
    "lastName": "",
    "mobNumber":"",
    "email":""
  });
  const [addTenantScroll] = React.useState('paper'); 
  const [isAddOrBrowse, setIsAddOrBrowse] = React.useState('Add Tenant');
  const [activateValidationBorder, setActivateValidationBorder] = React.useState(false);
  const [selectedProperty] = usePersistedState('selectedProperty', {}); 
  const isRefresh = React.useRef(true);
  const [endDateOpen,setEndDateOpen]=useState(false);
  const [startDateOpen,setStartDateOpen]=useState(false);
  useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) { 
        await setProeprtyData(selectedProperty);
      }
      if (!location.props && isRefresh.current && selectedProperty.hasOwnProperty("PropertyName")) {
        isRefresh.current = false; 
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [location.props,selectedProperty]); 
  useEffect(() => {
    let isSubscribed = true;    
     
     
    const userFetch=async ()=> {
       if(location.props && isSubscribed)
       {   
        let miscs = []; 
        const currentLease= location.props.currentLease;
        if (currentLease.Miscs && currentLease.Miscs.length) {
            miscs = currentLease.Miscs;
            miscs.added = true;
          } 
          await setTenantData(currentLease.CoRenters && currentLease.CoRenters.length ? currentLease.CoRenters : [])
          await setEditLease(location.props.editLease ? true : false); 
          await setLeaseData(currentLease? {
            "masterID": currentLease.MasterID,
            "unit": currentLease.RentUnit,
            "monthlyRent": currentLease.RentEachMonth,
            "securityDeposit": currentLease.RentSecurityDeposit,
            "leaseDueOn": currentLease.RentLeaseDueOn,
            "leaseStart": currentLease.RentStartDate,
            "leaseEnd": currentLease.RentEndDate,
            "afterLeaseEnd": currentLease.RentAfterLeaseEnd,
            "tenantSearch": "",
            "status": currentLease.RentStatus
          } : {
            "unit": "",
            "monthlyRent": "",
            "securityDeposit": "",
            "leaseDueOn": "",
            "leaseStart": new Date().toISOString(),
            "leaseEnd": new Date().toISOString(),
            "afterLeaseEnd": "",
            "tenantSearch": "",
            "status": ""
          });
        await setMiscData(miscs && miscs.length>0 ? miscs : [{
            "appfeeName": "",
            "appFeeValue": 0,
            "appFeeType":"percentage",
            "activeDatePlan":"oneTimePlan",
            "validFrom":"",
            "validTo": "",
            "added": false
          }]); 
          await setIsPastLeaseStart( currentLease.RentStartDate && currentLease.RentStartDate ? await validateLeaseStart(currentLease.RentStartDate) : false)       
       }  
      return () => isSubscribed = false;
    }
    userFetch();
  }, [location.props]);

  //Commented for showing Add date : Empty label on lease start field

/*   useEffect(()=>{    
    if(!leaseData.leaseStart || leaseData.leaseStart==="")
    {
      setLeaseData({...leaseData,...{'leaseStart':new Date().toISOString()}})
    }
  },[leaseData]) */
  useEffect(() => {
    console.log("useEffect is called : EVERY TIME on tenantData" , tenantData); 
  }, [tenantData]);

  useEffect(() => {
    
    if (!manualTenantAdd) {
      setManualTenant({
        "firstName": "",
        "lastName": "",
        "mobNumber":"",
        "email":""
      });
    }

  }, [manualTenantAdd]);
  useEffect(() => {
    let isSubscribed = true; 
    console.log('location.props',location.props);
    const userFetch = async () => { 
      if (location.props && location.props.tenant && isSubscribed)
      {
        let tenant=location.props.tenant;
        console.log('tenant',tenant)
        let ob = {};
        ob.Property = selectedProperty.PropertyName;
        ob.PropertyID = selectedProperty.PropertyID;
        if(Array.isArray(tenant))
        {
          for (var i = 0; i < tenant.length; i++) {
            await addTenantToLease(ob, tenant[i].MasterID, { RenterMailID: tenant[i].Email, RenterName: tenant[i].Name, RenterID: tenant[i].MasterID, RenterPhoneNumber: tenant[i].PhoneNumber });
          }
        }
        else{
          await addTenantToLease(ob, tenant.MasterID, { RenterMailID: tenant.Email, RenterName: tenant.Name, RenterID: tenant.MasterID, RenterPhoneNumber: tenant.PhoneNumber });
        }  
      }
      return () => isSubscribed = false;
    }
    userFetch();
  }, [location.props]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (addTenant) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (manualTenantAdd) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [addTenant, manualTenantAdd]);
const dueOn = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
    "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th",
    "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"]; 
  const DialogTitle = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }; 
  const DialogMiscTitle = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h2" component="h2" className="addlease-misc-header">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={"misc-close-bt"}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  const onChildClick= async (e,tenant)=>{
    if(e==='closeUser')
    {
      setAddUserOpen(false);
    }
    if(e==='add tenant')
    {
      if (tenant)
      { 
        console.log('add tenant 313',tenant)
        let ob = {};
        ob.Property = selectedProperty.PropertyName;
        ob.PropertyID = selectedProperty.PropertyID;
        if(Array.isArray(tenant))
        {
          for (var i = 0; i < tenant.length; i++) { 
            await addTenantToLease(ob, tenant[i].MasterID, { RenterMailID: tenant[i].Email, RenterName: tenant[i].Name, RenterID: tenant[i].MasterID, RenterPhoneNumber: tenant[i].PhoneNumber });
          }
        }
        else{
          await addTenantToLease(ob, tenant.MasterID, { RenterMailID: tenant.Email, RenterName: tenant.Name, RenterID: tenant.MasterID, RenterPhoneNumber: tenant.PhoneNumber });
        }  
        setAddUserOpen(false);
      }

    }
  }

  let deleteCurrentMisc = async () => {
    if (miscData.length) {
      let ob = miscData[0];
      ob.added = false;
      setMiscData([ob]);
    }
    
 

    openSnackbar({
      message:
      "Misc fee removed successfuly",
      variant: "success"
    });

  }

  return ( 
    <div className="addlease-root">  
      {addUserOpen && <AddTenant fromLease={true} userOpen={addUserOpen} userSession={props.userSession} onChildClick={onChildClick}/>}
      <Container component="main" className="addlease-main">
        <CssBaseline />
        <div className="addlease-paper">
        {isLoading ? <div className="common-spinner">
        <CircularProgress className={classes.spinner} size={50} /></div> : null}
        <Dialog
          maxWidth={"md"}
          open={addTenant}
          onClose={e => setAddTenant(false)}
          scroll={addTenantScroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
            <DialogTitle className="addnew-user-dialog-title"  id="customized-dialog-title" onClose={e=>{setAddTenant(false)}}>
                  Browse Tenant
            </DialogTitle> 
          <DialogContent className="addtenant-dialog-content">
            <DialogContentText>
                Add the existing tenant to this property that is already in your portfolio by searching the resident name below.To add co-tenants, select
                 multiple resident's names 
                from the dropdown. Only residents are shown here. 
            </DialogContentText>  
        
            <div style={{ padding: "0px 0px 20px 0px" }}>
              <Grid item xs={12}>
                <div className={classes.root}>
                  <Grid container
                    direction="row"
                    spacing={2}
                    minwidth={"md"}
                    justify="flex-start"
                    alignItems="flex-start">
                    <Grid item xs={12}>
                      <TextField
                        
                        id="searchUser"
                        variant="outlined"
                        name = "tenantSearch"
                        fullWidth
                        value={leaseData.tenantSearch}
                        placeholder="Search by email or name"
                        onChange={onResidentTextSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <MaterialTable
                          icons={tableIcons}
                          columns={residentTableState.columns}
                          data={residentTableState.data}
                          options={{
                            searchFieldStyle: {
                              display: 'none'
                            },
                            actionsColumnIndex: -1,
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            paging: false,
                            pageSize:25,
                            pageSizeOptions: [25, 50, 100, 200]
                          }}

                          actions={[
                            {
                              icon: 'Add',
                              tooltip: 'Add User',
                              onClick: (event, data) => { 
                                let ob = {};
                                ob.Property = data.Property;
                                ob.PropertyID = data.PropertyID;                                
                                  addTenantToLease(ob, data.MasterID, {RenterMailID:data.Email, RenterName: data.Name, RenterID: data.MasterID, RenterPhoneNumber: data.PhoneNumber}, (err, result) => {
                                  if (err) {
                                    console.log(err); 
                                  } 
                                });
                              }
                            }
                          ]}
                          components={{
                            Action: (props) => {
                              
                              return (
                                <div>
                                  { props.data.HasAdded !== "Yes" ?
                                    <div className={classes.wrapper}>
                                      <Fab
                                        size="small"
                                        color="secondary"
                                        aria-label="add"
                                        onClick={(event) => {
                                          
                                          props.action.onClick(event, props.data);
                                        }}>
                                        <AddIcon />
                                      </Fab>                                      
                                    </div> :
                                    <div className={classes.wrapper}>
                                      <Fab
                                        size="small"
                                        color="secondary"
                                        aria-label="check"
                                        onClick={((event) => {
                                          
                                          
                                          
                                        })}>
                                        <Check />
                                      </Fab>
                                    </div>
                                  }
                                </div>
                              )
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </div> 
          </DialogContent> 
        </Dialog>
{/* 
        <Dialog
          maxWidth={"md"}
          open={manualTenantAdd}       
          onClose={e => setManualTenantAdd(false)}
          aria-labelledby="addnew-user-dialog-title">
          <DialogTitle className="addnew-user-dialog-title"  id="addnew-user-dialog-title" onClose={e=>{setManualTenantAdd(false)}}>
            Add Tenant
          </DialogTitle>
          <DialogContent className="addtenant-dialog-content"> 
          <DialogContentText>
          <Typography variant="subtitle1">
          Add a new user to this property by filling out the details given below. Once it is submitted, the tenant portal will be created for the user and will be invited for the activation. And the lease terms will not be active at the moment.
          </Typography>
          </DialogContentText>  
            <Grid container
              className="addtenant-grid"
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  className="addtenant-firstname"
                  id="input-addtenant-firstname"
                  variant="outlined"
                  name="firstName"
                  value={manualTenant.firstName}
                  onChange={manaualTenantChange}
                  placeholder="First Name*"
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  className="addtenant-lastname"
                  id="input-addtenant-lastname"
                  variant="outlined"
                  name="lastName"
                  onChange={manaualTenantChange}
                  value={manualTenant.lastName}
                  placeholder="Last Name*"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  className="addtenant-mobnumber"
                  id="input-addtenant-mobnumber"
                  variant="outlined"
                  name="mobNumber"
                  onChange={manaualTenantChange}
                  value={manualTenant.mobNumber}
                  placeholder="Phone Number*"
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  className="addtenant-email"
                  id="input-addtenant-email"
                  variant="outlined"
                  name="email"
                  onChange={manaualTenantChange}
                  value={manualTenant.email}
                  placeholder="Email*"
                />
              </Grid>
            </Grid> 
          </DialogContent>
        <Divider variant="middle" style={{marginRight:"24px",marginLeft:"24px"}} />
        <DialogActions className={"addTenant-DialogActions"}>
          <Grid container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
              <Grid item xs={12}>
              <LoaderCLickButton
                    isLoading={false}
                    size="large"
                    disabled={false}
                    onClick={() => { saveManualTenant() }}
                  >
                    + ADD
              </LoaderCLickButton>
              </Grid>

            </Grid>
     
        </DialogActions>
        </Dialog>
         */}
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={addMiscFeeOpen}
          onClose={e => {
            
            setAddMiscFeeOpen(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
            <DialogMiscTitle className="addnew-user-dialog-title"  id="customized-dialog-title" onClose={e=>{setAddMiscFeeOpen(false)}}>
              Add Fee
            </DialogMiscTitle>
          <DialogContent className="addtenant-dialog-content">
            <div style={{ padding: "0px 0px 20px 0px" }}>
              <Grid container
                spacing={1}
                direction="column"
                justify="center"
                alignItems="flex-start">

{/*               <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={10}>
                <Typography
                    component="h1"
                    className="misc-headerone"
                    variant="h1"
                  >
                    {"Create Miscellaneous Fee"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={2}>
                  {addMiscFeeOpen ? (
                      <IconButton
                        aria-label="close"
                        className="misc-close-bt"
                        onClick={() => { setAddMiscFeeOpen(false) }}>
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </Grid>
              </Grid> */}
                <Grid item style={{ paddingTop: "1%" }}>
                  <Typography
                    component="h4"
                    className="misc-headerfour"
                    variant="h4"
                  >
                    {"Use this widget to add those varied expenses, that the tenants may have incurred during the lease term. The tenant will see the total fee on their next payment transaction in the checkout page."}
                  </Typography>
                </Grid>

                <Grid item
                  className="misc-card-parent-grid"
                >
                  <Card className="misc-card">
                    <div className="misc-details">
                      <CardContent className="misc-content">
                        <Grid
                          container
                          spacing={1}
                          direction="row">
                          <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-fst-child">
                            <Grid className="misc-card-snd-child">
                                <TextField
                                  variant="outlined"
                                  required
                                  fullWidth
                                  name="appfeeName"
                                  placeholder="e.g Application Fee"
                                  onChange={miscChange}
                                  id="appfeeName"
                                  autoComplete="current-password"
                                  value={miscData[0].appfeeName}
                                />

                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "29px",
                                    fontWeight: "lighter",
                                    paddingLeft: "6%",
                                    color: "#7c7c7c"
                                  }}
                                >
                                  {"This will read in the breakdown"}
                                </Typography>
                              </Grid>
                            </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}  className="misc-card-fst-child">
                            TYPES
                             <Grid item xs={12} className="misc-card-types">
                              <FormControl component="fieldset">

                                <RadioGroup
                                  defaultValue="percentage"
                                  value={miscData[0].appFeeType || "percentage"}
                                  aria-label="percentage"
                                  name="appFeeType"
                                  onChange={e => {
                                    console.log("CURRENTVAL : ", e.target.value)
                                    
                                    miscChange(e);
                                  }}
                                >
                                  <FormControlLabel value="percentage" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Percentage</Typography>} />
                                  <FormControlLabel value="fixedAmount" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Fixed Amount</Typography>} />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-fst-child">
                            VALUE
                            <Grid className="misc-card-value">
                              <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="appFeeValue"
                                placeholder="Application Fee"
                                label=""
                                id="applicationFee"
                                autoComplete="current-password"
                                value={miscData[0].appFeeValue}
                                onChange={miscChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      className="misc-unit"
                                    >
                                      {miscData[0].appFeeType ==="percentage" ? " %" : ""}
                                    </InputAdornment>
                                  ),
                                  startAdornment: (
                                    <InputAdornment
                                      className="misc-unit">
                                      {miscData[0].appFeeType === "fixedAmount" ? "$ " : ""}
                                    </InputAdornment>
                                  )
                                }}
                                InputAdornmentProps={{ position: "end" }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-activ-dates">
                            ACTIVE DATES

                              <Grid item xs={12} className="misc-add-padd-top-two">
                              <FormControl component="fieldset">

                                <RadioGroup
                                  defaultValue="oneTimePlan"
                                  value={miscData[0].activeDatePlan || "oneTimePlan"}
                                  aria-label="activeDatePlan"
                                  name="activeDatePlan"
                                  onChange={e => {
                                    console.log("CURRENTVAL : ", e.target.value)
                                    
                                    miscChange(e);
                                  }}
                                >
                                  <FormControlLabel value="oneTimePlan" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >One Time (on the next pay cycle)</Typography>} />
                                  <FormControlLabel value="reccuringPlan" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Recurring</Typography>} />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {
                              miscData[0].activeDatePlan != "oneTimePlan" &&

                              <Grid
                                container
                                spacing={1}
                                direction="row"
                                className="misc-add-padd-top-two">
                                <Grid className="misc-card-validfrom">


                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      autoOk 
                                      emptyLabel="Select Date"
                                      invalidDateMessage="" 
                                      variant="inline"
                                      inputVariant="outlined"
                                      format="MMMM dd, yyyy"
                                      value={miscData[0].validFrom}
                                      name="validFrom"
                                      InputAdornmentProps={{ position: "start" }}
                                      onOpen={(e) => { 
                                        if (miscData[0].validFrom) { 
                                          console.log("CURRENTVAL : DATE", miscData[0].validFrom) 
                                        }
                                        else {
                                          console.log("CURRENTVAL : DATE", miscData.validFrom) 
                                          let ob = {
                                            "name": "validFrom",
                                            "value": new Date().toISOString()
                                          };
                                          miscChange(ob);
                                        }

                                      }}
                                      onChange={date => { 
                                        if (date instanceof Date && isFinite(date)) {
                                          console.log("CURRENTVAL : DATE", date.toISOString())
                                          let ob = {
                                            "name": "validFrom",
                                            "value": date.toISOString()
                                          }; 
                                        } 
                                      }}
                                    />

                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid className="misc-card-validto">

                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      autoOk
                                      
                                      emptyLabel="Select Date"
                                      invalidDateMessage=""
                                      
                                      
                                      

                                      variant="inline"
                                      inputVariant="outlined"
                                      format="MMMM dd, yyyy"
                                      value={miscData[0].validTo}
                                      name="validTo"
                                      InputAdornmentProps={{ position: "start" }}
                                      onOpen={() => {
                                        
                                        if (miscData[0].validTo) {

                                          console.log("CURRENTVAL : DATE", miscData[0].validTo)

                                        }
                                        else {
                                          console.log("CURRENTVAL : DATE", miscData[0].validTo)
                                          let ob = {
                                            "name": "validTo",
                                            "value": new Date().toISOString()
                                          };
                                          miscChange(ob);
                                        }

                                      }}
                                      onChange={date => {
                                        if (date instanceof Date && isFinite(date)) {
                                          console.log("CURRENTVAL : DATE", date.toISOString())
                                          let ob = {
                                            "name": "validTo",
                                            "value": date.toISOString()
                                          };
                                          miscChange(ob);

                                        }
                                      }}
                                    />

                                  </MuiPickersUtilsProvider>
                                </Grid>
                              </Grid>

                            }



                          </Grid>
                        </Grid>
                      </CardContent>
                    </div>
                  </Card>

                </Grid>

{/*                 <Grid item xs={12}
                   style={{ paddingTop: "4%" }} 
                >
                <div className={"offline-bt-flex"}>
                  <LoaderCLickButton
                    style={{ fontSize: "30px" }}
                    isLoading={isLoading}
                    size="large"
                    disabled={false}
                    onClick={() => {
                      
                      updateMiscFee()
                    }}
                  >
                    DONE
                  </LoaderCLickButton>
                  </div>
                </Grid> */}

              </Grid>

            </div>
          </DialogContent>
          <Divider variant="middle" />
          <DialogActions className={"addTenant-DialogActions offline-modal-bt"}>
            <Grid container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start">
                <Grid item xs={12}>
                    <div className={"offline-bt-flex"}>
                    <LoaderCLickButton
                      /* style={{ fontSize: "30px" }} */
                      isLoading={isLoading}
                      size="large"
                      disabled={false}
                      onClick={() => {
                        
                        updateMiscFee()
                      }}
                    >
                      DONE
                    </LoaderCLickButton>
                    </div>
                </Grid>               
            </Grid>
          </DialogActions>
        </Dialog>
        <Grid container spacing={1} className={"add-padding-p8p"}  onClick={e =>{ 
         history.push({pathname: `/${propertyData.PropertyName.replace(/\s+/g, '-')}/lease` ,props:{PropertyName:propertyData.PropertyName, propertyData:propertyData}});                      
            }}>
          <Grid item>
            <Button>
            <div className="addlease-top-flex-container">
              <div className = "addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
            </div>
            </Button> 
          </Grid> 
        </Grid>
        <Grid container spacing={1} className={"add-padding-p8p"} >
          <Grid item>
            <Typography
              component="h1"
              className="addlease-headerone"
              variant="h1"
            >
              Add Lease
                </Typography>
          </Grid> 
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
          <Grid item xs={12}>
            <Typography className="addlease-headerfour"
              variant="subtitle1"
            >
             Add new tenant(s) to this property and the lease terms in the same unit. Once submitted, the tenant(s) will be invited to the resident portal to activate
             their account and the lease terms will be active. If you want to create seperate lease terms, for individual recipient, please add new tenants individually.
                </Typography>
          </Grid>
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
           <Grid item minWidth={'197px'} xs={props.whichDevice!=='Desktop'?5:4} sm={props.whichDevice!=='Desktop'?4:3} md={props.whichDevice!=='Desktop'?4:2} lg={props.whichDevice!=='Desktop'?4:2}>
          <TextField
            className={`addlease-unit-field ${activateValidationBorder && !leaseData.unit ? "common-mandatory-red": ""}`}
            id="input-with-icon-textfield"
            variant="outlined"
            name="unit"
            value={leaseData.unit || ''}
            onChange={onleaseElementsChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  UNIT #
                </InputAdornment>
              ),
            }}
          />
          </Grid>
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className="addlease-addtenant-inner"> 
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1} className="add-lease-addtenant-card-inner-grid" >
            <Grid item xs={5} sm={5} md={5.5} lg={3.5}>
              <Button
                variant="outlined"
                className="addlease-activity-bt"
                startIcon={<GroupAddIcon />}
                onClick={(e) => { setAddUserOpen(true);  /* setManualTenantAdd(true) */ }}
              >
                ADD TENANT
            </Button>
            </Grid>

            <Grid item xs={5} sm={5} md={5.5} lg={3.5}>
              <Button
                variant="outlined"
                className="addlease-activity-bt"
                startIcon={<SupervisorAccountIcon />}
                onClick={(e) => { LoadResidentCombo(); setIsAddOrBrowse("Browse Tenant"); }}
              >
                BROWSE TENANT
              </Button>
            </Grid>

            </Grid>
            <Grid className="add-lease-addtenant-table-inner-grid" item xs={12} sm={12} md={12} lg={12}>
            {
                    tenantData && tenantData.length > 0 && 
                    <Grid container spacing={1}  >
                      {
                        tenantData.map((e, keyIndex) => {
                          
                          return (
                            <Grid key={keyIndex} item xs={12}>
                              <Card className="addLease-tenantlist-card">    
                                <CardActions className="addLease-list-tenant-card-action">
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid item xs={5} className="addLease-list-tenant-each-lb">
                                      {e.RenterName}
                                    </Grid>
                                    <Grid item xs={6} className="addLease-list-tenant-each-lb">
                                      {e.RenterMailID}
                                    </Grid> 
                                    <Grid item xs={1}>
                                      <BootstrapTooltip title="Delete">
                                        <Fab
                                          id="outlined-basic"
                                          value={props.value}
                                          size="small"
                                          color="secondary"
                                          onClick={de => {removeTenantToLease(e.RenterID)}}>
                                          <CloseIcon />
                                        </Fab>

                                      </BootstrapTooltip>
                                    </Grid>
                                  </Grid>
                                </CardActions>
                              </Card>
                              <Divider />
                            </Grid>
                          );
                        })}
                        
                    </Grid>
                  }
                  
                  <Grid item xs={12}>
                        {
                          !tenantData || !tenantData.length &&                     
                              <SvgIcon
                              viewBox="0 0 240 240"
                              className={"addlease-cardsvg"}
                              >
                              <TableAccountSvg 
                                width='240'
                                height='240'                        
                              />
                              </SvgIcon>                      
                        }
                        </Grid>
                        
                  

          </Grid>

          </Grid>
         
          
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1} className="add-lease-addtenant-card-inner-grid" >     
          <Grid  item xs={12} sm={12} md={12} lg={12}>
            <Card className="home-card addlease-rightcard"> 
            <CardContent>
            <Grid container spacing={4} className="add-lease-addtenant-rightcard-inner-grid" >
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> MONTHLY RENT
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <TextField
                          className={`${activateValidationBorder && !leaseData.monthlyRent ? " common-mandatory-red": ""}`}
                          id="input-with-icon-rent"
                          name="monthlyRent"
                          variant="outlined"
                          fullWidth
                          value={leaseData.monthlyRent || ""}
                          onChange={onleaseElementsChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> SECURITY DEPOSIT
                    </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <TextField 
                          id="input-with-icon-security"
                          name="securityDeposit"
                          variant="outlined"
                          fullWidth
                          onChange={onleaseElementsChange}
                          value={leaseData.securityDeposit || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> DUE ON
                    </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <Select
                          className={`${activateValidationBorder && !leaseData.leaseDueOn ? " common-mandatory-red": ""}`}
                          labelid="select-outlined-label"
                          id="due-select-outlined"
                          name="leaseDueOn"
                          fullWidth
                          variant="outlined"
                          value={leaseData.leaseDueOn || ''}
                          labelWidth={labelWidth}
                        onChange={onleaseElementsChange}
                      >
                        {dueOn.map((day,index) => {
                          return(
                            <MenuItem key={index} value={day}>
                            {day}
                            </MenuItem>
                          )
                        })}
                        </Select>
                      </div>
                    </Grid>
                 
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary">LEASE START
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className={`${activateValidationBorder && !leaseData.leaseStart ? " common-mandatory-red": ""}`}
                            autoOk
                            emptyLabel="Add date"
                            invalidDateMessage=""
                            /* minDate={leaseData.leaseStart ? new Date(leaseData.leaseStart).toISOString() : new Date().toISOString()} */
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            fullWidth
                            value= {leaseData.leaseStart ? leaseData.leaseStart : ""}
                            name="leaseStart"
                            InputAdornmentProps={{ position: "start" }}
                            InputProps={{ readOnly: true }}
                            readOnly={isPastLeaseStart}
                            disabled={isPastLeaseStart}
                            onOpen={(e) => { }}
                            onChange={date => {
                              onleaseElementsChange(date, 'leaseStart')
                            }}
                            onClick={() => setStartDateOpen(true)}
                            onClose={() => setStartDateOpen(false)}
                            open={startDateOpen}
                          />

                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary">LEASE END
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className={`${activateValidationBorder && !leaseData.leaseEnd ? " common-mandatory-red": ""}`}
                            autoOk
                            emptyLabel="Add date"
                            invalidDateMessage=""
                            InputProps={{ readOnly: true }}
                            minDate={leaseData.leaseStart ? new Date(leaseData.leaseStart).toISOString() :  new Date().toISOString()}
                            variant="inline"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            fullWidth
                            value={leaseData.leaseEnd ? leaseData.leaseEnd : null}
                            name="leaseEnd"
                            InputAdornmentProps={{ position: "start" }}
                            onOpen={(e) => { }}
                            onChange={date => {
                              onleaseElementsChange(date, 'leaseEnd')
                            }}
                            onClick={() => setEndDateOpen(true)}
                            onClose={() => setEndDateOpen(false)}
                            open={endDateOpen}
                          />

                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary">AFTER LEASE END
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <Select
                          className={`${activateValidationBorder && !leaseData.afterLeaseEnd ? " common-mandatory-red": ""}`}
                          labelid="select-outlined-label"
                          id="due-select-outlined"
                          fullWidth
                          variant="outlined"
                          name="afterLeaseEnd"
                          fullWidth
                          value={leaseData.afterLeaseEnd ? leaseData.afterLeaseEnd : ""}
                          labelWidth={labelWidth}
                          onChange={onleaseElementsChange}
                        >
                          <MenuItem key="1" value="MONTH-TO-MONTH">
                            MONTH-TO-MONTH
                          </MenuItem>
                          <MenuItem key="2" value="TERMINATE">
                            TERMINATE
                          </MenuItem>
                        </Select>
                      </div>
                    </Grid> 
                  
                  </Grid>

            </CardContent>
            
              
            </Card>
          </Grid>
          </Grid>
          </Grid>
           <Grid item xs={12} sm={12} md={6} lg={6}>
            {/*<div style={{ padding: "20px 0px 40px 0px" }}>
               <LoaderCLickButton
                isLoading={false}
                size="large"  
                disabled={false}
                onClick={() => { activateLease() }}
              >
                ACTIVATE
              </LoaderCLickButton> 
            </div>*/}
          </Grid> 
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {miscData[0].added ?
              <Card className="home-card">

                  <CardMedia
                    className="addlease-bottomcard-inner-card "
                    image={""}
                    title="TENANT MANAGEMENT"
                  >

                    <div className="addlease-flex-container">
                      <div className="addlease-misc-label">{miscData[0].appfeeName}</div>
                      <div className="addlease-misc-label">{miscData[0].appFeeType == 'percentage' ? `${miscData[0].appFeeValue} %` :  `$ ${miscData[0].appFeeValue}`}</div>
                      <div className="addlease-misc-label">{miscData[0].activeDatePlan == 'oneTimePlan' ? 'Expires-One-Time' : 'Reccuring'}</div>
                      <div className="addLease-misc-edit"><Button variant="outlined" className="addLease-misc-edit-icon" onClick={miscEdit => setAddMiscFeeOpen(true)}> <EditIcon /> </Button></div>
                      <div className="addLease-misc-edit"><Button variant="outlined" className="addLease-misc-edit-icon" onClick={miscEdit => deleteCurrentMisc()}> <DeleteIcon /> </Button></div>
                    </div>
                  </CardMedia>

              </Card>
              :  
                <Button
                  variant="outlined"
                  className="addlease-add-misc-bt"
                  startIcon={<AddIcon />}
                  onClick={e => setAddMiscFeeOpen(true)}
                >
                  ADD FEE
                            </Button>
       
            }
          </Grid>
        </Grid> 
        <Grid container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className="addlease-addtenant-inner">
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <LoaderCLickButton
                isLoading={false}
                size="large"  
                disabled={false}
                onClick={() => { activateLease() }}
              >
                ACTIVATE
              </LoaderCLickButton>
            </div>
          </Grid>
          </Grid> 
        </div> 
      </Container>
    </div>
  );

  async function validateLeaseStart (date) {
    
    let currentTimeStamp = new Date().getTime();
    let leaseStartDate = new Date(date).getTime();
    if (leaseStartDate <= currentTimeStamp) return true;
    if (leaseStartDate > currentTimeStamp) return false;
    return false;
  }   
 
  function miscChange (e) {
    
    if (e.target && e.target.name && e.target.name != "validFrom" && e.target.name != "validTo") {
      
      
      let miscObject = miscData[0];
      miscObject = { ...miscObject, [e.target.name]: e.target.value }
      setMiscData([miscObject]);
      
    }
    else {
      
      let miscObject = miscData[0];
      miscObject =  { ...miscObject, [e.name]: e.value };
      setMiscData([miscObject]);
    }
  }

  function updateMiscFee () {
    
    
    

    let emptyFields = 0;
      Object.keys(miscData[0]).forEach((each) => {
        if (each == "validFrom" || each == "validTo") {
          if (miscData[0].activeDatePlan == "reccuringPlan" && !miscData[0][each]) emptyFields++;
        }
        else if (each == "added") {}
        else {
          if (!miscData[0][each]) emptyFields++;
        }
        
      });

      
    if (emptyFields) {
      openSnackbar({ message:"Enter all fields", variant: "error" });
    }
    else {

      miscData[0].added = true;
      setMiscData(miscData);
      
      setAddMiscFeeOpen(false)

      openSnackbar({
        message:
        "Misc fee added successfuly",
        variant: "success"
      });
    }
    
    
  }
 
  function onleaseElementsChange(e, dateType) {
    let ob = {};
    if (e.target && e.target.name) {
      
      ob[e.target.name] =  e.target.value
    }
    else {
      if (dateType == 'leaseStart') ob[dateType] =  e.toISOString();
      if (dateType == 'leaseEnd') ob[dateType] =  e.toISOString();
    }
 
    setLeaseData({...leaseData, ...ob});

  }

  function activateLease(e) {
      
    setIsLoading(true);
    if (leaseData.monthlyRent &&
        leaseData.leaseDueOn &&
        leaseData.leaseStart &&
        leaseData.afterLeaseEnd && 
        leaseData.leaseEnd && 
        tenantData.length) {
          
          
          
          if (!leaseData.securityDeposit) leaseData.securityDeposit = 0;

          
          let leaseRequest = {
            PropertyID: propertyData.PropertyID,
            PropertyName: propertyData.PropertyName,
            
            tenants: tenantData,
            edit: editLease
          }
          if (miscData.length && miscData[0].added) leaseRequest.miscs = miscData;
          leaseRequest = {...leaseRequest, ...leaseData,userData:props.userSession};
          setLeaseData({...leaseData, ...{status: "Activated", miscs: miscData, tenants: tenantData}});
          let apiName = "rentapi";
          let path = "/modules";
          let myInit = {
                headers: { "Content-Type": "application/json" },
                body: leaseRequest,
                queryStringParameters: {         
                  module: "biller",
                  op: "saveRent"
                }
          };

           
           API.post(apiName, path, myInit).then(response => {
            if(response.success)
            {
                
            openSnackbar({
              message:
              response.message,
              variant: "success"
            }); 
            setLeaseData({
              "unit": "",
              "monthlyRent": "",
              "securityDeposit": "",
              "leaseDueOn": "",
              "leaseStart": new Date().toISOString(),
              "leaseEnd": new Date().toISOString(),
              "afterLeaseEnd": "",
              "tenantSearch": "",
              "status": ""
            });
            setMiscData(
              [{
                "appfeeName": "",
                "appFeeValue": 0,
                "appFeeType":"percentage",
                "activeDatePlan":"oneTimePlan",
                "validFrom":"",
                "validTo": "",
                "added": false
              }]
            )
            setTenantData([]); 
            setActivateValidationBorder(false);
            setIsLoading(false);
            SetCurrentView("ManageRents")

            }
            else{
              openSnackbar({ message:response.message, variant: "error" });     
              setIsLoading(false);
            } 
          }).catch(err=>{
            console.log(err);
            setIsLoading(false); 
            openSnackbar({ message:err.message, variant: "error" });      
          });
        }
        else {
          
          setActivateValidationBorder(true);
          
          let validationMsg = "Please enter all required fields";
         
          if (!leaseData.monthlyRent) {
            validationMsg = "Monthly rent is required to activate a lease";
          }
          if (!leaseData.leaseDueOn) {
            validationMsg = "Due on is required to activate a lease";
          }
          if (!leaseData.leaseStart) {
            validationMsg = "Lease start is required to activate a lease";
          }
          if (!leaseData.afterLeaseEnd) {
            validationMsg = "After lease end is required to activate a lease";
          }
          if (!leaseData.leaseEnd) {
            validationMsg = "Lease end end is required to activate a lease";
          }
          if (!tenantData.length) {
            validationMsg = "Minimum one tenant is required to activate a lease";
          }
          if (!leaseData.unit){
            validationMsg = "Unit is required to activate a lease";
          }

          if (!leaseData.unit &&
            !leaseData.monthlyRent &&
            !leaseData.leaseDueOn &&
            !leaseData.leaseStart &&
            !leaseData.afterLeaseEnd &&
            !leaseData.leaseEnd &&
            !tenantData.length
            ) {
              validationMsg = "Please enter all required fields";
            }
          setIsLoading(false);
          openSnackbar({ message: "Please enter all required fields", variant: "error" });  
          
        }
    

  } 
  function manaualTenantChange(e) {
    let ob = {};
    if (e.target && e.target.name) {
      
      ob[e.target.name] =  e.target.value
      setManualTenant({...manualTenant, ...ob})
    }
  } 
  /* function saveManualTenant() { 
    
    if (manualTenant.firstName &&
      manualTenant.lastName &&
      manualTenant.mobNumber &&
      manualTenant.email) {
        
        let apiName = "rentapi";
        let path = "/modules";
        let userRequest = {
          firstName: manualTenant.firstName,
          lastName: manualTenant.lastName,
          PropertyID: propertyData.PropertyID,
          PropertyName: propertyData.PropertyName,
          mobileNo: manualTenant.mobNumber,
          tenants: tenantData,
          email: manualTenant.email,
          domain:MapData.aws_app_domain_prefix,
          userData:props.userSession
        }
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: userRequest,
          queryStringParameters: {
            module: "user",
            op: "addManualTenant"
          }
        };
        API.post(apiName, path, myInit).then(response => {
          
          setIsLoading(false);
          
          if(!response.success)
          {
            openSnackbar({ message: response.message, variant: "error" });
          }
          else
          {
            addTenantToLease({}, response.masterId, {RenterMailID:manualTenant.email, RenterName: `${manualTenant.firstName} ${manualTenant.lastName}`, RenterID: response.masterId, RenterPhoneNumber: manualTenant.mobNumber});            
            setManualTenantAdd(false)
            openSnackbar({ message: "Tenant added successfully", variant: "success" });
          }         
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          openSnackbar({ message: "Tenant adding failed", variant: "error" });
        });

    }
    else {
      
      setIsLoading(false);
      openSnackbar({ message: "Please enter all required fields", variant: "error" });
      
    }
  }
  */

  function onResidentTextSearch(e) {
    
    let ob = {};
    if (e.target && e.target.name) {
      ob[e.target.name] =  e.target.value;
      
    }
    setLeaseData({...leaseData, ...ob});

    if (e.target.value.length > 3) {
      
      setLeaseData({tenantSearch: e.target.value});
      
      LoadResidentCombo(e.target.value);
    }

    if (e.target.value.length == 0) {
      setLeaseData({tenantSearch: ""});
      LoadResidentCombo();
    }

  }

  async function LoadResidentCombo(filterString) {
    
    setResidentTableState({});
    setIsLoading(true);
    await GetTableData("Resident", filterString)
      .then(tablData => {
        setResidentTableState(tablData);
        
      })
      .then(() => {
        
        setIsLoading(false);
        setAddTenant(true);
      });
  }

  async function addTenantToLease(updateData, residentID, newTenantData, cb) {    
    setTenantData(prvValue=>[...prvValue,newTenantData]);     
    if (residentTableState.data) {      
      residentTableState.data.forEach((each, index) => {
        console.log("CH : " , residentID,  each.MasterID)
        if (residentID == each.MasterID) each.HasAdded = "Yes";
      })        
      setResidentTableState(residentTableState);
    } 
  }  
  function removeTenantToLease(residentID) { 
    setTenantData(tenantData.filter(f => f.RenterID != residentID));
  } 
  async function GetTableData(type, filterString = "") {    
    var columns = [], columns2 = [],
      masterType = "",
      apiModule = "",
      op = "";
    var body = {};
    switch (type) {
      case "Resident":
        columns.push(
          { title: "Resident", field: "Name", searchable: false },
          { title: "Email", field: "Email", searchable: false }
        );

        masterType = "User";
        apiModule = "user";
        op = "getResidents";
        body = {
          PropertyData: propertyData,
          FilterData: filterString !== "" ? filterString : ""
        }
        break;
      default:
        break;
    }

    let tableData = {
      columns: columns,
      data: []
    };
    body.userData=props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(async response => {
          
          
          tableData.data = response.data && response.data.length > 0 ? response.data : [];
          
          if(type==="ManageRents"){   
                     
            await setRentTableData(prvData=>{ return []});  
                  
            Object.assign(rentTableData,tableData.data);                 
                
            tableData.columns2= columns2;  
            
            setRentVacancy(response.Occupancy);
          }
          else if(type==="Resident")
          {
             
               
                        
            response.data.forEach(function (entry) {
              entry.HasAdded = "No";
              tenantData.forEach(function (childrenEntry) {
                
                
                if (entry.MasterID === childrenEntry.RenterID && 
                  entry.PropertyID && 
                  entry.PropertyID.some((s) => s.PropertyID == propertyData.PropertyID)) entry.HasAdded = "Yes";
              });
            });
          } 
          resolve(tableData);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

}


