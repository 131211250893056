import React, { useState } from "react";
import clsx from 'clsx';
import Container from "@material-ui/core/Container";
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom"; 
import Amplify,{API, Auth} from "aws-amplify";
import LoaderButton from "./LoaderButton";
import { openSnackbar } from '../components/Notifier'; 
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import usePersistedState from '../usePersistedState';
import * as Sentry from '@sentry/browser';
import md5 from 'crypto-js';
import Policy from "../lib/Policy";
import PoweredBy from '../lib/PoweredBy';
import { S3Image } from 'aws-amplify-react';
import awsdev from '../aws-dev';
import awsdev2 from '../aws-dev2';
import { LoginOverview } from "./auth";
import { load as loadIntercom, boot as bootIntercom, update as updateIntercom,  shutdown as shutdownIntercom} from "../lib/intercom";
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
Amplify.configure({
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }});
const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    flexGrow: 1
  },
  paper: {
   /*  marginTop: theme.spacing(8), */
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  header: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },
  myImage: {
    width: "100%"
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  }
}));
export default function SignIn(props) {
  const classes = useStyles();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(localStorage.email);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0); 
  const [overviewPage, setOverviewPage] = useState(false); 
  const [loginName, setLoginName] = useState('');
  const [selectedProperty] = usePersistedState('selectedProperty',{});
  React.useEffect(() => {
    if(inputLabel.current)
      setLabelWidth(inputLabel.current.offsetWidth); 
  }, [inputLabel.current]);
  function validateForm() {
    return email && email.length > 0 && password.length > 0;
  }
  
  const setLoginTime = async (userSession) => {
    try {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "User", userData: userSession },
        queryStringParameters: {
          module: "user",
          op: "setLoginTime"
        }
      };
      await API.post(apiName, path, myInit);
    }
    catch (error) {
      console.log("set login time error",error); 
    }
  } 
  function loadMyProperties(userSession) {
    setIsLoading(true);
    var masterType = 'User';
    var apiModule = 'property';
    var op = 'listMyProperty';
    let body = { userData: userSession, masterType: masterType, MasterID: userSession.UserName, for: 'CardDisplay' };
    return new Promise((resolve, reject) => {
      let apiName = 'rentapi';
      let path = '/modules';
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          if (response && response.length > 0) {
            
            resolve(response);
          }
          else {
            resolve([]);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }  
  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try { 
      await Auth.signIn(email, md5.MD5(password).toString()).then(async (user)=>{ 
        setLoginName(user.signInUserSession.idToken.payload["name"]); 
        if(user.signInUserSession.idToken.payload["custom:privilege"]!=='USER')    
        {
          Sentry.setUser({ email:  user.signInUserSession.idToken.payload["email"] });
          Object.assign(props.userSession,{
            FirstName: user.signInUserSession.idToken.payload["custom:firstname"],
            LastName: user.signInUserSession.idToken.payload["custom:lastname"],
            Email: user.signInUserSession.idToken.payload["email"],
            UserName: user.signInUserSession.idToken.payload["cognito:username"],
            Privilege: user.signInUserSession.idToken.payload["custom:privilege"]
          }); 
          const user1 = await Promise.resolve({
            email,
            name: user.signInUserSession.idToken.payload["name"],
            created_at: new Date().getTime() / 1000
          }) 
          bootIntercom(user1);  
          setLoginTime(props.userSession);     
          localStorage.email = user.signInUserSession.idToken.payload["email"];  
          props.userHasAuthenticated(true);    
          await setPropertyForLoginUser(props.userSession);  
        }
        else{
          await Auth.signOut();
          setOverviewPage(true);
        }   
      });   
    } catch (err) {
      console.log("Login Error", err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant:"error" }); 
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  } 
  const setPropertyForLoginUser =async (userSession) => {
    let myProperties = await loadMyProperties(userSession);
    localStorage.userProperties =JSON.stringify(myProperties); 
    if (Object.keys(selectedProperty).length === 0 && selectedProperty.constructor === Object && myProperties && myProperties.length >= 1 ) {         
         localStorage.selectedProperty =JSON.stringify(myProperties[0]); 
    } 
    if(myProperties && myProperties.length === 1 && userSession.Privilege === 'RENTERS')   
       history.push({ pathname: `/${myProperties[0].PropertyName.replace(/\s+/g, '-')}`,props:{ currentProperty:myProperties[0]}}); 
    else 
      history.push({ pathname: '/',props:{ currentProperty:myProperties[0]}});      
  }
  return (
    overviewPage?
    <LoginOverview loginName={loginName}/>:
    <div width="100%">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '85vh' }}
      >
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Container component="main">
            <CssBaseline />
            <div className='signup-paper'>
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} className="main-logo"/> :
                    <Typography className='managment-header' component="h2" variant="h2" >
                      {MapData.management_name}
                    </Typography>}

                </Grid>
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <FormControl fullWidth required className={clsx(classes.margin)} variant="outlined">
                  <InputLabel htmlFor="password" ref={inputLabel} >Password</InputLabel>
                  <OutlinedInput
                    id="password"

                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={e => setShowPassword(!showPassword)}
                          onMouseDown={e => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={labelWidth}
                  />
                </FormControl>

                <div style={{ padding: "20px 0px 40px 0px" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <LoaderButton
                        block="true"
                        type="submit"
                        size="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                      >
                        SUBMIT
                      </LoaderButton>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <MLink color="textSecondary" underline={"always"} component={Link} to="/forget">
                      <Typography color="textSecondary" gutterBottom={true} variant="body2">
                        FORGOT YOUR PASSWORD
                      </Typography>
                    </MLink>
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom={true} variant="body2">
                      |
                    </Typography>
                  </Grid>
                  <Grid item>
                    <MLink color="textSecondary" underline={"always"} component={Link} to="/register" variant="body2">
                      <Typography color="textSecondary" gutterBottom={true} variant="body2">
                        REGISTER
                      </Typography>
                    </MLink>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </Grid>

      </Grid>
      <PoweredBy />
      <Policy class={"policy-cls"} />
    </div>
  );
}
