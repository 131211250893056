import React, { useState,useEffect, useRef } from "react";
import { Storage, API } from 'aws-amplify';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl';
import LoaderButton from ".././LoaderButton";
import portaLogo from "../../static/images/leaseleads_logo_Rectangle_3.png";
import { openSnackbar } from '../../components/Notifier';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import md5 from 'crypto-js';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from "@material-ui/icons/Add";
import { S3Image } from 'aws-amplify-react';
import Fab from "@material-ui/core/Fab";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { useHistory,useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
const MapData = window['countryConfig'];

const ImpStyles = makeStyles(theme => ({
    card1: {
        display: "flex",
        marginRight: "auto",
        marginBottom: "30px",
        maxHeight: "215px",
        maxWidth: "215px"
    },
    card2: {
        display: "flex",
        marginRight: "auto",
        marginBottom: "30px",
        maxHeight: "315px",
        maxWidth: "315px"
    },
    details: {
        display: "flex"
    },
}));

const useStyles = makeStyles((theme) => createStyles({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    admRegHeader: {
        padding: theme.spacing(5, 0, 5)
    },
    myImage: {
        width: "100%"
    },
    input: {
        display: 'none',
    },
}));

export default function GlobalSettings(props) {
    const classes = useStyles();
    const location = useLocation();  
    let history = useHistory();
    const [adminData,setAdminData]=useState({
        MasterID:'',
        FirstName:'',
        LastName:'', 
        Email:'',
        Password:'',  
        ManagementName:'',
        BusinessAddress:'',
        City:'',
        PostalCode:'',
        PhoneNumber:'',
        WebSite:'',
        Country:'',
        State:'',
        ManagementLogo:{}
    });   
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0); 
    const [comboDisable, setcomboDisable] = useState(true); 
    const [deletedKey] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const currentRender=useRef(false);
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth); 
    }, [inputLabel  ]);
    useEffect(() => { 
        const propertyFetch = async () => {
            let data = await getManagementDetails();
            
            await setAdminData(data);
            if (data.Country!=="") {
                const result = MapData.filter((country) => country.name === data.Country);
                if(result.length>0)
                {
                  console.log("use effect country"+data.Country,result[0].states)
                  setStateData(result[0].states);
                  setcomboDisable(false);   
                }
              }
        }
        if(!currentRender.current)
            {
                propertyFetch();
                currentRender.current=true;
            }
    }, []);
    function imageDelete(e) { 
        let items = deletedKey;
        if (items.length > 0) {
            for (let i = 0; i < items; i++) {
                if (items[i].FileKey !== e.FileKey) {
                    items.push(e.FileKey);
                }
            }
        }
        else {
            items.push(e.FileKey);
        }
        Object.assign(deletedKey, items); 
        setAdminData({ ...adminData, [e.Type]:{} });    
    }
    const ImageComponent = props => {
        const classes = ImpStyles();
        return (<div>
            <Card className={classes.card1}>
                <div className={classes.details}>
                    <CardActions>
                        <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
                            <CloseSharpIcon />
                        </Fab>
                    </CardActions>
                </div>
                <CardMedia
                    className={classes.cover}>
                    <S3Image theme={{ photoImg: { maxWidth: '159px', maxHeight: '159px', display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={props.FileKey} />
                </CardMedia>
            </Card>
        </div>);
    };

    function getFileExtension(filename) {
        return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    }
    function onChange(e, inputProps) {
        
        if (e.target.name === "Country") {            
            setAdminData({ ...adminData, [e.target.name]: e.target.value });          
            var selected = inputProps.props.alldata;
            
            
            setcomboDisable(false);
            setStateData(selected.states)
        }            
        else if (e.target.name === "ManagementLogo") {
            const target = e.target;
            
            
            
            const file = target.files[0];
            let targetName = target.name;
            var img = document.createElement("img");
            img.onload = function () {
                
                if (targetName === "ManagementLogo" && this.width <= 500 && this.height <= 500) {

                    let fileName = file.name;
                    let fileExt = getFileExtension(fileName);
                    let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
                    const fileData = { FileKey: s3Key, FileName: fileName };
                    Storage.put(s3Key, file, {
                        contentType: file.type
                    })
                        .then(result => {
                                                        
                            setAdminData({ ...adminData, [target.name]: fileData });          
                        })
                        .catch(err => console.log(err));
                }
                else {
                    openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });
                }
            };
            var reader = new FileReader();
            reader.onloadend = function (ended) {
                img.src = ended.target.result;
            };
            reader.readAsDataURL(target.files[0]);
            
        }
        else
        {          
            setAdminData({ ...adminData, [e.target.name]: e.target.value });          
        }
    };
    function validateForm() { 
        return adminData.ManagementName && adminData.ManagementName.length > 0 
        && adminData.FirstName && adminData.FirstName.length > 0 
        && adminData.LastName && adminData.LastName.length > 0;
    };
   
    async function signUp(e) {  
        e.preventDefault();
        setIsLoading(true);
        adminData.Password=adminData.Password!==''? md5.MD5(adminData.Password).toString():"";
        let apiName = 'rentapi';
        let path = '/modules';
        let myInit = {
            headers: { 'Content-Type': 'application/json' },
            body: adminData,
            queryStringParameters: {
                module: "admin",
                op: "adminManagement"
            }
        };
        
        API.post(apiName, path, myInit).then(response => {
            
            if (response.success) {
                setIsLoading(false);
                openSnackbar({ message: "User Updated successfully.. !!", variant: "success" });                          
            }
            else {
                setIsLoading(false); 
                openSnackbar({ message: response.message, variant: "error" });
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false); 
            openSnackbar({ message: err.message, variant: "error" });
        });
    };
    const getManagementDetails = async () => {
        setIsLoading(true);
        let body = { userData: props.userSession };
        return new Promise((resolve, reject) => {
            let apiName = 'rentapi';
            let path = '/modules';
            let myInit = {
                headers: { 'Content-Type': 'application/json' },
                body: body,
                queryStringParameters: {
                    module: "admin",
                    op: "getAdminDetails"
                }
            };
            API.post(apiName, path, myInit)
                .then(response => {
                    setIsLoading(false);
                    if (response && response.data) {
                        resolve(response.data);
                    }
                    else {
                        resolve({});
                    }
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                    reject(err);
                });
        });
    }
    return (

        <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            spacing={0}
            direction="column"
        >
            <Grid item>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <form
                        id="admin-signup-form"
                        className={classes.form}
                    >
                        <Grid container justify="flex-start" alignItems="center" spacing={1} style={{ paddingBottom: "56px" }} >
                            <Grid item>
                                <Box mb={5} className="property-settings-return">
                                    <Button onClick={()=>{
                                        history.push('/')
                                    }}>
                                        <div className="addlease-top-flex-container">
                                            <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                                        </div>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="global-settings-header">
                                <Typography
                                    component="h1"
                                    className="home-headerOne"
                                    variant="h1"
                                >
                                    Global Settings
                                </Typography>
                            </Grid> 
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                       {!isLoading && <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={adminData.ManagementName}
                                            name="ManagementName"
                                            label="MANAGEMENT NAME"
                                            onChange={onChange}
                                            type="text"
                                            id="ManagementName"
                                        />}
                                    </Grid>

                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                        {!isLoading && <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="Email"
                                            label="EMAIL"
                                            value={adminData.Email}
                                            onChange={onChange}
                                            name="Email"
                                            autoComplete="Email"
                                            disabled
                                        />}
                                    </Grid>                                   
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading && <TextField
                                            autoComplete="fname"
                                            name="FirstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={adminData.FirstName}
                                            id="FirstName"
                                            label="FIRST NAME"
                                            onChange={onChange}
                                            autoFocus
                                        />}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading &&  <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="LastName"
                                            value={adminData.LastName}
                                            label="LAST NAME"
                                            onChange={onChange}
                                            name="LastName"
                                            autoComplete="lname"
                                        />}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading &&  <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="BusinessAddress"
                                            value={adminData.BusinessAddress}
                                            label="MANAGEMENT BUSINESS ADDRESS"
                                            onChange={onChange}
                                            name="BusinessAddress"
                                        />}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading && <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="City"
                                            value={adminData.City}
                                            label="CITY"
                                            onChange={onChange}
                                            name="City"
                                        />}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading &&  <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            value={adminData.PostalCode}
                                            id="PostalCode"
                                            label="ZIP/POSTAL CODE"
                                            onChange={onChange}
                                            name="PostalCode"
                                        />}
                                    </Grid>
                                    <Grid item xs={6}>
                                         {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                         {!isLoading && <FormControl variant="outlined" fullWidth>
                                            <InputLabel ref={inputLabel} id="select-state-label">
                                                COUNTRY/REGION
                                            </InputLabel>
                                            <Select
                                                labelid="select-Country-label"
                                                id="Country-select-outlined"
                                                fullWidth
                                                variant="outlined"
                                                name="Country"
                                                label="COUNTRY/REGION"
                                                value={adminData.Country}
                                                labelWidth={labelWidth}
                                                onChange={(e, alldata) => { onChange(e, alldata); }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {MapData.map((e, keyIndex) => {
                                                    return (
                                                        <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                                            {e.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>}
                                    </Grid>
                                    <Grid item xs={6}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading && <FormControl variant="outlined" fullWidth>
                                            <InputLabel ref={inputLabel} id="select-state-label">
                                                STATE/PROVINCE
                                            </InputLabel>
                                            <Select
                                                labelid="select-state-label"
                                                id="state-select-outlined"
                                                fullWidth
                                                variant="outlined"
                                                name="State"
                                                label=" STATE/PROVINCE"
                                                value={adminData.State}
                                                labelWidth={labelWidth}
                                                disabled={comboDisable}
                                                onChange={onChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {stateData.map((e, keyIndex) => {
                                                    return (
                                                        <MenuItem key={keyIndex} value={e}>
                                                            {e}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading &&   <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="PhoneNumber"
                                            value={adminData.PhoneNumber}
                                            label="PHONE NUMBER"
                                            onChange={onChange}
                                            name="PhoneNumber"
                                        />}
                                    </Grid>
                                    <Grid item xs={12}>
                                    {isLoading && <Skeleton variant="rect"  height={52}/>}  
                                    {!isLoading &&   <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="WebSite"
                                            value={adminData.WebSite}
                                            label="WEBSITE (optional)"
                                            onChange={onChange}
                                            name="WebSite"
                                        />}
                                    </Grid>
                                    {isLoading && <Grid item xs={12}>
                                    <Skeleton variant="rect" width='100%'  height={52}/>
                                    </Grid>}
                                    {!isLoading && <Grid item xs={12}>                                      
                                        {adminData.ManagementLogo && adminData.ManagementLogo.hasOwnProperty("FileName") ?
                                            <ImageComponent Type={"ManagementLogo"} FileKey={adminData.ManagementLogo.FileKey} FileName={adminData.ManagementLogo.FileName} />
                                            :
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item xs={1}>
                                                    <input name='ManagementLogo' onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                                                    <label htmlFor="icon-button-file1">
                                                        <Fab color="primary" size="small" color="secondary" aria-label="upload picture" component="span">
                                                            <AddIcon />
                                                        </Fab>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography variant="h6">UPLOAD MANAGEMENT LOGO (if applicable)</Typography>
                                                    <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
                                                </Grid>
                                            </Grid>}
                                    </Grid>}
                                </Grid>

                            </Grid>
                        </Grid>

                        <div style={{ padding: "20px 0px 40px 0px" }}>
                            <Grid
                                container
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item xs={12}>
                                    {!isLoading && <LoaderButton
                                        block="true"
                                        size="large"
                                        isLoading={isLoading}
                                        disabled={!validateForm()}
                                        onClick={signUp}
                                    >
                                       UPDATE
                                    </LoaderButton>}
                                    {isLoading && <Skeleton variant="rect" className={"skelton-min-width-259 skelton-max-width-259"} height={52}/>}
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Container>
            </Grid>
        </Grid>

    );
}
