import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from '@material-ui/core/ListItemText'; 
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { Skeleton } from '@material-ui/lab';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton"; 
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as VisaSvg } from '../../static/images/visa-light-large.svg';
import { ReactComponent as MasterCardSvg } from '../../static/images/mastercard-light-large.svg';
import { ReactComponent as AmericanExpressSvg } from '../../static/images/americanexpress-light-large.svg';
import { ReactComponent as MaestroSvg } from '../../static/images/maestro-light-large.svg';
import { ReactComponent as DiscoverSvg } from '../../static/images/discover-light-large.svg';


export default function AddCreditCard(props) {
    console.log("props AddCreditCard : " , props );
    let history = useHistory();
    const location = useLocation();
    const year = (new Date()).getFullYear();
    const [isLoadingModal, setIsLoadingModal] = useState(props.isLoading || false);
    const [creditCardData, setCreditCardData] = useState(props.creditCardData ? props.creditCardData : {});
    const [expYear] = useState(Array.from(new Array(20),(val, index) => (index + year).toString())); 
    const [expMonth] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);

    const MapData = window['countryConfig'];

    const DialogTitleCreditCard = (props) => {
        const { children, onClose } = props;
        return (
          <MuiDialogTitle disableTypography>
            <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      }

      function onChange(e,inputProps) {
        console.log(e);
        if (e.target.name === "Country") {
          var selected = inputProps.props.alldata;        
          setCreditCardData(prvCard => { return { ...prvCard, "Country": e.target.value,"CountryCode": selected.iso2 } });         
        }
        else
        {
            setCreditCardData({ ...creditCardData, [e.target.name]: e.target.value });
        }

        props.onChildClick(e.target.name, e.target.value/* , child && child.props && child.props.id ? child.props.id : null */);

      }

      return(
      <div>
          <Grid
            container
            spacing={2}
            direction="column"
            justify="center"
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
              <Grid item className={"addcreditcard-topicons"} xs={12}>
              {!props.isLoading && <div>
            <SvgIcon
                      viewBox="0 0 240 240"
                      className={"makepayment-gatewaysvg addcreditcard-marginleft3d5p"}
                      >
                      <VisaSvg 
                        width='240'
                        height='240'
                        
                      />
                      </SvgIcon>
                      <SvgIcon
                      viewBox="0 0 240 240"
                      className={"makepayment-gatewaysvg addcreditcard-marginleft3d5p"}
                      >
                      <MasterCardSvg 
                        width='240'
                        height='240'
                        
                      />
                      </SvgIcon>
                      <SvgIcon
                      viewBox="0 0 240 240"
                      className={"makepayment-gatewaysvg addcreditcard-marginleft3d5p"}
                      >
                      <AmericanExpressSvg 
                        width='240'
                        height='240'
                        
                      />
                      </SvgIcon>
                      <SvgIcon
                      viewBox="0 0 240 240"
                      className={"makepayment-gatewaysvg addcreditcard-marginleft3d5p"}
                      >
                      <MaestroSvg 
                        width='240'
                        height='240'
                        
                      />
                      </SvgIcon>
                      <SvgIcon
                      viewBox="0 0 240 240"
                      className={"makepayment-gatewaysvg addcreditcard-marginleft3d5p"}
                      >
                      <DiscoverSvg 
                        width='240'
                        height='240'
                        
                      />
                      </SvgIcon>
                      </div>}
                      {props.isLoading && <Skeleton variant="rect" height={50} /> }
            </Grid>

            <Grid item xs={12} className={"addcreditcard-width100p"}>

            {!props.isLoading && <TextField
                  variant="outlined"
                  className={"addcreditcard-nameOnCard"}
                  placeholder= "NAME ON CARD"
                  fullWidth
                  name="NameOnCard"
                  value = {creditCardData.NameOnCard}
                  onChange={onChange}
                  type="text"
                  id="NameOnCard"
                  
                />
            }

                {props.isLoading && <Skeleton variant="rect" height={55} /> }

              </Grid>

              <Grid item xs={12} className={"addcreditcard-width100p"}>

              {!props.isLoading && <TextField
                variant="outlined"
                className={"addcreditcard-billingAddress"}
                placeholder= "BILLING ADDRESS"
                fullWidth
                name="BillingAddress"
                value = {creditCardData.BillingAddress}
                onChange={onChange}
                type="text"
                id="BillingAddress"
                
              />
              }
                {props.isLoading && <Skeleton variant="rect" height={55} /> }

              </Grid>

                      <Grid item xs={12} className={"addcreditcard-width100p"}>
                      {!props.isLoading && <Select
                          //labelid="select-Country-label"
                          id="Country-select-outlined"
                          fullWidth
                          className={"addcreditcard-select-placeholder"}
                          variant="outlined"
                          name="Country"
                          displayEmpty
                          value={creditCardData.Country}
                          onChange={(e, alldata) => { onChange(e, alldata); }}
                        >
  
                          <MenuItem value="" disabled >
                          BILLING COUNTRY
                        </MenuItem>
                          {MapData.map((e, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        }
                        {props.isLoading && <Skeleton variant="rect" height={55} /> }
                      </Grid>
              

              <Grid item xs={12} className={"addcreditcard-width100p"}>

              {!props.isLoading && <TextField
                variant="outlined"
                className={"addcreditcard-billingPostalCode"}
                placeholder= "BILLING POSTAL CODE"
                fullWidth
                name="PostalCode"
                value = {creditCardData.PostalCode}
                onChange={onChange}
                type="text"
                id="PostalCode"
                
              />
              }
              {props.isLoading && <Skeleton variant="rect" height={55} /> }

              </Grid>

              <Grid item xs={12} className={"addcreditcard-width100p"}>

              {!props.isLoading && <TextField
                variant="outlined"
                className={"addcreditcard-cardNumber"}
                placeholder= "CARD NUMBER"
                fullWidth
                name="CardNumber"
                value = {creditCardData.CardNumber}
                onChange={onChange}
                type="text"
                id="CardNumber"
                
              />
              }
              </Grid>

              <Grid item xs={12} className={"addcreditcard-width100p"}>

              {!props.isLoading && <TextField
                variant="outlined"
                className={"addcreditcard-cvvcid"}
                placeholder= "CVV/CID"
                fullWidth
                name="CvvCid"
                value = {creditCardData.CvvCid}
                onChange={onChange}
                type="text"
                id="CvvCid"
                
              />
              }

              </Grid>

              <Grid item xs={12} sm={4} className={"addcreditcard-width100p"}>

              {!props.isLoading && <InputLabel  className={"addcreditcard-payment-labels"}>
                      EXPIRATION
                </InputLabel>
                }

                {!props.isLoading && <Select                  
                  id="ExpiryMonth-select-outlined"
                  className={"addcreditcard-select-placeholder"}
                  fullWidth
                  variant="outlined"
                  name="ExpiryMonth"
                  displayEmpty
                  value={creditCardData.ExpiryMonth}                  
                  onChange={onChange}
                  
                >
                    <MenuItem value="" disabled >
                        MM
                    </MenuItem>
                  {expMonth.map((e, keyIndex) => {
                    return (
                      <MenuItem
                        key={keyIndex}
                        value={e}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
                }

              </Grid>

              <Grid item xs={12} sm={4} className={"addcreditcard-width100p"}>
              <InputLabel  className={"addcreditcard-payment-labels-disbl"}>
                      EXPIRATION
                </InputLabel>
                {!props.isLoading && <Select                  
                  id="ExpiryYear-select-outlined"
                  fullWidth
                  className={"addcreditcard-select-placeholder"}
                  variant="outlined"
                  name="ExpiryYear"
                  displayEmpty
                  value={creditCardData.ExpiryYear}             
                  onChange={onChange}                  
                >
                    <MenuItem value="" disabled >
                        YYYY
                    </MenuItem>
                  {expYear.map((e, keyIndex) => {
                    return (
                      <MenuItem
                        key={keyIndex}
                        value={e}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
            }

              </Grid>
          </Grid>
      </div>
        );
}