import React from "react";
import MLink from "@material-ui/core/Link";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ReactComponent as StripeSvg } from '../static/images/stripe.svg';
import { ReactComponent as PlaidSvg } from '../static/images/Plaid_logo.svg';
import {Grid,Box,Typography,Divider,SvgIcon} from "@material-ui/core";
export default function SecurePayInfo(props) {

  console.log('Payment info props:', props);
  return (
    <div>
    <Box pl={3} pr={3} >   
                        <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Divider light className={"renter-margintop-3d8p add-marginbottom-1d2p"}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                         
                          <div className={"offline-bt-flex"}>
                          <LockOutlinedIcon className={"secure-icon-payment"}/>
                          <Typography className={"makepayment-footer-1"}>
                              Secure credit and debit card payment
                          </Typography>
                          </div>
                          <Typography className={"makepayment-footer-2"}>
                              This is a secure 128-bit-SSL encrypted payment
                          </Typography>
                          <Typography className={"makepayment-footer-1"}>
                              Is my information secure?
                          </Typography>
                          <Typography className={"makepayment-footer-2"}>
                                Lease Leads Resident Portal uses Stripe and Plaid for payment processing. Stripe has been audited by a a PCI-Certified
                                auditor and is certified to PCI Service Provider Level 1.This is the most stringent level of certification in the payment
                                industry.
                          </Typography>
                          <Typography className={"makepayment-footer-3"}>
                                Payment processing provided by:
                          </Typography>
                          <div>
                          <MLink color="inherit" href={"https://stripe.com/"}>
                          <SvgIcon
                            viewBox="0 0 240 240"
                            className={"makepayment-gatewaysvg"}
                            >
                            <StripeSvg 
                              width='240'
                              height='240'
                              
                            />
                            </SvgIcon>
                            </MLink>

                            <MLink color="inherit" href={"https://plaid.com/"}>
                            <SvgIcon
                            viewBox="0 0 240 240"
                            className={"makepayment-gatewaysvg makepayment-paddingleft-1p"}
                            >
                            <PlaidSvg 
                              width='240'
                              height='240'
                              
                            />
                            </SvgIcon>
                            </MLink>
                          </div>
                        
                      </Grid>
                      </Grid>
    </Box>
    </div>
  );
}
