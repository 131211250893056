import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline"; 
import {Grid,makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container"; 
import Fab from "@material-ui/core/Fab"; 
import { API } from "aws-amplify";
import MaterialTable from "material-table"; 
import Button from '@material-ui/core/Button'; 
import TextField from '@material-ui/core/TextField';
import Property from '../admin/property' 
import Moment from 'react-moment';
import MLink from "@material-ui/core/Link";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import { LoaderCLickButton } from "../LoaderButton";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'; 
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreateIcon from '@material-ui/icons/Create'; 
import SearchIcon from '@material-ui/icons/Search';
import CustomizedTooltips from '../../lib/tooltIp'; 
import MuiDialogTitle from '@material-ui/core/DialogTitle'; 
import { openSnackbar } from '../Notifier';
import { useHistory, useLocation } from "react-router-dom";
import usePersistedState from '../../usePersistedState';
import { Skeleton } from '@material-ui/lab'; 
const useStyles = makeStyles((theme) => ({
  tabindicator:{
    "& .MuiTabs-indicator": {
       backgroundColor:"#F8F8F8"
    },
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
} 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export function ManageRents(props) {
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles(); 
  const tableRef = React.createRef(); 
  const isRefresh = React.useRef(true);
  const [currentView, SetCurrentView] = useState(props.fromAddLease ? "ManageRents" : "home"); 
  const [propertyData, setPropertyData] = usePersistedState('propertyData', {});  
  const [thisProperty, setThisProperty] =usePersistedState('thisProperty',{}); 
  const [isLoading, setIsLoading] = useState(false); 
  const [editPropertyOpen, setEditPropertyOpen] = React.useState(false); 
  const [rentTabValue, setRentTabValue] = React.useState(0); 
  const [currentLease, setCurrentLease] = React.useState({}); 
  const [rentFilter, setRentFilter] = useState("");
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [pageSize, setPageSize] = usePersistedState('pageSize', 25); 
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) {
        await setPropertyData(selectedProperty); 
      }
      if (!location.props && isRefresh.current && selectedProperty.hasOwnProperty("PropertyName")) {
        isRefresh.current = false; 
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [location.props,selectedProperty]);
  useEffect(() => {
      if (tableRef.current)
        tableRef.current.onQueryChange();
  },[/* rentFilter, */currentLease])
  async function handleChildClick(type, data = {}) {
    if(type==="Property grid")
    {  
      setEditPropertyOpen(false);
      setIsLoading(false); 
    } 
  } 
  async function getPropertyData(property) { 
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    property.userData = props.userSession;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: property,
      queryStringParameters: {
        module: "biller",
        op: "getPropertyDetails"
      }
    }; 
      API.post(apiName, path, myInit).then(response => {  
        setIsLoading(false); 
        setPropertyData(response.data);
      }).catch(err => {
        console.log(err);
      }); 
  }
  /**
 *  Function will handle dialog close event
 * @param {*} e -dialog type 
 */
  const handleClose = (e) => {
    if (e === "delete") {
      setDeleteOpen(false);
      setCurrentLease({});
    }
  };
  /**
 *  Function will handle dialog open event
 * @param {*} e -dialog type 
 */
  const handleClickOpen = (e, rowData) => {
    if (e === "delete") {
      setCurrentLease(rowData);
      setDeleteOpen(true);
    }
  };
  async function deleteRent() { 
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { "MasterID": currentLease.MasterID, "userData": props.userSession },
      queryStringParameters: {
        module: "biller",
        op: "deleteRent"
      }
    };
     await API.post(apiName, path, myInit).then(response => { 
      if (response.success) {
        setDeleteOpen(false);
        setCurrentLease({});
        setIsLoading(false);
        if (tableRef.current)
              tableRef.current.onQueryChange();
        openSnackbar({
          message:
            response.message,
          variant: "success"
        });
        if(tableRef.current) tableRef.current.onQueryChange();
           console.log('tableRef.current #########',tableRef.current);  
      }
      else {
        setIsLoading(false);
        openSnackbar({ message: response.message, variant: "error" });
      }

    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: "error" });
    });

  }

  /**
   *  Function will handle tab change event
   * @param {tab change event} event 
   * @param {tab new index} newValue 
   */

  const handleLeaseTabChange = async (event, newValue) => {
    var rentTab = "All";  
    setRentTabValue(newValue);
    switch (newValue) {
      case 0:
        rentTab = "All";
        break;
      case 1:
        rentTab = "Active";
        break;
      case 2:
        rentTab = "MonthToMonth";
        break;
      case 3:
        rentTab = "Expired";
        break;
      default:
        break;
    } 
    if (tableRef.current)
      tableRef.current.onQueryChange();
  }
  async function GetPagingData(query) {
    var pageSize = query.pageSize;
    var pageNo = (query.page + 1);
    let body = {};
    var columns = [],
      masterType = "",
      apiModule = "",
      op = "",
      rentTab = "All";
    setIsLoading(true);
    setPageSize(pageSize);
    switch (rentTabValue) {
      case 0:
        rentTab = "All";
        break;
      case 1:
        rentTab = "Active";
        break;
      case 2:
        rentTab = "MonthToMonth";
        break;
      case 3:
        rentTab = "Expired";
        break;
      default:
        break;
    }
    masterType = "Rent";
    apiModule = "biller";
    op = "listRentPages";
    body = {
      masterType: masterType,
      property: selectedProperty,
      UserID: props.userSession.UserName,
      Status: rentTab,
      Filter: rentFilter,
      pageSize: pageSize,
      pageNo: pageNo
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);
        resolve(response.data);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  } 
  const DialogPropertyTitle = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h6" className="addlease-manualtenant-header">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  var infoButtonMisc = (rowData) => {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      > <Grid item xs={5}>
          <Typography className="biller-wrapper"> {rowData.Miscs && rowData.Miscs.length ? (rowData.Miscs[0].appFeeType == "fixedAmount" ? "$" : " ") + rowData.Miscs[0].appFeeValue + " " + (rowData.Miscs[0].appFeeType == "percentage" ? " %" : "") : "-"}</Typography>
        </Grid>
        <Grid item xs={7}>
          {rowData.Miscs && rowData.Miscs.length ?
            <CustomizedTooltips style={{ float: "left" }} content={rowData.Miscs && rowData.Miscs.length ? rowData.Miscs[0].activeDatePlan == "oneTimePlan" ? "Expires next payment" : "Expires " + (new Date(rowData.Miscs[0].validTo)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ""} />
            : null}
        </Grid>
      </Grid>
    )
  }
  function getCoRenterEmail(rowData) {
    if (rowData.CoRentersEmail && rowData.CoRentersEmail !== '')
      return rowData.CoRentersEmail;
    if (rowData.CoRenters && rowData.CoRenters.length == 0)
      return '';
    var obj = [];
    for (var i = 0; i < rowData.CoRenters.length; i++) {
      var counter = rowData.CoRenters[i];
      var schID = counter.RenterMailID
      obj.push(schID)
    }
    return obj.length > 0 ? obj.join(', ') : ''
  }
  function logicForLeaseactivity(rowData, rentTabIndex) { 
    let currentTimeStamp = new Date().setHours(23, 59, 59, 0);
    let incomingTimeStamp = new Date(rowData.RentEndDate).getTime();  
    switch (rentTabIndex) {
      case 0:
        if (currentTimeStamp > incomingTimeStamp) { 
          if (rowData.RentAfterLeaseEnd == 'MONTH-TO-MONTH') return 'MONTH-TO-MONTH';
          if (rowData.RentAfterLeaseEnd == 'TERMINATE') return 'EXPIRED';
        }
        else { 
          return 'ACTIVE';
        }
        break;
      case 1:
        if (currentTimeStamp <= incomingTimeStamp) return 'ACTIVE';
        break;
      case 2:
        return 'MONTH-TO-MONTH';
        break;
      case 3:
        return 'EXPIRED'
        break;
      default:
        break;
    }
  }
  function editLease(rowData) {
    setCurrentLease(rowData); 
    history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/edit` ,props:{editLease:true,currentLease:rowData}});
  } 
  async function onChange(e) { 
    setRentFilter(prvData => { return e.target.value }); 
  }

  async function onKeyPressFilter(e) {
    
    if(e.keyCode == 13){
        if(tableRef.current) {
          tableRef.current.onQueryChange();
        }
      
   }
  }

  async function onSearchFilter(e) {

    if (rentFilter) {
      if(tableRef.current) {
        tableRef.current.onQueryChange();
      }
    }

  }

  return (
    <div className="home-root"> 
      <Grid container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            {/* <Spinner isLoading={isLoading} className="common-spinner" 
              area={areas.admin}
            />*/}
            <div className="home-paper">
              <Dialog open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
                maxWidth={"sm"} aria-labelledby="form-dialog-title">
                <DialogTitle id="delete-dialog-title" onClose={e => handleClose("delete")}>
                  Deletion Confirmation
        </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    We just want to make sure this is the correct decision. This is irreversible action and the rent data will be removed from our system immediately
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <LoaderCLickButton
                    //size="large"
                    onClick={async e =>await deleteRent()}
                    isLoading={isLoading}
                  >
                    YES
                  </LoaderCLickButton>

                </DialogActions>
              </Dialog>
              <Dialog
                //fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={"sm"}
                open={editPropertyOpen}
                onClose={e => setEditPropertyOpen(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogPropertyTitle className="addnew-user-dialog-title" id="customized-dialog-title" onClose={e => {setEditPropertyOpen(false) }}>
                  Edit Property
            </DialogPropertyTitle>

                <DialogContent className="addtenant-dialog-content">
                  <Grid container
                    spacing={2}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography className="biller-headerfour"
                        variant="subtitle1"
                      >
                        {"Edit the Property details here "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Property {...props} rowEditData={selectedProperty} isEditing={true} fromRent={true} onChildClick={(type, e) => handleChildClick("Property grid", e)} />
                </DialogContent>
              </Dialog>

              <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
              }}>
                <Grid item>
                  <Button>
                    <div className="addlease-top-flex-container">
                      <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                    </div>
                  </Button>
                </Grid>
              </Grid>


              <Grid container
                spacing={2}
                direction="row"
                justify="space-between"
                alignItems="center"> 
                <Grid item xs={12}>
                  {!isLoading && 
                  <Typography
                    component="h1"
                    className="biller-headerOne biller-fontweight"
                    variant="h1"
                  >
                    Manage Lease {/* { props.userSession.Privilege === "ADMIN" && selectedProperty? "- " + selectedProperty.PropertyName : "" } QA: v2 : line number 52*/} 
                  </Typography>
                  }
                  {isLoading &&
                    <div className={"skelton-flex-parent"}>
                      <Skeleton  variant="rect" width={588} height={69} className={"skelton-margin-right-5"}/>
                      <Skeleton  variant="rect" width={588} height={69}/>
                    </div>
                  }
                </Grid>
              </Grid> 
              {props.userSession.Privilege === "ADMIN" && <Grid container
                spacing={2}
                maxwidth="50%"
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={"add-margintop-p8p"}
                >
               {/*  <Grid item>
                { 
                !isLoading && <Fab size="small" color="secondary" aria-label="edit" onClick={() => { setEditPropertyOpen(true);}}>
                    <CreateIcon style={{ fontSize: 18 }} />
                  </Fab>
                }
                  { isLoading && <Skeleton variant="circle" width={45} height={45}/>}
                </Grid>
                <Grid item>
                { !isLoading && 
                  <Typography className="biller-headerThree"
                    component="h5"
                    variant="h5"
                  >
                    EDIT PROPERTY
                </Typography>
                }
                { isLoading && <Skeleton variant="rect" width={132} height={32}/>} 
                </Grid> */}
                <Grid item>
                { !isLoading &&
                  <Fab size="small" color="secondary" aria-label="edit" onClick={() => {  
                    /* setBreadCrumb([...breadCrumb, { to: '/', key: "AddLease", label: 'Add Lease' }]);  SetCurrentView("AddLease"*/ 
                    history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add` ,props:{editLease:false,property:selectedProperty,currentLease:{}}});
                    }}>
                    <PersonOutlineIcon style={{ fontSize: 18 }} />
                    
                  </Fab>
                  }
                  { isLoading && <Skeleton variant="circle" width={45} height={45}/>}
                </Grid>
                <Grid item>
                { !isLoading && 
                  <Typography className='biller-headerThree add-lease-tag'
                    component="h5"
                    variant="h5"
                    onClick={() => {  
                      /* setBreadCrumb([...breadCrumb, { to: '/', key: "AddLease", label: 'Add Lease' }]);  SetCurrentView("AddLease"*/ 
                      history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add` ,props:{editLease:false,property:selectedProperty,currentLease:{}}});
                      }}
                  >
                    ADD LEASE
              
              </Typography>
              }
              { isLoading && <Skeleton variant="rect" width={94} height={32}/>} 
                </Grid>
              </Grid>}
              <Grid container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center">

                <Grid item xs={12} sm={12} md={12} lg={12}>
                {!isLoading &&
                  <Typography className='biller-headerfour add-margintop-p8p add-padding-p8p'
                  variant="subtitle1"
                  >
                    You can add, delete and modify rent details here
                </Typography>
                }
                {isLoading && <Skeleton className ={"add-margintop-p8p add-padding-p8p"} variant="rect" width={262} height={19}/>}
                </Grid>
              </Grid>

              {/*FILTER AND TABS*/}
              <Grid container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center">
                <Grid item  xs={12} sm={12} md={3} lg={3}>
                { !isLoading &&
                  <TextField
                    style={{ width: "100%" }} 
                    id="input-with-icon-textfield"
                    variant="outlined"
                    placeholder="Filter email, name unit #"
                    name="RentFIlter"
                    value={rentFilter}
                    onChange={onChange}
                    onKeyDown={onKeyPressFilter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MLink
                          className={"gridfilter-innersearch-bt"}>
                          <SearchIcon
                          onClick={onSearchFilter}
                          />
                          </MLink>
                        </InputAdornment>
                      ),
                    }}
                  />
                  }
                  { isLoading && <Skeleton variant="rect" height={52}/>}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                {!isLoading &&
                  <Tabs
                    value={rentTabValue}
                    spacing={2}
                    onChange={handleLeaseTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    inkbarstyle={{ background: '#6200EE' }}
                  >

                    <Tab label="ALL" {...a11yProps(0)} />
                    <Tab label="ACTIVE LEASES" {...a11yProps(1)} />
                    <Tab label="MONTH-TO-MONTH" {...a11yProps(2)} />
                    <Tab label="EXPIRED LEASES" {...a11yProps(3)} />
                  </Tabs>
                  }
                  {isLoading &&
                  <Tabs
                    spacing={2}
                    variant="fullWidth" 
                    textColor="primary"
                    className={classes.tabindicator}
                    value={0}
                    inkbarstyle={{ background: '#C7C7C7' }}
                  >

                    <Skeleton  variant="rect"  height={52} className={"skelton-margin-right-6 skelton-grid-element-custom-100"}/>
                  </Tabs>
                  }
                </Grid>
              </Grid>
              <div 
              //style={{ padding: "20px 0px 40px 0px" }}
              className={isLoading ? "property-table-hide" : "property-table-show"}
              > 
                <MaterialTable
                   tableRef={tableRef}
                  columns={[{
                    title: "UNIT #",
                    field: "RentUnit",
                    searchable: false,
                    //editable: 'never'
                  },{
                    title: "LEASE HOLDER(S)",
                    field: "CoRentersName", 
                    searchable: false 
                  },
                  {
                    title: "LEASE HOLDER(S) EMAILS",
                    field: "CoRentersName",
                    render:rowData=> { return getCoRenterEmail(rowData)},
                    searchable: false 
                  }, 
                  {
                    title: "LEASE START",
                    field: "RentStartDate",
                    searchable: false,
                    render: rowData => <Moment format="MMM DD YYYY">{rowData.RentStartDate}</Moment>
                  },
                  {
                    title: "LEASE END",
                    field: "RentEndDate",
                    searchable: false,
                    render: rowData => <Moment format="MMM DD YYYY">{rowData.RentEndDate}</Moment>
                  },
                  {
                    title: "SECURITY",
                    field: "RentSecurutyDeposit",
                    searchable: false,
                    render: rowData => rowData.RentSecurutyDeposit === 0 ? "-" : <div> {`$${rowData.RentSecurutyDeposit}`} </div>
                  },
                  {
                    title: "MONTHLY RENT",
                    field: "RentEachMonth",
                    searchable: false,
                    render: rowData => <div> {`$${rowData.RentEachMonth}`} </div>
                  },
                  {
                    title: "MISC FEE",
                    field: "RentMiscFee",
                    render: rowData => infoButtonMisc(rowData),
                    searchable: false,
                  },
                  {
                    title: "LEASE ACTIVITY",
                    field: "LeaseActivity",
                    searchable: false,
                    render: (rowData, index) => <TextField variant="outlined" inputProps={{ min: 0, style: { textAlign: 'center' } }} disabled={true} className={logicForLeaseactivity(rowData, rentTabValue) == 'EXPIRED' ? "biller-lease-activity-tf-dsbl" : "biller-lease-activity-tf"} value={logicForLeaseactivity(rowData, rentTabValue)} > </TextField>

                  },
                  {
                    title: "ACTIONS",
                    render: rowData => <div> <Button className="biller-edit-icon" onClick={e => { editLease(rowData) }} > <EditIcon /> </Button> <Button className="biller-edit-icon" onClick={e => { handleClickOpen("delete", rowData) }} > <DeleteIcon /> </Button></div>
                  }]}
                  data={async query => new Promise((resolve, reject) => {
                    GetPagingData(query)
                      .then(result => { 
                        resolve({
                          data: result.data,
                          page: result.page,
                          totalCount: result.total
                        })
                      })

                  })}
                  options={{
                    searchFieldStyle: {
                      display: 'none'
                    },
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    pageSize: pageSize,
                    pageSizeOptions: [25, 50, 100, 200]
                  }}
                />
              </div>


              <div 
              //style={{ padding: "20px 0px 40px 0px" }}
              className={!isLoading ? "property-table-hide" : "property-table-show"}
              > 
                <MaterialTable
                  columns={[{ title: "", width:"100%", render: rowData =>{ return <Skeleton variant="text" />}}]}
                  data={[{},{},{},{},{}]}
                  options={{
                    searchFieldStyle: {
                      display: 'none'
                    },
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    pageSize: 25,
                    pageSizeOptions: [25]
                  }}
                                     
                components={{
                  OverlayLoading: props => {
                      return <div />
                  }
                 }}
                 localization={{
                   header : {
                      actions: ''
                   }
                 }}

                />
              </div>

            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}