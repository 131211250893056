import React, { useState, useEffect, useRef  } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MaterialTable from "material-table";
import ListItemText from '@material-ui/core/ListItemText';
import { trackPromise } from 'react-promise-tracker'; 
import { areas } from '../../common/constants/areas';
import Moment from 'react-moment';
import { API } from "aws-amplify";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Spinner } from '../../common/components/spinner';
import { useHistory,useLocation} from "react-router-dom";
import { S3Image } from 'aws-amplify-react';
import profileDefault from "../../static/images/Avatar_A25_Ellipse_2_pattern.png";
import usePersistedState from '../../usePersistedState';
import CustomizedTooltips from '../../lib/tooltIp';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'; 
  const min = new Date('2018-01-01').getFullYear();
  const max = new Date().getFullYear();
  const comboYear = [];
    for (var i = min; i <= max; i++) {
      comboYear.push(i)
    }
  const useStyles = makeStyles(theme =>
    createStyles({
      "@global": {
        body: {
          backgroundColor: theme.palette.common.white
        }
      },    
      filterUType: {
        margin: theme.spacing(1),
        minWidth: 120,
        float: "left"
      },
      filterLabel: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(4),
        width: "56px",
        color: "rgba(123,123,123,1)",
        fontSize: "17px",
        float: "left"
      },
      
    })
  );   
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

  const transComboMonths = [
    {
      "Slno":0,
      "Name":"All"
    },
    {
      "Slno": 1,
      "Name": "January"
    },
    {
      "Slno": 2,
      "Name": "February"
    },
    {
      "Slno": 3,
      "Name": "March"
    },
    {
      "Slno": 4,
      "Name": "April"
    },
    {
      "Slno": 5,
      "Name": "May"
    },
    {
      "Slno": 6,
      "Name": "June"
    },
    {
      "Slno": 7,
      "Name": "July"
    },
    {
      "Slno": 8,
      "Name": "August"
    },
    {
      "Slno": 9,
      "Name": "September"
    },
    {
      "Slno": 10,
      "Name": "October"
    },
    {
      "Slno": 11,
      "Name": "November"
    },
    {
      "Slno": 12,
      "Name": "December"
    }

  ];
export  function ViewUser(props) {
  const classes = useStyles();   
  const location = useLocation();
  let history = useHistory(); 
  const isRefresh = React.useRef(true);
   const [isLoading, setIsLoading] = useState(false);   
   const [rowEditData, setRowEditData] =usePersistedState("rowEditData",{});
   const [userViewFilter, setuserViewFilter] = useState({"Month": [], "Year": [] });  
   const inputLabel = useRef(null);
   const [labelWidth, setLabelWidth] = useState(0);   
   const [tableState, setTableState] = useState({}); 
   const [selectedProperty] = usePersistedState('selectedProperty', {}); 
   useEffect(() => {
    let isSubscribed = true;    
    console.log(location.pathname); // result: '/secondpage'   
    console.log(location.props); // result: 'some_value' 
    const userFetch=async ()=> {
       if(location.props && isSubscribed)
       {        
        await setRowEditData(location.props.rowData);
       }       
       if (isSubscribed){
           var rwData=location.props && location.props.rowData? location.props.rowData:rowEditData;
        trackPromise(         
            GetTableData("view user",rwData)
            .then(async tablData => {
              if(isSubscribed)
              {                
                console.log("tablData", tablData);  
                await setTableState(tablData);    
                setIsLoading(false);
              }        
            })           
            .catch(er=>{ throw er}),
            areas.admin,
            )
       }
      return () => isSubscribed = false
    }
    userFetch();
  }, [userViewFilter]);   
useEffect(() => {
  if(inputLabel.current)
  {
    setLabelWidth(inputLabel.current.offsetWidth);
  }
},[inputLabel.current])
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
const createHeaderWithTooltip = (title, tooltip) => {
  return (
      <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
      > <Grid item>
              <Typography className="biller-view-trans-tooltipText">{title}</Typography>
          </Grid>
          <Grid item>
              <CustomizedTooltips content={tooltip} />
          </Grid>
      </Grid>
  );
};
  
  async function GetTableData(type, filters,tabName) {
    console.log("SWITCH ", type);
    if(filters == undefined){filters = rowEditData;}    
    var columns = [];
      columns.push(
        { title: "TRANSACTION DATE", field: "TransactionOn",render:rowData => <Moment format="MMM DD YYYY">{rowData.TransactionOn}</Moment> },
        { title: "PROPERTY", field: "TransactionPropertyName" },
        { title: "RENT MONTH", field: "TransactionRentMonth" },
       /*  { title: "CARD", field: "TransactionCard" }, */
        { title: "DEPOSIT", field: "TransactionDeposit", searchable: false, width:"5%",  render: rowData => {return rowData.TransactionDeposit===0? "-": "$"+rowData.TransactionDeposit}},
        { title: "MISC", field: "TransactionMiscValue", searchable: false, width:"5%",render:rowData => {return rowData.TransactionMiscValue===0?"-":"$"+rowData.TransactionMiscValue}},
        { title: "TAX", field: "TransactionTaxAmount", searchable: false, width:"5%",render:rowData => {return rowData.TransactionTaxAmount===0?"-":"$"+rowData.TransactionTaxAmount.toFixed(2)} },
        { title: "RENT", field: "TransactionRent", searchable: false, width:"5%",headerStyle: {maxWidth: 100}, cellStyle: {maxWidth: 100} ,render:rowData => {return rowData.TransactionRent===0?"-":"$"+rowData.TransactionRent} },
        { title: createHeaderWithTooltip('FEE (TENANT)', 'Stripe processing fee (2.9%) + $0.3 paid by the tenant'),sorting: false, field: "ConvenienceFee", searchable: false, render: rowData => { return (!rowData.ConvenienceFee || rowData.ConvenienceFee === 0) ? "-" : "$" + rowData.ConvenienceFee } },
        { title: "TOTAL", field: "TransactionTotal", searchable: false, width:"5%" ,render:rowData => {return rowData.TransactionTotal===0?"-":"$"+rowData.TransactionTotal}},
        { title: createHeaderWithTooltip('FEE (OWNER)', 'Stripe processing fee (2.9%) + $0.3 paid by the property'),sorting: false, field: "StripeCharges", searchable: false, render: rowData => { return (!rowData.StripeCharges || rowData.StripeCharges === 0) ? "-" : "$" + rowData.StripeCharges } },
        );
      var masterType = "Transaction";
      var apiModule = "renter";
      var op = "viewTransactions";
      let body = { masterType: masterType,UserID:filters.MasterID};
      if (userViewFilter && userViewFilter.hasOwnProperty("Month") && userViewFilter.Month.length > 0) {
        body.Month = userViewFilter.Month;
      }
      if (userViewFilter && userViewFilter.hasOwnProperty("Year") && userViewFilter.Year.length > 0) {
        body.Year = userViewFilter.Year;
      }
    var tableData = {
      columns: columns,
      data: []
    };    
    body.userData=props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit).then(response => {    
        tableData.data = response.hasOwnProperty("data")?response.data:response;
        console.log("tableData", tableData);
        resolve(tableData);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
 
  async function onFilterChange(e, child) {
    console.log("e.target.name",e.target.name);
    console.log(" FILTER CHANGE EVENT ", e.target.value);    
    let type = "User"   
    if (e.target.name === "month") setuserViewFilter({ ...userViewFilter, ["Month"]: e.target.value });

    if (e.target.name === "year") setuserViewFilter({ ...userViewFilter, ["Year"]: e.target.value });  
  };  
  return (
    <div className="home-root"> 
    <Grid
    container
    spacing={0}
    direction="column" 
    >
    <Grid item>
      <Container component="main" className="home-main">
      <CssBaseline />
      <Spinner className="common-spinner"
       area={areas.admin}
        />
      <div className="home-paper">       
      <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
            {rowEditData.ProfileImage && rowEditData.ProfileImage.hasOwnProperty("FileKey")?
            <S3Image theme={{ photoImg: { maxWidth: '159px', maxHeight: '159px', display: "flex", marginRight: "auto", padding: "14px" } }} level="public" imgKey={rowEditData.ProfileImage.FileKey} />
              :<img src={profileDefault} alt="" />}
            </Grid>
            <Grid item>
              <Grid
                container                 
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography                 
                    component="h2"
                    variant="h2"
                  >
                    {rowEditData.Name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                     component="h5"
                     variant="h5"
                  >
                    {rowEditData.Email}
                  </Typography>
                </Grid>
              </Grid>
                    </Grid>
                    <Grid style={{ alignSelf: 'baseline' }} item>
                      <BootstrapTooltip arrow title="Edit Profile" placement="right">
                        <IconButton onClick={()=>{ history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/edit`,props:{ isEditing: true,rowEditData:rowEditData,fromGrid:true }}); }} aria-label="delete">
                          <EditOutlinedIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Grid>

                  </Grid>
                </Grid>
          <Grid item xs={12}>
            <Typography
             variant="subtitle1"
             color="textSecondary"
            >
              This page will output a complete billing transaction history for
              the account holder.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              className={classes.filterLabel}
              component="h5"
              variant="h5"
            >
              FILTER
            </Typography>
            <FormControl variant="outlined" className={classes.filterUType}>
              <InputLabel ref={inputLabel} id="select-outlined-label">
                Month
              </InputLabel>
              <Select
                labelid="select-outlined-label"
                id="property-select-outlined"
                multiple                
                value={userViewFilter.Month}
                onChange={(e, child) => {
                  onFilterChange(e, child);
                }}
                variant="outlined"
                name="month"                 
                renderValue={(selected) => selected.join(", ")}  
            
                labelWidth={labelWidth}
              >
                <MenuItem value="">
                  <em>MM</em>
                </MenuItem>
                {transComboMonths.map((eachMap, keyIndex) => {
                  return (
                    <MenuItem
                      key={keyIndex}
                      value={eachMap.Name}
                      ob={{ value: eachMap.Name, Slno: eachMap.Slno }}
                    >
                      <Checkbox
                        checked={
                          userViewFilter.Month.indexOf(eachMap.Name) > -1
                        }
                      />
                      {eachMap.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.filterUType}>
              <InputLabel  id="select-outlined-label">
                Year
              </InputLabel>
              <Select
                labelid="select-outlined-label"
                id="property-select-outlined"
                multiple                 
                value={userViewFilter.Year}
                onChange={(e,child) => {
                  onFilterChange(e,child);
                }}
                variant="outlined"
                name="year"                 
                renderValue={(selected) => selected.join(", ")} 
                labelWidth={labelWidth}
              >
                <MenuItem value="">
                  <em>YYYY</em>
                </MenuItem>
                {comboYear.map((x, ind) => {
                  return (
                    <MenuItem key={ind} value={x}>
                      <Checkbox checked={userViewFilter.Year.indexOf(x) > -1} />
                      <ListItemText primary={x} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ padding: "20px 0px 40px 0px" }}>
          <MaterialTable
            columns={tableState.columns}
            data={tableState.data}
            options={{
              searchFieldStyle: {
                display: "none",
              },
              actionsColumnIndex: -1,
              search: false,
              showTitle: false,
              toolbar: false,
              pageSize:25,
              pageSizeOptions: [25, 50, 100, 200],
            }}
          />
        </div>
     
       </div>
      </Container>
      </Grid>
      </Grid>
      </div>
      
  );                
}

