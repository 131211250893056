import React, { useState, forwardRef, useImperativeHandle } from "react";
import { withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {LoaderCLickButton} from "../../../components/LoaderButton"; 


export const ModelWindow =  forwardRef((props, ref) => {
    const [modelOpen, setModelOpen] = useState(false);
    const handleClose = () => {
        setModelOpen(false);   
      };
      const handleOpen = () => {  
        setModelOpen(true);
      };
      useImperativeHandle(ref, () => {
        return {
            handleOpen: handleOpen,
            handleClose: handleClose
        };
      });
      const onClickOne= (e)=>{
        props.onClickOne(e)
      }
      const onClickTwo= (e)=>{
        props.onClickTwo(e)
      }
      const styles = theme => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });
    const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
    
    return (
     <div>
  <Dialog open={modelOpen} onClose={e=>handleClose(e)} fullWidth={true}
      maxWidth={"sm"} aria-labelledby="form-dialog-title">        
        <DialogTitle id="delete-dialog-title" onClose={e=>handleClose(e)}>
        {props.title}
        </DialogTitle>
        <DialogContent>   
        <DialogContentText>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>   

        {props.IsbuttonOne &&<LoaderCLickButton             
                    //size="large"
                    onClick={onClickOne}
                    isLoading={false}                    
                  >
                   {props.buttonOneText}
                  </LoaderCLickButton>}
                 {props.IsbuttonTwo && <LoaderCLickButton             
                    //size="large"
                    onClick={onClickTwo}
                    isLoading={false}                    
                  >
                   {props.buttonTwoText}
                  </LoaderCLickButton>}
        </DialogActions>
      </Dialog>
      </div>
    );
  });
  