import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { openSnackbar } from '../Notifier'; 
import { API } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"; 
import LoaderButton from "../LoaderButton";
import { Skeleton } from '@material-ui/lab';
const MapData =window['countryConfig']; 
const useStyles = makeStyles(theme =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: theme.spacing(8),
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    card: {
      display: "flex",
      padding: theme.spacing(5, 5, 5, 5)
    },
    details: {
      display: "flex",
      flexDirection: "column"
    },
    content: {
      flex: "1 0 auto"
    },
    cover: {
      width: 218
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    headerOne: {
      color: "rgba(0,0,0,1)",
      fontSize: "60px"
    },
    headerTwo: {
      color: "rgba(123,123,123,1)",
      fontSize: "60px"
    },
    headerThree: {
      color: "rgba(0,0,0,1)",
      fontSize: "22px"
    },
    headerfour: {
      color: "rgba(123,123,123,1)",
      fontSize: "17px"
    },
    headerFive: {
      color: "#7c7c7c",
      fontSize: "22px"
    },
    filterProperty: {
      margin: theme.spacing(1),
      minWidth: 220,
      width: 564
    },
    filterUType: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    filterLabel: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(4),
      minWidth: 100,
      color: "rgba(123,123,123,1)",
      fontSize: "17px"
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    papers: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    cards: {
      display: "flex",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
    },
    form: {
      width: "100%" // Fix IE 11 issue.      
    },  
  })
);

export default function _AddCard(props) {
    const classes = useStyles(); 
    const [labelWidth, setLabelWidth] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const year = (new Date()).getFullYear();
    const [expYear] = useState(Array.from(new Array(20),(val, index) => (index + year).toString())); 
    const [expMonth] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);
    const [cardData, setCardData] = useState({
      MasterID:"",
      NameOnCard: "",
      BillingAddress: "",
      Country:"",
      CountryCode:"",
      PostalCode: "",
      CardNumber: "",
      CvvCid: "",
      ExpiryMonth:"",
      ExpiryYear:"",
      UserName:props.userSession.UserName,
      RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
    });
    const inputLabel = React.useRef(null);
    React.useEffect(() => {
      
      if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);    
    }, []);

    function validateForm() {
          console.log("Log validate",cardData);
          
          return cardData.ExpiryMonth.length>0 && cardData.ExpiryYear.length>0 &&
           cardData.NameOnCard.length > 0 && cardData.BillingAddress.length > 0 &&
           cardData.Country.length > 0 && cardData.PostalCode.length > 0 && 
           cardData.CardNumber.length > 0 && cardData.CvvCid.length > 0;
      }
      function handleClearForm() {
        document.getElementById("addCard-form").reset();
      }
      function onChange(e,inputProps) {
        console.log(e);
        if (e.target.name === "Country") {
          var selected = inputProps.props.alldata;        
          setCardData(prvCard => { return { ...prvCard, "Country": e.target.value,"CountryCode": selected.iso2 } });         
        }
        else
        {
          setCardData({ ...cardData, [e.target.name]: e.target.value });
        }

      }
      function handleClick(event) {
        console.log("Props handle click ",props);    
        props.onChildClick(event); // pass any argument to the callback
      }



      async function saveCardData(e) {
        e.preventDefault();
        setIsLoading(true);
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: {...cardData,userData:props.userSession, MasterType: 'User Card', CurrentUserID: props.userSession.UserName, CurrentUserEmail:props.userSession.Email, CurrentUserName: props.userSession.FirstName + " " + props.userSession.LastName },
          queryStringParameters: {
            module: "renter",
            op: "saveCardData"
          }
        };
        API.post(apiName, path, myInit)
          .then(response => {
            setIsLoading(false);
            if(response && response.success)
            {
              console.log("response" + JSON.stringify(response));             
              openSnackbar({
                message:
                response.message,
                variant: "success"
              });
              setCardData({
                MasterID:"",
                NameOnCard: "",
                BillingAddress: "",
                Country:"",
                PostalCode: "",
                CardNumber: "",
                CvvCid: "",
                ExpiryMonth:"",
                ExpiryYear:"",
                UserName:props.userSession.UserName,
                RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
              });
              handleClearForm();     
              handleClick("manage card");
            }
            else{          
              openSnackbar({ message:response.message, variant: "error" });
              setIsLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
            openSnackbar({ message: err.message, variant: "error" });
            setIsLoading(false);
          });
      }
      return (
        <div>
        {
          !props.isNullForm ? 
        <form
          id="addCard-form"
          className={classes.form}
          noValidate
          onSubmit={saveCardData}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="NameOnCard"
                label="NAME ON CARD"
                onChange={onChange}
                name="NameOnCard"
                autoComplete="NameOnCard"
              />
 
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="BillingAddress"
                label="BILLING ADDRESS"
                onChange={onChange}
                name="BillingAddress"
                autoComplete="BillingAddress"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel ref={inputLabel} id="select-state-label">
                  COUNTRY/REGION
                </InputLabel>
                <Select
                        labelid="select-Country-label"
                        id="Country-select-outlined"
                        fullWidth
                        variant="outlined"
                        name="Country"
                        label="COUNTRY/REGION"
                        value={cardData.Country}
                        labelWidth={labelWidth}
                        onChange={(e, alldata) => { onChange(e, alldata); }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {MapData.map((e, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} alldata={e} value={e.name}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="PostalCode"
                label="BILLING ZIP/POSTAL CODE"
                onChange={onChange}
                name="PostalCode"
                autoComplete="PostalCode"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="CardNumber"
                label="CARD NUMBER"
                onChange={onChange}
                name="CardNumber"
                autoComplete="CardNumber"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="CvvCid"
                label="CVV/CID"
                onChange={onChange}
                name="CvvCid"
                autoComplete="CvvCid"
              />
            </Grid>
            <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
              <InputLabel  id="combo-outlined-label">
                  EXPIRATION
                </InputLabel> 
              </Grid>
              <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>    
              <InputLabel   ref={inputLabel} id="ExpiryMonth-outlined-label">
                  MM
                </InputLabel>     
                <Select                  
                  id="ExpiryMonth-select-outlined"
                  fullWidth
                  variant="outlined"
                  name="ExpiryMonth"
                  label="MM"
                  labelWidth={labelWidth}
                  value={cardData.ExpiryMonth}                  
                  onChange={onChange}
                  
                >
                  {expMonth.map((e, keyIndex) => {
                    return (
                      <MenuItem
                        key={keyIndex}
                        value={e}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>              
             </Grid>
             <Grid item xs={6}>
             <FormControl variant="outlined" fullWidth> 
              <InputLabel   ref={inputLabel}    id="ExpiryYear-outlined-label">
                  YYYY
                </InputLabel>     
                <Select                  
                  id="ExpiryYear-select-outlined"
                  fullWidth
                  variant="outlined"
                  name="ExpiryYear"
                  label="YYYY"
                  value={cardData.ExpiryYear}   
                  labelWidth={labelWidth}               
                  onChange={onChange}                  
                >
                  {expYear.map((e, keyIndex) => {
                    return (
                      <MenuItem
                        key={keyIndex}
                        value={e}
                      >
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>              
             </Grid>
            </Grid>
            </Grid>
          </Grid>
          <div style={{ padding: "20px 0px 40px 0px" }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <LoaderButton
                  block="true"
                  type="submit"
                  size="large"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  + SAVE CARD
                </LoaderButton>
              </Grid>
            </Grid>
          </div>
        </form>

        :
        <form
        id="addCard-form"
        className={classes.form}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect"  height={52}/>
          </Grid>
          <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
                <Skeleton variant="rect"  height={17}/>      
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="rect"  height={52}/>             
           </Grid>
           <Grid item xs={6}>
            <Skeleton variant="rect"  height={52}/>             
           </Grid>
          </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: "20px 0px 40px 0px" }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Skeleton variant="rect"  height={52}/>
            </Grid>
          </Grid>
        </div>
      </form>

        }
        </div>
                
      );
}

