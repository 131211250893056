import React, { useState } from "react"; 
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid"; 
import LoaderButton from "../LoaderButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { API } from 'aws-amplify';
import { openSnackbar } from '../../components/Notifier';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl'; 
import { S3Image } from 'aws-amplify-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { Skeleton } from '@material-ui/lab';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];
const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        justify: "center",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    header: {
        fontSize: 20,
        fontStyle: "bold",
        fontFamily: "Arial-BoldMT, Arial"
    },
    myImage: {
        width: "100%"
    },
   
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: 200,
    },
    welcomeText: {
        fontSize: "20px",
    }

}));

export function AddTeamMember(props) {
    const classes = useStyles();
    const [verifyData, setVerifyData] = useState({}); 
    var [isVerifyLoading, setIsVerifyLoading] = useState(false);
    const [confirmPass, setConfirmPass] = useState({
        newPassword: "",
        email: "",
        password: "",
        codeSent: false,
        confirmed: false,
        confirmPassword: "",
        isConfirming: false,
        isSendingCode: false,
        isLoading: false,
        firstName:'',
        lastName:'',
        mobNumber:'',
        setIsLoading: (value) => {
            confirmPass.isLoading = value;
        },
        showPassword: false
    });
    React.useEffect(() => {
        const queryParams = new URLSearchParams(props.location.search);
        var vrData = {}
        queryParams.forEach(function (value, key) {
            console.log('assuming query param is ' + key, value);
            vrData[key] = value;
        });
        setVerifyData(vrData);
    }, [props.location.search]);
    const handleClickShowPassword = () => {
        console.log("Handle show ", confirmPass.showPassword);
        setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
        console.log("Handle show ", confirmPass.showPassword);
    };
    const handleMouseDownPassword = e => {
        e.preventDefault();
    };
    function validateResetForm() {
        return (
            confirmPass.newPassword.length > 0 &&
            confirmPass.password.length > 0 &&
            confirmPass.password === confirmPass.newPassword
        );
    }
    const onChange = e => {
        console.log(e.target.id + ":" + e.target.value); 
        setConfirmPass({
            ...confirmPass,
            [e.target.id]: e.target.value
        });
    }
    const confirmPassword = async (e) => { 
        confirmPass.setIsLoading(true);
        setIsVerifyLoading(true); 
        e.preventDefault();
        setConfirmPass({ ...confirmPass, isConfirming: true });
        let apiName = "rentapi";
        let path = "/verify/reset-team";
        let myInit = {
            headers: { "Content-Type": "application/json" },
            body: {
                email: verifyData.email,
                firstName:confirmPass.firstName,
                lastName:confirmPass.lastName,
                mobNumber:confirmPass.mobNumber,
                password: confirmPass.newPassword,
                code: verifyData.code,
                userId: verifyData.username,
                clientId: MapData.aws_user_pools_web_client_id
            } 
        };
        API.post(apiName, path, myInit).then(response => { 
            setIsVerifyLoading(false);
            if (response.success) { 
                confirmPass.setIsLoading(false);
                setConfirmPass({ ...confirmPass, confirmed: true, password: "", confirmPassword: "", newPassword: "" });
                openSnackbar({ message: response.message, variant: "success" });
                props.onChildClick('success',`${confirmPass.firstName} ${confirmPass.lastName}`); 
            }
            else {
                openSnackbar({ message: response.message, variant: "error" });
                confirmPass.setIsLoading(false);
                setConfirmPass({ ...confirmPass, isConfirming: false }); 
            }
        }).catch(err => {
            console.log(err);
            openSnackbar({ message: err.message, variant: "error" });
            confirmPass.setIsLoading(false);
            setConfirmPass({ ...confirmPass, isConfirming: false });
        });
    }
    function SkeltonLoader() {
        return (
            <div className={classes.paper}>
            <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                        <Typography className='managment-header' component="h2" variant="h2">
                            {MapData.management_name}
                        </Typography>}
                </Grid>
                <Grid item>
                    <Typography className="verify-user-header" gutterBottom={true}>
                        <Skeleton variant="rect" width={385} height={30} />
                    </Typography>
                </Grid>
                <Grid item>
                <Typography component="h1" variant="h5">
                    <Skeleton variant="rect" width={385} height={56} />
                    </Typography>
                </Grid>
                <Grid item>
                <Typography component="h1" variant="h5">
                    <Skeleton variant="rect" width={385} height={56} />
                    </Typography>
                </Grid>
                <Grid item> 
                    <Typography component="h1" variant="h5">
                    <Skeleton variant="rect" width={385} height={56} />
                    </Typography> 
                </Grid> 
                <Grid item> 
                    <Typography component="h1" variant="h5">
                    <Skeleton variant="rect" width={385} height={56} />
                    </Typography> 
                </Grid> 
                <Grid>
                    <Skeleton variant="rect" width={112} height={45} />
                </Grid>
            </Grid> 
        </div>
  
        )
    }
    return (
        <>
        {isVerifyLoading? <SkeltonLoader/>:null}
        <div className={classes.paper}>          
           {!isVerifyLoading && <>       
                    <Grid container justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                <Typography className='managment-header' component="h2" variant="h2" >
                                    {MapData.management_name}
                                </Typography>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className="verify-user-header" component="h5" variant="h5">
                              New Registration
                            </Typography>
                        </Grid>
                    </Grid>
            <form className={classes.form} noValidate onSubmit={confirmPassword}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            className="addtenant-firstname"
                            id="firstName"
                            variant="outlined"
                            fullWidth
                            name="firstName"
                            value={confirmPass.firstName}
                            onChange={onChange}
                            placeholder="First Name*"
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField 
                            id="lastName"
                            variant="outlined"
                            name="lastName"
                            fullWidth
                            onChange={onChange}
                            value={confirmPass.lastName}
                            placeholder="Last Name*"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField 
                            id="mobNumber"
                            variant="outlined"
                            name="mobNumber"
                            fullWidth
                            onChange={onChange}
                            value={confirmPass.mobNumber}
                            placeholder="Phone Number*"
                            autocomplete="mobile-number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            autoComplete="new-password"
                            name="newPassword"
                            variant="outlined"
                            required 
                            fullWidth
                            type="password"
                            id="newPassword"
                            label="New Password"
                            value={confirmPass.newPassword}
                            onChange={onChange}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth required  variant="outlined">
                            <InputLabel htmlFor="password">Verify New Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                type={confirmPass.showPassword ? 'text' : 'password'}
                                value={confirmPass.password}
                                onChange={onChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword()}
                                            onMouseDown={(e) => handleMouseDownPassword(e)}
                                        >
                                            {confirmPass.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>                                }
                                labelWidth={200}
                            />
                        </FormControl>
                    </Grid>
                </Grid> 
                <Grid container direction="column" justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <LoaderButton
                            block="true"
                            type="submit"
                            size="large"
                            className={classes.submit}
                            isLoading={isVerifyLoading}
                            disabled={!validateResetForm()}
                        >
                            SUBMIT
                        </LoaderButton>
                    </Grid> 
                    <Grid item>
                        <MLink color="textSecondary" underline={"always"} component={Link} to="/">
                            <Typography color="textSecondary" gutterBottom={true} variant="body2">
                                BACK
                            </Typography>
                        </MLink>
                    </Grid>
                </Grid>
            </form>
          </> }
        </div> 
        </>
    )
}