import React, {  useState } from "react"; 
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid"; 
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles"; 
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle"; 
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoaderButton from "../LoaderButton"; 
import usePersistedState from "../../usePersistedState"; 
export function StipeKeyManage(props) {
  console.log("ADD NEW PROPERTY PROPS ", props); 
  const [isEditing, setIsEditing] = usePersistedState("isEditing", false);
  const [stripeProperty, setStripeProperty] = useState({ 
    MasterID: "", 
    StripePublicKey: "",
    StripeSecretKey: ""    
  });  
  const [stripeOpen, setStripeOpen] = useState(false);    
  function eventChange(type) {
    console.log("event change, type",type);
    if(type==="stripe add")
     {
        props.eventChange(type,stripeProperty);
        setStripeOpen(false);
      }
    else 
      props.eventChange(type);
  }
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  React.useEffect(() => {
    setStripeOpen(props.stripeOpen);
    setIsEditing(props.isEditing);    
    setStripeProperty(prvproperty => { return { ...prvproperty,MasterID:props.MasterID, StripePublicKey:props.StripePublicKey,
    StripeSecretKey: props.StripeSecretKey }});
  }, [props]);
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h2"component = "h2">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  function validateForm() { 
    return ( 
      (!isEditing ||
        (stripeProperty.StripePublicKey && stripeProperty.StripePublicKey.length > 0)) &&
      (!isEditing ||
        (stripeProperty.StripeSecretKey && stripeProperty.StripeSecretKey.length > 0))   
    );
  }  
  const handleClose = (e) => {
    setStripeOpen(false);
    eventChange("stripe close");
  };
  function onChange(e, inputProps) {
    console.log("stripeProperty onChange", e.target);
    setStripeProperty({ ...stripeProperty, [e.target.name]: e.target.value });
  }; 

  return (
    <>
      <Dialog
        open={stripeOpen}
        onClose={(e) => handleClose()}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={(e) => handleClose()}
        >
          {!isEditing ? "Add Stripe Keys" : "Edit Stripe Key"}
        </DialogTitle>
        <DialogContent>  
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="StripePublicKey"
                  value={stripeProperty.StripePublicKey}
                  label={
                    !isEditing
                      ? "Publishable key"
                      : "Publishable key"
                  }
                  onChange={onChange}
                  name="StripePublicKey"
                  autoComplete="StripePublicKey"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="StripeSecretKey"
                  value={stripeProperty.StripeSecretKey}
                  label={
                    !isEditing 
                      ? "Secret key"
                      : "Secret key"
                  }
                  onChange={onChange}
                  name="StripeSecretKey"
                  autoComplete="StripeSecretKey"
                />
              </Grid>
            </Grid>
            <DialogActions>
        <LoaderButton
                        block="true"
                        type="submit"
                        size="large" 
                        disabled={!validateForm()}
                        onClick={()=> eventChange('stripe add')}
                      >
                        {isEditing ? "EDIT" : "+ ADD"}
                      </LoaderButton>
        </DialogActions>
        
        </DialogContent> 
      </Dialog>
    </>
  );
}
