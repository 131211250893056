import React, {  useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Fab from '@material-ui/core/Fab'; 
import { openSnackbar } from '../Notifier';
import { API } from "aws-amplify";
import MaterialTable from "material-table";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import CsvExport from '../CsvExport';
import Moment from 'react-moment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'; 
import CloseIcon from '@material-ui/icons/Close';
import { LoaderCLickButton } from "../LoaderButton";
import { LoaderDeleteButton } from "../LoaderButton";
import CustomizedTooltips from '../../lib/tooltIp';
import EditIcon from '@material-ui/icons/Edit';
import PaymentIcon from '@material-ui/icons/Payment';
import { useHistory, useLocation } from "react-router-dom";
import { Skeleton } from '@material-ui/lab';
import AddPaymentOffline from './addpaymentdialog';
import usePersistedState from '../../usePersistedState';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';  
const min = new Date('2018-01-01').getFullYear();
const max = new Date().getFullYear();
const comboYear = [];
for (var i = min; i <= max; i++) {
    comboYear.push(i)
}
const transComboMonths = [
    {
        "Slno": 1,
        "Name": "January"
    },
    {
        "Slno": 2,
        "Name": "February"
    },
    {
        "Slno": 3,
        "Name": "March"
    },
    {
        "Slno": 4,
        "Name": "April"
    },
    {
        "Slno": 5,
        "Name": "May"
    },
    {
        "Slno": 6,
        "Name": "June"
    },
    {
        "Slno": 7,
        "Name": "July"
    },
    {
        "Slno": 8,
        "Name": "August"
    },
    {
        "Slno": 9,
        "Name": "September"
    },
    {
        "Slno": 10,
        "Name": "October"
    },
    {
        "Slno": 11,
        "Name": "November"
    },
    {
        "Slno": 12,
        "Name": "December"
    }

];

let transactionTypeCombo = [

];

const tableRef = React.createRef();

export function ViewTransactions(props) {
    let history = useHistory(); 
    const location = useLocation();
    
    const [propertyData, setPropertyData] = usePersistedState('propertyData', {}); 
    const inputLabel = React.useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [exportAvailable, setExportAvailable] = React.useState(false);
    const [userViewFilters, setuserViewFilters] = React.useState({ "Month": [], "": [] });
    const [userViewFilter, setuserViewFilter] = React.useState({ "Month": [], "Year": [], "TransactionType": [] });
    const [offlinePayment, setOfflinePayment] = React.useState({});
    const [tenantList, setTenantList] = React.useState([]);
    const [leaseHolderList, setLeaseHolderList] = React.useState([]);
    const [addOfflinePayment, setAddOfflinePayment] = React.useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [isLoadingModalButtonSave, setIsLoadingModalButtonSave] = useState(false);
    const [isLoadingModalButtonDelete, setIsLoadingModalButtonDelete] = useState(false); 
    const [transactionTypeCombo, setTransactionTypeCombo] = usePersistedState("transactionTypeCombo",[]);
    const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});

    useEffect(() => {
      const propertyData = localStorage.getItem("selectedProperty"); 
      setPropertyData(propertyData ?  JSON.parse(propertyData):{}); 
      //getTransactionType();
    }, []); 
   
    const [pageSize, setPageSize] = usePersistedState('pageSize', 25);

    const months = {
        "January": 0,
        "February": 1,
        "March": 2,
        "April": 3,
        "May": 4,
        "June": 5,
        "July": 6,
        "August": 7,
        "September": 8,
        "October": 9,
        "November": 10,
        "December": 11
    }
/*     useEffect(() => {
        let isSubscribed = true;
        console.log(location.pathname); // result: '/secondpage'   
        console.log(location.props); // result: 'some_value' 
        setIsLoading(true);
        const propertyFetch = async () => {
            if (location.props && isSubscribed) {
                await setPropertyData(location.props.propertyData); 
                setIsLoading(true);
            }
            return () => isSubscribed = false
        }
        propertyFetch();
    }, [location]); */

    
    useEffect(() => {
        console.log("offlinePayment:", offlinePayment);

    },[offlinePayment])
    function onFilterChange(e, child) {
        console.log(" FILTER CHANGE EVENT ", e.target.value);


        if (e.target.name == "month") {
            //Object.assign(userViewFilters, { Month: e.target.value });
            console.log(" FILTER CHANGE EVENT ", child.props.ob);
            console.log('AFTER VAKL CHANGE  , 1', userViewFilters);

            let arrayMonth = userViewFilters.Month;

            if (!arrayMonth.some((each) => each.Slno == child.props.ob.Slno)) {
                arrayMonth.push(child.props.ob);
            }
            else {
                arrayMonth.splice(arrayMonth.map((mapp) => mapp.Slno).indexOf(child.props.ob.Slno), 1);
            }

            Object.assign(userViewFilters, { Month: arrayMonth });
            
        }

        if (e.target.name == "year") {
            Object.assign(userViewFilters, { Year: e.target.value });
            console.log('AFTER VAKL CHANGE,', userViewFilters);
        }

        if (e.target.name == "transactionType") {
            Object.assign(userViewFilters, { TransactionType: e.target.value });
        };

        if (e.target.name == "month") setuserViewFilter({ ...userViewFilter, ["Month"]: e.target.value });

        if (e.target.name == "year") setuserViewFilter({ ...userViewFilter, ["Year"]: e.target.value });

        if (e.target.name == "transactionType") setuserViewFilter({ ...userViewFilter, ["TransactionType"]: e.target.value });
        
        if (tableRef.current)
            tableRef.current.onQueryChange();
    } 
    const createHeaderWithTooltip = (title, tooltip) => {
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > <Grid item>
                    <Typography className="biller-view-trans-tooltipText">{title}</Typography>
                </Grid>
                <Grid >
                    <CustomizedTooltips content={tooltip} />
                </Grid>
            </Grid>
        );
    };

    let capitalizeFirstLetter = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }

    async function getTransactionType() {  
            let apiName = "rentapi";
            let path = "/store/transactionType";
            let myInit = {
              headers: { "Content-Type": "application/json" }
            };
            console.log("transactionType props",props);
                   
            API.post(apiName, path, myInit).then(response => {  
                console.log("Trransaction type - -------------- - -------------- ", response);         
             
                setTransactionTypeCombo(response);
                return;
            }).catch(err=>{console.log(err); throw err;;
            });   
      }
    async function GetTableData(query) {
        console.log('get table data :', query);
        var pageSize = query.pageSize;
        var pageNo = (query.page + 1);
        var columns = [], columns2 = [],
            masterType = "",
            apiModule = "",
            op = "";
        setIsLoading(true);
        setPageSize(pageSize);
        var body = {};
        columns.push(
            { title: "TRANSACTION DATE", field: "TransactionDate", searchable: false, render: rowData => <Moment format="MMM DD YYYY">{rowData.TransactionDate}</Moment> },
            //{ title: "PROPERTY", field: "TransactionPropertyName", searchable: false },
            { title: "UNIT", field: "TransactionUnit", searchable: false, render: rowData => { return (!rowData.TransactionUnit || rowData.TransactionUnit === "") ? "-" : rowData.TransactionUnit } },
            { title: "RENTER", field: "TransactionRenter", searchable: false },
            { title: "RENT MONTH", field: "TransactionRentMonth", searchable: false },
            { title: "CARD", field: "TransactionCard", searchable: false },
            { title: "DEPOSIT", field: "TransactionDeposit", searchable: false, render: rowData => { return rowData.TransactionDeposit === 0 ? "-" : "$" + rowData.TransactionDeposit } },
            { title: "MISC", field: "TransactionMiscValue", searchable: false, render: rowData => { return rowData.TransactionMiscValue === 0 ? "-" : "$" + rowData.TransactionMiscValue } },
            //{ title: "TAX", field: "TransactionTaxAmount", searchable: false, render: rowData => { return rowData.TransactionTaxAmount === 0 ? "-" : "$" + rowData.TransactionTaxAmount.toFixed(2) } },
            { title: "RENT", field: "TransactionRent", searchable: false, render: rowData => { return rowData.TransactionRent === 0 ? "-" : "$" + rowData.TransactionRent } },
            { title: "TOTAL", field: "TransactionTotal", searchable: false, render: rowData => { return rowData.TransactionTotal === 0 ? "-" : "$" + rowData.TransactionTotal } },
            { title: createHeaderWithTooltip('FEE', 'Fee'), field: "StripeCharges", searchable: false, render: rowData => { return (!rowData.StripeCharges || rowData.StripeCharges === 0) ? "-" : "$" + rowData.StripeCharges } },
            { title: "NET(" + propertyData.CurrencyCode + ")", field: "NetAmount", searchable: false, render: rowData => { return (!rowData.NetAmount || rowData.NetAmount === 0) ? "-" : "$" + rowData.NetAmount } }
        );
        if (propertyData.OmitTax) {
            columns.splice(columns.findIndex(x => x.title == "TAX"), 1);
        }
        masterType = "Transaction";
        apiModule = "biller";
        op = "viewTransactionsAdmin";
        body = {
            masterType: masterType,
            property: propertyData,
            UserID: props.userSession.UserName,
            pageSize: pageSize,
            pageNo: pageNo
        };

        if (userViewFilters && userViewFilters.hasOwnProperty("Month") && userViewFilters.Month.length > 0) {
            body.Month = userViewFilters.Month;
        }


        if (userViewFilters && userViewFilters.hasOwnProperty("Year") && userViewFilters.Year.length > 0) {
            body.Year = userViewFilters.Year;
        } 

        if (userViewFilters && userViewFilters.hasOwnProperty("TransactionType") && userViewFilters.TransactionType.length) {
            body.TransactionType = userViewFilters.TransactionType;
        } 
        
        body.userData = props.userSession;
        return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
                headers: { "Content-Type": "application/json" },
                body: body,
                queryStringParameters: {
                    module: apiModule,
                    op: op
                }
            };
            API.post(apiName, path, myInit)
                .then(async response => {  
                    if (response.data && response.data.length > 0) setExportAvailable(true);
                    setIsLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                    reject([]);
                });
        });
    }

 


    function validateForm() {
        return (
          offlinePayment.rentAmount &&
          offlinePayment.rentMonth > -1 &&
          offlinePayment.tenant &&
          offlinePayment.transactionDate &&
          offlinePayment.transactionType &&
          offlinePayment.leaseHolder
        );
      }

    function saveOfflinePayment () {
        console.log("saveOfflinePayment : ", offlinePayment);
        console.log("saveOfflinePayment : ", propertyData);
        

        if (offlinePayment.rentAmount &&
            offlinePayment.rentMonth > -1 &&
            offlinePayment.tenant &&
            offlinePayment.transactionDate &&
            offlinePayment.transactionType &&
            offlinePayment.leaseHolder
            ) {
                //setIsLoadingModal(true);
                //setAddOfflinePayment(false)
                //Save offline transaction
                console.log("Save offline transaction");
                let ob = {};
                ob.rentAmount = offlinePayment.rentAmount;
                ob.rentMonth = offlinePayment.currentPayedRentMonth.Month;
                ob.transactionDate = offlinePayment.transactionDate;
                ob.miscFee = offlinePayment.proceedPayMiscFee ? offlinePayment.miscFee : 0;
                ob.transactionDate = offlinePayment.transactionDate;
                ob.transactionType = offlinePayment.transactionType;
                ob.CurrentUserID = offlinePayment.tenant;
                ob.PropertyID = propertyData.PropertyID;
                ob.PropertyName = propertyData.PropertyName;
                ob.Tax = propertyData.Tax;
                ob.unit = offlinePayment.unit;
                ob.securityDeposit = offlinePayment.proceedSecurityDeposit ? offlinePayment.securityDeposit : 0;
                ob.transactionId = offlinePayment.transactionId;
                ob.leaseId = offlinePayment.leaseHolder;
                ob.currentPayedRentMonth = offlinePayment.currentPayedRentMonth;

                let fullYear = new Date(ob.transactionDate).getFullYear();
                let month = Object.keys(months).find(key => months[key] === Number(ob.rentMonth));
                ob.rentMonthString = `${month} ${fullYear}`;

                let currentHolder = leaseHolderList.find(x => x.leaseMasterId == offlinePayment.leaseHolder);
                ob.leaseHolder = currentHolder.leaseHolders;

                console.log("saveOfflinePayment : ", ob);
                 return new Promise((resolve, reject) => {
                    let apiName = "rentapi";
                    let path = "/modules";
                    let op = offlinePayment.isEditing ?  "updateOfflinePayment" : "saveOfflinePayment";
                    let apiModule = "renter";
                    let myInit = {
                        headers: { "Content-Type": "application/json" },
                        body: ob,
                        queryStringParameters: {
                            module: apiModule,
                            op: op
                        }
                    };
                    API.post(apiName, path, myInit)
                        .then(async response => {  
                            
                            if (tableRef.current) tableRef.current.onQueryChange();
                            openSnackbar({ message: offlinePayment.isEditing ? "Payment updated successfully" : "Payment added successfully", variant: "success" });
                            setAddOfflinePayment(false)
                            setIsLoadingModal(false);
                            setOfflinePayment({});
                            resolve();
                        })
                        .catch(err => {
                            console.log(err);
                            openSnackbar({ message: "Payment adding failed", variant: "error" });
                            setIsLoadingModal(false);
                            
                            
                            reject([]);
                        });
                });
            }
            else {
                console.log("Enter all fields");
                setIsLoadingModal(false);
            }
    }

    function getTenants () {
        setAddOfflinePayment(true);
        setIsLoadingModal(true);
        let tenantArray = [];
        var  masterType = "User",
        apiModule = "user",
        op = "getActiveTenant";
        var body = {
            PropertyID: propertyData.PropertyID,
            PropertyData: propertyData,
            FilterData: ""
          }

          body.userData=props.userSession;
          return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
              headers: { "Content-Type": "application/json" },
              body: body,
              queryStringParameters: {
                module: apiModule,
                op: op
              }
            };
            API.post(apiName, path, myInit)
              .then(async response => {
                console.log("get active tenant response ---------------------------", response);
                    
                /* if (response && response.length>0) {
                    
                    response.forEach((each) => {
                        tenantArray.push({
                            "name": each.RenterName,
                            "value": each.RenterID,
                            "outstanding":each.outstandingMonths,
                            "leaseHolders": each.leaseHolders
                        })
                    })

                    setTenantList(tenantArray);
                } */
                if (response) {
                    if (response.paidBy) setTenantList(response.paidBy);
                    if (response.leaseHolders) setLeaseHolderList(response.leaseHolders);
                }
                

                console.log("TENANT LIST : ", response.paidBy);
                console.log('LEASE HOLDER LIST: ', response.leaseHolders);
                setIsLoadingModal(false)
                resolve({paidBy: response.paidBy, leaseHolders: response.leaseHolders});
              })
              .catch(err => {
                console.log(err);
                reject();
              });
          });
    }

    async function editOfflinePAyment (rowData) {
        console.log("RowData : ", rowData);
        let ob = {};
        ob.rentAmount = rowData.TransactionRent;
        ob.tenant = rowData.TransactionRenterID;
        ob.transactionDate = rowData.TransactionOn;
        let month = rowData.TransactionRentMonth ? rowData.TransactionRentMonth.split(" ")[0] : -1;


        ob.rentMonth = months[month];
        ob.rentAmount = rowData.TransactionRent;
        ob.securityDeposit = rowData.TransactionDeposit;
        ob.miscFee = rowData.TransactionMisc && rowData.TransactionMisc.length && rowData.TransactionMisc[0].appFeeValue? Number(rowData.TransactionMisc[0].appFeeValue) : 0;
        ob.unit = rowData.TransactionUnit;
        ob.transactionType = rowData.TransactionType;
        ob.isEditing = true;
        ob.transactionId = rowData.MasterID
        ob.leaseHolder = rowData.RentMasterID
        ob.currentPayedRentMonth = rowData.TransactionPaidMonth;

        ob.totalAmountTobePaid = 0;
        if (ob.rentAmount) ob.totalAmountTobePaid += ob.rentAmount;
        if (ob.securityDeposit) ob.totalAmountTobePaid += ob.securityDeposit;
        if (ob.miscFee) ob.totalAmountTobePaid += Number(ob.miscFee);

        ob.proceedPayMiscFee = false;
        ob.proceedSecurityDeposit = false;
        if (ob.miscFee && Number(ob.miscFee) > 0) ob.proceedPayMiscFee = true;
        if (ob.securityDeposit) ob.proceedSecurityDeposit = true;

        console.log("Set row data for add payment : " , ob);

        //setOfflinePayment(ob);
        let tenantResult = await getTenants();

        console.log("leaseHolders: ", tenantResult.leaseHolders);
        if (tenantResult.leaseHolders && tenantResult.leaseHolders.length) {
/*             let currentTenant = tenantList.find(x => x.value == rowData.TransactionRenterID);
            ob.outstanding = currentTenant.outstanding;
            ob.outstanding.push(Number(ob.rentMonth)); */

            let currentHolder = tenantResult.leaseHolders.find(x => x.leaseMasterId == rowData.RentMasterID);
            console.log("currentHolder : from edit ", currentHolder);
            ob.outstanding = currentHolder && currentHolder.outstanding ? currentHolder.outstanding : [];
            if (rowData.TransactionPaidMonth) ob.outstanding.push(rowData.TransactionPaidMonth);
        }

        if (tenantResult.paidBy && tenantResult.paidBy.length) {
            let tenantsIn = tenantResult.paidBy.filter(f => f.leaseMasterId == rowData.RentMasterID);
            console.log("tenantsIn: edit : ", tenantsIn);
            ob.paidByList = tenantsIn;
        }
        console.log("tenantList: ob ", ob);
        setOfflinePayment(ob);
        setAddOfflinePayment(true);
    }

    function deleteOffLinePayment () {
        console.log("deleteOffLinePayment : ", );

        return new Promise((resolve, reject) => {
            setIsLoadingModal(true);
            
            let apiName = "rentapi";
            let path = "/modules";
            let op = "deleteOfflinePayment";
            let apiModule = "renter";
            let myInit = {
                headers: { "Content-Type": "application/json" },
                body: {
                    transactionId: offlinePayment.transactionId
                },
                queryStringParameters: {
                    module: apiModule,
                    op: op
                }
            };
            API.post(apiName, path, myInit)
                .then(async response => {  
                    
                    if(tableRef.current) {
                        console.log('Table ref exist');
                        tableRef.current.onQueryChange();
                    }
                    else {
                        console.log('Table ref not exist');
                    }
                    openSnackbar({ message: "Payment deleted successfully", variant: "success" });
                    setIsLoadingModal(false);
                    setAddOfflinePayment(false)
                    setOfflinePayment({});
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    openSnackbar({ message: "Payment deleting failed", variant: "error" });
                    setIsLoadingModal(false);
                    
                    reject([]);
                });
        });

    }

    async function handleChildClick(fieldName, value, child, checkBoxValue) {
        console.log("FieldName : value : child : ", fieldName , value, child, checkBoxValue)

        console.log("offlinePayment--------------- : "  , offlinePayment)
        let ob = {};
        ob[fieldName] =  value;
        


/*         if (offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0 && (offlinePayment.proceedPayMiscFee || ob.proceedPayMiscFee)) ob.totalAmountTobePaid  += Number(offlinePayment.miscFee);
        if (offlinePayment.securityDeposit && (offlinePayment.proceedSecurityDeposit || ob.proceedSecurityDeposit)) ob.totalAmountTobePaid  += offlinePayment.securityDeposit;
         */
         if (fieldName === 'tenant') {
/*             let currentTenant = tenantList.find(x => x.value == value);
            console.log("currentTenant: ", currentTenant);
            ob.outstanding = currentTenant.outstanding ? currentTenant.outstanding : []; */
        }


        ob.totalAmountTobePaid = offlinePayment.totalAmountTobePaid ? offlinePayment.totalAmountTobePaid : 0;
        if (fieldName === 'leaseHolder') {
            // reset this on lease holder change
            ob.totalAmountTobePaid = 0;
            let currentHolder = leaseHolderList.find(x => x.leaseMasterId == value);
            console.log("currentHolder: ", currentHolder);
            ob.outstanding = currentHolder.outstanding ? currentHolder.outstanding : [];
            ob.currentLeaseId = currentHolder.leaseMasterId;
            ob.currentRentAmount = 0;
            ob.rentAmount = 0;
            let nextOutstanding = null;
            if (ob.outstanding.length) {
                nextOutstanding = ob.outstanding.filter(f => f.IsPaid == false);
                console.log("nextOutstanding : " , nextOutstanding)
                if (nextOutstanding.length) nextOutstanding = nextOutstanding[0];
                ob.rentAmount = nextOutstanding.Rent;
                ob.nextOutstanding = nextOutstanding;
                ob.rentMonth = nextOutstanding.ID;
                ob.currentPayedRentMonth = nextOutstanding;

            }

            if (ob.rentAmount) ob.totalAmountTobePaid  += (ob.rentAmount);

            if (child && (child.currentMiscAmount)) ob.miscFee = child.currentMiscAmount;
            if (child && child.currentMiscName) ob.currentMiscName = child.currentMiscName;
            if (child && child.currentSecurityAmount) ob.securityDeposit = child.currentSecurityAmount;


            let tenantsIn = tenantList.filter(f => f.leaseMasterId == value);
            console.log("tenantsIn: ", tenantsIn);
            ob.paidByList = tenantsIn;
            if (ob.paidByList && ob.paidByList.length == 1) ob.tenant = ob.paidByList[0].RenterID;
        }
        
        console.log("after sum : " , ob.totalAmountTobePaid, offlinePayment.rentAmount, ob.rentAmount, offlinePayment.miscFee, offlinePayment.securityDeposit)

        

        if ((fieldName === 'proceedPayMiscFee' || fieldName === 'proceedSecurityDeposit')) {
            ob[fieldName] =  checkBoxValue;
            if (checkBoxValue) {
                if (fieldName === 'proceedPayMiscFee' && offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0) ob.totalAmountTobePaid  += Number(offlinePayment.miscFee);
                if (fieldName === 'proceedSecurityDeposit' && offlinePayment.securityDeposit) ob.totalAmountTobePaid  += offlinePayment.securityDeposit;
            }

            if (checkBoxValue === false) {
                if (fieldName === 'proceedPayMiscFee' && offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0) ob.totalAmountTobePaid  -= Number(offlinePayment.miscFee);
                if (fieldName === 'proceedSecurityDeposit' && offlinePayment.securityDeposit) ob.totalAmountTobePaid  -= offlinePayment.securityDeposit;
            }
        }


        if (fieldName == 'rentMonth' && child ) {
            console.log("rentMonth ------------------------ : " , child);
            ob.currentPayedRentMonth = child;
            ob.rentAmount = child.Rent;
        }
        if (child && child.currentUnit) ob.unit  = child.currentUnit;

       
        setOfflinePayment({...offlinePayment, ...ob})
      }

    const DialogTitle = (props) => {
        const { children, onClose } = props;
        return (
          <MuiDialogTitle disableTypography>
            <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" onClick={onClose} className={"addTenant-closebt"}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      }; 

/*       function getTransactionTypeComboValue(value) {
          console.log("getTransactionTypeComboValue 1", value);
        let array = [value];
        return array;
    } */

    return (
        <div className="home-root"> 
            <Grid container
                spacing={0}
                direction="column"
            >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Container component="main" className="home-main">
                        <CssBaseline />
                        {/* <Spinner isLoading={isLoading} className="common-spinner"
                            area={areas.admin}
                        /> */}
                        <div className="home-paper">


                        <Dialog
                            fullWidth
                            maxWidth={'lg'}
                            open={addOfflinePayment}
                            /*  scroll={addManualTenantScroll} */
                            onClose={e => {
                                setOfflinePayment({});
                                setAddOfflinePayment(false)
                                }
                            }
                            aria-labelledby="addnew-user-dialog-title"
                            >
                            <DialogTitle className="addnew-user-dialog-title"  id="addnew-user-dialog-title" onClose={e=>{setAddOfflinePayment(false); setOfflinePayment({});}}>
                                
                                <div className={"skelton-flex-parent"}>
                                    {
                                isLoadingModal && 
                                <Skeleton  variant="rect" width={263} height={29} className={"skelton-margin-right-5 skelton-margintop-2p"}/>
                                }
                                    {!isLoadingModal && <div>Add Payment</div>}
                                
                                </div>
                            </DialogTitle>
                            <DialogContent className="addtenant-dialog-content">
                            <DialogContentText>
                            {/* {
                                !isLoadingModal &&
                            "Add payments made offline, checks or cash."
                            }
                            {
                                isLoadingModal &&
                                <Skeleton variant="rect" width={262} height={19}/>
                            } */}
                            <div className={"skelton-flex-parent"}>
                                    {!isLoadingModal && <div>Manually include payments made offline, ie e-transfer, checks or cash to active leases only.</div>}
                                {
                                isLoadingModal && 
                                <Skeleton variant="rect" width={262} height={19}/>
                                }
                                </div>
                            </DialogContentText>  
                                <AddPaymentOffline  isLoading = {isLoadingModal} leaseHolderList = {leaseHolderList} tenantList = {tenantList} rowData = {offlinePayment} onChildClick={(fieldName, value, child, checkBoxValue) => handleChildClick(fieldName, value, child, checkBoxValue)} />
                            </DialogContent>
                            <Divider variant="middle" />
                            <DialogActions className={"addTenant-DialogActions offline-modal-bt"}>
                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={12}>
                                    {!isLoadingModal && 
                                    <div className={"offline-bt-flex"}>
                                    <LoaderCLickButton
                                        isLoading={false}
                                        size="large"
                                        disabled={!validateForm()}
                                        onClick={ async () => {  
                                            setIsLoadingModal(true);
                                            await saveOfflinePayment(); 
                                            console.log('Inside tableRef : ', tableRef.current)

                                        }}
                                    >
                                         {offlinePayment.isEditing ? "UPDATE" : "+ ADD"}
                                    </LoaderCLickButton>
                                    { offlinePayment.isEditing &&
                                    <LoaderDeleteButton
                                        isLoading={false}
                                        size="large"
                                        disabled={false}
                                        onClick={() => { deleteOffLinePayment() }}
                                    >
                                         DELETE
                                        </LoaderDeleteButton>
                                    }
                                    </div>
                                    }
                                    {isLoadingModal &&
                                    <div className={"offline-bt-flex"}>
                                        <Skeleton variant="rect" className={"skelton-minwidth-212 skelton-width-p25 skelton-margin-r-25"}  height={45}/>
                                        { offlinePayment.isEditing &&
                                        <Skeleton variant="rect" className={"skelton-minwidth-212 skelton-width-p25"}  height={45}/>}
                                        </div>}
                                </Grid>

                                </Grid>
                        
                            </DialogActions>
                            </Dialog>





                            <Grid container spacing={1} className={"add-padding-p8p"}  onClick={e =>{ 
                                history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);                      
                                    }}>
                                <Grid item>
                                    <Button>
                                    <div className="addlease-top-flex-container">
                                    <div className = "addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                                    </div>
                                    </Button> 
                                </Grid> 
                                </Grid>
                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="space-between"
                                alignItems="flex-start">
                                    

                                <Grid item xs={12} sm={10} md={10} lg={10} className={"skelton-property-page-flex-compo-grid"}>
                                { !isLoading &&
                                    <Typography
                                        component="h1"
                                        className="biller-headerOne biller-fontweight"
                                        variant="h1"
                                    >
                                        View Transactions{/*  { props.userSession.Privilege === "ADMIN" ? "- " + propertyData.PropertyName : "" }  v2:line number : 21*/} 
                                    </Typography>
                                }
                                    { isLoading && <div className={"skelton-flex-parent skelton-width-p100"}>
                                        <Skeleton  variant="rect"  height={69} className={"skelton-margin-right-19 skelton-width-p58"}/>
                                        <Skeleton  variant="rect"  height={69} className={"skelton-width-p40"}/>
                                    </div>}
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} lg={2}>
                                        {isLoading && <div className={"skelton-viewtrans-page-flex-compo-grid-csv skelton-width-p45"}>
                                                <Skeleton variant="rect" width={30} height={32} className = {"skelton-margin-right-5"}/>
                                                <Skeleton variant="rect" width={120} height={32}/>
                                        </div>}
                                        { !isLoading && <CsvExport {...props} tableReference={tableRef} module="biller" masterType="ViewTransaction" UserID={props.userSession.UserName} PropertyID={propertyData.PropertyID}></CsvExport>}

                                </Grid>
                            </Grid> 
                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={12}>

                                { props.userSession && (props.userSession.Privilege === "BILLING" || props.userSession.Privilege === "ADMIN" ) &&
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1} alignItems="center"  className={"skelton-property-page-flex-compo-grid"}>
                                        <Grid item className={"skelton-property-page-flex-compo-grid"}>
                                            { !isLoading && <Fab size="small" color="secondary" onClick={async () => {
                                                 await getTenants();                                                 
                                                 
                                                 }} aria-label="add" className="home-margin">
                                                <PaymentIcon className="home-linkIcon" />
                                            </Fab>}
                                            { isLoading && <Skeleton variant="circle" width={40} height={40}/>}
                                        </Grid>
                                        <Grid item className={"skelton-property-page-flex-compo-grid"}>
                                            { !isLoading && <Typography className="home-headerThree add-payment-tag"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                component="h5"
                                                variant="h5"  
                                                onClick={async () => {
                                                    await getTenants();                                                 
                                                    
                                                    }}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                            > ADD PAYMENT 
                                            </Typography>}

                                            { isLoading && <Skeleton variant="rect" width={218} height={32}/>} 
                                            
                                        </Grid>

                                    </Grid>
                                </Grid>
                                }
                                
                                { !isLoading && 
                                    <Typography className="biller-headerfour biller-paddingtop-1p5p"
                                    variant="subtitle1"
                                    >
                                        View all successful transactions here. You can filter transaction data based on the date. And export it as a CSV.
                                    </Typography>
                                    }
                                    { isLoading && <Skeleton variant="text" height={32}/>}
                                </Grid>

                                

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} sm={12} md={2} lg={2} className="biller-filteerLavel-grd">
                                          {/*   <div className={"skelton-flex-parent skelton-view-trans-filter"}>
                                            {!isLoading && <Typography
                                                className="biller-filterLabel"
                                                component="h5"
                                                variant="h5"
                                            >  FILTER </Typography>
                                            }
                                         { isLoading && <Skeleton variant="rect" width={64} className={"skelton-txt-filter-viewtrans skelton-height-p38"}/>} 
                                         </div> 
                                         QA v2 : line nummber 29
                                         */}
                                        </Grid> 
                                        {!isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <FormControl fullWidth variant="outlined" className="biller-filterUType">
                                                <InputLabel ref={inputLabel}
                                                    id="select-outlined-label"
                                                >Month</InputLabel>
                                                <Select
                                                    labelid="select-outlined-label"
                                                    id="property-select-outlined"
                                                    multiple
                                                    fullWidth
                                                    // value={user && user.PropertyID ? user.PropertyID.map(eachP => eachP.PropertyName) : []}
                                                    value={userViewFilter.Month}
                                                    onChange={(e, child) => { onFilterChange(e, child) }}
                                                    variant="outlined"
                                                    name="month"
                                                    renderValue={selected => selected.join(", ")}
                                                    labelWidth={100}
                                                >
                                                    <MenuItem value="">
                                                        <em>MM</em>
                                                    </MenuItem>
                                                    {transComboMonths.map((eachMap, keyIndex) => {
                                                        return (
                                                            <MenuItem key={keyIndex} value={eachMap.Name} ob={{ value: eachMap.Name, Slno: eachMap.Slno }}>
                                                                <Checkbox checked={userViewFilter.Month.indexOf(eachMap.Name) > -1} />
                                                                {eachMap.Slno + "-" + eachMap.Name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            
                                        </Grid>
                                        }
                                        
                                        {!isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <FormControl variant="outlined" fullWidth className="biller-filterUType">
                                                <InputLabel ref={inputLabel}
                                                    id="select-outlined-label"
                                                >
                                                    Year
                                                 </InputLabel>
                                                <Select
                                                    labelid="select-outlined-label"
                                                    id="property-select-outlined"
                                                    multiple
                                                    fullWidth
                                                    // value={user && user.PropertyID ? user.PropertyID.map(eachP => eachP.PropertyName) : []}
                                                    value={userViewFilter.Year}
                                                    onChange={(e) => { onFilterChange(e) }}
                                                    variant="outlined"
                                                    name="year"
                                                    label="PROPERTY"
                                                    // input={<Input />}
                                                    renderValue={selected => selected.join(", ")}//selected.join(', ')}
                                                    //MenuProps={MenuProps}
                                                    labelWidth={100}
                                                >
                                                    <MenuItem value="">
                                                        <em>YYYY</em>
                                                    </MenuItem>
                                                    {comboYear.map((x, ind) => {
                                                        return (
                                                            <MenuItem key={ind} value={x}>
                                                                <Checkbox checked={userViewFilter.Year.indexOf(x) > -1} />
                                                                <ListItemText primary={x} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            
                                        </Grid>
                                        }
                                        {!isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <FormControl variant="outlined" fullWidth className="biller-filterUType">
                                                <InputLabel ref={inputLabel}
                                                    id="select-outlined-label"
                                                >
                                                    Transaction Type
                                                 </InputLabel>
                                                <Select
                                                    labelid="select-outlined-label"
                                                    id="property-select-outlined"
                                                    multiple
                                                    fullWidth
                                                    value={userViewFilter.TransactionType || []}
                                                    onChange={(e) => { onFilterChange(e) }}
                                                    variant="outlined"
                                                    name="transactionType"
                                                    renderValue={selected => selected.join(", ")}
                                                    labelWidth={200}
                                                >
                                                    {/* <MenuItem value="">
                                                        <em>Transaction Type</em>
                                                    </MenuItem> */}
                                                    {transactionTypeCombo.map((x, ind) => {
                                                        return (
                                                            <MenuItem key={ind} value={x.Value}>
                                                                <Checkbox checked={userViewFilter.TransactionType.indexOf(x.Value) > -1} />
                                                                <ListItemText primary={x.Text} />
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            
                                        </Grid>
                                        }
                                        { isLoading && <Grid item xs={12} sm={12} md={3} lg={3} className={"viewtrans-skelton-combo /* skelton-min-width-380 */ skelton-max-width-408"}>
                                            <Skeleton variant="rect" height={52} />
                                        </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div 
                            //style={{ padding: "20px 0px 40px 0px" }}
                            className={isLoading ? "property-table-hide" : "property-table-show"}>
                                <MaterialTable tableRef={tableRef}
                                    title=""
                                    columns={[
                                        { title: "PAID BY", field: "TransactionRenter", searchable: false },
                                        { title: "LEASE HOLDER(S)", field: "LeaseHolder", searchable: false },
                                        { title: "TRANSACTION DATE", field: "TransactionOn", searchable: false, render: rowData => <Moment format="MMM DD YYYY">{rowData.TransactionDate}</Moment> },
                                        //{ title: "PROPERTY", field: "TransactionPropertyName", searchable: false },
                                        { title: "RENT MONTH", field: "TransactionRentMonth", searchable: false },
                                        { title: "TRANSACTION TYPE", field: "TransactionType", searchable: false, render: rowData => { return rowData.TransactionType ?  (
                                            rowData.TransactionType  == 'cash' ? "Cash"
                                            :
                                            rowData.TransactionType  == 'etransfer' ? "E-transfer"
                                            :
                                            rowData.TransactionType  == 'checkdeposit' ? "Check"
                                            :
                                            rowData.TransactionType  == '' ? "":capitalizeFirstLetter(rowData.TransactionType.toLowerCase())
                                        ) : ""}  },
                                        { title: "UNIT", field: "TransactionUnit",width:"6%",  searchable: false, render: rowData => { return (!rowData.TransactionUnit || rowData.TransactionUnit === "") ? "-" : rowData.TransactionUnit } },
                                        /* { title: "CARD", field: "TransactionCard", searchable: false }, */
                                        { title: "DEPOSIT", field: "TransactionDeposit", width:"5%", searchable: false, render: rowData => { return rowData.TransactionDeposit === 0 ? "-" : "$" + rowData.TransactionDeposit } },
                                        { title: "MISC", field: "TransactionMiscValue", width:"5%", searchable: false, render: rowData => { return rowData.TransactionMiscValue === 0 ? "-" : "$" + rowData.TransactionMiscValue } },
                                        //{ title: "TAX", field: "TransactionTaxAmount",  width:"5%",searchable: false, render: rowData => { return rowData.TransactionTaxAmount === 0 ? "-" : "$" + rowData.TransactionTaxAmount.toFixed(2) } },
                                        { title: "RENT", field: "TransactionRent", width:"5%", searchable: false, render: rowData => { return rowData.TransactionRent === 0 ? "-" : "$" + rowData.TransactionRent } },
                                        //{ title: createHeaderWithTooltip('FEE (TENANT)', 'Stripe processing fee (2.9%) + $0.3 paid by the tenant'),sorting: false, field: "ConvenienceFee", searchable: false, render: rowData => { return (!rowData.ConvenienceFee || rowData.ConvenienceFee === 0) ? "-" : "$" + rowData.ConvenienceFee } },                                       
                                        { title: "GROSS", field: "TransactionTotal",  width:"5%",searchable: false, render: rowData => { return rowData.TransactionTotal === 0 ? "-" : "$" + rowData.TransactionTotal } },
                                        //{ title: createHeaderWithTooltip('FEE (OWNER)', 'Stripe processing fee (2.9%) + $0.3 paid by the property'),sorting: false, field: "StripeCharges", searchable: false, render: rowData => { return (!rowData.StripeCharges || rowData.StripeCharges === 0) ? "-" : "$" + rowData.StripeCharges } },
                                        { title: createHeaderWithTooltip('FEE (TENANT)', 'Payment gateway processing fee paid by the tenant'),sorting: false, field: "ConvenienceFee", searchable: false, render: rowData => { return (!rowData.ConvenienceFee || rowData.ConvenienceFee === 0) ? "-" : "$" + rowData.ConvenienceFee } },
                                        { title: createHeaderWithTooltip('FEE (PROPERTY)', 'Payment gateway processing fee paid by the property'),sorting: false, field: "StripeCharges", searchable: false, render: rowData => { return (!rowData.StripeCharges || rowData.StripeCharges === 0) ? "-" : "$" + rowData.StripeCharges } },
                                        { title: "TOTAL(" + propertyData.CurrencyCode + ")", width:"3%", field: "NetAmount", searchable: false, render: rowData => { return (!rowData.NetAmount || rowData.NetAmount === 0) ? "-" : "$" + rowData.NetAmount } },

                                        {
                                            title: "ACTION",
                                            render: rowData => <div className={ props.userSession && 
                                                                                (props.userSession.Privilege === "BILLING" ||
                                                                                props.userSession.Privilege === "ADMIN") && 
                                                                                rowData.TransactionType && 
                                                                                (rowData.TransactionType === 'cash' || 
                                                                                rowData.TransactionType === 'etransfer' ||
                                                                                rowData.TransactionType === 'checkdeposit') 
                                                                                ? "view-trans-editoffline-enb"
                                                                                : "view-trans-editoffline-dsb"
                                                                                }> <Button className="biller-edit-icon" onClick={e => { editOfflinePAyment(rowData) }} >
                                                                                 <EditIcon />  </Button> </div>
                                        }
                                    
                                    ]}
                                    data={async query => new Promise((resolve, reject) => {
                                        GetTableData(query)
                                            .then(result => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.page,
                                                    totalCount: result.total
                                                })
                                            })

                                    })}
                                    options={{
                                        searchFieldStyle: {
                                            display: 'none'
                                        },
                                        actionsColumnIndex: -1,
                                        search: false,
                                        showTitle: false,
                                        toolbar: false,
                                        pageSize: pageSize,
                                        pageSizeOptions: [25, 50, 100, 200]
                                    }}
                                />
                            </div>

                            <div 
                            //style={{ padding: "20px 0px 40px 0px" }}
                            className={!isLoading ? "property-table-hide" : "property-table-show"}>
                                <MaterialTable
                                    title=""
                                    columns={[{ title: "", width:"100%", render: rowData =>{ return <Skeleton variant="text" />}}]}
                                        data={[{},{},{},{},{}]}
                                    options={{
                                        searchFieldStyle: {
                                            display: 'none'
                                        },
                                        actionsColumnIndex: -1,
                                        search: false,
                                        showTitle: false,
                                        toolbar: false,
                                        pageSize: 25,
                                        pageSizeOptions: [25]
                                    }}
                                />
                            </div>

                        </div>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}