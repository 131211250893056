import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"; 
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline"; 
import LoaderButton from "../LoaderButton";
import DataFetcher from '../../lib/Fetcher'; 
 
import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { S3Image } from 'aws-amplify-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { Skeleton } from '@material-ui/lab';  
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  header: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },
  myImage: {
    width: "100%"
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
  welcomeText: {
    fontSize: "20px",
  }

}));
export function Decline(props) {    
  const queryParams = new URLSearchParams(props.location.search);
    var vrData = {}
    queryParams.forEach(function (value, key) { 
      vrData[key] = value;
    });
  const [verifyData,setVerifyData] = useState(vrData);
  const classes = useStyles();
  
 function SkeltonLoader() {
   return(
     <div width="100%">
       <Grid
         container
         spacing={0}
         direction="column"
         alignItems="center"
         justify="center"
         style={{ minHeight: '85vh' }}
       >
         <Grid item xs={12} sm={12} md={5} lg={5}>
           <Container component="main" >
             <CssBaseline />
             <div className={classes.paper}>
               <Grid container justify="flex-start" direction="column" alignItems="flex-start" spacing={2}>
               <Grid item>
                          {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                            <Typography className='managment-header' component="h2" variant="h2">
                              {MapData.management_name}
                            </Typography>}
                        </Grid> 
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography className="verify-user-header" gutterBottom={true}>
                     <Skeleton variant="rect" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography component="h1" variant="h5">
                     <Skeleton variant="rect" width={385} height={30} />
                     <Skeleton variant="rect" width={385} height={30} />
                     <Skeleton variant="rect" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography component="h1" variant="h5">
                     <Skeleton variant="rect" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Skeleton variant="rect" width={112} height={45} />
                 </Grid>
               </Grid>

             </div>
           </Container>
         </Grid>
       </Grid>
       <PoweredBy />
       <Policy class={"policy-cls"} />
     </div>
  ) 
 }
  return ( 
      <div>
        <DataFetcher type="decline" userData={verifyData}>
          {({ data, IsLoading, error }) => { 
            if (!data) {
              return ( 
                <SkeltonLoader/>
              );
            }
            if (error) {
              console.log("Verify error", error);
              return <p>error.message</p>;
            }
            if (IsLoading) {
              return ( 
                <SkeltonLoader/>
              );
            }
            return (data &&
              <div width="100%">
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '85vh' }}
                >
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Container component="main" >
                      <CssBaseline />
                      <div className={classes.paper}>
                      <Grid container justify="flex-start" direction="column" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                            <Typography className='managment-header' component="h2" variant="h2">
                              {MapData.management_name}
                            </Typography>}
                        </Grid>                      
                        <Grid style={{paddingTop:'64px'}} item xs={12} sm={12} md={12} lg={12}>
                          <Typography component="h1" variant="h5">
                            {data.message}
                          </Typography>
                        </Grid>                       
                      </Grid>
                      </div>
                    </Container>
                  </Grid>
                </Grid>
                <PoweredBy />
                <Policy class={"policy-cls"} />
              </div>
            );
          }}
        </DataFetcher>
      </div>
  );

}