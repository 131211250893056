import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline"; 
import LoaderButton from "../LoaderButton"; 
import clsx from 'clsx';
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { API } from 'aws-amplify';
import { openSnackbar } from '../Notifier';
import Visibility from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { S3Image } from 'aws-amplify-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { Skeleton } from '@material-ui/lab'; 
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];
const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        justify: "center",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    header: {
        fontSize: 20,
        fontStyle: "bold",
        fontFamily: "Arial-BoldMT, Arial"
    },
    myImage: {
        width: "100%"
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: 200,
    },
    welcomeText: {
        fontSize: "20px",
    }

}));
export function ResetPassword(props) {
    const queryParams = new URLSearchParams(props.location.search);
    var vrData = {}
    queryParams.forEach(function (value, key) {
        vrData[key] = value;
    });
    const [verifyData, setVerifyData] = useState(vrData);
    const [welcomeView, setWelcomeView] = useState(false);
    var [isVerifyLoading, setIsVerifyLoading] = useState(false);
    const [confirmPass, setConfirmPass] = useState({
        newPassword: "",
        email: "",
        password: "",
        codeSent: false,
        confirmed: false,
        confirmPassword: "",
        isConfirming: false,
        isSendingCode: false,
        isLoading: false,
        setIsLoading: (value) => {
            confirmPass.isLoading = value;
        },
        showPassword: false
    });
    const classes = useStyles();
    const handleClickShowPassword = () => {
        setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
    };
    const handleMouseDownPassword = e => {
        e.preventDefault();
    };
    function validateResetForm() {
        return (
            confirmPass.newPassword.length > 0 &&
            confirmPass.password.length > 0 &&
            confirmPass.password === confirmPass.newPassword
        );
    }
    function SkeltonLoader() {
        return (
            <div width="100%">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Container component="main" >
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Grid container justify="flex-start" direction="column" alignItems="flex-start" spacing={2}>
                                    <Grid item>
                                        {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                            <Typography className='managment-header' component="h2" variant="h2">
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="verify-user-header" gutterBottom={true}>
                                            <Skeleton variant="rect" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component="h1" variant="h5">
                                            <Skeleton variant="rect" width={385} height={30} />
                                            <Skeleton variant="rect" width={385} height={30} />
                                            <Skeleton variant="rect" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component="h1" variant="h5">
                                            <Skeleton variant="rect" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Skeleton variant="rect" width={112} height={45} />
                                    </Grid>
                                </Grid>

                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <PoweredBy />
                <Policy class={"policy-cls"} />
            </div>
        )
    }
    const onChange = e => {        
        setConfirmPass({
            ...confirmPass,
            [e.target.id]: e.target.value
        });
    }
    const confirmPassword = async (e) => {         
        e.preventDefault();
        setIsVerifyLoading(true);
        setConfirmPass({ ...confirmPass, isConfirming: true });      
        let apiName = "rentapi";
        let path = "/verify/reset";
        let myInit = {
            headers: { "Content-Type": "application/json" },
            body: {
                email: verifyData.email,
                password: confirmPass.newPassword,
                code: verifyData.code,
                userId: verifyData.username,
                clientId: MapData.aws_user_pools_web_client_id
            },
            queryStringParameters: {
                module: "user",
                op: "forceResetPassword"
            }
        };
        API.post(apiName, path, myInit).then(response => {
            setIsVerifyLoading(false);
            if (response.success) {                
                setConfirmPass({ ...confirmPass, confirmed: true, password: "", confirmPassword: "", newPassword: "" });
                openSnackbar({ message: response.message, variant: "success" });
                setWelcomeView(true);
            }
            else {
                openSnackbar({ message: response.message, variant: "error" });
                setConfirmPass({ ...confirmPass, isConfirming: false });
                setWelcomeView(true);
            }
        }).catch(err => {
            console.log(err);
            setIsVerifyLoading(false);
            openSnackbar({ message: err.message, variant: "error" });          
            setConfirmPass({ ...confirmPass, isConfirming: false });
        });
    } 
    return (
    <div width="100%">
    {!isVerifyLoading && <>   <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '85vh' }}
        >
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    {welcomeView ?
                          <div className="forgot-password-paper">
                          <Grid container justify="center" alignItems="center"   direction="column" spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                <Typography className='managment-header' component="h2" variant="h2" >
                                  {MapData.management_name}
                                </Typography>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div  className={'forgot-user-icon'}>
                              <CheckCircleIcon style={{fill: '#0AE20A'  , fontSize:'54px'}} />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className="forgot-user-header" component="h5" variant="h5">
                                Password set successfully
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className="forgot-user-body"  gutterBottom={true} variant="h5">
                                To continue, please click the login button below
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div style={{ padding: "20px 0px 40px 0px" }}>
                                <LoaderButton
                                  block="true"
                                  type="submit"
                                  size="large"
                                  component={Link}
                                  to="/"
                                >
                                  LOGIN
                                </LoaderButton>
                              </div>
                          
                            </Grid> 
                          </Grid> 
                        </div>          
                        :
                        <div className={classes.paper}>
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                        <Typography className='managment-header' component="h2" variant="h2">
                                            {MapData.management_name}
                                        </Typography>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className="verify-user-header" component="h5" variant="h5">
                                        Welcome {verifyData.name}!
                                    </Typography>
                                </Grid>
                            </Grid>
                            <form className={classes.form} noValidate onSubmit={confirmPassword}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoComplete="new password"
                                            name="newPassword"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type="password"
                                            id="newPassword"
                                            label="New Password"
                                            value={confirmPass.newPassword}
                                            onChange={onChange}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>

                                <FormControl fullWidth required className={clsx(classes.margin)} variant="outlined">
                                    <InputLabel htmlFor="password">Verify New Password</InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        autoComplete="old password"
                                        type={confirmPass.showPassword ? 'text' : 'password'}
                                        value={confirmPass.password}
                                        onChange={onChange}
                                        autoFocus
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword()}
                                                    onMouseDown={(e) => handleMouseDownPassword(e)}
                                                >
                                                    {confirmPass.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={200}
                                    />
                                </FormControl>


                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <LoaderButton
                                            block="true"
                                            type="submit"
                                            size="large"
                                            className={classes.submit}
                                            isLoading={isVerifyLoading}
                                            disabled={!validateResetForm()}
                                        >
                                            SUBMIT
                                        </LoaderButton>
                                    </Grid>


                                    <Grid item>
                                        <MLink color="textSecondary" underline={"always"} component={Link} to="/">
                                            <Typography color="textSecondary" gutterBottom={true} variant="body2">
                                                BACK
                                            </Typography>
                                        </MLink>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    }
                </Container>
            </Grid>
        </Grid>
        <PoweredBy />
        <Policy class={"policy-cls"} />
        </>}
        {isVerifyLoading && <SkeltonLoader/>  }  
    </div>
    );

}