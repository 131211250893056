import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: { 
        width:"48px",
        height:"32px"
    }, 
  });
  ;

export const AmericanExpress = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
            <g id="americanexpress-light-large" transform="translate(0.001)">
                <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
                <g id="amex" transform="translate(0 7)">
                    <path id="Path" d="M1.27.88,1.81,2.2H0L.55.88.91,0l.36.88Z" transform="translate(2.38 2.66)" fill="#296db6" />
                    <path id="Shape" d="M30.96,8.2a3.979,3.979,0,0,1-2.32-.741V8.2H23.83L22.67,6.74V8.2H4.33L3.87,6.98H2.75L2.28,8.2H0V6.98H1.52L2,5.8H4.63L5.11,6.94H6.65L4.23,1.3H2.45L0,6.978V3.8L1.6,0H4.86L5.8,2.1V0h4l.6,1.8L11,.04H28.64V.86A3.9,3.9,0,0,1,31.028.038l.112,0h5.34l1,2.1V.04h3.14L41.69,1.7V.04h3.59V8.2H41.9l-1-1.579V8.2H36L35.51,6.98H34.39L33.93,8.2H30.96Zm7.6-6.9V6.98H39.9V3.18l.36.62L42.35,6.94h1.6L44,1.26H42.6V4.94l-.38-.64-2-3Zm-4.469,0L31.67,6.98h1.46L33.61,5.8h2.67l.48,1.14H38.3L35.89,1.3Zm-3.271-.006A2.551,2.551,0,0,0,28.28,4.08v.08a2.5,2.5,0,0,0,2.483,2.784c.046,0,.092,0,.137,0h.08l.55-1.2H31.1l-.07,0A1.4,1.4,0,0,1,29.64,4.16V4.08a1.4,1.4,0,0,1,1.39-1.561l.07,0h1.49V1.3H31C30.94,1.3,30.879,1.293,30.819,1.293ZM26.11,1.3V6.94h1.37V1.3ZM21.79,4.94h.62l1.68,2h1.74L23.95,4.86a1.742,1.742,0,0,0,1.54-1.78,1.771,1.771,0,0,0-2-1.78H20.4V6.94h1.39v-2Zm-9.2-2.554h0V6.94h1.32V1.3H11.7L10.58,4.56l-.18.62-.18-.62L9.12,1.3H6.91V6.94H8.22V2.38l.28.86L9.78,6.94H11l1.33-3.7ZM15,1.3V6.9h4.5V5.7H16.33v-1H19.42V3.5H16.33v-1H19.5V1.3ZM35.9,4.66H34.121l.519-1.32L35,2.46l.34.841.2.484-.2-.484L35,2.46l.36.88.206.5h0l.318.772h0L35.29,3.34l.61,1.319ZM23.46,3.747a.619.619,0,0,1-.089-.007H21.79V2.5h1.58a.628.628,0,0,1,.7.617v.02a.61.61,0,0,1-.61.61Zm.4-.194a.609.609,0,0,1-.4.154A.608.608,0,0,0,23.864,3.553Z" transform="translate(0 0.2)" fill="#296db6" />
                    <path id="Shape-2" data-name="Shape" d="M20.5,8.161H17.07V6.1a4.927,4.927,0,0,1-1.456.22h-.6V8.16H9.96L9.12,6.94,8.26,8.16H0V0H8.28l.84,1.2L9.98,0H39.12V1.26H36.19A1.74,1.74,0,0,0,34.25,3.04,1.569,1.569,0,0,0,36.01,4.7H37.3a.511.511,0,0,1,.525.217A.509.509,0,0,1,37.3,5.7H34.41V6.9h2.87c.054,0,.109.007.163.007A1.777,1.777,0,0,0,39.1,5.76v1.9a3.348,3.348,0,0,1-1.763.5H21.66L20.5,6.7V8.16ZM9.12,5.1h0L10.48,6.9l1.721.04L9.92,4.1l2.24-2.84H10.5L9.12,3.04,7.75,1.28H6.05L8.32,4.1,6.12,6.9H7.71L9.119,5.1Zm20.08.6V6.9H32.12c.046,0,.093.006.139.006A1.75,1.75,0,0,0,34.01,5.1a1.549,1.549,0,0,0-1.76-1.62H30.94a.51.51,0,1,1,0-1h2.43l.54-1.2H30.98A1.74,1.74,0,0,0,29.04,3.04,1.569,1.569,0,0,0,30.8,4.7h1.32a.51.51,0,1,1,0,1Zm-9.63-.8h.62l1.68,2h1.74L21.72,4.82A1.793,1.793,0,0,0,21.3,1.26H18.24V6.9H19.57v-2ZM12.44,1.26V6.9h1.34V5.04h1.73a1.85,1.85,0,0,0,2.04-1.9,1.81,1.81,0,0,0-2-1.88Zm11.49,0V6.84h4.55V5.64H25.31v-1H28.4V3.46H25.31v-1h3.14l.03-1.2ZM1.3,1.26V6.84H5.83V5.64H2.66v-1H5.77V3.46H2.66v-1H5.83V1.26ZM39.068,4.4A1.619,1.619,0,0,0,37.43,3.46H36.12a.51.51,0,1,1,0-1H38.58l.49-1.16V4.4ZM15.43,3.84H13.81V2.46h1.62a.69.69,0,0,1,0,1.381Zm5.841-.134A.612.612,0,0,1,21.18,3.7H19.6V2.46h1.58a.63.63,0,0,1,.079,0,.62.62,0,0,1,.622.625V3.1a.61.61,0,0,1-.61.61Z" transform="translate(8.88 10.9)" fill="#296db6" />
                </g>
            </g>
        </SvgIcon>
    );
};

export const BitCoin = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
             <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8"/>
            <g id="bitcoin" transform="translate(13 5)">
                <path id="Path" d="M20.886,13.175a10.6,10.6,0,1,1-1.2-8.033,10.6,10.6,0,0,1,1.2,8.033Z" transform="translate(0.404 0.395)" fill="#f7941f" />
                <path id="Shape" d="M5.11,13.38h0L3.95,13.09l.47-1.9L3.5,10.95l-.48,1.91-1.16-.29.48-1.93-.83-.22L0,10.04.57,8.71l.85.21a.414.414,0,0,0,.32-.025.42.42,0,0,0,.21-.245l.76-3h.01l.54-2.18a.623.623,0,0,0-.54-.68c-.01-.012-.538-.137-.85-.21l.31-1.24,1.61.4.75.17L5.01,0,6.18.29,5.71,2.16l.931.22L7.11.52,8.27.81,7.79,2.72c1.776.617,2.52,1.469,2.34,2.68l-.011-.05A1.885,1.885,0,0,1,8.63,7.06,2,2,0,0,1,9.7,9.88a2.427,2.427,0,0,1-2.575,1.738A8.276,8.276,0,0,1,5.6,11.45l-.489,1.93ZM4.5,7.03h0L3.87,9.6l.208.053L4.1,9.66a8.083,8.083,0,0,0,1.91.321c.811,0,1.294-.287,1.435-.852A1.046,1.046,0,0,0,7.333,8.3c-.452-.731-1.81-1.038-2.539-1.2-.114-.026-.212-.048-.293-.069Zm.95-3.49L4.81,5.86,5,5.909a6.327,6.327,0,0,0,1.546.265c.709,0,1.136-.267,1.269-.793h.02a.988.988,0,0,0-.1-.781c-.38-.619-1.453-.867-2.03-1C5.6,3.577,5.516,3.557,5.45,3.54Z" transform="translate(5.55 4.09)" fill="#fff" />
            </g>

        </SvgIcon>
    );
};
export const Cirrus = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
            <path id="card_bg" d="M48,4V28a4,4,0,0,1-4,4H4a3.81,3.81,0,0,1-1.36-.24A4,4,0,0,1,0,28V4A4,4,0,0,1,4,0H44A4,4,0,0,1,48,4Z" fill="#dfe3e8" />
            <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
            <g id="maestro" transform="translate(4 3)">
                <g id="Group" opacity="0">
                    <rect id="Rectangle" width="38.71" height="25.6" transform="translate(0.65 0.2)" fill="#fff" />
                </g>
                <rect id="Rectangle-2" data-name="Rectangle" width="10.21" height="16.68" transform="translate(14.9 4.66)" fill="#0379bf" />
                <path id="Path" d="M13.169,10.61a10.58,10.58,0,0,1,4-8.34,10.61,10.61,0,1,0,0,16.68A10.58,10.58,0,0,1,13.169,10.61Z" transform="translate(2.831 2.39)" fill="#0067b3" />
                <path id="Shape" d="M.5.411H.43V0h.1L.66.29.78,0h.1V.409H.81V.1L.7.37H.62L.5.1V.41ZM.2.41H.14V.07H0V0H.35V.07H.2V.409Z" transform="translate(35.94 19.16)" fill="#00a1de" />
                <path id="Path-2" data-name="Path" d="M17.16,10.6A10.61,10.61,0,0,1,0,18.943,10.61,10.61,0,0,0,1.78,4.053,10.4,10.4,0,0,0,0,2.263,10.61,10.61,0,0,1,17.16,10.6Z" transform="translate(20 2.397)" fill="#00a1de" />
            </g>
        </SvgIcon>
    );
};

export const DinersClub = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}> <path id="card_bg" d="M48,4V28a4,4,0,0,1-4,4H4a3.81,3.81,0,0,1-1.36-.24A4,4,0,0,1,0,28V4A4,4,0,0,1,4,0H44A4,4,0,0,1,48,4Z" fill="#dfe3e8" />
            <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
            <g id="dinner_club" transform="translate(8 4)">
                <path id="Shape" d="M1.81,4.561H0v-.17l.026,0c.376-.038.564-.057.564-.5V.742C.59.181.29.181,0,.181V.012H1.89C1.967,0,2.045,0,2.122,0A2.38,2.38,0,0,1,4.5,2.291C4.5,2.524,4.425,4.561,1.81,4.561ZM1.485.244c-.085,0-.17,0-.255.007L1.19,3.792c0,.493.344.56.68.56h.068a1.81,1.81,0,0,0,1.8-2,2,2,0,0,0-2-2.1C1.655.246,1.569.244,1.485.244Z" transform="translate(0.63 14.439)" fill="#211e1f" />
                <path id="Path" d="M1.36,2.987v-.17H1.25c-.19,0-.33,0-.33-.22V.007a.27.27,0,0,0-.12,0,6.8,6.8,0,0,1-.7.24v.1c.24.13.34.17.34.46v1.76c0,.22-.13.22-.31.22H0v.2H1.36Z" transform="translate(5.39 16.013)" fill="#211e1f" />
                <path id="Path-2" data-name="Path" d="M.5.82A.32.32,0,1,0,.18.5.32.32,0,0,0,.5.82Z" transform="translate(5.55 14.24)" fill="#211e1f" />
                <path id="Path-3" data-name="Path" d="M3.22,2.78H3.11c-.19,0-.33,0-.33-.22V.91c0-.5-.19-.91-.75-.91A1.88,1.88,0,0,0,.91.49V0A8.31,8.31,0,0,1,.05.31V.44c.3.12.38.19.38.43h0V2.59c0,.22-.13.22-.32.22H0v.1H1.39V2.74H1.27c-.18,0-.32,0-.32-.22V.64A1.63,1.63,0,0,1,1.7.32a.53.53,0,0,1,.6.56V2.56c0,.22-.13.22-.31.22H1.87v.13H3.22Z" transform="translate(6.98 16.09)" fill="#211e1f" />
                <path id="Shape-2" data-name="Shape" d="M1.281,3.025h0a1.318,1.318,0,0,1-.924-.411A1.3,1.3,0,0,1,0,1.665C0,.3.947.005,1.311.005a1,1,0,0,1,1.1,1v.12H.521a2.234,2.234,0,0,0,0,.38,1.076,1.076,0,0,0,1,1.12A1.266,1.266,0,0,0,2.4,2.2l.09.09A1.531,1.531,0,0,1,1.281,3.025ZM1.292.155h.02C.928.164.661.44.581.915h1.08c.18,0,.18-.081.18-.16a.55.55,0,0,0-.529-.6h-.02Z" transform="translate(10.139 16.115)" fill="#211e1f" />
                <path id="Path-4" data-name="Path" d="M.18,2.811H0v.13H1.69v-.17H1.33c-.19,0-.32,0-.32-.22V1.061a.55.55,0,0,1,.48-.54c.23,0,.23.16.43.16a.31.31,0,0,0,.3-.33A.35.35,0,0,0,1.85,0c-.39,0-.67.42-.83.67h0V.091c0-.07,0-.09-.06-.09s-.32.17-.8.37v.1c.1,0,.34.09.34.29v1.83C.49,2.811.36,2.811.18,2.811Z" transform="translate(12.74 16.059)" fill="#211e1f" />
                <path id="Path-5" data-name="Path" d="M.94,2.917a.87.87,0,0,1-.82-.8H0l.08.74a1.68,1.68,0,0,0,.79.23c.77,0,1.12-.46,1.12-.89,0-1-1.51-.79-1.51-1.55A.46.46,0,0,1,.99.217a.68.68,0,0,1,.71.64h.17L1.82.2a1.69,1.69,0,0,0-.74-.19.89.89,0,0,0-1,.84c0,1,1.42.8,1.42,1.57a.47.47,0,0,1-.56.5Z" transform="translate(15.13 16.013)" fill="#211e1f" />
                <path id="Path-6" data-name="Path" d="M3.934,4.361l.15-.95h-.15a1.33,1.33,0,0,1-1.31,1.13,2.12,2.12,0,0,1-1.94-2.26A1.85,1.85,0,0,1,2.514.211a1.25,1.25,0,0,1,1.4,1.07h.16L4,.351A4.6,4.6,0,0,0,2.474,0,2.4,2.4,0,0,0,0,2.3a2.32,2.32,0,0,0,2.49,2.45,3.68,3.68,0,0,0,1.44-.39Z" transform="translate(18.926 14.429)" fill="#211e1f" />
                <path id="Path-7" data-name="Path" d="M1.35,4.98V4.81H1.24c-.19,0-.33,0-.33-.22V0A.217.217,0,0,0,.77.07,3.76,3.76,0,0,1,.1.35V.46C.34.53.44.54.44.97V4.59c0,.22-.13.22-.31.22H0v.17H1.35Z" transform="translate(23.28 14.02)" fill="#211e1f" />
                <path id="Path-8" data-name="Path" d="M.31.53V2.2a.7.7,0,0,0,.71.78,1.76,1.76,0,0,0,1.16-.55v.53h0a6.84,6.84,0,0,1,.94-.21V2.64H2.91c-.11,0-.19,0-.19-.12V0s-.43.05-1,.09V.21c.23.06.54.13.54.26V2.23a1.47,1.47,0,0,1-.78.39c-.57,0-.57-.56-.57-.75V.19C.91.05.91,0,.84,0L0,.05V.17C.31.2.31.36.31.53Z" transform="translate(24.69 16.16)" fill="#211e1f" />
                <path id="Shape-3" data-name="Shape" d="M1.52,5.09h0a1.922,1.922,0,0,1-.9-.23l-.2.15L.29,4.94a7.558,7.558,0,0,0,.09-1.2V.94C.38.554.284.531.03.467L0,.46V.35A3.21,3.21,0,0,0,.67.07L.82,0C.87,0,.87.089.87.09V2.48a1.657,1.657,0,0,1,1-.471A1.271,1.271,0,0,1,3.08,3.38,1.657,1.657,0,0,1,1.52,5.09Zm0-2.77a1,1,0,0,0-.62.31L.85,4.21a.7.7,0,0,0,.671.67c.687,0,1-.658,1-1.27a1.243,1.243,0,0,0-1-1.29Z" transform="translate(27.81 14.06)" fill="#211e1f" />
                <path id="Path-9" data-name="Path" d="M.54.02H.02V.16H.09C.22.16.33.16.34.35V2.2c0,.18-.12.19-.25.19H0v.17H1.08V2.43H1.05c-.13,0-.25,0-.25-.19V.33C.8.15.92.14,1.05.14h.07V0h0Z" transform="translate(0.6 20.44)" fill="#211e1f" />
                <path id="Path-10" data-name="Path" d="M2.43.03h-.5V.17h.09c.11,0,.29,0,.3.32v1.3L.76.04H.02V.18H.08a.3.3,0,0,1,.31.3v1.5c0,.28-.07.43-.31.43H0v.16H.94V2.44H.86c-.26,0-.31-.09-.32-.4V.57l1.8,2h.17v-2c0-.41.16-.43.3-.43h.07V0h0Z" transform="translate(1.82 20.43)" fill="#211e1f" />
                <path id="Path-11" data-name="Path" d="M2.32,0h0c0,.09-.08.09-.18.09H.29C.2.09.14.09.11,0H0A2.64,2.64,0,0,1,0,.29v.3H.14C.2.33.2.28.46.28h.56V2.16c0,.27-.08.27-.28.28H.66v.23H1.83V2.54H1.75c-.17,0-.28,0-.28-.29V.32h.56c.23,0,.24.19.25.33h0L2.41.6h0V.02h-.1Z" transform="translate(4.83 20.33)" fill="#211e1f" />
                <path id="Path-12" data-name="Path" d="M1.91,1.85h0c-.1.4-.28.42-.7.42-.23,0-.42,0-.42-.22V1.24H1.2c.24,0,.24.13.26.34h.13V.76H1.45c0,.19-.06.29-.26.3H.78V.16h.48c.36,0,.39.17.41.4h.12V0H0V.14H.06C.19.14.3.14.31.33V2.18c0,.18-.12.19-.25.19H0v.16H1.94a2.959,2.959,0,0,1,.12-.56H1.95Z" transform="translate(7.36 20.47)" fill="#211e1f" />
                <path id="Shape-4" data-name="Shape" d="M1.12,2.561H0V2.37H.08c.146,0,.24-.071.24-.18V.39C.32.14.187.14.08.14H0V0H1.12c.571,0,.86.215.86.64a.667.667,0,0,1-.49.63l.6.89a.371.371,0,0,0,.356.27H2.46v.128L2.07,2.5H1.73A9.794,9.794,0,0,1,.99,1.36H.78v.8c.01.27.112.27.28.27H1.12v.13ZM.77.2v1H.93c.318,0,.53-.092.53-.54A.46.46,0,0,0,1,.2L.96.2Z" transform="translate(9.54 20.44)" fill="#211e1f" />
                <path id="Path-13" data-name="Path" d="M2.87.14V0H1.93V.14h.09c.11,0,.29,0,.3.32v1.3L.74.01H0V.15H.06a.3.3,0,0,1,.31.3V1.94c0,.29-.07.44-.31.44H0v.18H.94V2.43H.86c-.26,0-.31-.09-.31-.4V.56l1.79,2h.17v-2c0-.41.16-.43.3-.43h.07Z" transform="translate(12.06 20.44)" fill="#211e1f" />
                <path id="Shape-5" data-name="Shape" d="M.81,2.631H0V2.45a.314.314,0,0,0,.3-.22L.86.6A3.435,3.435,0,0,0,1,.15,1.441,1.441,0,0,0,1.33,0V.09l.65,1.86c.013.031.028.062.042.093a.693.693,0,0,1,.087.267.22.22,0,0,0,.111.19.224.224,0,0,0,.11.029A.221.221,0,0,0,2.44,2.5v.129H1.42V2.5h.05c.1,0,.211-.024.211-.09a1.092,1.092,0,0,0-.07-.25l-.14-.4H.69L.58,2.12A2.169,2.169,0,0,0,.5,2.39a.1.1,0,0,0,.083.1A.651.651,0,0,0,.75,2.5H.81v.13ZM.98.52.67,1.47h.62Z" transform="translate(15.02 20.37)" fill="#211e1f" />
                <path id="Path-14" data-name="Path" d="M2.32,0h0c0,.09-.08.09-.18.09H.3C.21.09.14.09.12,0H0A1.37,1.37,0,0,1,0,.29V.6H.14C.19.34.19.29.45.29h.57V2.17c0,.27-.09.27-.28.28H.65v.22H1.83V2.54H1.75c-.17,0-.28,0-.28-.29V.32h.57c.22,0,.23.19.24.33h0L2.41.6h0a2.71,2.71,0,0,1,0-.29V.02h-.1Z" transform="translate(17.14 20.33)" fill="#211e1f" />
                <path id="Path-15" data-name="Path" d="M.53.02H0V.16H.06C.19.16.31.16.31.35V2.2c0,.18-.12.19-.25.19H0v.17H1.08V2.43H1.01c-.13,0-.24,0-.24-.19V.33c0-.18.11-.19.24-.19h.07V0h0Z" transform="translate(19.69 20.44)" fill="#211e1f" />
                <path id="Shape-6" data-name="Shape" d="M1.269,2.617A1.269,1.269,0,0,1,0,1.326,1.317,1.317,0,0,1,.383.39,1.3,1.3,0,0,1,1.31.005h.01C1.36,0,1.4,0,1.439,0A1.261,1.261,0,0,1,2.7,1.246a1.318,1.318,0,0,1-1.318,1.37H1.34ZM1.32.2C.587.2.53.969.53,1.206c0,.809.286,1.22.85,1.22.531,0,.789-.36.789-1.1C2.17.778,1.872.2,1.32.2Z" transform="translate(20.86 20.384)" fill="#211e1f" />
                <path id="Path-16" data-name="Path" d="M2.84.14V0H1.91V.14H2c.1,0,.29,0,.29.32v1.3L.73.01H0V.15H.06a.29.29,0,0,1,.3.3v1.5c0,.28-.07.43-.3.43H0v.18H.94V2.43H.87c-.27,0-.31-.09-.32-.4V.56l1.79,2H2.5a2,2,0,0,1,0-.35V.56c0-.41.15-.43.3-.43h.07Z" transform="translate(23.66 20.44)" fill="#211e1f" />
                <path id="Shape-7" data-name="Shape" d="M.81,2.631H0V2.45a.308.308,0,0,0,.29-.22L.86.6A3.436,3.436,0,0,0,1,.15,1.626,1.626,0,0,0,1.32,0V.09l.65,1.86c.035.115.078.236.13.36a.21.21,0,0,0,.211.2A.21.21,0,0,0,2.37,2.5v.129H1.35V2.5H1.4c.092,0,.2-.024.2-.09a1.222,1.222,0,0,0-.07-.25l-.13-.4H.75l-.11.36a1.217,1.217,0,0,0-.08.27.1.1,0,0,0,.081.1A.655.655,0,0,0,.81,2.5v.13ZM.97.52.65,1.47h.62Z" transform="translate(26.6 20.37)" fill="#211e1f" />
                <path id="Path-17" data-name="Path" d="M1.87,1.87h0a.77.77,0,0,1-.11.25.51.51,0,0,1-.33.18H.97a.176.176,0,0,1-.2-.19V.32C.77.14.86.14.99.14H1.1V0H0V.14H.06C.19.14.31.14.31.33V2.15c0,.21-.12.22-.25.22H0v.19H1.87A3.221,3.221,0,0,1,2,1.96H1.87Z" transform="translate(29.13 20.44)" fill="#211e1f" />
                <path id="Shape-8" data-name="Shape" d="M.37.741A.37.37,0,0,1,.36,0H.382A.355.355,0,0,1,.631.1.359.359,0,0,1,.74.371a.371.371,0,0,1-.366.37ZM.36.041a.3.3,0,1,0,.3.3A.3.3,0,0,0,.36.041Z" transform="translate(30.64 20.359)" fill="#211e1f" />
                <path id="Shape-9" data-name="Shape" d="M.31.38H.24C.214.38.192.346.161.3.144.272.125.241.1.21V.33H0V0H.19C.272,0,.32.037.32.1A.11.11,0,0,1,.23.21V.29L.308.378Z" transform="translate(30.84 20.53)" fill="#211e1f" />
                <g id="mark" transform="translate(8)">
                    <path id="Path-18" data-name="Path" d="M9.342,12.592a6.55,6.55,0,0,0,6.58-6.24A6.43,6.43,0,0,0,9.342,0h-3A6.2,6.2,0,0,0,0,6.352a6.31,6.31,0,0,0,6.34,6.24Z" transform="translate(0.018 0.998)" fill="#0779be" />
                    <path id="Shape-10" data-name="Shape" d="M5.76,11.52a5.76,5.76,0,1,1,5.76-5.76A5.767,5.767,0,0,1,5.76,11.52Zm1.3-9.17V9.17a3.65,3.65,0,0,0,0-6.82Zm-2.61,0a3.655,3.655,0,0,0,0,6.82Z" transform="translate(0.66 1.52)" fill="#fff" />
                    <path id="Path-19" data-name="Path" d="M9.342,12.592a6.55,6.55,0,0,0,6.58-6.24A6.43,6.43,0,0,0,9.342,0h-3A6.2,6.2,0,0,0,0,6.352a6.31,6.31,0,0,0,6.34,6.24Z" transform="translate(0.018 0.998)" fill="#0779be" />
                    <path id="Shape-11" data-name="Shape" d="M5.76,11.52a5.76,5.76,0,1,1,5.76-5.76A5.767,5.767,0,0,1,5.76,11.52Zm1.3-9.17V9.17a3.65,3.65,0,0,0,0-6.82Zm-2.61,0a3.655,3.655,0,0,0,0,6.82Z" transform="translate(0.66 1.52)" fill="#fff" />
                </g>
            </g> 
        </SvgIcon>
    );
};
export const Discover = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}> <path id="card_bg" d="M48,4V28a4,4,0,0,1-4,4H4a3.81,3.81,0,0,1-1.36-.24A4,4,0,0,1,0,28V4A4,4,0,0,1,4,0H44A4,4,0,0,1,48,4Z" fill="#dfe3e8" />
            <g id="discover" transform="translate(2 10)">
                <path id="Path" d="M46,0V11.965A4.046,4.046,0,0,1,41.944,16H1.379A3.882,3.882,0,0,1,0,15.758c3.407-.484,6.693-1.09,9.918-1.776.852-.161,1.683-.363,2.515-.545C25.84,10.391,37.441,5.912,43.972,1.513,44.7,1.009,45.392.5,46,0Z" transform="translate(0 6)" fill="#ee7623" />
                <path id="Shape" d="M2.019,6.414c-.066,0-.133,0-.2-.006H0V.049H1.82V.008C1.9,0,1.981,0,2.053,0A3.22,3.22,0,0,1,4.12,5.688,3.422,3.422,0,0,1,2.019,6.414ZM1.26,1.129v4.28h.32c.067.006.135.009.2.009A2.307,2.307,0,0,0,3.3,4.848a2.14,2.14,0,0,0,.7-1.6,2.106,2.106,0,0,0-.7-1.6,2.334,2.334,0,0,0-1.72-.52Z" transform="translate(2 0.592)" fill="#231f20" />
                <rect id="Rectangle" width="1.25" height="6.42" transform="translate(7.85 0.63)" fill="#231f20" />
                <path id="Path-2" data-name="Path" d="M2.65,2.574c-.75-.27-1-.46-1-.81a.856.856,0,0,1,.93-.71,1.33,1.33,0,0,1,1,.52l.65-.85A2.75,2.75,0,0,0,2.35,0a1.88,1.88,0,0,0-2,1.84c0,.89.4,1.34,1.57,1.76a5.14,5.14,0,0,1,.87.37.76.76,0,0,1,.38.66.92.92,0,0,1-1,.91,1.48,1.48,0,0,1-1.36-.86L0,5.464a2.49,2.49,0,0,0,2.28,1.22,2.06,2.06,0,0,0,2.2-2.1C4.48,3.564,4.07,3.094,2.65,2.574Z" transform="translate(9.52 0.516)" fill="#231f20" />
                <path id="Path-3" data-name="Path" d="M0,3.36A3.32,3.32,0,0,0,3.39,6.71a3.43,3.43,0,0,0,1.57-.37V4.87a2,2,0,0,1-1.51.7A2.11,2.11,0,0,1,1.29,3.35a2.13,2.13,0,0,1,2.1-2.2,2.09,2.09,0,0,1,1.57.72V.4A3.19,3.19,0,0,0,3.42,0,3.38,3.38,0,0,0,0,3.36Z" transform="translate(14.41 0.48)" fill="#231f20" />
                <path id="Path-4" data-name="Path" d="M3.08,4.31,1.36,0H0L2.72,6.58h.67L6.17,0H4.81L3.08,4.31Z" transform="translate(26.22 0.63)" fill="#231f20" />
                <path id="Path-5" data-name="Path" d="M0,6.42H3.56V5.33H1.25V3.6H3.47V2.51H1.25V1.09H3.56V0H0V6.42Z" transform="translate(32.95 0.63)" fill="#231f20" />
                <path id="Shape-2" data-name="Shape" d="M1.26,6.361H0V0H1.84C3.289,0,4.12.685,4.12,1.88A1.738,1.738,0,0,1,2.66,3.72l2,2.639-1.519,0L1.4,3.82H1.26V6.36ZM1.26,1V2.94h.4c.795,0,1.181-.308,1.181-.94,0-.663-.4-1-1.181-1Z" transform="translate(37.34 0.64)" fill="#231f20" />
                <path id="Path-6" data-name="Path" d="M6.82,3.41A3.41,3.41,0,1,1,3.41,0,3.41,3.41,0,0,1,6.82,3.41Z" transform="translate(19.77 0.43)" fill="#ee7623" />
                <path id="Shape-3" data-name="Shape" d="M.14.581H0V0H.2C.344,0,.42.062.42.18A.152.152,0,0,1,.3.32L.479.578.32.58.14.34V.58Z" transform="translate(41.7 0.58)" fill="#231f20" />
                <path id="Shape-4" data-name="Shape" d="M.5,1A.5.5,0,0,1,.48,0H.5a.5.5,0,0,1,.347.14A.5.5,0,0,1,.5,1ZM.48.121A.38.38,0,0,0,.1.5a.391.391,0,0,0,.38.42H.488A.319.319,0,0,0,.74.8.457.457,0,0,0,.86.5.381.381,0,0,0,.48.121Z" transform="translate(41.46 0.36)" fill="#231f20" />
            </g>
        </SvgIcon>
    );
};
export const Jcb = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
            <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
            <g id="jcb-logo" transform="translate(8 4)">
                <rect id="Rectangle" width="9.85" height="13.54" transform="translate(0.03 6.41)" fill="#fff" />
                <rect id="Rectangle-2" data-name="Rectangle" width="9.87" height="11.9" transform="translate(11.09 6.41)" fill="#fff" />
                <rect id="Rectangle-3" data-name="Rectangle" width="9.85" height="13.02" transform="translate(22.14 6.13)" fill="#fff" />
                <path id="Path" d="M0,2.008H2.59a1,1,0,0,0,.81-1,1.07,1.07,0,0,0-.81-1,1.23,1.23,0,0,0-.28,0H0Z" transform="translate(24.1 12.562)" fill="#40a648" />
                <path id="Path-2" data-name="Path" d="M4.02,0a4,4,0,0,0-4,4V8.15H5.67a1.71,1.71,0,0,1,.4,0c1.27.06,2.22.72,2.22,1.86a1.88,1.88,0,0,1-1.83,1.83h0c1.3.09,2.29.81,2.29,1.94s-1.1,2-2.55,2H0V24H5.88a4,4,0,0,0,4-4V0Z" transform="translate(22.12)" fill="#40a648" />
                <path id="Path-3" data-name="Path" d="M3.12,1a.94.94,0,0,0-.81-1H0V1.93H2.09a.72.72,0,0,0,.22,0A.93.93,0,0,0,3.12.99Z" transform="translate(24.1 9.31)" fill="#40a648" />
                <path id="Path-4" data-name="Path" d="M4,0A4,4,0,0,0,0,4v9.87a7.91,7.91,0,0,0,3.45.9,1.92,1.92,0,0,0,2.14-2V8.13H9v4.64c0,1.8-1.12,3.27-4.92,3.27A17,17,0,0,1,0,15.54V24H5.87a4,4,0,0,0,4-4V0Z" transform="translate(0)" fill="#0d67b0" />
                <path id="Path-5" data-name="Path" d="M4,0A4,4,0,0,0,0,4V9.23c1-.86,2.77-1.41,5.6-1.27a17.27,17.27,0,0,1,3.14.48v1.69a7.59,7.59,0,0,0-3-.88c-2.15-.15-3.45.9-3.45,2.75s1.3,2.92,3.45,2.75a8.1,8.1,0,0,0,3-.88v1.69a16.9,16.9,0,0,1-3.14.48c-2.83.14-4.59-.41-5.6-1.27V24H5.91a4,4,0,0,0,4-4V0Z" transform="translate(11.09)" fill="#be2034" />
            </g>
        </SvgIcon>
    );
};
export const Maestro = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
            <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
            <g id="maestro" transform="translate(4 3)">
                <g id="Group" opacity="0">
                    <rect id="Rectangle" width="38.71" height="25.6" transform="translate(0.65 0.2)" fill="#fff" />
                </g>
                <rect id="Rectangle-2" data-name="Rectangle" width="10.21" height="16.68" transform="translate(14.9 4.66)" fill="#7272b5" />
                <path id="Path" d="M13.169,10.61a10.58,10.58,0,0,1,4-8.34,10.61,10.61,0,1,0,0,16.68A10.58,10.58,0,0,1,13.169,10.61Z" transform="translate(2.831 2.39)" fill="#ed1c24" />
                <path id="Shape" d="M.5.411H.43V0h.1L.66.29.78,0h.1V.409H.81V.1L.7.37H.62L.5.1V.41ZM.2.41H.14V.07H0V0H.35V.07H.2V.409Z" transform="translate(35.94 19.16)" fill="#00a1de" />
                <path id="Path-2" data-name="Path" d="M17.16,10.6A10.61,10.61,0,0,1,0,18.943,10.61,10.61,0,0,0,1.78,4.053,10.4,10.4,0,0,0,0,2.263,10.61,10.61,0,0,1,17.16,10.6Z" transform="translate(20 2.397)" fill="#00a1de" />
            </g>
        </SvgIcon>
    );
};
export const MasterCard = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
  <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8"/>
  <g id="mastercard" transform="translate(4 3)">
    <g id="Group" opacity="0">
      <rect id="Rectangle" width="38.71" height="25.6" transform="translate(0.65 0.2)" fill="#fff"/>
    </g>
    <rect id="Rectangle-2" data-name="Rectangle" width="10.21" height="16.68" transform="translate(14.9 4.66)" fill="#f26122"/>
    <path id="Path" d="M13.169,10.61a10.58,10.58,0,0,1,4-8.34,10.61,10.61,0,1,0,0,16.68A10.58,10.58,0,0,1,13.169,10.61Z" transform="translate(2.831 2.39)" fill="#ea1d25"/>
    <path id="Shape" d="M.5.411H.43V0h.1L.66.29.78,0h.1V.409H.81V.1L.7.37H.62L.5.1V.41ZM.2.41H.14V.07H0V0H.35V.07H.2V.409Z" transform="translate(35.94 19.16)" fill="#f69e1e"/>
    <path id="Path-2" data-name="Path" d="M17.16,10.6A10.61,10.61,0,0,1,0,18.943,10.61,10.61,0,0,0,1.78,4.053,10.4,10.4,0,0,0,0,2.263,10.61,10.61,0,0,1,17.16,10.6Z" transform="translate(20 2.397)" fill="#f69e1e"/>
  </g>
 
</SvgIcon>
  );
};
export const Paypal = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}>
            <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
            <g id="paypal" transform="translate(15 6)">
                <path id="Path" d="M4.11,18.64l.35-2.2H0L2.56.18A.21.21,0,0,1,2.63.05.24.24,0,0,1,2.77,0H8.98c2.07,0,3.49.43,4.24,1.28a2.75,2.75,0,0,1,.67,1.27,4.42,4.42,0,0,1,0,1.75v.5l.35.2a2.49,2.49,0,0,1,.71.53,2.59,2.59,0,0,1,.57,1.29,5.73,5.73,0,0,1-.08,1.86,6.48,6.48,0,0,1-.77,2.11,4.3,4.3,0,0,1-1.21,1.33,5.06,5.06,0,0,1-1.63.73,7.91,7.91,0,0,1-2,.24H9.34a1.47,1.47,0,0,0-.94.34,1.42,1.42,0,0,0-.49.88v.2L7.3,18.39v.15a.15.15,0,0,1,0,.08H7.24Z" transform="translate(1.23 0.68)" fill="#253d80" />
                <path id="Path-2" data-name="Path" d="M11.175,0l-.06.37c-.82,4.2-3.63,5.66-7.21,5.66H2.085a.89.89,0,0,0-.88.75L.275,12.7l-.27,1.68a.47.47,0,0,0,.46.54h3.24a.78.78,0,0,0,.77-.66V14.1l.61-3.87v-.21a.77.77,0,0,1,.76-.66h.53c3.13,0,5.59-1.27,6.3-5a4.24,4.24,0,0,0-.64-3.73,3.23,3.23,0,0,0-.86-.63Z" transform="translate(4.625 5.08)" fill="#189bd7" />
                <path id="Path-3" data-name="Path" d="M9.04.291l-.38-.1L8.24.111A10.06,10.06,0,0,0,6.64,0H1.76a.72.72,0,0,0-.33.07A.76.76,0,0,0,1,.651L0,7.221v.19a.89.89,0,0,1,.88-.75H2.7C6.28,6.661,9.09,5.2,9.91,1l.06-.37A4.48,4.48,0,0,0,9.3.351Z" transform="translate(5.9 4.449)" fill="#242e65" />
                <path id="Path-4" data-name="Path" d="M6.376,5.1a.76.76,0,0,1,.43-.58.72.72,0,0,1,.33-.07h4.88a10.06,10.06,0,0,1,1.6.11l.42.08.38.1.19.06a4.48,4.48,0,0,1,.67.28,4,4,0,0,0-.8-3.57C13.476.45,11.836,0,9.7,0H3.476A.89.89,0,0,0,2.6.75L.006,17.16a.54.54,0,0,0,.53.62h3.84l1-6.11Z" transform="translate(0.524 0)" fill="#253d80" />
            </g>
        </SvgIcon>
    );
};
 
export const Visa = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon  classes={{  root: classes.root}}  viewBox="0 0 48 32"  {...props}>
      <rect id="card_bg" width="48" height="32" rx="4" fill="#dfe3e8" />
      <path
        id="visa-logo"
        d="M17.014,9.52a7.127,7.127,0,0,1-2.6-.491l.43-2a5.019,5.019,0,0,0,2.377.6h.063c.712-.01,1.43-.3,1.43-.91.01-.392-.326-.7-1.24-1.15-.9-.439-2.09-1.176-2.09-2.51,0-1.8,1.645-3.06,4-3.06a6.414,6.414,0,0,1,2.24.41l-.419,2a4.512,4.512,0,0,0-1.92-.432c-.147,0-.295.007-.441.022-.691.091-1,.453-1,.77,0,.388.5.647,1.123.975,1,.521,2.234,1.17,2.227,2.695l.05-.08c-.01,1.92-1.647,3.16-4.17,3.16Zm6.626-.08H21.12L24.71.87a1.087,1.087,0,0,1,1-.68h2l1.939,9.249H27.43l-.29-1.38H24.13l-.49,1.38ZM26.02,2.69,24.76,6.16h1.99ZM12.7,9.41H10.3l2-9.25h2.4l-2,9.249ZM7.45,9.38H4.84L2.93,1.99a1,1,0,0,0-.571-.81A9.917,9.917,0,0,0,0,.4L.06.13H4.13a1.118,1.118,0,0,1,1.11.941l1,5.35L8.73.13h2.6L7.45,9.379Z"
        transform="translate(9.16 11.23)"
        fill="#2a2a6c"
      />
    </SvgIcon>
  );
};


export const ACH = (props) => {
    const classes = useStyles();
    return (
        <SvgIcon classes={{ root: classes.root }} viewBox="0 0 48 32"  {...props}> 
            <path id="Shape" d="M44,0H4A4,4,0,0,0,0,4V28a4,4,0,0,0,4,4H44a4,4,0,0,0,4-4V4A4,4,0,0,0,44,0Z" fill="#212b36" />
            <g id="account_balance-24px" transform="translate(14.242 4.242)">
                <g id="Group_1" data-name="Group 1">
                    <rect id="Rectangle_1" data-name="Rectangle 1" width="21" height="21" transform="translate(-0.242 -0.242)" fill="none" />
                </g>
                <g id="Group_3" data-name="Group 3" transform="translate(1.73 0.865)">
                    <g id="Group_2" data-name="Group 2">
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="2" height="6" transform="translate(2.028 7.893)" fill="#fff" />
                        <rect id="Rectangle_3" data-name="Rectangle 3" width="2.595" height="6.054" transform="translate(7.352 7.784)" fill="#fff" />
                        <rect id="Rectangle_4" data-name="Rectangle 4" width="17" height="2" transform="translate(0.028 15.893)" fill="#fff" />
                        <rect id="Rectangle_5" data-name="Rectangle 5" width="3" height="6" transform="translate(13.028 7.893)" fill="#fff" />
                        <path id="Path_1" data-name="Path 1" d="M10.649,1,2,5.325v1.73H19.3V5.325Z" transform="translate(-2 -1)" fill="#fff" />
                    </g>
                </g>
            </g> 
        </SvgIcon>
    );
  };