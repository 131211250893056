import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { API } from 'aws-amplify';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { LoaderDeleteButton } from "../LoaderButton";
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import { openSnackbar } from '../Notifier';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CropPortraitSharpIcon from '@material-ui/icons/CropPortraitSharp';
import usePersistedState from '../../usePersistedState'; 
import Avatar from '@material-ui/core/Avatar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
const MapData = (process.env.REACT_APP_STAGE === 'dev') ? awsdev : (process.env.REACT_APP_STAGE === 'dev2') ? awsdev2 : window['runConfig'];

/* const MapData = window['getConfig']; */
const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            width: '100%',
          },
          button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
          },
          actionsContainer: {
            marginBottom: theme.spacing(2),
          },
          actionsExtContainer: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          },
          resetContainer: {
            padding: theme.spacing(3),
            paddingTop:theme.spacing(0)
          },
          disabled:{
            backgroundColor:'#707070'
        },
        activeLabel:{
            color:'#000000',
            display: 'inline',
            fontWeight:'bold',
            fontSize:'1rem'
        },
        activeSubLabel:{
            color:'#757575',
            display: 'inline', 
            fontSize:'1rem'
        },
        disabledLabel:{
            color: '#757575'
        },
        completedLabel:{
            color: '#000000'
        }
    })
);
const ColorlibStepContent = withStyles({
    root:{
        marginTop: '8px',
        borderLeft: '1px solid #707070',
        marginLeft: '22px',
        paddingLeft: '20px',
        paddingRight: '8px'
    },
    
  })(StepContent);
const ColorlibConnector = withStyles({
    
    active: {
      '& $line': {
            backgroundColor:'#707070',
      },
    },
    completed: {
      '& $line': {
        backgroundColor: '#707070',
      },
    },
    vertical:{
        marginLeft:'22px',
    },
    line: {
      width: 1,      
      border: 0,
      backgroundColor: '#707070',
      borderRadius: 1,
    },
  })(StepConnector);
const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',    
        justifyContent: 'center',
        alignItems: 'center',
    },
    inherit:{
        color: 'inherit',
        backgroundColor:  'inherit'
    },
    active: {
        backgroundColor: '#9354F3', 
    }, 
    active2:{
        backgroundColor:'#FF0000'
    },
    active3:{
        backgroundColor:'#FF7600'
    },    
    completed: {
        backgroundColor:'#ccc',
    },
    completed2: {
        backgroundColor: '#0AE20A',
      },
    activeLabel:{
        color:'#000000',
        display: 'inline',
        fontWeight:'bold',
        fontSize:'1rem'
    },
});


function getSteps() {
    return ['Enter the email addresses','Enter the email addresses'];
  }
  let defaultFields={ 'MasterID':'', 'FirstName': '-',
  'LastName': '-',
  'CognitoUserID':'',
  'Name':'',
  'PhoneNumber':'',
  'Email':'',
  'PropertyID': [], 
  'Privilege':'RENTERS' };
export function AddTenant(props) {
    const location = useLocation();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [labelWidth, setLabelWidth] = useState(0);   
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);    
    const [selectedProperty] = usePersistedState('selectedProperty', {});
    const [email, setEmail]= useState('');
    const [emailHelper, setEmailHelper]= useState('');
    const [isUserExist,setIsUserExist]=useState(false);
    const [tenantUser, setTenantUser] =usePersistedState('tenantUser',defaultFields);
    const [leaseUsers, setLeaseUsers] =usePersistedState('leaseUsers',[]); 
    useEffect(() => {
        if (props.userOpen) {
            setLeaseUsers([]);
            setTenantUser(defaultFields);
        }
        setAddUserOpen(props.userOpen);
    }, [props]);
      
    useEffect(() => {
       console.log('leaseUsers',leaseUsers);
     },[leaseUsers])
    //fromLease
    const steps = getSteps();
    const handleNext =async () => {
        //getUserByEmail]
        try {
            let userData= await getUserByEmail();
            console.log('userData',userData);
            if(userData.length>0)
            {
                if(userData[0].Privilege==='RENTERS' || userData[0].Privilege==='USER')
                { 
                    if(userData[0].hasOwnProperty('Property') && _isContains(userData[0].Property,selectedProperty.PropertyID))
                    {
                        setIsLoading(false); 
                            setEmailHelper(`The user already exists in the system as a tenant. Please try again with a different email`)                       
                        
                    }
                    else
                    {                       
                        setIsUserExist(true);
                        setTenantUser({...tenantUser, ...userData[0]});  
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);   
                        setIsLoading(false);
                    }                             
                }
                else{
                    setIsLoading(false); 
                    setEmailHelper(`The user already exists in the system as a team member. Please try again with a different email or remove the user as an administrator.`)
                    //openSnackbar({ message: `${tenantUser.Email} already exist in the system with Administrator privilege.`, variant: "error" });
                }             
            }
            else
            {
                setIsLoading(false); 
                setIsUserExist(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
           
        } catch (er) {
            setIsLoading(false); 
            console.log('check tenantUser exist error');
            openSnackbar({ message: 'Error happened, Please try after some time!', variant: "error" });
        }
    };
    const _isContains = (json, value) => {
        let contains = false;
        Object.keys(json).some(key => {
            contains = typeof json[key] === 'object' ? _isContains(json[key], value) : json[key] === value;
            return contains;
        });
        return contains;
    }
    const handleFinish =async () => {
        setIsLoading(true);
        try {
            await addNewTenant();    
            //  setActiveStep((prevActiveStep) => prevActiveStep + 1);    //      
            setIsLoading(false); 
        } catch (err) {
            console.log(err);
            setIsLoading(false);  
        }      
    };
    const handleBack = () => {
        setIsUserExist(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
        setEmail('');
        setTenantUser(defaultFields);
    };
    const handleClose = (e) => {
        if (e === "addUser") {
            setAddUserOpen(false);
            setActiveStep(0);
            props.onChildClick('closeUser');
        }
    };
    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;
    
        const icons = {
            1: activeStep !== steps.length ?<Avatar className={classes.inherit}>1</Avatar>:<CheckOutlinedIcon/>,
            2:activeStep !== steps.length ?<Avatar className={classes.inherit}>2</Avatar>:<CheckOutlinedIcon/>,
            3:activeStep !== steps.length ?<Avatar className={classes.inherit}>3</Avatar>:<CheckOutlinedIcon/>,
        };
    
        return (
            <div
                className={clsx(classes.root, {
                    [Boolean(emailHelper)? classes.active2:isUserExist?classes.active3:classes.active]: active,
                    [activeStep !== steps.length? classes.completed:classes.completed2]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    };
    const DialogTitle = (props => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
                <Typography variant="h2" component="h2">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });
    function onChange(e) {
        setEmail(e.target.value);
        setEmailHelper('');
        setTenantUser({...tenantUser,Email:e.target.value});
    };
    async function getUserByEmail(userSession) {
        setIsLoading(true);
        let body = { userData: userSession,PropertyID: selectedProperty.PropertyID, masterType: 'User', email: email };
        return new Promise((resolve, reject) => {
            let apiName = 'rentapi';
            let path = '/modules';
            let myInit = {
                headers: { 'Content-Type': 'application/json' },
                body: body,
                queryStringParameters: {
                    module: 'user',
                    op: 'getUserByEmail'
                }
            };
            API.post(apiName, path, myInit)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }
    const validateForm =()=>{
        console.log('email && email.length>0',email, email.length);
        return Boolean(email) && !Boolean(emailHelper)
    }
    function addNewTenant() { 
        /* const MapData =window['getConfig']; */
        setIsLoading(true);
        if (tenantUser.Email) {            
            let apiName = 'rentapi';
            let path = '/modules';
            let userRequest = { 
              MasterID: tenantUser.MasterID,
              FirstName: tenantUser.FirstName,
              LastName: tenantUser.LastName,
              PropertyID: selectedProperty.PropertyID,
              PropertyName: selectedProperty.PropertyName, 
              Email: tenantUser.Email,
              domain:MapData.aws_app_domain_prefix,
              userData:props.userSession,
              Privilege:tenantUser.Privilege  
            } 
            let myInit = {
              headers: { 'Content-Type': 'application/json' },
              body: userRequest,
              queryStringParameters: {
                module: 'user',
                op: 'addTenantUser'
              }
            };
            /* let tenant=[...leaseUsers, { Email:tenantUser.Email,Name:'shijil try',MasterID:'id are same',PhoneNumber:'no'} ]
            setLeaseUsers(tenant);
            openSnackbar({ message: 'User added', variant: 'success' });
            setTenantUser({Email:tenantUser.Email,Name:'shijil try',MasterID:'id are same',PhoneNumber:'no'});
            setActiveStep((prevActiveStep) => prevActiveStep + 1); 
            setIsLoading(false);  */   
            API.post(apiName, path, myInit).then(response => { 
              setIsLoading(false);              
              if(response.success && response.data)
              { 
                setLeaseUsers(prvValue=>[...prvValue,response.data]);
                setTenantUser(response.data);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);    
                  let message =response.isUserExist?`${tenantUser.Email} already exist in the system. We have sent out an invitation`:`Invitation sent out ${tenantUser.Email}`;
                  //setEmailHelper(message);
                  openSnackbar({ message: message, variant: 'success' });
              }
              else
              {      
                //setEmailHelper(response.message);      
                openSnackbar({ message: response.message, variant: 'error' });
              }                  
            }).catch(err => {
              console.log(err);
              setIsLoading(false);
              openSnackbar({ message: 'Tenant adding failed', variant: 'error' });
            });    
        }
        else {
          //PLEASE FILL ALL DATA
          setIsLoading(false);
          setEmailHelper('Please enter all required fields');
          openSnackbar({ message: 'Please enter all required fields', variant: 'error' }); 
        }
      }
    const classes = useStyles();
    return (     
            <Dialog open={addUserOpen} onClose={e => handleClose("addUser")} fullWidth={true}
                maxWidth={"sm"} aria-labelledby="form-dialog-title">
                <DialogTitle className="addnew-tenantUser-dialog-title" id="email-dialog-title" onClose={e => handleClose("addUser")}>
                Add Tenant
                </DialogTitle>
                <DialogContent style={{ padding: '8px 24px 16px 24px' }}>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical" connector={<ColorlibConnector />}>                          
                             <Step>
                                    <StepLabel 
                                      classes={{
                                        active: classes.activeLabel,
                                        disabled: classes.disabledLabel,
                                        completed:classes.completedLabel
                                      }} 
                                    StepIconComponent={ColorlibStepIcon}>
                                  {activeStep===0?<>
                                    {'Enter the email addresses'}
                                   <Typography  component="span"  className={classes.activeSubLabel}>{` of the user you would like to invite to the resident portal. The tenant will receive an email  invitation to input their personal information and setup a password.`}
                                    </Typography> </>: "Enter the email addresses"}                                   
                                     </StepLabel>
                                    <ColorlibStepContent>
                                <Grid container direction="column" spacing={2}>           
                                    <Grid item xs={12} sm={12}> 
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email"                                             
                                                onChange={onChange}
                                                name="email"
                                                autoComplete="off"
                                                value={email} 
                                                error={Boolean(emailHelper)}
                                                helperText={emailHelper}
                                            /> 
                                    </Grid> 
                                    </Grid>                                      
                                        <div className={classes.actionsContainer}>
                                            <div> 
                                                <LoaderDeleteButton                                                    
                                                    size="large"                                                    
                                                    disabled
                                                    onClick={handleBack} 
                                                >
                                                   BACK
                                                </LoaderDeleteButton> 
                                                <LoaderCLickButton                                                    
                                                    size="large"                                                    
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    isLoading={isLoading}
                                                    disabled={!validateForm()}
                                                >
                                                    NEXT
                                                </LoaderCLickButton>                                             
                                            </div>
                                        </div>
                                    </ColorlibStepContent>
                                </Step>
                                <Step>
                                    <StepLabel    
                                     classes={{
                                        active: classes.activeLabel,
                                        disabled: classes.disabledLabel,
                                        completed:classes.completedLabel
                                      }} 
                                    StepIconComponent={ColorlibStepIcon}>
                                  {activeStep===1?<>
                                    {'Confirm invitation'}
                                   <Typography  component="span"  className={classes.activeSubLabel}>{isUserExist?` for ${email} into ${selectedProperty.PropertyName}’s resident portal. 
                                   We noticed the tenant already exists in the platform. If you wish to invite the same user, please click finish and we will notify the user. `:
                                  ` for ${email} into ${selectedProperty.PropertyName}'s resident portal.`}
                                    </Typography> </>: "Confirm invitation"} 
                                      </StepLabel>
                                    <ColorlibStepContent>                                        
                                        <div className={classes.actionsContainer}>
                                            <div> 
                                                <LoaderDeleteButton                                                    
                                                    size="large"    
                                                    onClick={handleBack} 
                                                >
                                                   BACK
                                                </LoaderDeleteButton> 
                                                <LoaderCLickButton                                                    
                                                    size="large"                                                    
                                                    onClick={handleFinish}
                                                    className={classes.button}
                                                    isLoading={isLoading}
                                                >
                                                    FINISH
                                                </LoaderCLickButton>                                             
                                            </div>
                                        </div>
                                    </ColorlibStepContent>
                                </Step>
                        </Stepper>
                        {activeStep === steps.length && (
                            <Box className={classes.resetContainer}>
                                <Typography className={classes.activeSubLabel}>
                                   { `Success, we have sent out an invitation to ${email}. Please inform the user to check the inbox for email confirmation shortly`}
                                    <br/> <br/>
                                    Now let’s activate the lease for the tenant.
                                </Typography>
                                <div className={classes.actionsExtContainer}>
                                             
                                                <LoaderDeleteButton                                                    
                                                    size="large"     
                                                    block="true"  
                                                    onClick={handleReset} 
                                                    
                                                >
                                                   START OVER
                                                </LoaderDeleteButton> 
                                                <LoaderCLickButton                                                    
                                                    size="large"                                                    
                                                    onClick={()=>{
                                                        if (props.fromLease) {
                                                                props.onChildClick('add tenant',leaseUsers);
                                                        }
                                                        else {
                                                            history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add`, props: { editLease: false, property: selectedProperty, tenant: leaseUsers/* tenantUser */, currentLease: {} } });
                                                        }
                                                    }}
                                                   
                                                >
                                                    ACTIVATE LEASE
                                                </LoaderCLickButton>                                             
                                            
                                        </div>
                            </Box>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        )
}