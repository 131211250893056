import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InputBase from '@material-ui/core/InputBase';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from '@material-ui/core/ListItemText'; 
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import ErrorIcon from '@material-ui/icons/Error';
import CustomizedTooltipsWithoutIcon from '../../lib/tooltIp'; 
import { Skeleton } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    MuiPickersUtilsProvider,  
    KeyboardDatePicker
  } from '@material-ui/pickers';

export default function AddPaymentOffline(props) {
    console.log("props offline : " , props );
    let history = useHistory();
    const location = useLocation();
    const [offlinePayment, setOfflinePayment] = useState(props.rowData ?  props.rowData : {});
    const [paidByList, setPaidByList] = React.useState(props.rowData && props.rowData.paidByList);
    const [labelWidth, setLabelWidth] = useState(0);
    const [isSecurityDepositExist, setIsSecurityDepositExist] = useState(true);
    const inputLabel = React.useRef(null);
    const [transactionDateOpen, setTransactionDateOpen]=useState(false);
    const [outstandingMonth, setOutstandingMonth] = useState(props.rowData && props.rowData.outstanding ? props.rowData.outstanding
       : props.rowData && props.rowData.rentMonth ? [props.rowData.rentMonth] : []);
       console.log("outstandingMonth :" , outstandingMonth);

       const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
          color: theme.palette.common.black,
        },
        tooltip: {
          backgroundColor: theme.palette.common.black,
        },
      }));

      function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
      
        return <Tooltip arrow classes={classes} {...props} />;
      }

    useEffect(() => {
        if(inputLabel.current)
        {
          setLabelWidth(inputLabel.current.offsetWidth);
        }
      },[inputLabel.current])

      useEffect(() => {
        console.log("After props.rowdata load"  , props.rowData);
        setOfflinePayment(props.rowData);
        setOutstandingMonth(props.rowData && props.rowData.outstanding ? props.rowData.outstanding : [])
        setPaidByList(props.rowData && props.rowData.paidByList ? props.rowData.paidByList : []);
      },[props.rowData])

      useEffect(() => {
        console.log("After offlinePayment load : "  , offlinePayment);
      },[offlinePayment])


    function onChange(e, child) {
      console.log("onChange CLICK " , child);
        let ob = {};
        console.log("e.target: " , e.target)
        if (e.target && e.target.name) {
          console.log("leaseAfterLeaseEndChange CLICK " , e.target.name, e.target.value, e.target.checked);
          if (e.target.name === 'proceedPayMiscFee' || e.target.name === 'proceedSecurityDeposit') {
            ob[e.target.name] =  e.target.checked;
          }
          else ob[e.target.name] =  e.target.value
          
          setOfflinePayment({...offlinePayment, ...ob})
          if (child && child.props && child.props.id && e.target.name !== 'rentMonth') setIsSecurityDepositExist(child.props.id.isSecuriteDepositExist);
          console.log("leaseAfterLeaseEndChange checked " , e.target.checked);
          props.onChildClick(e.target.name, e.target.value, child && child.props && child.props.id ? child.props.id : null, e.target.checked); // pass any argument to the callback
        }

/*          if (e.target.name == 'tenant') {
          let currentTenant = props.tenantList.find(x => x.value == e.target.value);
          console.log("currentTenant: ", currentTenant);
          setOutstandingMonth(currentTenant.outstanding);
          console.log("outstandingMonth: ", outstandingMonth);
        } */
 

      } 

    return (
      <div>
        <Grid
          container
          className="addtenant-grid"
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                LEASE HOLDER(S)
              </InputLabel>

              <Select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="addnew-user-filterUType offline-width-100p offline-height-lb-56px"
                name="leaseHolder"
                value={
                  offlinePayment && offlinePayment.leaseHolder
                    ? offlinePayment.leaseHolder
                    : ""
                }
                variant="outlined"
                onChange={(e, child) => {
                  onChange(e, child);
                }}
                labelWidth={labelWidth}
              >
                {props.leaseHolderList.map((e, keyIndex) => {
                  return (
                    <MenuItem
                      key={keyIndex}
                      value={e.leaseMasterId}
                      id={{
                        isSecuriteDepositExist: e.isSecuriteDepositExist,
                        currentUnit: e.unit,
                        currentMiscAmount: e.miscAmount,
                        currentMiscName: e.miscName,
                        currentSecurityAmount: e.securityDepositAmount
                      }}
                    >
                      <ListItemText primary={e.leaseHolders} />
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                className={"skelton-width-p100 skelton-minwidth-212"}
                variant="rect"
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={`${
                  !(offlinePayment && offlinePayment.leaseHolder)
                    ? "offline-payment-labels addpayment-deposit-label-dsbl"
                    : "offline-payment-labels"
                }`}
              >
                PAID BY
              </InputLabel>

              <Select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="addnew-user-filterUType offline-width-100p offline-height-lb-56px"
                name="tenant"
                value={
                  offlinePayment && offlinePayment.tenant
                    ? offlinePayment.tenant
                    : ""
                }
                variant="outlined"
                onChange={onChange}
                labelWidth={labelWidth}
                disabled={!(offlinePayment && offlinePayment.leaseHolder)}
              >
                {paidByList.map((e, keyIndex) => {
                  return (
                    <MenuItem key={keyIndex} value={e.value}>
                      <ListItemText primary={e.name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                className={"skelton-width-p100 skelton-minwidth-212"}
                variant="rect"
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels addpayment-margintop-5p"}
              >
                UNIT #
              </InputLabel>

              <InputBase
                defaultValue="-"
                inputProps={{ "aria-label": "naked" }}
                className={"addpyment-unit"}
                name="unit"
                value={offlinePayment.unit}
                id="unit"
                fullWidth
              />
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className="addtenant-grid"
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                OUTSTANDING MONTH
              </InputLabel>

              <Select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="addnew-user-filterUType offline-width-100p"
                name="rentMonth"
                value={
                  offlinePayment && offlinePayment.rentMonth > -1
                    ? offlinePayment.rentMonth
                    : ""
                }
                variant="outlined"
                //onChange={onChange}
                onChange={(e, child) => {
                  onChange(e, child);
                }}
                labelWidth={labelWidth}
                disabled={!(offlinePayment && offlinePayment.leaseHolder)}
              >
                {outstandingMonth &&
                  outstandingMonth.length &&
                  outstandingMonth.map((e, keyIndex) => {
                    return (
                      <MenuItem key={keyIndex} value={Number(e.ID)} id={e}>
                        {e.RentMonth}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                TRANSACTION DATE
              </InputLabel>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={"offline-width-100p"}
                  autoOk
                  emptyLabel="Add date"
                  invalidDateMessage=""
                  //minDate={leaseData.leaseStart ? new Date(leaseData.leaseStart).toISOString() : new Date().toISOString()}
                  InputProps={{ readOnly: true }}
                  variant="inline"
                  inputVariant="outlined"
                  format="MMMM dd, yyyy"
                  fullWidth
                  value={offlinePayment.transactionDate ? offlinePayment.transactionDate : null}
                  name="transactionDate"
                  InputAdornmentProps={{ position: "start" }}
                  //readOnly={isPastLeaseStart}
                  onOpen={(e) => {}}
                  onChange={(date) => {
                    if (date instanceof Date && isFinite(date)) {
                      console.log("DATE CLICKED: : ", date);
                      let ob = {
                        name: "transactionDate",
                        value: date.toISOString(),
                      };
                      let e = {
                        target: ob,
                      };
                      onChange(e);
                    }
                  }}
                  onClick={() => setTransactionDateOpen(true)}
                  onClose={() => setTransactionDateOpen(false)}
                  open={transactionDateOpen}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4}  className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                TRANSACTION TYPE
              </InputLabel>

              <Select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className="addnew-user-filterUType offline-width-100p"
                name="transactionType"
                value={
                  offlinePayment && offlinePayment.transactionType
                    ? offlinePayment.transactionType
                    : ""
                }
                variant="outlined"
                onChange={onChange}
                labelWidth={labelWidth}
              >
                {/* <MenuItem value="">
                        <em>SELECT TYPE</em>
                      </MenuItem> */}
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="etransfer">E-Transfer</MenuItem>
                <MenuItem value="checkdeposit">Check</MenuItem>
              </Select>
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className="addtenant-grid"
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {!props.isLoading && (
            <Grid item xs={12} sm={4}  className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                RENT AMOUNT
              </InputLabel>
              {offlinePayment && offlinePayment.leaseHolder ? (
                <InputBase
                  defaultValue="-"
                  inputProps={{ "aria-label": "naked" }}
                  className={"addpyment-unit"}
                  name="rentAmount"
                  value={"$" + offlinePayment.rentAmount}
                  id="rentAmount"
                  readOnly={true}
                  fullWidth

                />
              ) : (
                ""
              )}

              {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="rentAmount"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.rentAmount}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
            </Grid>
          )}

          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <div className={"offline-bt-flex"}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={`${
                    !isSecurityDepositExist
                      ? "offline-payment-labels addpayment-deposit-label-dsbl"
                      : "offline-payment-labels"
                  }`}
                >
                  SECURITY DEPOSIT
                </InputLabel>
                {/*                   { !isSecurityDepositExist && <BootstrapTooltip title="Security deposit is already paid">
                    <ErrorIcon className={"addpayment-deposit-tooltip"}/> 
                  </BootstrapTooltip>
                  } */}
              </div>

              {!isSecurityDepositExist ? (
                <InputBase
                  inputProps={{ "aria-label": "naked" }}
                  className={"addpyment-unit"}
                  name="securityDeposit"
                  value={"Already Paid"}
                  id="rentAmount"
                  disabled={true}
                  fullWidth
                />
              ) : offlinePayment.securityDeposit > 0 ? (
                <FormControlLabel
                className="formlabel-weight"
                control={<Checkbox checked={offlinePayment.proceedSecurityDeposit}  onChange={onChange} name="proceedSecurityDeposit"/>}
                label={" $" + offlinePayment.securityDeposit}
                />
              ) : (
                ""
              )}

              {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="securityDeposit"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.securityDeposit}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  disabled={!isSecurityDepositExist}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}

          {!props.isLoading && (
            <Grid item xs={12} sm={4}  className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={`${
                  !(offlinePayment && offlinePayment.miscFee > 0) &&
                  offlinePayment.leaseHolder
                    ? "offline-payment-labels addpayment-deposit-label-dsbl"
                    : "offline-payment-labels"
                }`}
              >
                MISC FEE 
              </InputLabel>

              {offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0 ? (
                <div>
                  
                  <FormControlLabel
                  className="formlabel-weight"
                  control={<Checkbox checked={offlinePayment.proceedPayMiscFee} onChange={onChange} name="proceedPayMiscFee"/>}
                  label={" " + offlinePayment.currentMiscName + ": $" + offlinePayment.miscFee}
                  />
                  <InputBase
                    inputProps={{ "aria-label": "naked" }}
                    className={"addpyment-unit hiddenfields-1"}
                    name="miscFee"
                    value={offlinePayment.miscFee}
                    id="miscFee"
                    fullWidth
                  />
                </div>
              ) : offlinePayment && offlinePayment.leaseHolder ? (
                <InputBase
                  inputProps={{ "aria-label": "naked" }}
                  className={"addpyment-unit"}
                  name="miscFee"
                  value="None"
                  id="miscFee"
                  disabled={true}
                  fullWidth
                />
              ) : (
                ""
              )}

              {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="miscFee"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.miscFee}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
            </Grid>
          )}
          {props.isLoading && (
            <Grid item xs={12} sm={4} className="addpayment-modal-lastrow-col">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={"offline-payment-labels"}
              >
                <Skeleton variant="text" className={"skelton-width-50p"} />
              </InputLabel>
              <Skeleton
                variant="rect"
                className={"skelton-width-p100 skelton-minwidth-fld"}
                height={55}
              />{" "}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className="addtenant-grid"
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {!props.isLoading && 
          <Grid item>
          <InputLabel
                id="demo-simple-select-outlined-label"
                className="addpayment-special-labels-1"
              >
                Total Payment
          </InputLabel>
          </Grid>
          
          }
          {!props.isLoading && offlinePayment.totalAmountTobePaid ?
          <Grid item>
          <InputLabel
                id="demo-simple-select-outlined-label"
                className={"addpayment-special-labels-2"}
              >
                {" $" + offlinePayment.totalAmountTobePaid}
          </InputLabel>

          </Grid>
          :
          ""
          }
        </Grid>
      </div>
    );
}