import React from "react";
import { Link } from "react-router-dom"; 
import MLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
export default function Policy(props) {
  return (
<Box className={props.class}   >

<Grid
container
spacing={1}
direction="row"
justify="center"
alignItems="center"
>
<Grid item >
  <MLink color="textSecondary" underline={"always"} component={Link} to="/terms-of-service">   
  <p>   TERMS OF SERVICES
      </p>            
  </MLink>
</Grid>
<Grid item>
<p> | </p>     
</Grid>
<Grid item>
  <MLink color="textSecondary" underline={"always"} component={Link} to="/privacy-policy" variant="body2">
  <p> 
    PRIVACY POLICY
    </p>                  
  </MLink>
</Grid>
</Grid>
        
    </Box>
  );
}

