import React, { useState, useEffect } from "react";  
import PropTypes from 'prop-types';   
import {Visibility,Add, Search, Close } from '@material-ui/icons';
import {Container, CssBaseline, InputAdornment, Link,
   Grid, Typography, Tabs, Tab, TextField, Fab, Box,
   Button, Dialog, DialogActions, DialogContent, DialogContentText,
   IconButton} from "@material-ui/core"; 
import MuiDialogTitle from '@material-ui/core/DialogTitle';  
import MaterialTable,{MTableBodyRow}  from "material-table"; 
import { areas } from '../../common/constants/areas';
import Moment from 'react-moment';
import { Auth, API } from "aws-amplify";  
import { Spinner } from '../../common/components/spinner';
import CsvExport from '../CsvExport';
import usePersistedState from '../../usePersistedState';
import { useHistory,useLocation } from "react-router-dom";
import { Skeleton } from '@material-ui/lab';  
import { AddTenant } from "./AddTenant"; 
import { LoaderCLickButton, LoaderDeleteButton } from "../LoaderButton";
import { openSnackbar } from '../Notifier';  
import { trackPromise } from 'react-promise-tracker';   
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';


const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
var  defaultUserValue={ 'firstName': '-',
'lastName': '-',
'CognitoUserID':'',
'Name':'',
'mobNumber':'',
'Email':'',
'PropertyID': [], 
'Privilege':'',
'checkedNotification': false };

export  function User(props) {
  let history = useHistory(); 
  const location = useLocation();
  const [userTable, setUserTable] = React.useState({
    columns:[],
    data:[] 
   });
   const tableRef = React.createRef();
   const isRefresh = React.useRef(true);
   const [userViewFilters, setuserViewFilters] = useState({"PrivilegeName":[],"Property":[], "Month": [], "Year": [],"UserFilter":"" }); 
   const [propertyData, setPropertyData] = usePersistedState('propertyData',[]);
   const [userTabValue, setUserTabValue] = useState(0);  
   const [pageSize,setPageSize]=usePersistedState('pageSize',25);
   const [isLoading, setIsLoading] = useState(false);
   const [selectedProperty] = usePersistedState('selectedProperty', {});
   const [addUserOpen, setAddUserOpen] = useState(false);
   const [fromGrid, setFromGrid] = usePersistedState("fromGrid",false);
   const [user, setUser] =usePersistedState("user",{ "PropertyID": [], "PassWord": "", "Privilege": "RENTERS" });// useState({ "PropertyID": [], "PassWord": "" });      
   const [emailOpen, setEmailOpen] = useState(false);  
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false); 
  const [deleteOpen, setDeleteOpen] = useState(false);
   useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) {
        await setPropertyData(selectedProperty); 
      }
      if (!location.props && isRefresh.current && selectedProperty.hasOwnProperty("PropertyName")) {
        isRefresh.current = false; 
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [location.props,selectedProperty]);
 
   function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
  }  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  } 
  function propertyNameCombine(property) {
    var propertyName=(property && property.length>0)?  Array.prototype.map.call(property, s => s.PropertyName).join(", "):"-";
    
    return propertyName;
  }
  function RenderUserStatus (rowData) {    
    switch (rowData.CognitoUserStatus) {
      case "CONFIRMED":
        return 'Active';
        break;
      case "UNCONFIRMED":
        return 'Invited';
        break;
      case "FORCE_CHANGE_PASSWORD":
        return 'Password Set Required';
        break;
      case "DISABLED":
        return 'Inactive';
        break;
      default:
        break;
    }
  }

  async function GetPagingData(query) { 
    var pageSize= query.pageSize;
    var pageNo=(query.page + 1); 
    let body = {};
    var columns = [],
      masterType = "",
      apiModule = "",
      op = "",
      userTab="All";  
      setPageSize(pageSize); 
      switch (userTabValue) {
        case 0:
          userTab="All";
          break;
        case 1:
            userTab="Active";
            break;
        case 2:
          userTab="Invite Sent";
          break;
        case 3:
          userTab="Password Set Required";
          break;
        case 4:
          userTab="Inactive";
          break;
        default:
          break;
      }
      columns.push(
        { title: "RENTER", field: "Name", searchable: false },
        { title: "EMAIL", field: "Email", searchable: false },
        { title: "PROPERTY", field: "Property", searchable: false, render: rowData =>{ return propertyNameCombine(rowData.Property)} },
        { title: "LAST ACTIVITY", field: "CreatedDate", searchable: false, render: rowData => <Moment format="YYYY-MM-DD">{rowData.TransactionDate}</Moment> },
        { 
          title: "STATUS", 
          field: "LeaseActivity", 
          searchable: false,
          render: (rowData, index) =>  <TextField  variant="outlined"  inputProps={{min: 0, style: { textAlign: 'center' }}} disabled= {true} className={RenderUserStatus(rowData) == 'Inactive' ? "tenant-grid-tf-dsbl" : "tenant-grid-tf"} value = {RenderUserStatus(rowData)} > </TextField>

        } 
      );
      masterType = "User";
      apiModule = "user";
      op = "loadUserGrid";
      body = {Privilege:"RENTERS", masterType: masterType,tabName:userTab,pageSize:pageSize,pageNo:pageNo,PropertyID:selectedProperty.PropertyID};
      console.log("userViewFilters.UserFilter",userViewFilters.UserFilter);        
      if (userViewFilters && userViewFilters.UserFilter!=="") {
        body.UserFilter = userViewFilters.UserFilter;
      } 
    
    body.userData=props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      setIsLoading(true);
      API.post(apiName, path, myInit).then(response => { 
        setIsLoading(false);
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
/**
   *  Function will handle tab change event
   * @param {tab change event} event 
   * @param {tab new index} newValue 
   */

  const handleUserTabChange =async (event, newValue) => {
    var userTab="All";
    Object.assign(userTable,{}); 
    setUserTabValue(newValue);   
    switch (newValue) {
      case 0:
        userTab="All";
        break;
      case 1:
          userTab="Active";
          break;
      case 2:
        userTab="Invite Sent";
        break;
      case 3:
        userTab="Password Set Required";
        break;
      case 4:
        userTab="Inactive";
        break;
      default:
        break;
    }
    console.log("rentTab",userTab, newValue);  
     if(tableRef.current)
      tableRef.current.onQueryChange(); 
  }

  async function onKeyPressFilter(e) { 
    if(e.keyCode == 13){
      if (e.target.name == 'UserFilter') {
        if(tableRef.current) {
          tableRef.current.onQueryChange();
        }
      } 
   }
  }

  async function onSearchFilter(e) {

    if (userViewFilters.UserFilter) {
      if(tableRef.current) {
        tableRef.current.onQueryChange();
      }
    }

  }

  async function onFilterChange(e, child) {
    console.log("e.target.name",e.target.name);
    console.log(" FILTER CHANGE EVENT ", e.target.value);    
    let type = "User" 
    if (e.target.name === "UserFilter") {
     // Object.assign(userViewFilters, {UserFilter:  e.target.value  });     
     setuserViewFilters({ ...userViewFilters, "UserFilter": e.target.value });
    }   
    if (e.target.name === "month") {
      type = "view user";
      //Object.assign(userViewFilters, { Month: e.target.value });
      console.log(" FILTER CHANGE EVENT ", child.props.ob);
      console.log('AFTER VAKL CHANGE  , 1-----', userViewFilters);

      let arrayMonth = userViewFilters.Month;
      if(child.props.ob.Slno == 0){
        arrayMonth.length = 0;
      }
      else{
      if (!arrayMonth.some((each) => each.Slno === child.props.ob.Slno)) {
        arrayMonth.push(child.props.ob);
      }
      else {
        arrayMonth.splice(arrayMonth.map((mapp) => mapp.Slno).indexOf(child.props.ob.Slno), 1);
      }
    }
      Object.assign(userViewFilters, { Month: arrayMonth });
      console.log('AFTER VAKL CHANGE, 2 ', userViewFilters);
    }

    if (e.target.name === "year") {
      type = "view user";
      Object.assign(userViewFilters, { Year: e.target.value });
      console.log('AFTER VAKL CHANGE, 3 ', userViewFilters);
    }
    if(tableRef.current) {
      if (e.target.name !== "UserFilter") tableRef.current.onQueryChange();

    }
      
  }; 
  const onChildClick= async (e)=>{
    if(e==='closeUser')
    {
      setAddUserOpen(false);
    }
  }
    
  const handleClose = (e) => {
    if (e === "delete") {
      setDeleteOpen(false);
      setUser(defaultUserValue);
    } 
    if(e==="resetPassword")
    {
      setResetPasswordOpen(false);
      setUser(defaultUserValue);
    }
  };
  const handleGridAction = (e, rowData) => {
    setUser({
      'firstName': rowData.FirstName,
      'Name': rowData.Name,
      'lastName': rowData.LastName,
      'CognitoUserID':rowData.CognitoUserID,
      'mobNumber': rowData.PhoneNumber,
      'Email': rowData.Email,
      'PropertyID': rowData.PropertyID,
      'Privilege': rowData.Privilege,
      'MasterID': rowData.MasterID
    }) 
    if (e === 'delete') {
      setDeleteOpen(true);
    } 
    if (e === 'reset') {
      setResetPasswordOpen(true);
    }
  };
  async function disableUser() {
    console.log("DISABLE USER ");     
    setIsLoading(true);
    user.userData = props.userSession;
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: user,
      queryStringParameters: {
        module: "user",
        op: "disableUser"
      }
    };
    console.log("PROPERTY OBJECT ", myInit);
    trackPromise(  
    API.post(apiName, path, myInit).then(response => {

      console.log("UPDATE USER", response);
      setDeleteOpen(false);
      setIsLoading(false);
      openSnackbar({
        message:
          response.message,
        variant: "success"
      });
      setIsLoading(false); 
      setUser(defaultUserValue);
      history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: "error" });
    }), areas.admin);
  }
  async function resetPassword() {    
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: {...user,userData: props.userSession,DomainName: MapData.aws_app_domain_prefix, firstName:user.FirstName, lastName: user.LastName },
      queryStringParameters: {
        module: 'user',
        op: 'resetPassword'
      }
    }; 
   await API.post(apiName, path, myInit).then(response => {  
      setResetPasswordOpen(false); 
      openSnackbar({
        message:
          response.message,
        variant: 'success'
      });
      setIsLoading(false); 
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err.message, variant: 'error' });
    });

  }
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h2"component = "h2">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  console.log("tableState", userTable);    
  return (
    <div className="home-root"> 
    <Dialog open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle className="delete-dialog-title" id="delete-dialog-title" onClose={e => handleClose("delete")}>
          Remove User From Tenant
        </DialogTitle>
        <DialogContent>
          {fromGrid ? <DialogContentText>
            {`${user.Email} will no longer be able to access this account.`}
          </DialogContentText> :
            <DialogContentText>
              You are about to delete your account.<br />
              If you confirm the deletion request, your data will be removed from our system immediately!
            </DialogContentText>}
        </DialogContent> 
        <DialogActions style={{ padding: '8px 24px' }} >
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                 onClick={e => disableUser()}
                isLoading={isLoading}
              >
                REMOVE
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('delete'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </Dialog>
    <Dialog classes={{ paper: 'dialog-paper' }} open={resetPasswordOpen} onClose={e => handleClose('resetPassword')} fullWidth
        aria-labelledby='form-dialog-title'>
        <DialogTitle className="delete-dialog-title" id='delete-dialog-title' onClose={e => handleClose('resetPassword')}>
          Reset password
        </DialogTitle>
        <DialogContent className='addtenant-dialog-content'>
          <DialogContentText color='textPrimary'>
            {user.Email} will have to change their password the next time they sign in or link a new device.
            <br /><br />
            They will also receive email in their inbox requesting to change the login. To proceed, please hit reset below.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }} >
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => resetPassword()}
                isLoading={isLoading}
              >
                RESET PASSWORD
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('resetPassword'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </Dialog>
    <AddTenant fromLease={false} userOpen={addUserOpen} userSession={props.userSession} onChildClick={onChildClick}/>
    <Grid
    container
    spacing={0}
    direction="column" 
    >

    <Grid item>
      <Container component="main" className="home-main">
      <CssBaseline />
      <Spinner className="common-spinner"
       area={areas.admin}
        />
      <div className="home-paper">       
        <div style={{ padding: "20px 0px 40px 0px" }}>

        <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
          history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
        }}>
          <Grid item>
            <Button>
              <div className="addlease-top-flex-container">
                <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
              </div>
            </Button>
          </Grid>
        </Grid>

        
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={10} md={10} lg={10}>
            {!isLoading && 
            <Typography
                component="h1"
                className="home-headerOne"
                variant="h1"
              >
                Tenant Management
              </Typography>
              }
              {isLoading && <Skeleton  variant="rect" width={545} height={69}/>}
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
            {isLoading && <div className={"skelton-user-page-flex-compo-grid"}>
            <Skeleton variant="rect" width={30} height={32} className = {"skelton-margin-right-5"}/>
            <Skeleton variant="rect" width={120} height={32}/>
             </div>}
             {!isLoading && <CsvExport  {...props} module="user" masterType="User"></CsvExport>}
            </Grid>
          </Grid>
        </div>
        <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                    { !isLoading &&
                      <Fab
                        size="small"
                        onClick={() => {
                        /*   history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/add`, props:{ isEditing: false,rowEditData:{ "PropertyID": [], "PassWord": "" },fromGrid:false }});    */                   
                        setAddUserOpen(true);
                        }}
                        color="secondary"
                        aria-label="add"

                      >
                        <Add className="home-linkIcon" />
                      </Fab>
                      }
                      { isLoading && <Skeleton variant="circle" width={40} height={40}/>}
                    </Grid>
                    <Grid item>
                    { !isLoading && 
                    <Typography
                      className="home-headerThree add-tenant-tag"
                      component="h5"
                      variant="h5"
                      onClick={() => {
                       /*  history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/add` ,props:{ isEditing: false,rowEditData:{ "PropertyID": [], "PassWord": "" },fromGrid:false }});                      
                        */
                       setAddUserOpen(true);
                      }}
                    >
                      ADD TENANT
                </Typography>
                }
                { isLoading && <Skeleton variant="rect" width={218} height={32}/>} 
                </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                {!isLoading && 
                <Typography
                    className="home-headerfour"
                    variant="subtitle1"
                  >
                  Manage all your tenant details here. You can add new tenants, edit the personal information, reset the login and review the transaction history.
                </Typography>
                }
                {isLoading && <Skeleton variant="rect" width={364} height={32}/>}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Grid container direction="row"  justify="center" alignItems="center" spacing={1}>
                  { !isLoading && 
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                    
                    </Grid>
                    }
                  
                  </Grid>
               </Grid>
              </Grid>
              <div style={{ padding: "20px 0px 40px 0px" }}>
              <Grid container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center">
                <Grid item  xs={12} sm={12} md={3} lg={3}>
                {!isLoading && <TextField
                        style={{ width: "100%" }}                     
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder="Filter email, name unit #"
                        name="UserFilter"
                        value={userViewFilters.UserFilter}
                        onChange={onFilterChange}
                        onKeyDown={onKeyPressFilter}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Link
                              className={"gridfilter-innersearch-bt"}>
                              <Search
                              onClick={onSearchFilter}
                              />
                              </Link>
                            </InputAdornment>
                          ),
                        }}

                      />}
                {isLoading && <Skeleton variant="rect" height={52}/>}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                {!isLoading &&
              <Tabs
                  value={userTabValue}
                  spacing={2}
                  onChange={handleUserTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  inkbarstyle={{background: '#6200EE'}}
                >                  
                <Tab label="ALL" {...a11yProps(0)} />
                <Tab label="ACTIVE" {...a11yProps(1)} />  
                <Tab label="INVITE SENT " {...a11yProps(2)} />    
                <Tab label="PASSWORD SET REQUIRED" {...a11yProps(3)} />     
                <Tab label="INACTIVE" {...a11yProps(4)} />            
                </Tabs>
                }
                {isLoading &&
                  <Tabs
                    spacing={2} 
                    color="primary"
                    inkbarstyle={{ background: '#6200EE' }}
                  >
                      <Skeleton  variant="rect"  height={41} className={"skelton-margin-right-6 skelton-grid-element-custom-100"}/>
                  </Tabs>
                  }
                </Grid>
                </Grid>
                <div className={isLoading ? "tenant-table-hide" : "tenant-table-show"}>
                <MaterialTable
                  tableRef={tableRef}
                  columns={[{ title: "TENANT", width:"20%", field: "Name", searchable: false },
                  { title: "EMAIL", width:"20%", field: "Email", searchable: false },
                  { title: "PROPERTY", width:"20%", field: "Property", searchable: false, hidden: true, render: rowData =>{ return propertyNameCombine(rowData.Property)} },
                  { title: "LAST ACTIVITY", width:"20%", field: "CreatedDate", searchable: false, render: rowData => <Moment format="YYYY-MM-DD">{rowData.LastLogin?rowData.LastLogin:rowData.CreatedDate}</Moment> },
                  { 
                    title: "STATUS", 
                    field: "LeaseActivity", 
                    searchable: false,
                    width:"20%",
                    render: (rowData, index) =>  <TextField  variant="outlined"  inputProps={{min: 0, style: { textAlign: 'center' }}} disabled= {true} className={RenderUserStatus(rowData) == 'Inactive' ? "tenant-grid-tf-dsbl" : "tenant-grid-tf"} value = {RenderUserStatus(rowData)} > </TextField>
          
                  }]}
                  components={{
                    Row: props => {
                      const propsCopy = { ...props };
                      propsCopy.actions.find(a => a.name === 'edit').disabled = propsCopy.data.UserStatus==='Deleted';
                      propsCopy.actions.find(a => a.name === 'delete').disabled =  propsCopy.data.UserStatus==='Deleted';
                      propsCopy.actions.find(a => a.name === 'lock').disabled = propsCopy.data.UserStatus==='Deleted';
                      return <MTableBodyRow {...propsCopy} />
                    }
                  }}
                  data={async query=> new Promise((resolve, reject) => { 
                    GetPagingData(query) 
                      .then(result => { 
                        resolve({
                          data: result.data,
                          page: result.page,
                          totalCount: result.total
                        })
                      })
                    
                  })}
                  onRowClick={(event, rowData) => {history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/view`,props:{ rowData:{...rowData}}});}}
                  options={{
                    searchFieldStyle: {
                      display: "none"
                    },
                    actionsCellStyle: {
                      display:"flex", 
                      justifyContent: "center",
                      padding: "16px",
                      width: "100%"
                    },
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: true,
                    pageSize:pageSize,
                    pageSizeOptions: [5,25, 50, 100, 200]
                  }}
                  actions={[
                    {
                      name: 'edit',
                      icon: "edit",
                      tooltip: "Edit",
                      onClick: (event, rowData) => {
                        console.log("edit user");
                        history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/edit`,props:{ isEditing: true,rowEditData:rowData,fromGrid:true }});
                      }
                    }, 
                    { 
                      name: 'delete',
                      icon: 'delete',
                      tooltip: 'Delete',
                      onClick: (event, rowData) => {
                        console.log('edit user');
                        handleGridAction('delete',rowData);
                      }
                    },
                    { 
                      name: 'lock',
                      icon: 'lock',
                      tooltip: 'Reset password',
                      onClick: (event, rowData) => {
                        console.log('edit user');
                        handleGridAction('reset',rowData);
                      }
                    },
                    {
                        icon: () => <Visibility />,
                        tooltip: 'View Transactions',
                        onClick: (event, rowData) => {history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/view`,props:{ rowData:{...rowData}}});}
                        
                    }
                  ]}
                />
                </div>

                <div className={!isLoading ? "tenant-table-hide" : "tenant-table-show"}>
                <MaterialTable
                  columns={[{ title: "", width:"100%", render: rowData =>{ return <Skeleton variant="text" />}}]}
                  data={[{},{},{},{},{}]}
                  options={{
                    searchFieldStyle: {
                      display: "none"
                    },
                    actionsColumnIndex: -1,
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: true,
                    pageSize:25,
                    pageSizeOptions: [25]
                  }}
                  /* actions={[
                    {
                      icon: "edit"
                    }
                  ]} */
                  components={{
                    OverlayLoading: props => {
                        console.log("OVERLAYLOADING props", props);
                        return <div />
                    },
                     /*  Actions: props => {
                     return <div className={"skelton-action-parent-div"}><Skeleton className={"skelton-action"}/></div>
                    }   */
                   }}
                   localization={{
                     header : {
                        actions: ''
                     }
                   }}
                />
                </div>
              </div>     
      </div>
      </Container>
      </Grid>
      </Grid>
      </div>
      
  );                
}

