import React, { useRef, useState } from "react"; 
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl"; 
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoaderButton, { LoaderCLickButton } from "../LoaderButton"; 
import usePersistedState from "../../usePersistedState"; 
 
export function PlaidKeyManage(props) {
  console.log("ADD NEW PROPERTY PROPS ", props); 
  const [isEditing, setIsEditing] = usePersistedState("isEditing", false);
  const [plaidproperty, setPlaidproperty] = useState({ 
    MasterID: ""
    ,PlaidApiMode:""
    ,PlaidSecretKey:""
    ,PlaidClientKey:""  
  }); 
  const inputLabel = useRef(null); 
  const [plaidOpen, setPlaidOpen] = useState(false);   
  const [labelWidth, setLabelWidth] = useState(0); 
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  React.useEffect(() => {
    setPlaidOpen(props.plaidOpen);
    setIsEditing(props.isEditing);
    setPlaidproperty(prvproperty => { return { ...prvproperty,MasterID:props.MasterID, PlaidClientKey:props.PlaidClientKey,
      PlaidClientKey: props.PlaidClientKey,PlaidApiMode:props.PlaidApiMode}});
  }, [props]);
  React.useEffect(() => {
      if(inputLabel.current)
        setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const DialogTitle =  (props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className="addnew-user-root" {...other}>
        <Typography variant="h2"component = "h2">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className="addnew-user-close-button" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  function validateForm() {
    console.log("plaidproperty",plaidproperty);
    return ( 
     plaidproperty.PlaidClientKey && plaidproperty.PlaidClientKey.length > 0 && 
     plaidproperty.PlaidSecretKey && plaidproperty.PlaidSecretKey.length > 0       
    );
  }
 
  const handleClose = (e) => {
    setPlaidOpen(false);
    eventChange("plaid close");
  };

  function onChange(e, inputProps) {
    console.log("plaidproperty onChange", e.target);
    setPlaidproperty({ ...plaidproperty, [e.target.name]: e.target.value });
  }  
  function eventChange(type) {
    console.log("event change, type",type);
    if(type==="plaid add")
     {
        props.eventChange(type,plaidproperty);
        setPlaidOpen(false);
      }
    else 
      props.eventChange(type);
  }
  return (
    <>
      <Dialog
        open={plaidOpen}
        onClose={(e) => handleClose()}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={(e) => handleClose()}
        >
          {!isEditing ? "Add Plaid Keys" : "Edit Plaid Key"}
        </DialogTitle>
        <DialogContent>  
          <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="PlaidClientKey"
                            value={plaidproperty.PlaidClientKey}
                            label={isEditing ? "New plaid client key" : "Plaid client key"}
                            onChange={onChange}
                            name="PlaidClientKey"
                            autoComplete="PlaidClientKey"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="PlaidSecretKey"
                            value={plaidproperty.PlaidSecretKey}
                            label={isEditing ? "New plaid secret key" : "Plaid secret key"}
                            onChange={onChange}
                            name="PlaidSecretKey"
                            autoComplete="PlaidSecretKey"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel ref={inputLabel} id="select-plaid-label">
                           PLAID API MODE
                          </InputLabel>
                          <Select
                            labelid="select-plaid-label"
                            id="select-plaid-outlined"
                            fullWidth
                            variant="outlined"
                            name="PlaidApiMode"
                            label="PLAID API MODE"
                            value={plaidproperty.PlaidApiMode?plaidproperty.PlaidApiMode:'Sandbox'}
                            labelWidth={labelWidth}
                            onChange={(e) => { onChange(e); }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem  value={"Sandbox"}>
                                  {"Sandbox"}
                            </MenuItem>
                            <MenuItem  value={"Development"}>
                                  {"Development"}
                            </MenuItem>
                            <MenuItem  value={"Production"}>
                                  {"Production"}
                            </MenuItem>
                          </Select>
                        </FormControl> 
                        </Grid>
                      </Grid> 
        
        </DialogContent>
        <DialogActions>
        <LoaderButton
                        block="true"
                        type="submit"
                        size="large" 
                        disabled={!validateForm()}
                        onClick={()=> eventChange('plaid add')}
                      >
                        {isEditing ? "EDIT" : "+ ADD"}
                      </LoaderButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
