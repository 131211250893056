import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import LoaderButton from "../LoaderButton"; 
import { Link } from "react-router-dom"; 
import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { S3Image } from 'aws-amplify-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];
const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        justify: "center",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    header: {
        fontSize: 20,
        fontStyle: "bold",
        fontFamily: "Arial-BoldMT, Arial"
    },
    myImage: {
        width: "100%"
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: 200,
    },
    welcomeText: {
        fontSize: "20px",
    }

}));
export function RegistrationConfirmation(props) { 
    return (
        <div width="100%">
            <>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Container component="main" maxWidth="md">
                            <CssBaseline />
                            <div className="forgot-password-paper">
                                <Grid container justify="center" alignItems="center" direction="column" spacing={1}>
                                    <Grid item>
                                        {MapData.management_logo && MapData.management_logo != '' ? <S3Image level="public" imgKey={MapData.management_logo} /> :
                                            <Typography className='managment-header' component="h2" variant="h2" >
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className={'page-content-underline'}>
                                        <Avatar style={{ backgroundColor: '#0AE20A', color: '#fff', fontSize: '54px' }} >
                                            <CheckIcon />
                                        </Avatar>
                                        </div> 
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-header" component="h5" variant="h5">
                                        Registration is almost complete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-body" gutterBottom={true} variant="h5">
                                        {`Your email, ${props.email} has been successfully registered. `}
                                        <br/>
                                        <br/>
                                        Please confirm your email address by clicking the link in the confirmation email we have sent to your inbox.
                                        <br/>
                                        <br/>
                                        Once confirmed, one of our team member will provide you access.
                                        </Typography>
                                    </Grid> 
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ padding: "20px 0px 40px 0px" }}>
                                            <MLink
                                                color="textSecondary"
                                                underline={"always"}
                                                component={Link}
                                                to="/"
                                            >
                                                <Typography
                                                    color="textSecondary"
                                                    gutterBottom={true}
                                                    variant="body2" 
                                                >
                                                    &#8249; {"BACK"}
                                                </Typography>
                                            </MLink> 
                                        </div>

                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <PoweredBy />
                <Policy class={"policy-cls"} />
            </>
        </div>
    );
}