import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}
const styles = makeStyles(theme => ({
  
    lightTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    arrowPopper: arrowGenerator(theme.palette.grey[700]),
    arrow: {
      position: 'absolute',
      fontSize: 6,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    bootstrapPopper: arrowGenerator(theme.palette.common.black),
    bootstrapTooltip: {
      backgroundColor: theme.palette.common.black,
    },
    bootstrapPlacementLeft: {
      margin: '0 8px',
    },
    bootstrapPlacementRight: {
      margin: '0 8px',
    },
    bootstrapPlacementTop: {
      margin: '8px 0',
    },
    bootstrapPlacementBottom: {
      margin: '1px 0',
    },
    htmlPopper: arrowGenerator('#dadde9'),
    htmlTooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      '& b': {
        fontWeight: theme.typography.fontWeightMedium,
      },      
    },
    button:{
        float:"left"
    }
  })); 
 
export default function CustomizedTooltips({
    content,
    ...props
  }) {
    const classes = styles();  
    const [arrowRef, setArrowRef] = React.useState(null);
 
    const handleArrowRef= (node) => {
        setArrowRef(node);        
      };   
    return (
        <div>
        <Tooltip
             title={
               <React.Fragment>
                 {content}
                 <span className={classes.arrow} ref={handleArrowRef} />
               </React.Fragment>
             }
             placement='top'
             classes={{
               tooltip: classes.bootstrapTooltip,
               popper: classes.bootstrapPopper,
               tooltipPlacementLeft: classes.bootstrapPlacementLeft,
               tooltipPlacementRight: classes.bootstrapPlacementRight,
               tooltipPlacementTop: classes.bootstrapPlacementTop,
               tooltipPlacementBottom: classes.bootstrapPlacementBottom,
             }}
             PopperProps={{
               popperOptions: {
                 modifiers: {
                   arrow: {
                     enabled: Boolean(arrowRef),
                     element: arrowRef,
                   },
                 },
               },
             }}
           >
               <ErrorIcon className={classes.button}/>               
           </Tooltip>     
         </div>
    );
  }