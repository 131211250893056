import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom"; 
import Grid from "@material-ui/core/Grid"; 
import { makeStyles, createStyles } from "@material-ui/core/styles"; 
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"; 
import TextField from "@material-ui/core/TextField"; 
import usePersistedState from "../../usePersistedState";
import usePersistedStateString from "../../usePersistedStateString"; 
const MapData = window['countryConfig']; 
const useStyles = makeStyles(theme =>
    createStyles({
        "@global": {
            body: {
                backgroundColor: theme.palette.common.white
            }
        },
        root: {
            flexGrow: 1
        },
        paper: {
            marginTop: theme.spacing(8),
            flexDirection: "column",
            alignItems: "center",
            /*   minHeight: "600px", */
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        },
        card: {
            display: "flex",
            /* padding: theme.spacing(2, 2,3, 2)  */
        },
        details: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            /*  width:"-moz-available" */
        },
        content: {
            flex: "1 0 auto"
        },
        cover: {
            width: "170px",
            height: "170px"
        },
        controls: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1)
        },
        headerOne: {
            color: "rgba(0,0,0,1)",
            fontSize: "60px"
        },
        headerTwo: {
            color: "rgba(123,123,123,1)",
            fontSize: "60px"
        },
        headerThree: {
            color: "rgba(0,0,0,1)",
            fontSize: "22px"
        },
        headerfour: {
            color: "rgba(123,123,123,1)",
            fontSize: "20px"
        },
        headerFive: {
            color: "#7c7c7c",
            fontSize: "40px"
        },
        headerSix: {
            color: "rgba(0,0,0,1)",
            fontSize: "22px",
            fontWeight: "bold"
        },
        headerSeven: {
            color: "#7c7c7c",
            fontSize: "14px"
        },
        thankYouHTwo: {
            color: "rgba(123,123,123,1)"
        },
        thankYouHThree: {
            color: "rgba(123,123,123,1)",
            fontSize: "16px",
        },
        thankYouHFour: {
            color: "rgba(0,0,0,1)",
            borderLeft: "6px solid #ffe100",
            paddingLeft: "7px",
        },

        filterProperty: {
            margin: theme.spacing(1),
            minWidth: 220,
            width: 564
        },
        filterUType: {
            margin: theme.spacing(1),
            minWidth: 120,
            float: "left"
        },
        filterLabel: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(4),
            width: "56px",
            color: "rgba(123,123,123,1)",
            fontSize: "17px",
            float: "left"
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        papers: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary
        },
        cards: {
            display: "flex",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
        },
        rentCardClick: {
            display: "flex",
            width: "100%",
            background: "#3f51b52e",
            border: "1px solid #3f51b5",
            borderRadius: "5px",
            /*  opacity:"0.8", */
            boxShadow: "none",
        },
        rentCard: {
            display: "flex",
            width: "100%",
            border: "1px solid #DADADA",
            borderRadius: "5px"
        },
        f: {
            display: "flex",
            width: "100%",
            border: "1px solid #DADADA",
            borderRadius: "5px",
            boxShadow: "none"
        },
        rentCardSelect: {
            display: "flex",
            width: "100%",
            border: "1px solid #6200EE",
            borderRadius: "5px"
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(3)
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "36%",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest
            })
        },
        expandOpen: {
            transform: "rotate(180deg)"
        },
        payImg: {
            width: "100%",
            height: "100%"
        },
        payCardCont: {
            padding: "8px"
        },
        main: {
            maxWidth: "100% !important"
        },
    })
);

export default function CardForm(props) {
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();
    console.log("Renter home props", props);   
    const [carDetails, setCardDetails] = usePersistedState("carDetails", ""); 
    const [labelWidth, setLabelWidth] = useState(0); 
    const [cardData, setCardData] = useState({
        MasterID: "",
        NameOnCard: "",
        BillingAddress: "",
        Country: "",
        PostalCode: "",
        CardNumber: "",
        CvvCid: "",
        ExpiryMonth: "",
        ExpiryYear: "",
        UserName: props.userSession.UserName,
        RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName
    }); 
    const year = (new Date()).getFullYear();
    const [expYear] = useState(Array.from(new Array(20), (val, index) => (index + year).toString()));
    const [expMonth] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
    const inputLabel = useRef(1); 
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []); 
    function onChange(e, child) {
        setCardDetails({});
        setCardData({ ...cardData, [e.target.name]: e.target.value });
        props.formChange('card',{name:e.target.name,value:e.target.value},child);
    }
    
    return (
        <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="NameOnCard"
              label="NAME ON CARD"
              onChange={onChange}
              name="NameOnCard"
              autoComplete="NameOnCard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="BillingAddress"
              label="BILLING ADDRESS"
              onChange={onChange}
              name="BillingAddress"
              autoComplete="BillingAddress"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel ref={inputLabel} id="select-state-label">
                COUNTRY/REGION
              </InputLabel>
              <Select
                labelid="select-Country-label"
                id="Country-select-outlined"
                fullWidth
                variant="outlined"
                name="Country"
                label="COUNTRY/REGION"
                value={cardData.Country}
                labelWidth={labelWidth}
                onChange={onChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {MapData.map((e, keyIndex) => {
                  return (
                    <MenuItem key={keyIndex} value={e.name}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="PostalCode"
              label="BILLING ZIP/POSTAL CODE"
              onChange={onChange}
              name="PostalCode"
              autoComplete="PostalCode"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="CardNumber"
              label="CARD NUMBER"
              onChange={onChange}
              name="CardNumber"
              autoComplete="CardNumber"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="CvvCid"
              label="CVV/CID"
              onChange={onChange}
              name="CvvCid"
              autoComplete="CvvCid"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <InputLabel id="combo-outlined-label">
                  EXPIRATION
              </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="ExpiryMonth-outlined-label">
                    MM
                   </InputLabel>
                  <Select
                    id="ExpiryMonth-select-outlined"
                    fullWidth
                    variant="outlined"
                    name="ExpiryMonth"
                    label="MM"
                    labelWidth={labelWidth}
                    value={cardData.ExpiryMonth}
                    onChange={onChange}

                  >
                    {expMonth.map((e, keyIndex) => {
                      return (
                        <MenuItem
                          key={keyIndex}
                          value={e}
                        >
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="ExpiryYear-outlined-label">
                    YYYY 
                  </InputLabel>
                  <Select
                    id="ExpiryYear-select-outlined"
                    fullWidth
                    variant="outlined"
                    name="ExpiryYear"
                    label="YYYY"
                    value={cardData.ExpiryYear}
                    labelWidth={labelWidth}
                    onChange={onChange}
                  >
                    {expYear.map((e, keyIndex) => {
                      return (
                        <MenuItem
                          key={keyIndex}
                          value={e}
                        >
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
}