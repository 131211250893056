import React, { useState,useEffect,useRef } from "react";
import CssBaseline from "@material-ui/core/CssBaseline"; 
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"; 
import Container from "@material-ui/core/Container"; 
import { API } from "aws-amplify";
import MaterialTable from "material-table";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"; 
import Moment from 'react-moment'; 
import CsvExport from '../CsvExport';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText'; 
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import { useHistory } from "react-router-dom";
import { Skeleton } from '@material-ui/lab';
import usePersistedState from '../../usePersistedState';
import CustomizedTooltips from '../../lib/tooltIp'; 
import Button from '@material-ui/core/Button';

const tableRef = React.createRef();

export function ViewPaymentHistory(props) { 
    let history = useHistory(); 

    const [tableState, setTableState] =useState( []); //useState({});
    const [exportAvailable, setExportAvailable] = React.useState(false);  
    const [userViewFilters, setuserViewFilters] = React.useState({"MonthValue":[], "Month": [], "Year": [] });
    const [isLoading, setIsLoading] = useState(false);
    const transComboMonths = [
        {
            "Slno": 1,
            "Name": "January"
        },
        {
            "Slno": 2,
            "Name": "February"
        },
        {
            "Slno": 3,
            "Name": "March"
        },
        {
            "Slno": 4,
            "Name": "April"
        },
        {
            "Slno": 5,
            "Name": "May"
        },
        {
            "Slno": 6,
            "Name": "June"
        },
        {
            "Slno": 7,
            "Name": "July"
        },
        {
            "Slno": 8,
            "Name": "August"
        },
        {
            "Slno": 9,
            "Name": "September"
        },
        {
            "Slno": 10,
            "Name": "October"
        },
        {
            "Slno": 11,
            "Name": "November"
        },
        {
            "Slno": 12,
            "Name": "December"
        }

    ]; 
    const [labelWidth, setLabelWidth] = useState(0);
    const [labelWidth1, setLabelWidth1] = useState(0);
    const inputLabel = useRef(null);
    const inputLabel1 = useRef(null);
    const [proeprtyData, setProeprtyData] = usePersistedState('proeprtyData', {});
    const [selectedProperty] = usePersistedState('selectedProperty', {});
  
    useEffect(() => {
        if(inputLabel.current)
        {
          setLabelWidth(inputLabel.current.offsetWidth);
        }
        if(inputLabel1.current)
        {
          setLabelWidth1(inputLabel1.current.offsetWidth);
        }
      },[inputLabel.current,inputLabel1.current])

    useEffect(() => {
        let isSubscribed = true; 
        const userFetch=async ()=> {            
           if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed){     
            trackPromise(         
                GetTableData(selectedProperty.PropertyID)
                .then(async tablData => {
                  if(isSubscribed)
                  {    
                    await setTableState(tablData);     
                  }        
                })           
                .catch(er=>{ throw er}),
                areas.renter,
                )
           }
          return () => isSubscribed = false
        }
        userFetch();
      }, [userViewFilters,selectedProperty]);  
      const createHeaderWithTooltip = (title, tooltip) => {
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            > <Grid item>
                    <Typography className="biller-view-trans-tooltipText">{title}</Typography>
                </Grid>
                <Grid item>
                    <CustomizedTooltips content={tooltip} />
                </Grid>
            </Grid>
        );
      };
    async function GetTableData(PropertyID) {       
        let columns = [],
            masterType = "",
            apiModule = "",
            op = "";
        let body = {}; 
        columns.push(
            { title: "TRANSACTION DATE", field: "TransactionDate", searchable: false, render: rowData => <Moment format="MMM DD YYYY">{rowData.TransactionDate}</Moment> },
          /*   { title: "PROPERTY", field: "TransactionPropertyName", searchable: false }, */
            { title: "RENT MONTH", field: "TransactionRentMonth", searchable: false },
            { title: "TRANSACTION TYPE", field: "TransactionType", searchable: false, render: rowData => { return rowData.TransactionType  ? (rowData.TransactionType + "").toUpperCase() : "" } },
            { title: "DEPOSIT", field: "TransactionDeposit", searchable: false, width:"5%", render: rowData => { return rowData.TransactionDeposit === 0 ? "-" : "$" + rowData.TransactionDeposit } },
            { title: "MISC", field: "TransactionMiscValue", searchable: false,width:"5%", render: rowData => { return rowData.TransactionMiscValue === 0 ? "-" : "$" + rowData.TransactionMiscValue } },
            //{ title: "TAX", field: "TransactionTaxAmount", searchable: false,width:"5%", render: rowData => { return rowData.TransactionTaxAmount === 0 ? "-" : "$" + rowData.TransactionTaxAmount.toFixed(2) } },
            { title: "RENT", field: "TransactionRent", searchable: false,width:"5%", render: rowData => { return rowData.TransactionRent === 0 ? "-" : "$" + rowData.TransactionRent } },
            { title: createHeaderWithTooltip('FEE (TENANT)', 'Payment gateway processing fee paid by the tenant'),sorting: false, field: "ConvenienceFee", searchable: false, render: rowData => { return (!rowData.ConvenienceFee || rowData.ConvenienceFee === 0) ? "-" : "$" + rowData.ConvenienceFee } },
            { title: "TOTAL", field: "TransactionTotal", searchable: false,width:"5%", render: rowData => { return rowData.TransactionTotal === 0 ? "-" : "$" + rowData.TransactionTotal } },
        );
        masterType = "Transaction";
        apiModule = "renter";
        op = "viewTransactions";
        body = {
            masterType: masterType,
            UserID: props.userSession.UserName,
            PropertyID:PropertyID
        };    
        if (userViewFilters && userViewFilters.hasOwnProperty("Month") && userViewFilters.Month.length > 0) {
            body.Month = userViewFilters.Month;
        } 
        if (userViewFilters && userViewFilters.hasOwnProperty("Year") && userViewFilters.Year.length > 0) {
            body.Year = userViewFilters.Year;
        }
        var tableData = {
            columns: columns,
            data: []
        };
        body.userData = props.userSession;
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
                headers: { "Content-Type": "application/json" },
                body: body,
                queryStringParameters: {
                    module: apiModule,
                    op: op
                }
            };
            API.post(apiName, path, myInit)
                .then(response => {
                    setIsLoading(false);
                    tableData.data = response.data;
                    console.log("tableData", tableData);
                    setExportAvailable(response.data.length > 0?true:false); 
                    resolve(tableData);
                })
                .catch(err => {
                    console.log(err); 
                    reject();
                });
        });
    }
    function onFilterChange(e, child) { 
        if (e.target.name === "month") { 
            let arrayMonth = userViewFilters.Month; 
            if (!arrayMonth.some((each) => each.Slno === child.props.ob.Slno)) {
                arrayMonth.push(child.props.ob);
            }
            else {
                arrayMonth.splice(arrayMonth.map((mapp) => mapp.Slno).indexOf(child.props.ob.Slno), 1);
            } 
            Object.assign(userViewFilters, { Month: arrayMonth,MonthValue: e.target.value }); 
        }

        if (e.target.name === "year") {
            Object.assign(userViewFilters, { Year: e.target.value }); 
        } 
        if (e.target.name === "month") setuserViewFilters({ ...userViewFilters, "MonthValue": e.target.value });

        if (e.target.name === "year") setuserViewFilters({ ...userViewFilters, "Year": e.target.value }); 
    } 
    const min = new Date('2018-01-01').getFullYear();
    const max = new Date().getFullYear();
    const comboYear = [];
    for (var i = min; i <= max; i++) {
        comboYear.push(i)
    }
    return (
        <div className={"home-root"}> 
            <Grid
                container
                spacing={0}
                direction="column">
                <Grid item>
                    <Container component="main"  className="home-main">
                        <CssBaseline />
                       {/*  <Spinner className="common-spinner"
                            area={areas.renter}
                        /> */}
                        <div className="home-paper">
                        <Grid container spacing={1} className={"add-padding-p8p"} onClick={e => {
                            history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                        }}>
                            <Grid item>
                            <Button>
                                <div className="addlease-top-flex-container">
                                <div className="addlease-to-managerent-lbl">&#8249; {" RETURN"}</div>
                                </div>
                            </Button>
                            </Grid>
                        </Grid>


                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="space-between"
                                alignItems="flex-start">

                                <Grid item xs={6}>
                                {!isLoading && 
                                    <Typography
                                        component="h1"
                                        className={"renter-headerOne fontweight-401"}
                                        variant="h1"
                                    >
                                        Billing History
                                </Typography>
                                }
                                {isLoading && <Skeleton  variant="rect" width={350} height={69}/>}
                                </Grid>
                                <Grid item xs={6}>
                                 
                                  {
                                  exportAvailable?  
                                  <div> 
                                      {isLoading && <div className={"skelton-viewtrans-page-flex-compo-grid-csv"}>
                                                <Skeleton variant="rect" width={30} height={32} className = {"skelton-margin-right-5"}/>
                                                <Skeleton variant="rect" width={120} height={32}/>
                                        </div>}
                                        { !isLoading && <CsvExport {...props} tableReference={tableRef}  module="biller" masterType="ViewTransaction" UserID={props.userSession.UserName} PropertyID={selectedProperty.PropertyID} ></CsvExport>}
                                  
                                  </div>
                                  :
                                  <div> 
                                      {isLoading && <div className={"skelton-viewtrans-page-flex-compo-grid-csv"}>
                                                <Skeleton variant="rect" width={30} height={32} className = {"skelton-margin-right-5"}/>
                                                <Skeleton variant="rect" width={120} height={32}/>
                                        </div>}
                                  
                                  </div>
                                    }
                                </Grid>
                            </Grid>


                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={12}>
                                { !isLoading && 
                                    <Typography  className={"renter-headerfour"} 
                                    variant="subtitle1"
                                    >
                                        A complete history of your account's billing activity.
                                </Typography>
                                }
                                { isLoading && <Skeleton variant="text" width={810} height={32}/>}
                                </Grid>

                                <Grid item xs={12}>
                                <div className={"skelton-flex-parent skelton-view-trans-filter skelton-billing-history-filter skelton-billing-history-filter-par skelton-margin-right-14"}>
                                { !isLoading &&
                                    <Typography className={"renter-filterLabel"}
                                        component="h5"
                                        variant="h5"
                                    >
                                        FILTER
                                     </Typography>
                                     }
                                     
                                     { isLoading && <Skeleton variant="rect" width={64} height={32} className={"skelton-billing-history-filter-txt"}/>} 
                                     </div>
                                     <div style={{display: "flex", float: "left"}}>
                                     { !isLoading &&
                                    <FormControl variant="outlined" className={"renter-filterUType"}>
                                        <InputLabel ref={inputLabel1}
                                            id="select-outlined-label"
                                        >
                                            MONTH
                                         </InputLabel>
                                        <Select
                                            labelid="select-outlined-label"
                                            id="property-select-outlined"
                                            multiple
                                            // value={user && user.PropertyID ? user.PropertyID.map(eachP => eachP.PropertyName) : []}
                                            value={userViewFilters.MonthValue}
                                            onChange={(e, child) => { onFilterChange(e, child) }}
                                            variant="outlined"
                                            name="month"
                                            // input={<Input />}
                                            renderValue={selected => selected.join(", ")}//selected.join(', ')}
                                            //MenuProps={MenuProps}
                                            labelWidth={labelWidth1}
                                        >
                                            <MenuItem value="">
                                                <em>MM</em>
                                            </MenuItem>
                                            {transComboMonths.map((eachMap, keyIndex) => {
                                                return (
                                                    <MenuItem key={keyIndex} value={eachMap.Name} ob={{ value: eachMap.Name, Slno: eachMap.Slno }}>
                                                        <Checkbox checked={userViewFilters.MonthValue.indexOf(eachMap.Name) > -1} />
                                                        {eachMap.Slno + "-" + eachMap.Name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    }
                                    { isLoading && <Skeleton variant="rect" width={143} height={61} className={"skelton-billing-history-filter skelton-margin-right-14 skelton-filter-cus"}/>}
                                    </div>
                                    { !isLoading && 
                                    <FormControl variant="outlined" className={"renter-filterUType"}>
                                        <InputLabel ref={inputLabel} id="select-outlined-label"> Year</InputLabel>
                                        <Select
                                            labelid="select-outlined-label"
                                            id="property-select-outlined"
                                            multiple
                                            // value={user && user.PropertyID ? user.PropertyID.map(eachP => eachP.PropertyName) : []}
                                            value={userViewFilters.Year}
                                            onChange={(e) => { onFilterChange(e) }}
                                            variant="outlined"
                                            name="year"
                                            // input={<Input />}
                                            renderValue={selected => selected.join(", ")}//selected.join(', ')}
                                            //MenuProps={MenuProps}
                                            labelWidth={labelWidth}
                                        >
                                            <MenuItem value="">
                                                <em>YYYY</em>
                                            </MenuItem>
                                            {comboYear.map((x, ind) => {
                                                return (
                                                    <MenuItem key={ind} value={x}>
                                                        <Checkbox checked={userViewFilters.Year.indexOf(x) > -1} />
                                                        <ListItemText primary={x} />
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    }
                                    { isLoading && <Skeleton variant="rect" width={143} height={61} className={"skelton-billing-history-filter skelton-margin-right-14 skelton-filter-cus"}/>}
                                </Grid>
                            </Grid>
                            <div 
                            //style={{ padding: "20px 0px 40px 0px" }}
                            className={isLoading ? "property-table-hide" : "property-table-show"}
                            >
                                <MaterialTable
                                    tableRef={tableRef}
                                    title=""
                                    columns={tableState.columns}
                                    data={tableState.data} 
                                    options={{
                                        searchFieldStyle: {
                                            display: 'none'
                                        },
                                        actionsColumnIndex: -1,
                                        pageSize: 25,
                                        pageSizeOptions: [25, 50, 100, 200]
                                    }}
                                />
                            </div>

                            <div 
                            //style={{ padding: "20px 0px 40px 0px" }}
                            className={!isLoading ? "property-table-hide" : "property-table-show"}
                            >
                                <MaterialTable
                                    title=""
                                    columns={[{ title: "", width:"100%", render: rowData =>{ return <Skeleton variant="text" />}}]}
                                    data={[{},{},{},{},{}]}

                                    options={{
                                        searchFieldStyle: {
                                            display: 'none'
                                        },
                                        actionsColumnIndex: -1,
                                        pageSize: 25,
                                        pageSizeOptions: [25]
                                    }}
                                />
                            </div>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </div>
    )
}
