import React, { Component, useState, setState } from "react";
//import awsconfig from '../../aws-exports';
import Amplify, { Storage , API } from 'aws-amplify';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import { openSnackbar } from '../Notifier';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { S3Image } from 'aws-amplify-react';
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomizedTooltips from '../../lib/tooltIp';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from "@material-ui/core/CardContent";
import StripeLogo from "../../static/images/Stripe_Logo.svg"
import SvgIcon from '@material-ui/core/SvgIcon';
const MapData =window['countryConfig']; 

const ImpStyles = makeStyles(theme => ({
  card1: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"215px",
    maxWidth:"215px"
  },
  card2: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"315px",
    maxWidth:"315px"
  },
  details: {
    display: "flex"   
  },
  cover: {
    
  },
}));


const useStyles = makeStyles((theme) => createStyles({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {    
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.

    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  myImage: {
    width: "100%"
  },
  input: {
    display: 'none',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    wordWrap:"nowrap",
    paddingLeft: "9px"
   },
}));
const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}


export default function Property(props) {
  
  const classes = useStyles();  
  const inputSize= props.fromRent? 12:6;
  const [property, setProperty] = useState(props.rowEditData != null ? {... props.rowEditData,StripePublicKey: ""
  , StripeSecretKey: "" } : {
    PropertyName: ""
    , MasterID: ""
    , OwnerFirstName: ""
    , OwnerLastName: ""
    , Email: ""
    , PropertyUnit: ""
    , BusinessAddress: ""
    , City: ""
    , State: ""
    , Country: ""
    , PostalCode: ""
    , Phone: ""
    , WebSite: ""
    , StripePublicKey: ""
    , StripeSecretKey: ""
    , PropertyLogo:{}
    ,PropertyImage:{}
    ,OmitTax:true
    ,tax:0
    ,CurrencyName:""
    ,CurrencyCode:"",
    IncludeStripeFee:true
  })
  const [deltedKey]=useState([]);
  const [fromRent] = useState(props.hasOwnProperty("fromRent") ? props.fromRent : false);
  const [editing] = useState(props.isEditing);
  const [stripeEditMode,setStripeEditMode]=useState(props.isEditing?false:true);
  const [isLoading, setIsLoading] = useState(false);
  const [comboDisable, setcomboDisable] = useState(true); 
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [passOpen, setpassOpen] = React.useState(false);
  const [omitTax,setOmitTax]=useState((props.isEditing && props.rowEditData && props.rowEditData.hasOwnProperty("OmitTax")?props.rowEditData.OmitTax:true));
  const [includeStripeFee,setIncludeStripeFee]=useState((props.isEditing && props.rowEditData && props.rowEditData.hasOwnProperty("IncludeStripeFee")?props.rowEditData.IncludeStripeFee:true));
  
  
  const [stateData,setStateData]=useState([]);
  const [isStateSelect,setIsStateSelect]=useState(false);
  
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    /*  if (!initialized) {
       //getMaster("State");        
     } */
  }, []);
  React.useEffect(function effectFunction() {
    if (!isStateSelect && property.Country!=="") {
      const result = MapData.filter((country) => country.name === property.Country);
      if(result.length>0)
      {
        console.log("use effect country"+property.Country,result[0].states)
        setStateData(result[0].states);
        setcomboDisable(false);  
        setIsStateSelect(true);
      }
    }
  }, []); 
  const ImageComponent = props => {
    const classes = ImpStyles();
    return (<div style={{paddingTop:"30px"}}>
        <Card  className={props.Type==="PropertyLogo"?classes.card1:classes.card2}>
             <div className={classes.details}>             
               <CardActions>
                    <Fab  onClick={() =>imageDelete(props)} style={{ color:"#000000", background:"none",boxShadow:"none",  }} size="small"  aria-label="delete picture" component="span">
                    <CloseSharpIcon />
                  </Fab>
               </CardActions>
             </div>                          
             <CardMedia
               className={classes.cover}>
               <S3Image theme={{ photoImg: { maxWidth: props.Type==="PropertyLogo"?'159px':'259px', maxHeight:props.Type==="PropertyLogo"?'159px':"259px",display: "flex",marginRight: "auto",padding: "14px" } }} level="public" imgKey={props.FileKey} />
             </CardMedia>
           </Card>  
    </div>);
  };
  const StripeDisplayCard = props => { 
    return (
       <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card className="stripe-key-card">
          <CardContent className="stripe-key-card-content">
          <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            > 
              <Grid item xs={2}>
              
             <img src={StripeLogo} alt=""  height="24" />
           
              </Grid>
             <Grid item xs={3}>
            <Typography>
             {"API KEY:"}
            </Typography>
            </Grid>
            <Grid item xs={7}>
            <Typography>
             {props.stripeKey}
            </Typography>
            </Grid>
            </Grid>    
          </CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
            /*  justify="flex-start"
             alignItems="flex-start" */
            > 
              <Grid item xs={6}>
                <BootstrapTooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      setStripeEditMode(true);
                    }}
                    className={classes.button}
                    aria-label="Edit"
                  >
                    <EditIcon/>
                  </IconButton>
                </BootstrapTooltip>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    )
  }
  function imageDelete(e) {
    
    let items =deltedKey;
    if(items.length>0){
      for (let i = 0; i < items; i++) {
        if(items[i].FileKey!==e.FileKey)
        {
          items.push(e.FileKey);
        }   
      }
    }
    else
    {
      items.push(e.FileKey);
    }   
    Object.assign(deltedKey,items);   
    (e.Type==="PropertyImage")?  setProperty({...property,"PropertyImage":{}}):setProperty({...property,"PropertyLogo":{}});    
    

  }
  
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  function onChange(e,inputProps) {      
    
    if (e.target.name === "Country") {
      var selected=inputProps.props.alldata;   
      
      
      setcomboDisable(false);   
      setStateData(selected.states)
      setIsStateSelect(true);
      setProperty(prvproperty => {return {...prvproperty, "Country": e.target.value,"CurrencyName":selected.currency.name+ " ("+selected.currency.code+")","CurrencyCode":selected.currency.code}});
/*       setProperty({ ...property, [e.target.name]: e.target.value });
      setProperty({ ...property, ["CurrencyName"]: selected.currency.name});
      setProperty({ ...property, ["CurrencyCode"]: selected.currency.code}); */
    }   
    else if (e.target.name === "OmitTax") {
      setOmitTax(e.target.checked);
      //setProperty({ ...property, ["OmitTax"]: e.target.checked });
      if (e.target.checked) {
        //Object.assign(property,{"tax": 0 });
        
        setProperty(prvproperty => { return { ...prvproperty, "tax": 0, "OmitTax": true} });
      }
      else {
        setProperty(prvproperty => { return { ...prvproperty, "OmitTax": false } });
      }
    }
    else if (e.target.name === "IncludeStripeFee") {
      setIncludeStripeFee(e.target.checked);      
      if (e.target.checked) {        
        
        setProperty(prvproperty => { return { ...prvproperty,  "IncludeStripeFee": true} });
      }
      else {
        setProperty(prvproperty => { return { ...prvproperty, "IncludeStripeFee": false } });
      }
    }
    else if(e.target.name === "PropertyImage"||e.target.name ==="PropertyLogo")
    {      
      const target=e.target;        
      const file = target.files[0];     
      let targetName=target.name;
      var img = document.createElement("img");
      img.onload = function () {    
        if(targetName === "PropertyLogo" && this.width<=500 && this.height<=500 || (targetName === "PropertyImage" && this.width<=1000 && this.height<=500))
        {
         
          let fileName=file.name;
          let fileExt=getFileExtension(fileName);
          let s3Key=(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))+'.'+fileExt;
          const fileData={FileKey:s3Key,FileName:fileName};
          Storage.put(s3Key, file, {
              contentType:file.type 
          })
          .then (result =>{ 
            setProperty({ ...property, [targetName]: fileData}); 
          })
          .catch(err => console.log(err));
        }
        else
        {
          ( targetName === "PropertyImage") ?openSnackbar({ message: "Image size should be samaller than 1000X500 pixels", variant: "error" }):openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });                   
        }
      };
      var reader = new FileReader();
      reader.onloadend = function(ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL( target.files[0]);      
    }
    else {
      setProperty({ ...property, [e.target.name]: e.target.value });
    }

  }
  const handleClose = (e) => {
    if (e == "delete") {
      setDeleteOpen(false);
    }
    else {
      setpassOpen(false);
    }

  };
  const handleClickOpen = (e) => {
    setDeleteOpen(true);
  };
  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  function handleClick(event,data) {     
    props.onChildClick(event,data); // pass any argument to the callback
  }
  function validateForm() {
    
    return (
      property.PropertyName.length > 0 &&
      property.OwnerFirstName.length > 0 &&
      property.OwnerLastName.length > 0 &&
      property.Email.length > 0 &&
      property.PropertyUnit.length > 0 &&
      property.BusinessAddress.length > 0 &&
      property.City.length > 0 &&
      property.State.length > 0 &&
      property.Country.length > 0 &&
      property.PostalCode.length &&
      property.Phone.length > 0 &&
      (!stripeEditMode || (property.StripePublicKey && property.StripePublicKey.length > 0)) &&       
      (!stripeEditMode || (property.StripeSecretKey && property.StripeSecretKey.length > 0)) &&       
      (omitTax || (property.tax && property.tax>0) ) &&
      property.CurrencyName && 
      property.CurrencyName.length>0

    );
  }
  
   
  function handleClearForm() {
    document.getElementById("proeprty-form").reset();
  }

  async function deleteProperty() {     
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property, MasterType: 'Property',userData:props.userSession },
      queryStringParameters: {
        module: "property",
        op: "deleteProperty"
      }
    };    
    API.post(apiName, path, myInit).then(response => { 
      setIsLoading(false);
      openSnackbar({
        message:
          response.message,
        variant: "success"
      });

      handleClearForm();
      handleClick("Property grid");
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      openSnackbar({ message: err, variant: "error" });
    });

  }
   const delteFileFromS3=async (fileKeys)=>{
    fileKeys.forEach(async element => {
     await Storage.remove(element)
      .then(result =>{ console.log(result); })
      .catch(err => console.log(err));
     });
 

  }
  async function saveProperty(e) {
    e.preventDefault();
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property,userData:props.userSession,OmitTax:omitTax,IncludeStripeFee:includeStripeFee,MasterType: 'Property',UserName:props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "saveProperty"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        setIsLoading(false);
        if (response && response.success) {           
          await delteFileFromS3(deltedKey);
          openSnackbar({
            message:
              response.message,
            variant: "success"
          });
          handleClearForm();
          handleClick("Property grid", { "PropertyID": property.MasterID, "PropertyName": property.PropertyName });
        }
        else {
          openSnackbar({ message: response.message, variant: "error" });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: "error" });
        setIsLoading(false);
      });
  }

  return (
    <div className={classes.paper}>
      <Dialog
        open={deleteOpen}
        onClose={e => handleClose("delete")}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={e => handleClose("delete")}
        >
          Deletion Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We just want to make sure this is the correct decision. This is irreversible action and the data will be removed from our system immediately
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoaderCLickButton
            //size="large"
            onClick={e => deleteProperty()}
            isLoading={isLoading}           
          >
            YES
          </LoaderCLickButton>
        </DialogActions>
      </Dialog>
      <form
        id="proeprty-form"
        className={classes.form}
        noValidate
        onSubmit={saveProperty}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="PropertyName"
              label="PROPERTY NAME"
              onChange={onChange}
              value={property.PropertyName}
              name="PropertyName"
              autoComplete="name"
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="Email"
              value={property.Email}
              label="EMAIL"
              onChange={onChange}
              name="Email"
              autoComplete="property-Email"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="OwnerFirstName"
              value={property.OwnerFirstName}
              label="PROPERTY OWNER FIRSTNAME"
              onChange={onChange}
              name="OwnerFirstName"
              autoComplete="owner-FirstName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="OwnerLastName"
              value={property.OwnerLastName}
              label="PROPERTY OWNER LASTNAME"
              onChange={onChange}
              name="OwnerLastName"
              autoComplete="owner-LastName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="PropertyUnit"
              value={property.PropertyUnit}
              label="# OF UNITS"
              onChange={onChange}
              type="text"
              id="PropertyUnit"
              autoComplete="current-PropertyUnit"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="BusinessAddress"
              value={property.BusinessAddress}
              label="BUSINESS ADDRESS"
              onChange={onChange}
              id="BusinessAddress"
              autoComplete="current-address"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="City"
              value={property.City}
              label="CITY"
              onChange={onChange}
              id="City"
              autoComplete="current-City"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="PostalCode"
              value={property.PostalCode}
              label="ZIP/POSTAL CODE"
              onChange={onChange}
              id="PostalCode"
              autoComplete="current-PostalCode"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="select-state-label">
                    COUNTRY/REGION
                  </InputLabel>
                  <Select
                          labelid="select-Country-label"
                          id="Country-select-outlined"
                          fullWidth
                          variant="outlined"
                          name="Country"
                          label="COUNTRY/REGION"
                          value={property.Country}
                          labelWidth={labelWidth}
                          onChange={(e,alldata)=>{onChange(e,alldata);}}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {MapData.map((e, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="select-state-label">
                    STATE/PROVINCE
                  </InputLabel>
                  <Select
                    labelid="select-state-label"
                    id="state-select-outlined"
                    fullWidth
                    variant="outlined"
                    name="State"
                    label="STATE/PROVINCE"
                    value={property.State}
                    labelWidth={labelWidth}
                    disabled={comboDisable}
                    onChange={onChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {stateData.map((e, keyIndex) => {
                      return (
                        <MenuItem key={keyIndex} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          <Grid container direction="row"   alignItems="center" spacing={2}>
          <Grid item  xs={12} sm={12} md={6} lg={6}>          
          <CustomizedTooltips content={"Currency is based on coutry/region selection. Contact support if you want to change your currency"}/>         
          <InputLabel style={{color:"rgba(0, 0, 0, 0.87)"}} className={classes.wrapIcon} >         
            PROPERTY CURRENCY
          </InputLabel> 
          </Grid>    
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              variant="outlined"
              fullWidth
              id="CurrencyName"
              disabled={true}
              required={true}
              value={property.CurrencyName}
              label="PROPERTY CURRENCY"    
            />
          </Grid>          
          </Grid>  
          </Grid>           
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize} >
          <FormControlLabel
             label="OMIT TAX"
             control={<Checkbox             
             color="default"
             checked={omitTax}             
             name="OmitTax"
             onChange={onChange}
          /> }
           /> 
          </Grid>
          <Grid item xs={12} sm={8} md={inputSize} lg={inputSize} >
            <TextField
              variant="outlined"
              fullWidth
              id="tax"
              disabled={omitTax}
              required={!omitTax}
              value={property.tax}
              label="TAX %"
              onChange={onChange}
              name="tax"
              autoComplete="tax"
            />
          </Grid>         
          </Grid>  
          </Grid>  
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          {stripeEditMode?     
          <Grid container direction="column" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="StripePublicKey"
              value={property.StripePublicKey}
              label={editing?"NEW STRIPE PUBLIC ACCESS KEY":"STRIPE PUBLIC ACCESS KEY"}
              onChange={onChange}
              name="StripePublicKey"
              autoComplete="StripePublicKey"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="StripeSecretKey"
              value={property.StripeSecretKey}
              label={editing?"NEW STRIPE SECRET ACCESS KEY":"STRIPE SECRET ACCESS KEY"}
              onChange={onChange}
              name="StripeSecretKey"
              autoComplete="StripeSecretKey"
            />
          </Grid>
          </Grid>:
          <StripeDisplayCard stripeKey={property.DisplayStripeKey} inputSize={inputSize} />}
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              autoComplete="p-Phone"
              name="Phone"
              variant="outlined"
              required
              fullWidth
              id="Phone"
              value={property.Phone}
              label="PHONE #"
              onChange={onChange}
            />
          </Grid>    
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
            <TextField
              variant="outlined"
              fullWidth
              id="WebSite"
              value={property.WebSite}
              label="WEBSITE (optional)"
              onChange={onChange}
              name="WebSite"
              autoComplete="Website"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
          <Grid item  >
          <FormControlLabel
             label="YES, PASS PROCESSING FEE ON TO TENANTS"
             control={<Checkbox             
             color="default"
             checked={includeStripeFee}             
             name="IncludeStripeFee"
             onChange={onChange}
          /> }
           />               
          </Grid>
          <Grid item>
          <CustomizedTooltips content={"Stripe fee of 2.9% + 0.3 cents transaction"}/>               
          </Grid>         
          </Grid>  
          </Grid>
          {!props.fromRent? 
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          {property.PropertyLogo && property.PropertyLogo.hasOwnProperty("FileName")?
          <ImageComponent Type={"PropertyLogo"} FileKey={property.PropertyLogo.FileKey} FileName={property.PropertyLogo.FileName} /> 
           :
            <Grid container style={{paddingTop:"30px"}} direction="row" spacing={3}>
              <Grid item xs={1}>                
                <input name='PropertyLogo'  onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                <label htmlFor="icon-button-file1">
                  <Fab  size="small" color="secondary" aria-label="upload picture" component="span">
                    <AddIcon />
                  </Fab>
                </label>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6"> UPLOAD PROPERTY LOGO</Typography>
                <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
              </Grid>
            </Grid>}
          </Grid>
          : null}
           {!props.fromRent? 
          <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
          {property.PropertyImage && property.PropertyImage.hasOwnProperty("FileName")?
          <ImageComponent Type={"PropertyImage"}  FileKey={property.PropertyImage.FileKey} FileName={property.PropertyImage.FileName} /> 
             :
            <Grid container direction="row" spacing={3}>
              <Grid item xs={1}>
                <input name='PropertyImage'  onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file2" type="file" />
                <label htmlFor="icon-button-file2">
                  <Fab  size="small" color="secondary" aria-label="upload picture" component="span">
                    <AddIcon />
                  </Fab>
                </label>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6"> UPLOAD PROPERTY IMAGE</Typography>
                <Typography variant="caption">1000x500 - png, jpeg, gif</Typography>
              </Grid>
            </Grid>}
          </Grid>
          : null}
       </Grid>
        <div style={{ padding: "20px 0px 40px 0px" }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <LoaderButton
                block="true"
                type="submit"
                size="large"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
              {editing? "UPDATE PROPERTY":"SETUP A PROPERTY"}
              </LoaderButton>
            </Grid>
            {editing && !fromRent && (
              <Grid item>
                <Button
                  block="true"
                  style={{ backgroundColor: 'transparent' }}
                  size="large"
                  onClick={() => { handleClickOpen("delete") }}>
                  DELETE
              </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </form>
    </div>
  );
}
